import Proptypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCartNameValue } from '@redux/epic/product/cartDetail/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import AddToSalesOrderPopup from '../../BuyingOptions/BOPaymentInfo/AddToSalesOrderPopup';
import LoadingMask from '../../Share/LoadingMask';

const BundleBOAddToCartButton = ({ textFields, dataFields, onClickAddHandler, textDataFields }) => {
  const [isShow, setShow] = useState(false);
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId');
  const { isLoading } = useSelector((state) => state.cPStartDemoReducer);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleAddToCart = useCallback(() => {
    const cartName = getCartNameValue();
    if (isClientTelingUser) {
      if (cpCustomerId || cartName) {
        onClickAddHandler(dataFields);
      } else handleShowModal();
    } else onClickAddHandler(dataFields);
  }, [dataFields, cpCustomerId, getCartNameValue, onClickAddHandler]);

  return (
    <Fragment>
      <span className={'buying-option-header__btn__item btn btn-primary active'} onClick={handleAddToCart}>
        {textFields}
      </span>
      <AddToSalesOrderPopup isShow={isShow} onCloseHandler={handleCloseModal} ondataSources={textDataFields} />
      {isLoading ? <LoadingMask parent={BundleBOAddToCartButton} /> : <></>}
    </Fragment>
  );
};

BundleBOAddToCartButton.propTypes = {
  textFields: Proptypes.any,
  dataFields: Proptypes.any,
  onClickAddHandler: Proptypes.any,
  textDataFields: Proptypes.any
};

export default BundleBOAddToCartButton;
