import './SendOrderToCustomer.scss';

import axios from 'axios';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import { sendSaleOrder } from '@redux/actions/cpSendSaleOrder';
import { getCurrentSOCart, saveSOCart } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import config from '@temp/config';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE, DEFAULT_POS_COUNTRY_CODE, HK_SITE_NAME,MY_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import { isAtSiteName, useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import { useGetSOContactData } from './hooks';
import ItemCheckbox from './ItemCheckbox';
import SendOrderToCustomerModel from './Models/SendOrderToCustomerModel';
import SendOrderToCustomerModal from './SendOrderToCustomerModal/index';
import SendOrderToCustomerWarningModal from './SendOrderToCustomerWarningModal';
import { generateEmailArray, generateSmsArray } from './utils';

const SendOrderToCustomer = ({ fields }) => {
  const dispatch = useDispatch();
  // const contactData = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.json_facet);
  const externalId = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.externalId);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const dataSendSaleOrder = useSelector((state) => state?.cpSendSaleOrderReducer?.dataApi);
  const isSuccess = useSelector((state) => state?.cpSendSaleOrderReducer?.isSuccess);
  const isLoading = useSelector((state) => state?.cpSendSaleOrderReducer?.isLoading);
  const dataSOCart = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCart);
  const isLoadingSOCart = useSelector((state) => state?.productReducer?.cartDetailReducer?.isLoading);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const cpSessionId = SitecoreContextFactoryService.getValueContextItem('cpSessionId') || '';
  const cPStartDemoReducer = useSelector((state) => state.cPStartDemoReducer);
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);
  const isSaveSOSuccess = useSelector((state) => state?.productReducer?.cartDetailReducer?.isSaveSO);
  const isSavingSOCart = useSelector((state) => state?.productReducer?.cartDetailReducer?.isLoading);
  const isUpdateContactSuccess = useSelector((state) => state.clientTellingPortalCustomer.isUpdateContactSuccess);
  const isUpdateContactLoading = useSelector((state) => state.clientTellingPortalCustomer.isUpdateContactLoading);
  const formatDateUtil = useFormatDate();
  const isMySite = useIsAtSiteName(MY_SITE_NAME);
  const isHKSite = useIsAtSiteName(HK_SITE_NAME);
  const [layoutData, setLayoutData] = useState(null);
  const [isShow, setShow] = useState(false);
  const [quantityPackage, setQuantityPackage] = useState('');
  const [inputEnter, setInputEnter] = useState('');
  const [categoryEvent, setCategoryEvent] = useState(null);
  const [typeEvent, setTypeEvent] = useState(null);
  const [isWarningPopup, setWarningPopup] = useState(null);
  const [dataUpdateContact, setDataUpdateContact] = useState(null);
  const getSoContactDataService = useGetSOContactData();
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  const [contactData, setContactData] = useState(null);
  const [isFetchingContactData, setIsFetchingContactData] = useState(false);
  const [isSendingSaveSOContact, setIsSendingSaveSOContact] = useState(false);
  const [isNeedToReSaveSOCar, setIsNeedToReSaveSCart] = useState(false);
  const [contactDuplicate, setContactDuplicate] = useState(null);
  const [delayLoading, setDelayLoading] = useState(false);
  const fieldPhoneCode = useRef('');

  const renderID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  const enableSendEmail = useMemo(() => layoutData?.['Enable send by emails']?.value, [layoutData]);
  const enableSendSMS = useMemo(() => layoutData?.['Enable send by SMS']?.value, [layoutData]);

  const schema = Yup.object({
    firstName: Yup.string()
      .required(<Message messageCode='Msg_001.1' />)
      .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.1'])
      .trim(),
    // lastName: Yup.string()
    // .required(<Message messageCode='Msg_001.2' />)
    // .matches(Global.nameValidateRegex(), messageObj?.['Msg_001.2'])
    // .trim(),
    arrData: Yup.array()
      .of(
        Yup.object().shape({
          checked: Yup.boolean().required()
        })
      )
      .test('no-checked-true', layoutData?.['Required Selection']?.value, function (value) {
        return value.some((item) => item.checked === true && item.value);
      })
  });

  const isEmail = () => {
    if (contactData?.Email?.PreferredRealEmail?.SmtpAddress) {
      return contactData?.Email?.PreferredRealEmail?.SmtpAddress;
    }
  };

  const isSMS = () => {
    if (contactData?.PhoneNumbers?.PreferredRealPhoneNumber?.Number) {
      return contactData?.PhoneNumbers?.PreferredRealPhoneNumber?.Number;
    }
  };

  const accountFullName = useMemo(() => {
    const firstName = contactData?.Personal?.FirstName || '';
    const lastName = contactData?.Personal?.LastName || '';

    return getUserFullNameWithoutFormat({ firstName, lastName });
  }, [contactData]);

  const handleTitleCp = () => {
    if (layoutData && contactData) {
      const cartTitle = layoutData?.['Send sale order']?.value || '';
      const customerNameTitle = layoutData?.['Customer name text']?.value || '';

      // if (firstName || lastName) {
      // return `${cartTitle} ${firstName} ${lastName}`.trim();
      if (accountFullName) {
        return `${cartTitle} ${accountFullName}`.trim();
      } else {
        return `${cartTitle} ${customerNameTitle}`.trim();
      }
    }
  };

  const contactPhoneNumber = useMemo(() => {
    const phoneNumber = Global.splitPhoneNumberWithPOSCode(contactData?.PhoneNumbers?.PreferredRealPhoneNumber?.Number || '');
    const { value, code } = Global.splitPhoneNumberWithoutCode(true, phoneNumber, settingGlobal?.AreaCodes);
    const countryCodeRawValue = contactData?.PhoneNumbers?.PreferredRealPhoneNumber?.CountryCode || '';
    const countryCode = !DEFAULT_POS_COUNTRY_CODE.includes(countryCodeRawValue) ? countryCodeRawValue : '';
    let phoneNumberValue = '';

    if (phoneNumber) {
      if (!countryCode && !code) {
        phoneNumberValue = value || phoneNumber || '';
        if (isMySite && phoneNumberValue?.includes('+')) phoneNumberValue = phoneNumberValue?.replace('+', '');
        if (isHKSite && !phoneNumberValue?.includes('+')) phoneNumberValue = `+${phoneNumberValue}`;
      }
      let countryCodeValue = countryCode || code;
      if (countryCodeValue) {
        if (isMySite && countryCodeValue?.includes('+')) countryCodeValue = countryCodeValue?.replace('+', '') || '';
        if (isHKSite && !countryCodeValue?.includes('+')) countryCodeValue = `+${countryCodeValue}`;

        phoneNumberValue = `${countryCodeValue}${value || phoneNumber || phoneNumberValue}`;
      }
    }

    return { phoneNumberValue, rawValue: phoneNumber };
  }, [settingGlobal, contactData, isMySite, isHKSite]);

  const initialValues = useMemo(() => {
    let arrData = [
      {
        id: renderID(),
        category: layoutData?.WhatsApp?.value,
        value: '',
        checked: false
      },
      {
        id: renderID(),
        category: layoutData?.WeChat?.value,
        value: '',
        checked: false
      }
    ];
    if (enableSendEmail) {
      arrData = [
        {
          id: renderID(),
          category: layoutData?.Email?.value,
          value: contactData?.Email?.PreferredRealEmail?.SmtpAddress || '',
          checked: contactData?.Email?.PreferredRealEmail?.SmtpAddress ? true : false
        }, ...arrData
      ];
    }
    if (enableSendSMS) {
      arrData = [
        {
          id: renderID(),
          category: layoutData?.Sms?.value,
          value: contactPhoneNumber.phoneNumberValue,
          checked: !!contactPhoneNumber.rawValue
        }, ...arrData
      ];
    }

    return {
      firstName: accountFullName,
      // lastName: contactData?.Personal?.LastName || '',
      customerId: externalId || '',
      arrData
    };
  }, [contactData, layoutData, settingGlobal, enableSendEmail, enableSendSMS, contactPhoneNumber]);

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);

    setInputEnter('');
  };

  const handleUpdate = (item, event) => {
    onShowHandler();

    setCategoryEvent({
      item,
      event
    });

    setTypeEvent({
      category: item.category,
      error: false
    });
  };

  const changeDataArrData = (id, values) => {
    const newData = values?.arrData?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: !item.checked
        };
      } else {
        return item;
      }
    });

    return newData;
  };

  const handleRemove = (id, values) => {
    const type = values?.arrData.find((item) => item.id === id)?.category;
    const dataLength = values?.arrData.filter((item) => item.category === type)?.length;
    let newData = [];
    if (dataLength > 1) {
      newData = values.arrData?.filter((item) => item.id !== id);
    } else {
      const data = values.arrData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: ''
          };
        } else {
          return item;
        }
      });

      newData = data;
    }

    return newData;
  };

  const handleSave = (values) => {
    let newData = [];
    const dataLength = values?.arrData.filter((item) => item.category === categoryEvent.item.category)?.length;
    const inputEnterValue = categoryEvent.item.category === 'SMS' ? `${fieldPhoneCode.current || ''}${inputEnter}` : inputEnter;
    if (categoryEvent.event === 'add') {
      if (dataLength <= 1 && categoryEvent.item.value) {
        newData = values.arrData.concat({
          id: renderID(),
          category: categoryEvent.item.category,
          value: inputEnterValue,
          checked: false
        });
      } else {
        const data = values.arrData.map((item) => {
          if (item.id === categoryEvent.item.id) {
            return {
              ...item,
              value: inputEnterValue
            };
          } else {
            return item;
          }
        });

        newData = data;
      }
    } else if (categoryEvent.event === 'edit') {
      const data = values.arrData.map((item) => {
        if (item.id === categoryEvent.item.id) {
          return {
            ...item,
            value: inputEnterValue
          };
        } else {
          return item;
        }
      });

      newData = data;
    }

    setInputEnter('');

    setShow(false);

    return newData;
  };

  const handleUpdateContact = (setFieldValue, values) => {
    setIsSendingSaveSOContact(true);
    let formData = new window.FormData();
    const API_URL = `/api/osim/clientteling/sendso-save-contact?sc_apikey=${config.sitecoreApiKey}`;

    const emailArray = generateEmailArray(values, layoutData);

    const SMSArray = generateSmsArray(values, layoutData);

    const params = {
      ContactID: clientTellingPortalCustomer.customerInformation.ContactID || '',
      Email: emailArray[0]?.value || '',
      SecondaryEmail: emailArray[1]?.value || '',
      PhoneNumber: (isMySite ? SMSArray[0]?.value?.replace('+', '') : SMSArray[0]?.value) || '',
      SecondaryPhoneNumber: SMSArray[1]?.value || '',
      SaleOrderContactId: dataSOCart?.SaleOrderContactId
    };

    for (let keyParams in params) {
      formData.append(keyParams, params[keyParams]);
    }

    // Call check setFieldValue
    axios
      .post(API_URL, formData)
      .then((response) => {
        if (response.data?.contacts?.length < 1) {
          setFieldValue('arrData', values);
        } else {
          setWarningPopup(true);

          setContactDuplicate({
            isDuplicatedEmail: response.data.isDupplicatedEmail,
            isDuplicatedPhone: response.data.isDupplicatedPhone,
            SMSArray,
            emailArray
          });

          setDataUpdateContact(response.data.contacts);

          setIsNeedToReSaveSCart(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => setIsSendingSaveSOContact(false));
  };

  const handleCheckSave = (setFieldValue, values) => {
    if (typeEvent.category === layoutData?.Sms.value) {
      if (!inputEnter) {
        setShow(false);
      } else if (new RegExp(settingGlobal.PhoneNumberFormat).test(inputEnter)) {
        if (cpSessionId) {
          handleUpdateContact(setFieldValue, handleSave(values));
        } else {
          setFieldValue('arrData', handleSave(values));
        }
      } else {
        setTypeEvent((prev) => ({
          ...prev,
          error: true
        }));
      }
    } else if (typeEvent.category === layoutData?.Email.value) {
      if (!inputEnter) {
        setShow(false);
      } else if (new RegExp(emailRegex).test(inputEnter)) {
        if (cpSessionId) {
          handleUpdateContact(setFieldValue, handleSave(values));
        } else {
          setFieldValue('arrData', handleSave(values));
        }
      } else {
        setTypeEvent((prev) => ({
          ...prev,
          error: true
        }));
      }
    }
  };

  const handleSend = (values) => {
    const arrEmail = values.arrData.filter((item) => item.category === 'Email' && item.checked === true);
    const arrSMS = values.arrData.filter((item) => item.category === 'SMS' && item.checked === true);

    dispatch(
      sendSaleOrder({
        cartName: dataSOCart?.Name || '',
        customerId: values?.customerId,
        firstName: values?.firstName,
        // lastName: values?.lastName,
        sendEmail: values.arrData.some((item) => (item.category === 'Email' ? item.checked : '')),
        emailAddresses: `
        ${arrEmail?.[0]?.value || ''},
        ${arrEmail?.[1]?.value || ''},
        `,
        sendSms: values.arrData.some((item) => (item.category === 'SMS' ? item.checked : '')),
        phoneNumbers: `
        ${(isMySite ? arrSMS?.[0]?.value?.replace('+', '') : arrSMS?.[0]?.value) || ''},
        ${(isMySite ? arrSMS?.[1]?.value?.replace('+', '') : arrSMS?.[1]?.value) || ''},
        `
      })
    );
  };

  const handleFetchSOContactData = useCallback(() => {
    if (dataSOCart?.SaleOrderContactId) {
      setIsFetchingContactData(true);

      getSoContactDataService(dataSOCart.SaleOrderContactId)
        .then((response) => {
          if (response && response?.length) setContactData(response[0]?.json_facet);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsFetchingContactData(false));
    }
  }, [dataSOCart]);

  useEffect(() => {
    setLayoutData(new SendOrderToCustomerModel().getData(deepCopy(fields)));

    dispatch(getCurrentSOCart());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.location.href = `${layoutData['Send sale order link']?.value?.href}?cartName=${dataSendSaleOrder?.CartId}`;
    }
  }, [isSuccess, layoutData]);

  useEffect(() => {
    if (dataSOCart) {
      const countLineItems = (dataSOCart?.Lines || []).reduce((prev, next) => prev + Number(next?.Quantity ?? 0), 0);

      setQuantityPackage(countLineItems);
    }
  }, [dataSOCart]);

  useEffect(() => {
    // INFO: fetch customer data by SO Cart Contact Id
    handleFetchSOContactData();
  }, [dataSOCart]);

  useDidUpdateEffect(() => {
    const isStartSaveSOCart = isNeedToReSaveSOCar && cPStartDemoReducer.isStartDemoSuccess && cPStartDemoReducer.needRunSaveSOCartApi && dataSOCart;
    if (isStartSaveSOCart) {
      // INFO: show loading mask in time delay
      setDelayLoading(true);
      let cartStatusString = soCartStatuses[dataSOCart.CartStatus] || 'Unknown';
      const soCartExpiredDate = Global.convertJSONDateToNumber(dataSOCart.ExpiredDate);

      // INFO: delay 2.5s before save SO cart
      setTimeout(() => {
        dispatch(
          saveSOCart({
            ExpiredDate: Global.formartDateIOS8601UTC(soCartExpiredDate) || '',
            Status: cartStatusString
          })
        );
      }, 2500);
    }
  }, [cPStartDemoReducer, dataSOCart, isNeedToReSaveSOCar]);

  useDidUpdateEffect(() => {
    if (isSaveSOSuccess) window.location.reload();
  }, [isSaveSOSuccess]);

  useDidUpdateEffect(() => {
    if (isUpdateContactSuccess) handleFetchSOContactData();
  }, [isUpdateContactSuccess]);

  const isShowLoadingEl = useMemo(
    () => isLoading || isSendingSaveSOContact || isSavingSOCart || isUpdateContactLoading || isLoadingSOCart || isFetchingContactData || delayLoading,
    [isLoading, isSendingSaveSOContact, isSavingSOCart, isUpdateContactLoading, isLoadingSOCart, isFetchingContactData, delayLoading]
  );

  const currentCartStatusText = useMemo(() => {
    const status = dataSOCart?.CartStatus;

    return soCartStatuses?.[status] ?? '';
  }, [dataSOCart, soCartStatuses]);

  return layoutData ? (
    <>
      {!!dataSOCart?.CartStatus || !!dataSOCart?.SalesOrderId ? (
        <>
          <CPPageHeader title={handleTitleCp()} />
          <div className='send-order-customer'>
            <div className='container'>
              <div className='send-order-customer__wrapper'>
                <div className='send-order-customer__head'>
                  <h3 className='send-order-customer__head__title'>{layoutData?.Header?.value.replace('{orderId}', dataSOCart?.SalesOrderId)}</h3>
                  <p className='send-order-customer__head__info'>
                    {layoutData?.['Creation and Expiration text']?.value
                      .replace('{createDate}', formatDateUtil(dataSOCart?.CreatedDateWithFormat))
                      .replace('{expireDate}', formatDateUtil(dataSOCart?.ExpiredDateWithFormat))}
                  </p>
                  <p className='send-order-customer__head__info'>
                    {layoutData?.['Number of packages text']?.value.replace('{packagesNumber}', quantityPackage)}
                  </p>
                  <p className='send-order-customer__head__info'>{currentCartStatusText}</p>
                </div>
                <div className='send-order-customer__content'>
                  <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSend(values)} enableReinitialize={true}>
                    {({ errors, touched, handleChange, values, setFieldValue }) => {
                      return (
                        <Form className='send-order-customer__content__form'>
                          <div className='form-group'>
                            <input
                              id='firstName'
                              className={'form-control'}
                              name='firstName'
                              type='text'
                              onChange={contactData?.Personal?.FirstName ? undefined : handleChange}
                              value={values.firstName}
                              placeholder={layoutData?.['Firstname']?.value || 'Name'}
                              disabled={contactData?.Personal?.FirstName ? true : false}
                            />
                            <label htmlFor='firstName' className='form-group__label'>
                              <Text field={layoutData?.['Firstname']} />
                            </label>
                            {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                          </div>
                          {/* <div className='form-group'>
                            <input
                              id='lastName'
                              className={'form-control'}
                              name='lastName'
                              type='text'
                              onChange={contactData?.Personal?.LastName ? undefined : handleChange}
                              value={values.lastName}
                              placeholder={'LastName'}
                              disabled={contactData?.Personal?.LastName ? true : false}
                            />
                            <label htmlFor='lastName' className='form-group__label'>
                              <Text field={{ value: 'LastName' }} />
                            </label>
                            {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                          </div> */}
                          {enableSendEmail &&
                            values?.arrData
                              ?.filter((item) => item.category === layoutData?.Email?.value)
                              ?.map((item) => {
                                return (
                                  <div key={item.id} className='form-group form-group--checkbox'>
                                    <ItemCheckbox
                                      item={item}
                                      values={values}
                                      layoutData={layoutData}
                                      handleUpdate={handleUpdate}
                                      setFieldValue={setFieldValue}
                                      changeDataArrData={changeDataArrData}
                                      handleRemove={() => setFieldValue('arrData', handleRemove(item.id, values))}
                                      arrCheckbox={values.arrData.filter((item) => item.category === layoutData?.Email?.value)}
                                      isCheckFieldDefault={isEmail()}
                                      label={layoutData?.['EmailLabel']?.value}
                                    />
                                  </div>
                                );
                              })}
                          {enableSendSMS &&
                            values.arrData
                              ?.filter((item) => item.category === layoutData?.Sms?.value)
                              ?.map((item) => {
                                return (
                                  <div key={item.id} className='form-group form-group--checkbox'>
                                    <ItemCheckbox
                                      item={item}
                                      values={values}
                                      layoutData={layoutData}
                                      handleUpdate={handleUpdate}
                                      setFieldValue={setFieldValue}
                                      changeDataArrData={changeDataArrData}
                                      handleRemove={() => setFieldValue('arrData', handleRemove(item.id, values))}
                                      arrCheckbox={values.arrData.filter((item) => item.category === layoutData?.Sms?.value)}
                                      isCheckFieldDefault={isSMS()}
                                      label={layoutData?.['SmsLabel']?.value}
                                    />
                                  </div>
                                );
                              })}
                          {layoutData?.['Enable send by WhatsApp']?.value &&
                            values.arrData
                              ?.filter((item) => item.category === layoutData?.WhatsApp?.value)
                              ?.map((item) => {
                                return (
                                  <div key={item.id} className='form-group form-group--checkbox'>
                                    <ItemCheckbox
                                      item={item}
                                      values={values}
                                      layoutData={layoutData}
                                      handleUpdate={handleUpdate}
                                      setFieldValue={setFieldValue}
                                      changeDataArrData={changeDataArrData}
                                      handleRemove={() => setFieldValue('arrData', handleRemove(item.id, values))}
                                      arrCheckbox={values.arrData.filter((item) => item.category === layoutData?.WhatsApp?.value)}
                                      label={layoutData?.['WhatsAppLabel']?.value}
                                    />
                                  </div>
                                );
                              })}
                          {layoutData?.['Enable send by WeChat']?.value &&
                            values.arrData
                              ?.filter((item) => item.category === layoutData?.WeChat?.value)
                              ?.map((item) => {
                                return (
                                  <div key={item.id} className='form-group form-group--checkbox'>
                                    <ItemCheckbox
                                      item={item}
                                      values={values}
                                      layoutData={layoutData}
                                      handleUpdate={handleUpdate}
                                      setFieldValue={setFieldValue}
                                      changeDataArrData={changeDataArrData}
                                      handleRemove={() => setFieldValue('arrData', handleRemove(item.id, values))}
                                      arrCheckbox={values.arrData.filter((item) => item.category === layoutData?.WeChat?.value)}
                                      label={layoutData?.['WeChatLabel']?.value}
                                    />
                                  </div>
                                );
                              })}
                          {errors.arrData && touched.arrData && <span className='error-validate'>{errors.arrData}</span>}
                          <SendOrderToCustomerModal
                            isShow={isShow}
                            onCloseHandler={onCloseHandler}
                            onHandleSave={() => {
                              handleCheckSave(setFieldValue, values);
                            }}
                            onSetInputEnter={setInputEnter}
                            onLayoutData={layoutData}
                            onInputEnter={inputEnter}
                            typeEvent={typeEvent}
                            fieldPhoneCode={fieldPhoneCode}
                          />
                          <SendOrderToCustomerWarningModal
                            layoutData={layoutData}
                            isWarningPopup={isWarningPopup}
                            setWarningPopup={() => setWarningPopup(false)}
                            dataUpdateContact={dataUpdateContact}
                            contactDuplicate={contactDuplicate}
                            fieldPhoneCode={fieldPhoneCode}
                          />
                          <div className='form-btn'>
                            <button className='btn btn-primary form-btn__send' type='submit'>
                              <Text field={layoutData?.['Send sale order']} />
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className='send-order-customer__content__link-back'>
                    <Link field={layoutData?.['Back to sale cart link']} className='btn-link'>
                      <Text field={layoutData?.['Back to sale cart']} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {isShowLoadingEl ? <LoadingMask parent={SendOrderToCustomer} /> : <></>}
    </>
  ) : (
    <></>
  );
};

SendOrderToCustomer.propTypes = {
  fields: PropTypes.any
};

export default SendOrderToCustomer;
