import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import AddressSettingModel from '../../../SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';

const TEXT_ARR = [
  'Agree Terms And Conditions Message',
  'Fully Booked Message',
  'Input Valid Birth Date Message',
  'Input Valid Email Address Message',
  'Input Valid FirstName Message',
  'Input Valid LastName Message',
  'Input Valid Mobile Number Message',
  'Input Valid OTP Message',
  'Mobile Registered Message',
  'OTP Incorrect Message',
  'OTP Sent Message',
  'OTP Timeout Message',
  'OTP Verification Exceeded Message',
  'Select Answers Message',
  'Continue Guest Checkout Button Text',
  'Email Already Exists Label',
  'Email Already Exists Message',
  'Button Confirm Selection Text',
  'Select Scent Label',
  'Select Tea Label',
  'You Are Selecting Text',
  'Available Translation Text',
  'Button Schedule Appointment Text',
  'Date Time Booking',
  'Just Me Button Text',
  'Me Plus1 Placeholder Text',
  'Select Store Label',
  'Select Time Slot Label',
  'Selected Translation Text',
  'Not Available Translation Text',
  'Number Of Guests Label',
  'Schedule Appointment Title',
  'Select Date Label',
  'Enter OTP Placeholder Text',
  'Get OTP Button Text',
  'Update Mobile Number Message',
  'Proceed OTP Button Text',
  'Resend OTP Link Label',
  'Apply Filters Button Label',
  'Area Label',
  'Clear Filters Button Label',
  'Filter Stores Label',
  'No Store Satisfying The Selected Filters Label',
  'Please Enter Area Label',
  'Create Osim Account Checkbox',
  'Create Osim Account Text',
  'Email',
  'First Name',
  'Last Name',
  'PDPA Copy And Terms Checkbox',
  'PDPA Copy And Terms Text',
  'Phone Number',
  'Terms And Conditions Agreement Checkbox',
  'Terms And Conditions Agreement Text',
  'Book Experience Text',
  'Schedule Experience Title',
  'Button Schedule Experience Text',
  'Personalize Experience Title',
  'Selection Label',
  'Contact Details Title',
  'Date Of Birth Text',
  'Login Button Text',
  'Login Title',
  'Forgot Password Text',
  'Password Text',
  'Subtitle',
  'Subtitle After Login Text',
  'Title Update Appointment Modal',
  'Successfully Update Appointment Label',
  'Confirm Your Reschedule Label',
  'See You Soon Label',
  'Button Appointment Details Text',
  'Number Of Guest Text',
  'Select Store Error Message',
  'Select Date Error Message',
  'Select TimeSlot Error Message',
  'Select Guest Error Message'
];

const LINK_ARR = [
  'Button Confirm Selection Link',
  'Button Schedule Appointment Link',
  'Book Experience Link',
  'Forgot Password Link',
  'Button Appointment Details Link'
];

const IMG_ARR = [
  'Schedule Appointment Icon',
  'Personalize Experience Icon',
  'Contact Details Icon',
  'Product Icon',
  'Background Desktop',
  'Background Mobile',
  'Icon Successfully Change Schedule'
];

export default class ScheduleAppointmentComponentModel {
  constructor() {
    TEXT_ARR.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((itemText) => {
      this[itemText] = new SitecoreLinkModel();
    });

    IMG_ARR.forEach((itemText) => {
      this[itemText] = new SitecoreImageModel();
    });

    this['List Questions'] = [];

    this['Scents'] = [];

    this['Teas'] = [];

    this['questions'] = [];

    this['appointment'] = null;

    this['contact'] = null;

    this['Address Settings'] = new AddressSettingModel();

    this['block_day_booking'] = 0;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    LINK_ARR.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    IMG_ARR.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Questions')) {
      this['List Questions'] = deepCopy(dataSource['List Questions']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Scents')) {
      this['Scents'] = deepCopy(dataSource['Scents']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Teas')) {
      this['Teas'] = deepCopy(dataSource['Teas']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'questions')) {
      this['questions'] = deepCopy(dataSource['questions']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointment')) {
      this['appointment'] = deepCopy(dataSource['appointment']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'contact')) {
      this['contact'] = deepCopy(dataSource['contact']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {}, true);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'block_day_booking')) {
      this['block_day_booking'] = dataSource['block_day_booking'];
    }

    return this;
  }
}
