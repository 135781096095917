import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import ModalComponent from '@components/Share/ModalComponent';
import { UpdateBookingExperienceStatus } from '@redux/epic/bookingExperience/updateBookingExperience/responseStatus';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import Global from '../../../../utils/global';
import { useScheduleAppointmentContext } from '../hooks/useScheduleAppointmentContext';

const UpdateAppointmentSuccessPopup = () => {
  const { updateBookingExperienceResponse } = useSelector((state) => state.bookingExperienceReducer.updateBookingExperienceReducer);
  const { layoutData } = useScheduleAppointmentContext();
  const formatDate = useFormatDate();

  const appointmentId = useMemo(() => {
    return updateBookingExperienceResponse?.Result?.Id;
  }, [updateBookingExperienceResponse]);

  const updateResponse = useMemo(() => {
    return updateBookingExperienceResponse?.Result;
  }, [updateBookingExperienceResponse]);

  const updateAppointmentSuccess = useMemo(() => {
    return (
      updateBookingExperienceResponse &&
      UpdateBookingExperienceStatus.SuccessStatusCode.includes(updateBookingExperienceResponse?.StatusCode) &&
      updateBookingExperienceResponse?.Result
    );
  }, [updateBookingExperienceResponse]);

  const numberOfGuestValue = useMemo(() => {
    const numberOfGuestText = layoutData?.['Number Of Guest Text']?.value;

    return numberOfGuestText?.replace('{numberOfGuest}', updateResponse?.NumberOfGuests);
  }, [layoutData, updateResponse]);

  const appointmentDetailLink = useMemo(() => {
    return `${layoutData?.['Button Appointment Details Link']?.value?.href}?appointment_id=${appointmentId}`;
  }, [layoutData, appointmentId]);

  const handleViewAppointmentDetail = useCallback(() => {
    window.location.href = appointmentDetailLink;
  }, [updateBookingExperienceResponse]);

  return updateAppointmentSuccess && appointmentId ? (
    <>
      <ModalComponent
        isShow={true}
        onCloseHandler={handleViewAppointmentDetail}
        titlePopup={layoutData?.['Title Update Appointment Modal']?.value}
        cssClass='osim-popup--no-padding'
      >
        <div className='appointment-detail__header__content__wrap__cancel-modal'>
          <div className='change-schedule-modal'>
            <div className='change-schedule-modal__background'>
              {/*<div className='appointment-detail__header__background'>*/}
              <ResponsiveImage 
                imageMobile={layoutData['Background Mobile']}
                srcSet={[{ mw: 768, image: layoutData['Background Desktop'] }]}
              />
              {/*</div>*/}
            </div>
            <div className='change-schedule-modal__content'>
              <div className='container change-schedule-modal__content__container'>
                <div className='change-schedule-modal__content__wrap'>
                  <Image field={layoutData?.['Icon Successfully Change Schedule']} className='change-schedule-modal__content__wrap__icon' />
                  <Text
                    field={layoutData?.['Successfully Update Appointment Label']}
                    tag='h2'
                    className='change-schedule-modal__content__wrap__title'
                  />
                  {updateResponse?.ProductName ? (
                    <span className='change-schedule-modal__content__wrap__uid'>{updateResponse?.ProductName}</span>
                  ) : (
                    <></>
                  )}
                  <div className='change-schedule-modal__content__wrap__time'>
                    <span>{formatDate(updateResponse?.Date)}</span>
                    <span>{updateResponse?.TimeSlotStr}</span>
                    <span>{numberOfGuestValue}</span>
                    {/*<Text field={{ value: `${result?.NumberOfGuests} Guest(s)` }} tag='span' />*/}
                  </div>
                  <div className='change-schedule-modal__content__wrap__address'>
                    <h3 className='change-schedule-modal__content__wrap__address__title'>{updateResponse?.Store?.Name}</h3>
                    <p className='change-schedule-modal__content__wrap__address__description'>{updateResponse?.Store?.Address?.Address}</p>
                    <p className='change-schedule-modal__content__wrap__address__description'>{updateResponse?.Store?.Address?.PostalCode}</p>
                    {updateResponse?.Store?.PhoneNumber ? (
                      <p className='change-schedule-modal__content__wrap__address__description'>+{updateResponse?.Store?.PhoneNumber}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Text field={layoutData?.['Confirm Your Reschedule Label']} tag='div' className='change-schedule-modal__content__wrap__excerpts' />
                  <Text field={layoutData?.['See You Soon Label']} tag='p' className='change-schedule-modal__content__wrap__text' />
                  <Link field={{ href: appointmentDetailLink }} className='change-schedule-modal__content__wrap__link-view'>
                    <Text field={layoutData?.['Button Appointment Details Text']} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};

export default UpdateAppointmentSuccessPopup;
