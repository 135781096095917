import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getAppliedCouponList } from '@redux/actions/coupon';
import { getClientSOCartStart } from '@redux/actions/personalizeOffer/getClientSOCart';
import { getCurrentCart } from '@redux/actions/product';
import { postAsObservable } from '@services/genericService';
import { useQuery } from '@utils/customsHook/useQuery';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';

const endpoint = '/Checkout/UpdatePaymentOptionFreeGiftPopup';

export const useUpdatePaymentOption = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const clientSOCartId = useMemo(() => {
    return query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);
  }, [query]);

  return useCallback(
    (paymentOptionId, paymentName, policyId = '', isInstallment = false, installmentPeriod = '', bankCode = '') => {
      const params = {
        PaymentOptionId: paymentOptionId,
        PolicyId: policyId,
        PaymentName: paymentName,
        Installment: isInstallment,
        InstallmentPeriod: installmentPeriod,
        BankCode: bankCode
      };
      if (clientSOCartId) params.CartId = clientSOCartId;

      return new Promise((resolve, reject) => {
        return from(postAsObservable(endpoint, params))
          .pipe(
            map((res) => {
              if (res.status === 200 || res.statusText === 'OK') {
                if (clientSOCartId) {
                  dispatch(getClientSOCartStart({ cartId: clientSOCartId }));
                } else {
                  dispatch(getCurrentCart());
                }

                dispatch(getAppliedCouponList());

                resolve(res.data);
              }
            }),
            catchError((error) => reject(error || 'Failed'))
          )
          .subscribe();
      });
    },
    [clientSOCartId]
  );
};

export const useGetCurrentPaymentOptions = () => {
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  return useMemo(() => {
    const osimSelectedPaymentOption = currentCart?.OsimSelectedPaymentOption;
    const paymentOptionIdFromApi = osimSelectedPaymentOption?.PaymentOptionId || '';
    const paymentNameFromApi = osimSelectedPaymentOption?.PaymentName || '';
    const policyIdFromApi = osimSelectedPaymentOption?.PolicyId || '';
    const installmentPeriodFromApi = osimSelectedPaymentOption?.InstallmentPeriod || '';

    return {
      osimSelectedPaymentOption,
      paymentOptionIdFromApi,
      paymentNameFromApi,
      policyIdFromApi,
      installmentPeriodFromApi
    };
  }, [currentCart]);
};

export const useCheckSelectedPayment = () => {
  const currentPaymentOptions = useGetCurrentPaymentOptions();

  return useCallback(
    (item, installmentPeriod) => {
      const {
        paymentNameFromApi,
        policyIdFromApi,
        installmentPeriodFromApi,
        paymentOptionIdFromApi
      } = currentPaymentOptions;

      return (
        (item?.PaymentGatewayId || '') === paymentOptionIdFromApi &&
        (item?.PaymentName || '') === paymentNameFromApi &&
        (item?.PolicyId || '') === policyIdFromApi &&
        (installmentPeriod || '') === installmentPeriodFromApi
      );
    },
    [currentPaymentOptions]
  );
};

export const useSetPaymentDefault = () => {
  const updatePaymentOptionService = useUpdatePaymentOption();
  const checkSelectedPaymentService = useCheckSelectedPayment();
  const { installmentPeriodFromApi } = useGetCurrentPaymentOptions();
  const paymentData = useSelector((state) => state.checkoutProcesstReducer.paymentReducer.paymentData);

  const payInFulls = useMemo(() => {
    return paymentData?.PayInFulls || [];
  }, [paymentData]);

  const payInInstallments = useMemo(() => {
    return paymentData?.PayInInstallments || [];
  }, [paymentData]);

  return useCallback(
    async ({
      selectedPaymentMethod,
      selectedInstallment,
      selectedBankCode,
      selectedPaymentMethodCode,
      selectedPaymentPolicyId
    }) => {
      if (payInFulls.length || payInInstallments.length) {
        let paymentSelected = {
          ...payInFulls.find(
            (paymentItem) =>
              (selectedPaymentMethod === paymentItem.PaymentGatewayId &&
                paymentItem.PaymentMethodCode === selectedPaymentMethodCode &&
                selectedPaymentPolicyId === paymentItem.PolicyId) ||
              checkSelectedPaymentService(paymentItem, paymentItem?.InstallmentPeriod)
          ),
          isInstallment: false
        };
        let installmentPeriod = '';

        if (Object.keys(paymentSelected).length <= 1) {
          paymentSelected = {
            ...payInInstallments.find((paymentItem) => {
              if (paymentItem.InstallmentBanks.length) {
                return (
                  (selectedPaymentMethod === paymentItem.PaymentGatewayId &&
                    selectedPaymentPolicyId === paymentItem.PolicyId &&
                    paymentItem.InstallmentBanks.find((bankItem) => bankItem.InstallmentBankCode === selectedBankCode)) ||
                  checkSelectedPaymentService(paymentItem, paymentItem?.InstallmentPeriod)
                );
              }
              if (paymentItem.InstallmentPeriods.length) {
                const installmentPeriodItem = paymentItem.InstallmentPeriods.find(
                  (item) => selectedInstallment === item.InstallmentPeriod || installmentPeriodFromApi === item.InstallmentPeriod
                );
                if (installmentPeriodItem) installmentPeriod = installmentPeriodItem?.InstallmentPeriod;

                return (
                  (selectedPaymentMethod === paymentItem.PaymentGatewayId &&
                    installmentPeriodItem &&
                    selectedPaymentPolicyId === paymentItem.PolicyId) ||
                  checkSelectedPaymentService(paymentItem, installmentPeriodItem?.InstallmentPeriod)
                );
              }
            }),
            isInstallment: true
          };
        }

        if (paymentSelected && Object.keys(paymentSelected).length > 1) {
          try {
            await updatePaymentOptionService(
              paymentSelected?.PaymentGatewayId || '',
              paymentSelected?.PaymentGatewayText || '',
              paymentSelected?.PolicyId || '',
              paymentSelected.isInstallment || false,
              installmentPeriod,
              selectedBankCode
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    [payInFulls, payInInstallments, installmentPeriodFromApi]
  );
};
