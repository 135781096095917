import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import LoginForm from './LoginForm';

const LoginModal = (props) => {
  const { isOpenModal, onClose } = props;
  const { layoutData } = useScheduleAppointmentContext();
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const isLoading = useSelector((state) => state.singleSignOnReducer.loginReducer?.isLoading);

  useDidUpdateEffect(() => {
    if (isLoginSuccess && onClose) onClose();
  }, [isLoginSuccess, onClose]);

  return (
    <>
      <ModalComponent isShow={isOpenModal} onCloseHandler={onClose} titlePopup={layoutData?.['Login Title']?.value}>
        <div className='login-modal-form'>
          <LoginForm />
        </div>
      </ModalComponent>
      {isLoading ? <LoadingMask parent={LoginModal} /> : <></>}
    </>
  );
};

LoginModal.propTypes = {
  isOpenModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default LoginModal;
