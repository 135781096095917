import { object } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { SG_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';

import MyRewardWidgetLayout from '../MyRewardWidgetLayout';

const IsNotLoyaltyLeftSideInfo = ({ layoutData }) => {
  return (
    <div className='my-reward-widget__content-left'>
      <Text tag='h5' field={layoutData?.['Not Member Label']} />
      <RichText tag='h5' field={layoutData?.['Become Member Text']} />
    </div>
  );
};

const IsNotMemberLoyalty = ({ layoutData }) => {
  // const { myRewardInfo } = useSelector((state) => state.loyaltyReducer.getMyRewardWidgetInfoReducer);
  const dataCrm = useSelector((state) => state.loyaltyReducer.getCrmCustomerReducer.dataCrm);
  const isAtSGSite = useIsAtSiteName(SG_SITE_NAME);
  const dataReward = useMemo(() => dataCrm?.Data, [dataCrm]);

  const leftSideElement = useMemo(
    () => !isAtSGSite ? <IsNotLoyaltyLeftSideInfo layoutData={layoutData} /> : null,
    [isAtSGSite]
  );

  return (
    <MyRewardWidgetLayout
      layoutData={layoutData} hideLeftSide={true}
      leftSide={leftSideElement}
      tierText={layoutData?.['OSIM Member Text']?.value}
      rewardIcon={layoutData?.['User Icon']?.value?.src || ''}
      memberId={dataReward?.customer_id}
    >
      {isAtSGSite ? <Text tag='h5' field={layoutData?.['Not Member Label']} className='content' /> : <></>}
      <Text
        tag='h5'
        field={layoutData?.['Spend Member Label']}
        className='content'
      />
      {isAtSGSite ? (
        <RichText
          tag='h5'
          field={layoutData?.['Tier Status Update Time Text']}
          className='content'
        />
      ) : (
        <></>
      )}
      <RichText tag='h5' field={layoutData['See Benefits']} />
    </MyRewardWidgetLayout>
  );
};

IsNotMemberLoyalty.propTypes = {
  layoutData: object
};

IsNotLoyaltyLeftSideInfo.propTypes = {
  layoutData: object
};

export default IsNotMemberLoyalty;
