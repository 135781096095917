import LinkModelWithTitle from '@models/LinkModelWithTitle';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ChangePasswordModel {
  constructor() {
    this['Current Password Label'] = new SitecoreTextModel();

    this['New Password Label'] = new SitecoreTextModel();

    this['Retype Password Label'] = new SitecoreTextModel();

    this['Change Password Message'] = new SitecoreTextModel();

    this['Successful Message'] = new SitecoreTextModel();

    this['Save Change Text'] = new SitecoreTextModel();

    this['Back To Account Link'] = new LinkModelWithTitle();

    this['Remind Change Password Message'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'New Password Label')) {
      this['New Password Label'].getData(dataSource['New Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Current Password Label')) {
      this['Current Password Label'].getData(dataSource['Current Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Password Message')) {
      this['Change Password Message'].getData(dataSource['Change Password Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Retype Password Label')) {
      this['Retype Password Label'].getData(dataSource['Retype Password Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Successful Message')) {
      this['Successful Message'].getData(dataSource['Successful Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Save Change Text')) {
      this['Save Change Text'].getData(dataSource['Save Change Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back To Account Link')) {
      this['Back To Account Link'].getData(dataSource['Back To Account Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remind Change Password Message')) {
      this['Remind Change Password Message'].getData(dataSource['Remind Change Password Message']);
    }

    return this;
  }
}
