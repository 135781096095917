import { element, string } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking';
import { mediaTrackingGoalSelector } from '@redux/reducers/mediaTrackingGoal/selector';
import { MEDIA_NAME_IS_ALLOWED_TRIGGER_GOAL } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const DownloadButton = ({
  children,
  href,
  ...props
}) => {
  const dispatch = useDispatch();

  const {
    success,
    isLoading
  } = useSelector(mediaTrackingGoalSelector);

  useDidUpdateEffect(() => {
    if (typeof window !== 'undefined' && !isLoading && success && href) {
      window.location.href = href;
    }
  }, [isLoading, success, href]);

  const handleTriggerDownloadGoal = useCallback((event) => {
    if (MEDIA_NAME_IS_ALLOWED_TRIGGER_GOAL.test(href)) {
      event.preventDefault();

      dispatch(mediaStartTrackingGoal({
        'GoalId': MEDIA_TRACKING_GOAL_ID,
        'UseTrackerAPI': false
      }));
    }
  }, [href]);

  return <a {...props} href={href} onClick={handleTriggerDownloadGoal}>{children}</a>;
};

DownloadButton.propTypes = {
  children: element,
  href: string
};

export default DownloadButton;
