import './MemberEventChange.scss';

import { useFormik } from 'formik';
import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { rescheduleEventBookingStart } from '@redux/actions/eventBooking';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import LoadingMask from '../../Share/LoadingMask';
import LocationForm from '../EventRegistration/LocationForm';
import { handleGenerateDateSlot } from '../utils';
import MemberEventChangeModel from './models/MemberEventChangeModel';

const MemberEventChange = ({ fields }) => {
  const dispatch = useDispatch();
  const { isLoading, rescheduleResponse, error } = useSelector((state) => state.eventBookingReducer.eventBookingRescheduleReducer);
  const [layoutData, setLayoutData] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = useState(null);
  const RESCHEDULE_SUCCESS_PAGE_PATH = '/my-account/Member%20Events/Rescheduled%20Successfully?eventId=';

  const errorMessage = useMemo(() => {
    if (layoutData?.['Field Required Message Text']?.value) {
      return layoutData?.['Field Required Message Text']?.value;
    }
  }, [layoutData?.['Field Required Message Text']?.value]);

  useEffect(() => {
    let model = new MemberEventChangeModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    if (rescheduleResponse && rescheduleResponse?.Success) {
      window.location.href = `${RESCHEDULE_SUCCESS_PAGE_PATH}${layoutData?.['EventDetails']?.EventId}`;
    }
  }, [rescheduleResponse, rescheduleResponse?.Success]);

  const questionAndAnswerInitValue = useMemo(() => {
    const qaValues = [];
    const qaValuesSelected = [];

    if (layoutData?.['Answers Id']?.length) {
      for (let qa of layoutData?.['Answers Id']) {
        qaValuesSelected.push({
          answerId: qa.split(':')?.[1],
          questionId: qa.split(':')?.[0]
        });
      }
    }

    if (layoutData?.EventDetails?.['Question and Answer']?.length) {
      for (let qa of layoutData?.EventDetails?.['Question and Answer']) {
        qaValues.push({
          answerId: qaValuesSelected.find((item) => item.questionId === qa.Id)?.answerId,
          questionId: qa.Id
        });
      }
    }

    return qaValues;
  }, [layoutData?.EventDetails, layoutData?.['Answers Id']]);

  const dateSlot = useMemo(() => {
    return handleGenerateDateSlot(layoutData?.['EventDetails']?.Location, layoutData?.['Location Selected']?.value);
  }, [layoutData?.['EventDetails']?.Location, layoutData?.['Location Selected']?.value]);

  const initialValues = useMemo(() => {
    const dateSlotSelected = dateSlot?.find((date) => date?.['Event Date Format 1'] === layoutData?.['Date Selected']?.value);

    if (layoutData) {
      return {
        location: { id: layoutData?.['Location Selected']?.value, title: layoutData?.['Location Title']?.value },
        preferredDate: {
          value: layoutData?.['Date Selected']?.value,
          dateSlotId: dateSlotSelected?.Id?.replace(/{|}/g, '')
        },
        preferredTimeSlot: {
          timeSlotId: layoutData?.['Time SlotId Chosed']?.value,
          value: dateSlotSelected?.['Time Slots'].find((timeSlot) => timeSlot.Id === layoutData?.['Time SlotId Chosed']?.value)?.Time
        },
        numberOfGuest: String(layoutData?.['Number Of Guest']),
        questionAndAnswer: questionAndAnswerInitValue
      };
    }
  }, [layoutData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      location: Yup.object()
        .shape({ 
          id: Yup.string().required(errorMessage), 
          title: Yup.string().required(errorMessage) 
        }),
      preferredDate: Yup.object()
        .shape({ 
          value: Yup.string().required(errorMessage),
          dateSlotId: Yup.string().required(errorMessage) 
        }),
      preferredTimeSlot: Yup.object()
        .shape({ 
          value: Yup.string().required(errorMessage), 
          timeSlotId: Yup.string().required(errorMessage) 
        }),
      numberOfGuest: Yup.string().required(errorMessage),
      questionAndAnswer: Yup.array().of(
        Yup.object().shape({
          answerId: Yup.string().required(errorMessage)
        })
      )
    }),
    onSubmit: (values) => {
      const answersId = values.questionAndAnswer.map((answer) => `${answer.questionId}:${answer.answerId}`).join(',');

      const rescheduleEventData = {
        CustomerId: SitecoreContextFactoryService.getValueContextItem('CustomerId'),
        UserName: SitecoreContextFactoryService.getValueContextItem('user')?.name,
        Email: layoutData?.['Email ']?.value,
        EventId: layoutData?.['EventDetails']?.EventId,
        EventName: layoutData?.['EventDetails']?.Title,
        LocationId: values.location.id,
        LocationTitle: values.location.title,
        DateSlotId: values.preferredDate.dateSlotId,
        EventDate: global.customDate(values.preferredDate.value),
        TimeSlotId: values.preferredTimeSlot.timeSlotId,
        EventTime: values.preferredTimeSlot.value,
        NumberOfGuest: values.numberOfGuest,
        OldNumberOfGuest: layoutData?.['Number Of Guest'],
        SubmitedDate: layoutData?.['SubmitedDate'],
        AnswersId: answersId,
        FirstName: layoutData?.['FirstName']?.value,
        LastName: layoutData?.['LastName']?.value,
        PhoneNumber: layoutData?.['PhoneNumber']?.value,
      };

      dispatch(rescheduleEventBookingStart(rescheduleEventData));
    }
  });

  useEffect(() => {
    if (initialValues && formik.values) {
      JSON.stringify(initialValues) === JSON.stringify(formik.values) ? setIsDisableBtn(true) : setIsDisableBtn(false);
    }
  }, [initialValues, formik.values]);

  return layoutData ? (
    <div className='container member-event-change'>
      <form onSubmit={formik.handleSubmit} className='event-registration__form'>
        <LocationForm
          layoutData={layoutData}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
          touched={formik.touched}
          errors={formik.errors}
        />
        <button className={`btn btn-primary ${isDisableBtn ? 'disabled' : ''}`} type='submit'>
          <Text field={layoutData['Change Button Text']} />
        </button>
        {error ? <span className='error-validate'>{error}</span> : <></>}
        <Link field={{ href: layoutData['Cancel Button Link'].value }} className='btn btn-link btn-back'>
          <Text field={layoutData['Cancel Button Text']} />
        </Link>
      </form>
      {isLoading ? <LoadingMask parent={MemberEventChange} /> : <></>}
    </div>
  ) : (
    <></>
  );
};

MemberEventChange.propTypes = {
  fields: object
};

export default MemberEventChange;
