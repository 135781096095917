import './TierBenefit.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import { formatTierAmountNumber } from '../MyRewardsWidget/utils';
import TierBenefitModel from './model/TierBenefitModel';

const TierBenefit = ({ fields }) => {
  const dataTiers = useSelector((state) => state.loyaltyReducer?.getCrmCustomerReducer?.dataCrm?.Data);
  const [dataSource, setDataSources] = useState(null);

  useEffect(() => {
    setDataSources(new TierBenefitModel().getData(fields));
  }, []);

  const unlockNextTierText = useMemo(() => {
    return dataSource?.['New Tier']?.value?.replace('{Tier}', dataTiers?.crm_next_tier);
  }, [dataTiers]);

  const spendBenefitsText = useMemo(() => {
    const crmAmountNextTier = Number(dataTiers?.crm_amt_next_tier || 0);
    const crmToFixed = global.roundUpDecimal(crmAmountNextTier);

    return dataSource?.['Spend Benefits Label']?.value?.replace('{money}', formatTierAmountNumber(crmToFixed));
  }, [dataTiers]);

  return dataSource ? (
    <div className='container my-reward__box tier-benefit'>
      <h3 className='my-reward__box-title tier-benefit__title'>{unlockNextTierText}</h3>
      {dataTiers?.crm_tier !== 'DIAMOND' ? <p className='tier-benefit__info'>{spendBenefitsText}</p> : <></>}
      <ul className='tier-benefit__list'>
        <div className='row'>
          {dataSource.Tiers.map((tier) => {
            if (tier?.['Display Text']?.value === dataTiers?.crm_next_tier) {
              return tier.Benefits.map((item, index) => {
                return (
                  <li key={index} className='col-lg-6 tier-benefit__list__item'>
                    <Image field={item?.Icon} className='tier-benefit__list__item__image' />
                    <Text field={item.Content} tag='span' className='tier-benefit__list__item__text' />
                  </li>
                );
              });
            }
          })}
        </div>
      </ul>
      <div className='tier-benefit__button'>
        <Link field={dataSource?.['Button Link']} className='btn btn-outline-primary'>
          <Text field={dataSource?.['Button Text']} />
        </Link>
      </div>
    </div>
  ) : (
    <></>
  );
};

TierBenefit.propTypes = {
  fields: PropTypes.object
};

export default TierBenefit;
