import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import CountdownTimerResend from '@components/Share/CountdownTimerResend';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { changeEmail, verificationCodeEmail } from '@redux/actions/myAccount';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const ChangeEmail = ({ dataSources, isChangeEmail, errMessage, isLoading }) => {
  const dispatch = useDispatch();
  const [sendCode, setsendCode] = useState(true);

  const formik = useFormik({
    initialValues: {
      email: '',
      codeVerify: ''
    },
    validationSchema: Yup.object({
      codeVerify: Yup.string().required(<Message messageCode='Msg_002.8' />),
      email: Yup.string()
        .required(<Message messageCode='Msg_001.3' />)
        .email(<Message messageCode='Msg_001.3' />)
    }),
    onSubmit: (values) => {
      dispatch(
        changeEmail({
          email: values.email,
          code: values.codeVerify,
          changeEmail: true
        })
      );
    },
    validateOnMount: true
  });

  const onSendCodeHandler = () => {
    dispatch(verificationCodeEmail({ email: formik.values.email }));

    setsendCode(false);
  };

  useEffect(() => {
    if(isChangeEmail) {
      window.location.href = dataSources['Change Email Success Page'].value.href;
    }
  },[isChangeEmail]);

  return (
    <div className='contact-info'>
      <div className='container'>
        <div className='contact-info__wrap'>
          <form onSubmit={formik.handleSubmit} className='contact-info__form'>
            {isChangeEmail ? <span className='message-successful text-center'><Message messageCode='Msg_002.5'/></span> : ''}
            {errMessage ? <span className='error-message text-center'>{errMessage}</span> : ''}
            <div className='form-group'>
              <input
                onChange={formik.handleChange}
                value={formik.values.email}
                type='text'
                id='email'
                placeholder={dataSources['New Email Label'].value}
                className='form-control form-control-lg'
                name='email'
              />
              {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
            </div>
            <div className='form-group'>
              <input
                type='text'
                id='codeVerify'
                onChange={formik.handleChange}
                value={formik.values.codeVerify}
                className='form-control form-control-lg'
                placeholder={dataSources['Verify Email Code Placeholder'].value}
                name='codeVerify'
              />
              {formik.errors.codeVerify && formik.touched.codeVerify && <span className='error-validate'>{formik.errors.codeVerify}</span>}
            </div>
            <div className='contact-info__btn text-center'>
              <div className='contact-info__btn__item'>
                {sendCode ? (
                  <button type='button' className={!formik.errors.email && formik.values.email ? 'btn btn-outline-CTA2' : 'btn btn-outline-CTA2 btn-disable'} onClick={onSendCodeHandler}>
                    <Text field={dataSources['Send Code Text']} />
                  </button>
                ) : (
                  <button type='button' className='btn btn-outline-CTA2 btn-disable'>
                    <Text field={dataSources['Send Code Text']} />
                    <CountdownTimerResend timmer={60} setEndTimer={() => setsendCode(true)} />
                  </button>
                )}
              </div>
              <div className='contact-info__btn__item'>
                {sendCode ?
                  <button type='button' className='btn btn-outline-CTA1 btn-disable'>
                    <Text field={dataSources['Save Change Button Text']} />
                  </button> : 
                  <button type='submit' className='btn btn-outline-CTA1'>
                    <Text field={dataSources['Save Change Button Text']} />
                  </button>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading ? <LoadingMask parent={ChangeEmail}/> : ''}
    </div>
  );
};

ChangeEmail.propTypes = {
  emailUser: PropTypes.string,
  dataSources: PropTypes.object,
  setInputNewEmailEvt: PropTypes.func,
  errMessage: PropTypes.string,
  isChangeEmail: PropTypes.bool,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isChangeEmail: state.singleSignOnReducer.forgotEmailReducer.isChangeEmail,
  errMessage: state.singleSignOnReducer.forgotEmailReducer.errMessage,
  isLoading: state.singleSignOnReducer.forgotEmailReducer.isLoading
});

export default connect(mapStateToProps)(ChangeEmail);
