import { element, object, string } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const MyRewardWidgetLayout = ({ children, layoutData, rewardIcon, tierText, memberId, leftSide }) => {
  return (
    <div className='my-account-section__content my-reward-widget__content'>
      {!leftSide ? (
        <div className='my-reward-widget__content-left'>
          <div className='reward-icon'>
            <div className='reward-icon__container'>
              <img src={rewardIcon} alt='My reward widget icon' />
            </div>
          </div>
          <h4 className='member-elite'>{tierText}</h4>
          <Text tag='p' field={layoutData['Member ID Label']} className='member-id' />
          <p className='member-id__number'>{layoutData['Member ID']?.value.replace('{member_id}', memberId || 'NA')}</p>
        </div>
      ) : (
        leftSide
      )}
      <div className='my-reward-widget__content-right'>
        <Text tag='h4' field={layoutData['Your Status Label']} className='my-reward-widget__content-right__title' />
        {children}
      </div>
    </div>
  );
};

MyRewardWidgetLayout.propTypes = {
  rewardIcon: string,
  layoutData: object,
  tierText: string,
  memberId: string,
  children: element,
  leftSide: element
};

export default MyRewardWidgetLayout;