import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState }  from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import CountdownTimerResend from '@components/Share/CountdownTimerResend';
import LoadingMask from '@components/Share/LoadingMask';
import {validateChangeEmail, verificationCodeEmail} from '@redux/actions/myAccount';
import {Text} from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const VerifyThroughOldEmail = ({dataSources, emailUser, setInputNewEmailEvt, isValidEmail, errMessage, isLoading}) => {
  const dispatch = useDispatch();
  const [sendCode, setsendCode] = useState(true);

  const formik = useFormik({
    initialValues: {
      email: emailUser,
      codeVerify: ''
    },
    validationSchema: Yup.object({
      codeVerify: Yup.string()
        .required('Please enter verify code'),
    }),
    onSubmit: (values) => {
      dispatch(validateChangeEmail({
        email: values.email,
        code: values.codeVerify,
      }));
    },
    validateOnMount: true
  });

  const onSendCodeHandler = () => {
    dispatch(verificationCodeEmail({email: emailUser}));

    setsendCode(false);
  };

  useDidUpdateEffect(() => {
    if (isValidEmail) {
      setInputNewEmailEvt();
    }
  }, [isValidEmail]);
  

  return (
    <div className='contact-info'>
      <div className='container'>
        <div className='contact-info__wrap'>
          <form onSubmit={formik.handleSubmit} className='contact-info__form'>
            {errMessage ? <span className='error-message'>{errMessage}</span> : ''}
            <div className='form-group'>
              <input
                onChange={formik.handleChange}
                value={formik.values.email}
                type='email'
                id='email'
                className='form-control form-control-lg form-bg-gray'
                name='email'
                disabled
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                id='codeVerify'
                onChange={formik.handleChange}
                value={formik.values.codeVerify}
                className='form-control form-control-lg'
                name='codeVerify'
                placeholder={dataSources['Verify Email Code Placeholder'].value}
              />
              {formik.errors.codeVerify && formik.touched.codeVerify && <span className='error-validate'>{formik.errors.codeVerify}</span>}
            </div>
            <div className='contact-info__btn text-center'>
              <div className='contact-info__btn__item'>
                {sendCode ?
                  <button type='button' className='btn btn-outline-CTA2' onClick={onSendCodeHandler}>
                    <Text field={dataSources['Send Code Text']}/>
                  </button> : 
                  <button type='button' className='btn btn-outline-CTA2 btn-disable'>
                    <Text field={dataSources['Send Code Text']}/>
                    <CountdownTimerResend timmer={60} setEndTimer={() => setsendCode(true)}/>
                  </button>
                }
              </div>
              <div className='contact-info__btn__item'>
                {sendCode ?
                  <button type='button' className='btn btn-outline-CTA1 btn-disable'>
                    <Text field={dataSources['Verify Code Button Text']}/>
                  </button> : 
                  <button type='submit' className='btn btn-outline-CTA1'>
                    <Text field={dataSources['Verify Code Button Text']}/>
                  </button>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading ? <LoadingMask parent={VerifyThroughOldEmail}/> : ''}
    </div>
  );
};

VerifyThroughOldEmail.propTypes = {
  emailUser: PropTypes.string,
  dataSources: PropTypes.object,
  setInputNewEmailEvt: PropTypes.func,
  errMessage: PropTypes.string,
  isValidEmail: PropTypes.bool,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isValidEmail: state.singleSignOnReducer.forgotEmailReducer.isValidEmail,
  errMessage: state.singleSignOnReducer.forgotEmailReducer.errMessage,
  isLoading: state.singleSignOnReducer.forgotEmailReducer.isLoading,
});

export default connect(mapStateToProps)(VerifyThroughOldEmail);
