import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import SitecoreImageModel from '../../../models/SitecoreImageModel';

const TEXT_ARR = [
  'Title',
  'Manage Label',
  'Not Member Label',
  'Become Member Text',
  'Member Code',
  'Your Status Label',
  'Spend Member Label',
  'See Benefits',
  'Member Label',
  'Member ID Label',
  'Member ID',
  'Review Level Date',
  'View Current Privileges',
  'Member Text',
  'Spend Level Member Label',
  'Spend Level Member Upgrade Label',
  'OSIM Member Text',
  'Bronze Membership Expired Text',
  'Tier Status Update Time Text',
  'Membership Expired Text'
];
const LINK_ARR = ['Manage Link'];
const IMAGE_ARR = ['User Icon'];

export default class MyRewardsWidgetModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['ListImageTiers'] = [];

    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListImageTiers')) {
      this['ListImageTiers']= dataSource['ListImageTiers'];
    }

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
