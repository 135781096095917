/* eslint-disable no-undef */
import './BaiduMap.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  asyncWrapper,
  BaiduMap,
  GeolocationControl,
  Label,
  MapTypeControl,
  Marker,
  NavigationControl,
  OverviewMapControl,
  ScaleControl
} from 'react-baidu-maps';
import { connect } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

const AsyncMap = asyncWrapper(BaiduMap);

const mapStateToProp = (state) => {
  return {
    hoveredTab: state.storeLocatorReducer.allStoreReducer.hoveredTab,
    selectedTab: state.storeLocatorReducer.allStoreReducer.selectedTab,
    searchResultList: state.storeLocatorReducer.allStoreReducer.searchResultList || []
  };
};

const ReactBaiduMap = ({ hoveredTab, selectedTab, markerIcon, initConfig, onClickStoreHandler, onHoverStoreHandler, searchResultList }) => {
  const [hoveringMarker, setHoveringMarker] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const baiduMapApiKey = useMemo(
    () => SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Baidu Map API Key']?.value || '',
    [SitecoreContextFactoryService]
  );

  useEffect(() => {
    if (hoveredTab?.ItemId) {
      searchResultList.map((item) => {
        if (item.ItemId === hoveredTab.ItemId) {
          setSelectedMarker(null);

          setHoveringMarker(deepCopy(item));
        }
      });
    } else {
      setHoveringMarker(null);
    }
  }, [hoveredTab, searchResultList]);

  useEffect(() => {
    if (selectedTab?.ItemId) {
      searchResultList.map((item) => {
        if (item.ItemId === selectedTab.ItemId) {
          setSelectedMarker(deepCopy(item));
        }
      });
    } else {
      setSelectedMarker(null);
    }
  }, [selectedTab, searchResultList]);

  const handleMarkerHover = (item) => {
    onHoverStoreHandler(item);
  };

  const handleMarkerHoverOut = () => {
    onHoverStoreHandler(null);
  };

  const listMarkers = useMemo(() => {
    return searchResultList.map((item) => {
      const { src, height, width } = markerIcon;
      const { Latitude, Longitude, Name, Phone } = item;

      if (JSON.stringify(selectedMarker) === JSON.stringify(item)) {
        return (
          <Marker
            key={item.ItemId}
            position={{ lng: Longitude, lat: Latitude }}
            icon={{
              imageUrl: src,
              size: { width: width * 1.5, height: height * 1.5 },
              imageSize: { width: width * 1.5, height: height * 1.5 }
            }}
            onClick={() => onClickStoreHandler(item, false)}
          >
            <Label
              position={{ lng: Longitude, lat: Latitude }}
              content={
                Phone
                  ? `
              <div class='info-window'>
                <h4>${Name}</h4>
                <p>${Phone}</p>
              </div>
            `
                  : `
            <div class='info-window'>
              <h4>${Name}</h4>
            </div>
          `
              }
            />
          </Marker>
        );
      }

      if (!selectedMarker && JSON.stringify(hoveringMarker) === JSON.stringify(item)) {
        return (
          <Marker
            key={item.ItemId}
            position={{ lng: Longitude, lat: Latitude }}
            icon={{
              imageUrl: src,
              size: { width: width * 1.5, height: height * 1.5 },
              imageSize: { width: width * 1.5, height: height * 1.5 }
            }}
            onClick={() => onClickStoreHandler(item, false)}
            onMouseover={() => handleMarkerHover(item)}
            onMouseout={() => handleMarkerHoverOut(item)}
          >
            <Label
              position={{ lng: Longitude, lat: Latitude }}
              content={
                Phone
                  ? `
            <div class='info-window'>
              <h4>${Name}</h4>
              <p>${Phone}</p>
            </div>
          `
                  : `
          <div class='info-window'>
            <h4>${Name}</h4>
          </div>
        `
              }
            />
          </Marker>
        );
      }

      return (
        <Marker
          key={item.ItemId}
          position={{ lng: Longitude, lat: Latitude }}
          icon={{ imageUrl: src, size: { width, height } }}
          onClick={() => onClickStoreHandler(item, false)}
          onMouseover={() => handleMarkerHover(item)}
          onMouseout={() => handleMarkerHoverOut(item)}
        />
      );
    });
  }, [searchResultList, selectedMarker, hoveringMarker]);

  return baiduMapApiKey ? (
    <div className='baidu-map'>
      <AsyncMap
        mapUrl={`https://api.map.baidu.com/api?v=3.0&ak=${baiduMapApiKey}`}
        loadingElement={<div>Loading.....</div>}
        mapContainer={<div style={{ height: '100%', width: '100%' }} />}
        {...initConfig}
      >
        <ScaleControl unit='imperial' />
        <MapTypeControl />
        <OverviewMapControl />
        <NavigationControl />
        <GeolocationControl />
        {listMarkers}
      </AsyncMap>
    </div>
  ) : (
    <></>
  );
};

ReactBaiduMap.propTypes = {
  hoveredTab: PropTypes.any,
  selectedTab: PropTypes.any,
  markerIcon: PropTypes.any,
  initConfig: PropTypes.any,
  onClickStoreHandler: PropTypes.func,
  onHoverStoreHandler: PropTypes.func,
  searchResultList: PropTypes.array
};

export default connect(mapStateToProp)(ReactBaiduMap);
