import './NotificationsContent.scss';

import { any } from 'prop-types';
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { getHadNotificationsSuccess } from '@redux/actions/cpNotificationPopup/getHadNotifications';
import { getNotificationStart } from '@redux/actions/cpNotificationPopup/notificationData';
import { readAllNotificationStart } from '@redux/actions/cpNotificationPopup/readAllNotification';
import { DEFAULT_CURRENT_LOGS_PAGE, DEFAULT_LOG_PER_VIEW, defaultFetchLogsParams } from '@redux/epic/cpNotificationPopup/configs';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import LoadingMore from './LoadingMore';
import NotificationsContentModel from './Models/NotificationsContentModel';
import NotificationItem from './NotificationItem';

const NotificationsContent = ({ fields }) => {
  const notificationBodyRef = useRef(null);
  const dispatch = useDispatch();
  const { isLoading, notificationLogs } = useSelector((state) => state.cpNotificationPopupReducer.notificationDataReducer);
  const { isOpen, spacing } = useSelector((state) => state.cpNotificationPopupReducer.popupToggleReducer);

  const { isLoading: isReadingAllNotification, readNotificationResponse } = useSelector(
    (state) => state.cpNotificationPopupReducer.readAllNotificationReducer
  );

  const [layoutData, setLayoutData] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_LOGS_PAGE);
  const [appointmentActivityLogs, setAppointmentActivityLogs] = useState([]);

  const notificationItemRef = useMemo(() => {
    return notificationLogs?.appointmentActivityLogs?.map(() => ({ ref: createRef() }));
  }, [notificationLogs?.appointmentActivityLogs]);

  useEffect(() => {
    const model = new NotificationsContentModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    if (isOpen) {
      // INFO: set current state into empty
      setAppointmentActivityLogs([]);

      // INFO: start fetch new data when open notification box
      handleFetchNotificationLogs(defaultFetchLogsParams);
    }
  }, [isOpen]);

  useEffect(() => {
    // INFO: find out the shortest element and make it as default height
    if (notificationItemRef?.length && !appointmentActivityLogs.length) {
      const sortShorterElement = notificationItemRef.sort((prev, next) => prev.ref.current?.offsetHeight - next.ref.current?.offsetHeight);
      const shortestElement = sortShorterElement?.[0]?.ref;
      if (notificationBodyRef.current)
        notificationBodyRef.current.style.maxHeight = `${shortestElement?.current?.offsetHeight * DEFAULT_LOG_PER_VIEW}px`;
    }
  }, [notificationItemRef, notificationBodyRef, appointmentActivityLogs]);

  useEffect(() => {
    // INFO: set state had notification fot notification icon
    dispatch(getHadNotificationsSuccess(notificationLogs?.HadNotification));
    // INFO: set data from api to appointmentActivityLogs state
    if (notificationLogs?.appointmentActivityLogs?.length) {
      setAppointmentActivityLogs((prevState) => [...prevState, ...notificationLogs?.appointmentActivityLogs]);
    }
  }, [notificationLogs]);

  useDidUpdateEffect(() => {
    if (readNotificationResponse?.StatusCode === 200) {
      // INFO: clear current data before re-fetch new data
      setAppointmentActivityLogs([]);

      // INFO: re-fetch new data by default
      handleFetchNotificationLogs(defaultFetchLogsParams);
    }
  }, [readNotificationResponse]);
  const handleReadAllNotification = () => dispatch(readAllNotificationStart());

  const handleFetchNotificationLogs = useCallback(
    ({ page, pageSize }) => {
      if (page && page) {
        // INFO: set state for currentPage state
        setCurrentPage(page);

        return dispatch(getNotificationStart({ page, pageSize }));
      }
      if (currentPage < notificationLogs?.TotalPages || !notificationLogs?.appointmentActivityLogs?.length) {
        const nextPage = currentPage + 1;

        const params = {
          page: nextPage,
          pageSize: DEFAULT_LOG_PER_VIEW
        };

        // INFO: set state for currentPage state
        setCurrentPage(nextPage);

        return dispatch(getNotificationStart(params));
      }
    },
    [notificationLogs, currentPage]
  );

  return layoutData ? (
    <div className={`cp-popup infor-popup notification-popup ${isOpen ? 'popup-show' : ''}`} style={{ right: `${spacing}px` }}>
      <div className='notification-popup__title'>
        <Text tag='h5' field={layoutData['Notifications Label']} className='title' />
        <button type='button' className='read-all-button' onClick={handleReadAllNotification}>
          <Text field={layoutData['Mark all as read Label']} />
        </button>
      </div>
      {appointmentActivityLogs.length ? (
        <div ref={notificationBodyRef} className='notification-popup__body'>
          {appointmentActivityLogs.map((noti, index) => (
            <NotificationItem layoutData={layoutData} key={noti.Id} ref={notificationItemRef?.[index]?.ref} notification={noti} />
          ))}
          {isLoading ? (
            <div className='notification-popup__footer'>
              <LoadingMore />
            </div>
          ) : !(currentPage >= notificationLogs?.TotalPages) ? (
            <div className='load-more-button' onClick={handleFetchNotificationLogs}>
              <button className='btn btn-outline-CTA1' disabled={currentPage >= notificationLogs?.TotalPages}>
                <Text field={layoutData['Load More Label']} />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isReadingAllNotification ? <LoadingMask parent={NotificationsContent} /> : <></>}
    </div>
  ) : (
    <></>
  );
};

NotificationsContent.propTypes = {
  fields: any
};

export default NotificationsContent;