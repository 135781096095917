import './MyAppointments.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import MyAppointmentsModel from './models/MyAppointmentsModel';
import { useTimeSlotGenerator } from './useTimeSlotGenerator';

const TAB_EVENT = {
  UP_COMING_APPOINTMENT_TAB: 'upcoming-tab',
  PAST_APPOINTMENT_TAB: 'past-tab'
};

const MyAppointments = ({ fields }) => {
  const timeSlotGenerator = useTimeSlotGenerator();
  const formatDate = useFormatDate();
  const [tabEvent, setTabEvent] = useState(TAB_EVENT.UP_COMING_APPOINTMENT_TAB);
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    setLayoutData(new MyAppointmentsModel().getData(fields));
  }, []);

  return layoutData ? (
    <div className='my-appointment my-orders'>
      <div className='container'>
        <div className='my-appointment__tabs my-orders__tabs'>
          <Tabs activeKey={tabEvent} onSelect={(tabCurrent) => setTabEvent(tabCurrent)}>
            <Tab key='upComing' eventKey='upcoming-tab' title={layoutData?.['Upcoming Appointments Label']?.value} className=''>
              <div className='my-appointment__tabs__content  my-orders__tabs__content'>
                <div className='my-appointment__tabs__content'>
                  {layoutData?.upcomingAppointments.length ? (
                    layoutData?.upcomingAppointments.map((appoint) => (
                      <div key={appoint.Id} className='my-appointment__item'>
                        <h5 className='my-appointment__item__title'>
                          <Text field={{ value: formatDate(appoint?.Date) }} tag='span' />
                          {appoint?.IsBookExperience || appoint?.IsLoyaltyEvent ? (
                            <>
                              <Text
                                field={{
                                  value: timeSlotGenerator(
                                      appoint?.TimeSlot?.Id,
                                      appoint?.Store?.TimeSlots?.TimeSlots
                                  )
                                }} tag='span'
                              />
                              <span>{layoutData?.['Guests Label']?.value?.replace(
                                '{numberOfGuest}',
                                appoint?.NumberOfGuests
                              )}</span>
                            </>
                          )
                            : (
                              <></>
                            )}
                        </h5>

                        <div className='my-appointment__item__box'>
                          <div className='my-appointment__item__link'>
                            {appoint?.IsLoyaltyEvent ? (
                              <a
                                href={`${layoutData?.['View Loyalty Link']?.value?.href}?eventId={${appoint?.Campaign?.Id}}`}
                                className='btn btn-link'
                              >
                                <Text field={layoutData?.['View Loyalty Label']} />
                              </a>
                            ) : (
                              <a
                                href={`${layoutData?.['View Link']?.value?.href}?campaign_id=${appoint?.Campaign?.Id}&appointment_id=${appoint?.Id}`}
                                className='btn btn-link'
                              >
                                <Text field={layoutData?.['View Label']} />
                              </a>
                            )}
                          </div>
                          <div className='my-appointment__item__box__address'>
                            <Text field={{ value: appoint?.Store?.Name }} tag='h6' className='my-appointment__item__box__address__title' />
                            <Text field={{ value: appoint?.Store?.Address?.District }} tag='p' />
                            <RichText field={{ value: appoint?.Store?.Address?.Address }} tag='p' />
                            <Text field={{ value: appoint?.Store?.Address?.PostalCode }} tag='p' />
                            {appoint?.Store?.PhoneNumber ? <Text field={{ value: `+${appoint?.Store?.PhoneNumber}` }} tag='p' /> : <></>}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Text field={layoutData?.['Upcoming Appointments Empty Text']} />
                    </>
                  )}
                </div>
              </div>
            </Tab>
            <Tab key='past' eventKey='past-tab' title={layoutData?.['Past Appointments Label']?.value} className=''>
              <div className='my-orders__tabs__content my-orders__tabs__content'>
                <div className='my-appointment__tabs__content'>
                  {layoutData?.pastAppointments.length ? (
                    layoutData?.pastAppointments.map((appoint) => (
                      <div key={appoint.Id} className='my-appointment__item'>
                        <h5 className='my-appointment__item__title'>
                          <Text field={{ value: formatDate(appoint?.Date) }} tag='span' />
                          {appoint?.IsBookExperience || appoint?.IsLoyaltyEvent ? (
                            <>
                              <Text field={{ value: timeSlotGenerator(appoint?.TimeSlot?.Id, appoint?.Store?.TimeSlots?.TimeSlots) }} tag='span' />
                              <span>{layoutData?.['Guests Label']?.value?.replace(
                                '{numberOfGuest}',
                                appoint?.NumberOfGuests
                              )}</span>
                            </>
                          ) : (
                            <></>
                          )}
                        </h5>
                        <div className='my-appointment__item__box'>
                          <div className='my-appointment__item__link'>
                            {appoint?.IsLoyaltyEvent ? (
                              <a
                                href={`${layoutData?.['View Loyalty Link']?.value?.href}?eventId={${appoint?.Campaign?.Id}}`}
                                className='btn btn-link'
                              >
                                <Text field={layoutData?.['View Loyalty Label']} />
                              </a>
                            ) : (
                              <a
                                href={`${layoutData?.['View Link']?.value?.href}?campaign_id=${appoint?.Campaign?.Id}&appointment_id=${appoint?.Id}`}
                                className='btn btn-link'
                              >
                                <Text field={layoutData?.['View Label']} />
                              </a>
                            )}
                          </div>
                          <div className='my-appointment__item__box__address'>
                            <Text field={{ value: appoint?.Store?.Name }} tag='h6' className='my-appointment__item__box__address__title' />
                            <Text field={{ value: appoint?.Store?.Address?.District }} tag='p' />
                            <RichText field={{ value: appoint?.Store?.Address?.Address }} tag='p' />
                            <Text field={{ value: appoint?.Store?.Address?.PostalCode }} tag='p' />
                            {appoint?.Store?.PhoneNumber ? <Text field={{ value: `+${appoint?.Store?.PhoneNumber}` }} tag='p' /> : <></>}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Text field={layoutData?.['Past Appointments Empty Text']} />
                    </>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

MyAppointments.propTypes = {
  fields: any
};

export default MyAppointments;