import { array, object } from 'prop-types';
import React, { useState } from 'react';

import LoadingMask from '@components/Share/LoadingMask';
import SwipeComponent from '@components/Share/SwipeComponent';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { swiperParams } from '../configs';
import { getProductByCategory } from '../services';
import { useProductSupportContext } from '../useProductSupportContext';

const ProductCategorySwiper = ({ sectionTitle, categoryData }) => {
  const [isGettingProduct, setIsGettingProduct] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const { setProductsData } = useProductSupportContext();

  const handleSelectCategory = async (category) => {
    setActiveCategory(category.id);

    setIsGettingProduct(true);

    setProductsData([]);
    const response = await getProductByCategory(category.id);

    if (response?.ChildProducts?.length) setProductsData(response.ChildProducts);

    setIsGettingProduct(false);
  };

  return (
    <div className='product-support-cta__swiper'>
      <Text tag='h4' field={sectionTitle} className='product-support-cta__swiper-title' />
      <div className='product-support-cta__swiper-container'>
        <SwipeComponent param={swiperParams}>
          {categoryData.map((category) => (
            <div key={category.id} className='swiper-item' onClick={() => handleSelectCategory(category)}>
              <div className={`swiper-image ${activeCategory === category.id  ? 'active' : ''}`}>
                <Image field={category.fields['Menu Image']} />
              </div>
              <Text tag='h3' field={category.fields.Title} className='swiper-name' />
            </div>
          ))}
        </SwipeComponent>
      </div>
      {isGettingProduct ? <LoadingMask parent={ProductCategorySwiper} /> : <></>}
    </div>
  );
};

ProductCategorySwiper.propTypes = {
  sectionTitle: object,
  categoryData: array
};

export default ProductCategorySwiper;
