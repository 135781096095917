import './LoadingMask.scss';

import { any } from 'prop-types';
import React from 'react';

const LoadingMask = ({ parent }) => {
  return (
    <div className='loading-mask'>
      <div className='loading__default'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

LoadingMask.propTypes = {
  parent: any
};

export default LoadingMask;