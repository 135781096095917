import { object } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { LOYALTY_BRONZE_TIER_VALUE, LOYALTY_DIAMOND_TIER_VALUE } from '@utils/constant';
import global from '@utils/global';

import MyRewardWidgetLayout from '../MyRewardWidgetLayout';
import { convertEvalDate, formatTierAmountNumber } from '../utils';

const IsMemberLoyalty = ({ layoutData }) => {
  // const { myRewardInfo } = useSelector((state) => state.loyaltyReducer.getMyRewardWidgetInfoReducer);
  const dataCrm = useSelector((state) => state.loyaltyReducer.getCrmCustomerReducer.dataCrm);
  const dataReward = useMemo(() => dataCrm?.Data, [dataCrm]);

  const evalDateValue = useMemo(() => {
    return dataReward?.crm_eval_date ? convertEvalDate(dataReward.crm_eval_date) : '';
  }, [dataReward?.crm_eval_date]);

  const crmAmountEval = useMemo(() => {
    const crmAmountEvalNumber = Number(dataReward?.crm_amt_retain || 0);
    const crmToFixed = global.roundUpDecimal(crmAmountEvalNumber);

    return formatTierAmountNumber(crmToFixed);
  }, [dataReward]);

  const crmAmountNextTier = useMemo(() => {
    const crmAmountEvalNumber = Number(dataReward?.crm_amt_next_tier || 0);
    const crmToFixed = global.roundUpDecimal(crmAmountEvalNumber);

    return formatTierAmountNumber(crmToFixed);
  }, [dataReward]);

  const isBronzeTier = useMemo(() => dataReward?.crm_tier === LOYALTY_BRONZE_TIER_VALUE, [dataReward]);

  const rewardIconUrl = useMemo(() => {
    let iconUrl = '';
    if (dataReward) {
      const listImageTiers = layoutData?.['ListImageTiers'] || [];

      const dataImageTier = listImageTiers?.find((item) => item.CodeLoyalty.toLowerCase()
        === dataReward?.crm_tier?.toLowerCase());

      if (dataImageTier) iconUrl = dataImageTier.UrlImage;
    }

    return iconUrl;
  }, [dataReward]);

  const expiredDate = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const [fromDate, toDate] = dataReward?.['crm_eval_period']?.split('-') || '';

    return toDate || '';
  }, [dataReward]);

  return (
    <MyRewardWidgetLayout
      layoutData={layoutData}
      tierText={dataReward?.crm_tier}
      rewardIcon={rewardIconUrl}
      memberId={dataReward?.customer_id}
    >
      {isBronzeTier ? (
        <Text
          tag='p'
          field={layoutData?.['Bronze Membership Expired Text']}
          className='content'
        />
      ) : (
        <>
          <Text
            tag='p'
            field={{
              value: layoutData?.['Membership Expired Text']?.value?.replace(
                '{date}',
                expiredDate
              )
            }}
            className='content'
          />
          <Text
            tag='p'
            field={{ value: layoutData['Review Level Date']?.value?.replace('{DateTimeReview}', evalDateValue) }}
            className='content'
          />
        </>
      )}
      {dataReward?.crm_tier && !isBronzeTier ? (
        <RichText
          tag='p'
          field={{
            value: layoutData['Spend Level Member Label']?.value
              ?.replace('{SpentAmountNextStr}', crmAmountEval)
              .replace('{NameLoyaltyNext}', dataReward?.crm_tier)
          }}
          className='content'
        />
      ) : (
        <></>
      )}
      {dataReward?.crm_tier !== LOYALTY_DIAMOND_TIER_VALUE ? (
        <RichText
          tag='p'
          field={{
            value: layoutData['Spend Level Member Upgrade Label']?.value
              ?.replace('{SpentAmountUpgrateNextStr}', crmAmountNextTier)
              .replace('{NameLoyaltyUpgrateNext}', dataReward?.crm_next_tier)
          }}
          className='content'
        />
      ) : (
        <></>
      )}
      <RichText tag='p' field={layoutData['View Current Privileges']} className='link' />
    </MyRewardWidgetLayout>
  );
};

IsMemberLoyalty.propTypes = {
  layoutData: object
};

export default IsMemberLoyalty;
