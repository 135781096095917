import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useMappingPaymentString = () => {
  const orderData = useSelector(state => state.myOrderReducer.orderByIdReducer.currentCart);

  return useMemo(() => {
    const paymentsList = orderData?.Payments || [];
    if (paymentsList.length) {
      let paymentStringsList = [];
      for (const paymentItem of paymentsList) {
        let paymentString = '';

        paymentString = paymentItem.PaymentMethodName || '';
        const cartType = paymentItem?.CardType;
        const bankCode = paymentItem?.BankCode;
        if (cartType && !bankCode) paymentString = `${paymentString} - ${cartType}`;
        if (bankCode) paymentString = `${paymentString} - ${bankCode}`;

        paymentStringsList.push(paymentString);
      }

      return paymentStringsList;
    }

    return [];
  }, [orderData]);
};