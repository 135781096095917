import './ChangeSchedule.scss';

import { any, bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import { updateAppointment } from '@redux/actions/myAppointment';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import SelectStoreAndDate from '../SelectStoreAndDate';
import ChangeScheduleModal from './ChangeScheduleModal/index';
import ChangeScheduleModel from './model/ChangeScheduleModel';

const ChangeSchedule = (props) => {
  const { fields, isLoading, isUpdated } = props;
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new ChangeScheduleModel();

    setLayoutData(model.getData(fields));
  }, []);

  useDidUpdateEffect(() => {
    if (isUpdated) {
      setIsShowModal(!isShowModal);
    }
  }, [isUpdated]);


  // Handle Model Change Schedule
  const [isShowModal, setIsShowModal] = useState(false);

  const handleCloseModal = () => {
    if (isUpdated) {
      window.location.href =
        `${window.location.origin}${layoutData?.['Button Appointment Details Link']?.value?.href}?campaign_id=${layoutData?.appointment?.Campaign?.Id}&appointment_id=${layoutData?.appointment?.Id}`;
    }
  };

  const dispatch = useDispatch();

  const handleUpdateAppointment = (values) => {
    dispatch(updateAppointment({
      'AppointmentId': layoutData?.appointment?.Id,
      'StoreId': values.storeId,
      'Date': new Date(values.date).toLocaleDateString('en-CA'),
      'NumberOfGuests': values.numberOfGuests
    }));
  };

  return layoutData ? (
    <div className='container'>
      <SelectStoreAndDate layoutData={{
        ...layoutData,
        availableTranslationText: layoutData?.['Available Translation Text'],
        notAvailableTranslationText: layoutData?.['Not Available Translation Text'],
        selectedTranslationText: layoutData?.['Selected Translation Text']
      }} isOnUpdateScreen={true} onSubmit={handleUpdateAppointment} isShowBtnCancel={true} />
      {isLoading && (
        <LoadingMask parent={ChangeSchedule} />
      )}
      <ModalComponent
        isShow={isShowModal}
        onCloseHandler={handleCloseModal}
        titlePopup={layoutData?.['Title Cancel Appointment Modal']?.value}
        cssClass='osim-popup--no-padding'
      >
        <div className='appointment-detail__header__content__wrap__cancel-modal'>
          <ChangeScheduleModal layoutData={layoutData} onCloseModal={handleCloseModal} />
        </div>
      </ModalComponent>
    </div>
  ) : (<></>);
};

ChangeSchedule.propTypes = {
  fields: any,
  isLoading: bool,
  isUpdated: bool
};

const mapStateToProps = (state) => ({
  isLoading: state.myAppointmentReducer.updateAppointmentReducer.isLoading,
  isUpdated: state.myAppointmentReducer.updateAppointmentReducer.isUpdated,
});

export default connect(mapStateToProps)(ChangeSchedule);
