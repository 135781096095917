import './CustomerWishlistPage.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { getProductWishlistAboutUserId } from '@redux/actions/product';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import ProductItemViewAll from '../GenericComponents/ProductItemViewAll';
import CustomerWishlistPageModel from './Models/CustomerWishlistPageModel';

const CustomerWishlistPage = ({ fields }) => {
  const [wishListFields, setWishListFields] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const productWishlist = useSelector((state) => state.productReducer.productWishlistReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const wishListPage = new CustomerWishlistPageModel();

    setWishListFields(wishListPage.getData(deepCopy(fields || {})));

    let url = new URL(window.location.href);

    dispatch(
      getCPCustomer({
        id: url.searchParams.get('contactId'),
        fromURL: true,
        isGetCustomerProduct: true
      })
    );
  }, []);

  useDidUpdateEffect(() => {
    if (clientTellingPortalCustomer?.externalId) {
      dispatch(getProductWishlistAboutUserId(clientTellingPortalCustomer.externalId));
    }
  }, [clientTellingPortalCustomer.externalId]);

  const renderWishlistItem = () =>
    productWishlist?.listProductWishlist &&
    productWishlist.listProductWishlist.map((productWishlist, index) => (
      <ProductItemViewAll key={index} isWishList={true} ProductsItem={productWishlist} ProductFields={wishListFields} />
    ));

  return (
    wishListFields &&
    (productWishlist.isLoading ? (
      <LoadingMask parent={CustomerWishlistPage} />
    ) : (
      <>
        <CPPageHeader
          title={{
            value: wishListFields['Section Title'].value.replace(
              '{customer-name}',
              `${clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.FirstName || ''} ${
                clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.LastName || ''
              }`
            )
          }}
          description={{ value: wishListFields['Description'].value.replace('{products-total}', `${productWishlist?.listProductWishlist.length}`) }}
        />
        <div className='product-wishlist'>
          <div className='container tablet-container'>
            <div className='product-wishlist__list'>{renderWishlistItem()}</div>
          </div>
        </div>
      </>
    ))
  );
};

CustomerWishlistPage.propTypes = {
  fields: any
};

export default CustomerWishlistPage;
