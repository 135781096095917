import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGeneratePasswordRegex = () => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  return useMemo(() => {
    const minLength = settingGlobal?.CharacterLengthMinimum || 8;
    const upperAndLowercaseRegex = settingGlobal?.UppercaseAndLowercaseRegex || '(?=.*[a-z])(?=.*[A-Z])';
    const atLeastOneNumberRegex = settingGlobal?.NumberRegex || '(?=.*\\d)';
    const atLeastOneSpecialCharacterRegex = settingGlobal?.NonAlphanumericRegex || '(?=.*\\W)';

    return (
      {
        minLength,
        upperAndLowercaseRegex,
        atLeastOneNumberRegex,
        atLeastOneSpecialCharacterRegex,
        regexSummary: new RegExp(`${upperAndLowercaseRegex}${atLeastOneNumberRegex}${atLeastOneSpecialCharacterRegex}{${minLength},}`)
      }
    );
  }, [settingGlobal]);
};