import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import ErrorComponent from '@components/ErrorComponent';
import LoadingMask from '@components/Share/LoadingMask';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { updateOneCartLine } from '@redux/actions/cartDetail';
import { updateSOCartLine } from '@redux/actions/cartDetail';
import { addToCart } from '@redux/actions/product';
import { addToSOCart } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { PROMO_ADDITIONAL_TYPE, PROMO_DEFAULT_QUANTITY, PROMO_WARRANTY_TYPE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import BundleBOAdditionalOptions from './BundleBOAdditionalOptions';
import BundleBOAddToCartButton from './BundleBOAddToCartButton';
import BundleBOFreeGifts from './BundleBOFreeGifts';
import BundleBOHeader from './BundleBOHeader';
import BundleBOImages from './BundleBOImages';
import BundleBOPaymentInfo from './BundleBOPaymentInfo';
import BundleBOVariants from './BundleBOVariants';
import BundleBOWarranty from './BundleBOWarranty';
import BundleBuyingOptionLayoutShimmer from './BundleBuyingOptionLayoutShimmer';
import { changeActiveItem, checkPassValidate, combinedItems, findMaxValue, sumTotal } from './bundleFunction';

const BundleBuyingOptionLayout = (
  {
    dataBundle,
    isEditing,
    EditQuantity,
    isLoading,
    isActionSuccess,
    isToggleChangePage,
    cartLineIdToSuccessPage,
    errorMsg,
    isError,
    editSelectedFreeGift,
    soCartStatuses
  }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [firstLoading, setFirstLoading] = useState(true);

  const [dataTextFields] = useState(dataBundle['Text Data']);

  const [isDynamic] = useState(dataBundle['Is Dynamic']);

  const [ProductPageID, setProductPageID] = useState(null);

  const [CartLineID, setCartLineID] = useState(null);

  const [SelectedFreeGift, setSelectedFreeGift] = useState(editSelectedFreeGift || []);

  const [dataFields, setDataFields] = useState(dataBundle['Chosen Bundle']);

  const [allowedOptions, setAllowedOptions] = useState(null);

  const [variantSelected, setVariantSelected] = useState(dataBundle['Chosen Bundle']['Active Variant All'].filter(item => item !== null).filter(item => item.active === true));

  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const [dataLabel] = useState({
    'In Stock Label': `${messageObj?.['LBL-InStock'] || ''}${messageObj?.['LBL-InStock'] === '' ? '' : '. '}${dataBundle['In Stock Label'].value}`,
    'In Stock With BackOrder Label': `${messageObj?.['LBL-BackOrder'] || ''}${messageObj?.['LBL-BackOrder'] === '' ? '' : '. '}${dataBundle['In Stock With BackOrder Label'].value}`,
    'In Stock With PreOrder Label': `${messageObj?.['LBL-PreOrder'] || ''}${messageObj?.['LBL-PreOrder'] === '' ? '' : '. '}${dataBundle['In Stock With PreOrder Label'].value}`,
    'Out Stock Label': `${messageObj?.['LBL-OutOfStock'] || ''}${messageObj?.['LBL-OutOfStock'] === '' ? '' : '. '}${dataBundle['Out Stock Label'].value}`,
  });

  const [bundleDeliveryStatus, setBundleDeliveryStatus] = useState({
    'Delivery Status': {
      value: '',
    }
  });

  const freeGiftRef = useRef();

  const [freeItemId, setFreeItemId] = useState(null);

  const [passValidate, setPassValidate] = useState(false);

  const [checkValidate, setCheckValidate] = useState(false);

  const refs = useRef(dataFields['Validate Product Pass']?.reduce((acc, value) => {
    acc[value.UniqueVariantId] = React.createRef();

    acc[value.UniqueWarrantyId] = React.createRef();

    acc[value.UniqueAddID] = React.createRef();

    return acc;
  }, {}));

  const [Price, setPrice] = useState({
    totalSellingPrice: '',
    totalListingPrice: '',
    totalSellingPriceNumber: 0
  });
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  useEffect(() => {
    if (dataFields) {
      const onGetAdditionalOptionsPrice = () => {
        let total = 0;
        let selectedAdditionalOption = [];

        dataFields.Products.forEach((product) => {
          product.AdditionalOptions.forEach((additionalOption) => {
            additionalOption.Products.forEach((addtionalOptionProduct) => {
              if (addtionalOptionProduct.IsChoseAdditionalOption) {
                selectedAdditionalOption.push(addtionalOptionProduct);
              }
            });
          });
        });

        selectedAdditionalOption.forEach((value) => {
          total = total + value?.ListPrice;
        });

        return total;
      };

      setFreeItemId(findMaxValue(dataFields['Active Variant All'])?.UniqueProductId || null);

      // setPassValidate(checkPassValidate(dataFields['Validate Product Pass'], SelectedFreeGift.length === dataBundle['Chosen Bundle'].FreeGift.length) || null);
      setPassValidate(checkPassValidate(dataFields['Validate Product Pass'], SelectedFreeGift.length === dataBundle['Chosen Bundle'].FreeGift.length, dataFields?.Products) || null);

      sumTotalBundleFunction(findMaxValue(dataFields['Active Variant All'])?.UniqueProductId || null, onGetAdditionalOptionsPrice());

      renderStatusLabel(dataFields['Active Variant All']);
    }
  }, [dataFields, SelectedFreeGift]);

  useEffect(() => {
    if (dataFields) {
      setVariantSelected(dataFields['Active Variant All'].filter(item => item !== null).filter(item => item.active === true));
    }
  }, [dataFields['Active Variant All']]);

  useEffect(() => {
    const fullPath = window.location.href;

    const productPageId = global.getQueryParams(fullPath, 'bundlePageId') || null;

    const cartLineId = global.getQueryParams(fullPath, 'cartLineId') || null;

    setProductPageID(productPageId);

    setCartLineID(cartLineId);
  }, []);

  useDidUpdateEffect(() => {
    if (!isEditing) {
      //In Add Page
      if (isActionSuccess) {
        const nextPageUrl = dataBundle?.['Text Data']?.['Add To Cart Success Page']?.value?.href;
        if (ProductPageID) query.set('productid', ProductPageID);
        if (cartLineIdToSuccessPage) query.set('cartLineId', cartLineIdToSuccessPage);

        window.location = `${nextPageUrl}?${query.toString()}`;
      }
    } else {
      //In Editing Page
      if (isActionSuccess) {
        window.location = `${dataBundle?.['Text Data']?.['Cart Link Page']?.value?.href}?${query.toString()}`;
      }
    }
  }, [isToggleChangePage, query, ProductPageID, cartLineIdToSuccessPage, dataBundle, isActionSuccess]);

  useEffect(() => {
    setDataFields(onFilterAdditionalOptionsListHandler(dataFields, dataTextFields));
  }, []);

  const onSelectVariantHandler = (variantId, parentId) => {
    const productIndex = dataFields.Products.findIndex(obj => obj.UniqueProductId == parentId);

    const newVariant = dataFields.Products[productIndex].Variants.filter(item => item.VariantId === variantId)[0];

    let newVariantsList = changeActiveItem(dataFields.Products[productIndex].Variants, newVariant.VariantId, 'VariantId');

    // const newDataFields = deepCopy(dataFields);
    const newDataFields = { ...dataFields };

    newDataFields.Products[productIndex]['Active Variant'] = { ...newVariant, active: true };

    newDataFields.Products[productIndex].isChoseVariant = true;

    newDataFields.Products[productIndex].Variants = deepCopy(newVariantsList);

    let newProductPass;

    if (dataFields.Products[productIndex].AdditionalOptions.length) {
      newProductPass = newDataFields.Products[productIndex].isChoseVariant && newDataFields.Products[productIndex].isChoseWarranty && newDataFields.Products[productIndex].isChoseAdd ? true : false;
    } else {
      newProductPass = newDataFields.Products[productIndex].isChoseVariant && newDataFields.Products[productIndex].isChoseWarranty ? true : false;
    }

    newDataFields.Products[productIndex].isProductPass = newProductPass;

    newDataFields['Active Variant All'].splice(newDataFields['Active Variant All'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newVariant.UniqueProductId), 1, { ...newVariant, active: true });

    newDataFields['Validate Product Pass'].splice(newDataFields['Validate Product Pass'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newVariant.UniqueProductId), 1, { isProductPass: newProductPass, UniqueProductId: parentId });

    // const newDataFields = produce(deepCopy(dataFields), (draf) => {

    //   draf.Products[productIndex]['Active Variant'] = {...newVariant, active: true};

    //   draf.Products[productIndex].isChoseVariant = true;

    //   draf.Products[productIndex].Variants = deepCopy(newVariantsList);

    //   const newProductPass = draf.Products[productIndex].isChoseVariant && draf.Products[productIndex].isChoseWarranty ? true : false;

    //   draf.Products[productIndex].isProductPass = newProductPass;

    //   draf['Active Variant All'].splice(draf['Active Variant All'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newVariant.UniqueProductId), 1, {...newVariant, active: true});

    //   draf['Validate Product Pass'].splice(draf['Validate Product Pass'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newVariant.UniqueProductId), 1, {isProductPass: newProductPass, UniqueProductId: parentId});

    //   return draf;
    // });

    setDataFields(newDataFields);
  };

  const onSelectWarrantyHandler = (warrantyId, parentId) => {
    const productIndex = dataFields.Products.findIndex(obj => obj.UniqueProductId == parentId);

    const newWarranty = dataFields.Products[productIndex].Warranty.filter(item => item.ProductId === warrantyId)[0];

    let newWarrantyList = changeActiveItem(dataFields.Products[productIndex].Warranty, newWarranty.ProductId, 'ProductId');

    const newDataFields = { ...dataFields };

    newDataFields.Products[productIndex]['Active Warranty'] = { ...newWarranty, active: true };

    newDataFields.Products[productIndex].isChoseWarranty = true;

    newDataFields.Products[productIndex].Warranty = deepCopy(newWarrantyList);

    let newProductPass;

    if (dataFields.Products[productIndex].AdditionalOptions.length) {
      newProductPass = newDataFields.Products[productIndex].isChoseVariant && newDataFields.Products[productIndex].isChoseWarranty && newDataFields.Products[productIndex].isChoseAdd ? true : false;
    } else {
      newProductPass = newDataFields.Products[productIndex].isChoseVariant && newDataFields.Products[productIndex].isChoseWarranty ? true : false;
    }

    newDataFields.Products[productIndex].isProductPass = newProductPass;

    newDataFields['Active Warranty All'].splice(newDataFields['Active Warranty All'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newWarranty.UniqueProductId), 1, { ...newWarranty, active: true });

    newDataFields['Validate Product Pass'].splice(newDataFields['Validate Product Pass'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newWarranty.UniqueProductId), 1, { isProductPass: newProductPass, UniqueProductId: parentId });


    // const newDataFields = produce(deepCopy(dataFields), (draf) => {

    //   draf.Products[productIndex]['Active Warranty'] = {...newWarranty, active: true};

    //   draf.Products[productIndex].isChoseWarranty = true;

    //   draf.Products[productIndex].Warranty = deepCopy(newWarrantyList);

    //   const newProductPass = draf.Products[productIndex].isChoseVariant && draf.Products[productIndex].isChoseWarranty ? true : false;

    //   draf.Products[productIndex].isProductPass = newProductPass;

    //   draf['Active Warranty All'].splice(draf['Active Warranty All'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newWarranty.UniqueProductId), 1, {...newWarranty, active: true});

    //   draf['Validate Product Pass'].splice(draf['Validate Product Pass'].filter(item => item !== null).findIndex(item => item.UniqueProductId === newWarranty.UniqueProductId), 1, {isProductPass: newProductPass, UniqueProductId: parentId});

    //   return draf;
    // });

    setDataFields(newDataFields);
  };

  const onSelectFreeGiftHandler = (slot, productId, variantId, variantName) => {

    const selectedFreeGiftObj = {
      ProductId: productId,
      VariantId: variantId,
      Type: 1,
      Slot: slot,
      DisplayName: variantName,
      CatalogName: ''
    };

    let selectedFreeGift = [...SelectedFreeGift];

    let index = selectedFreeGift.findIndex(item => item.Slot === slot);

    if (index !== -1) {
      selectedFreeGift[index] = selectedFreeGiftObj;
    } else {
      selectedFreeGift.push(selectedFreeGiftObj);
    }

    setSelectedFreeGift(selectedFreeGift);
  };

  const onAddToCartHandler = (Data) => {
    let newSubLine = [];

    if (Data.Products.length) {
      Data.Products.map(item => {
        let newPromotionCartLines = [];

        if (item['Active Warranty']) {
          newPromotionCartLines.push({
            ProductId: item['Active Warranty']?.ProductId,
            CatalogName: item.Catalog,
            Type: PROMO_WARRANTY_TYPE
          });
        }

        item.AdditionalOptions.forEach((additionalOption) => {
          additionalOption.Products.forEach((product) => {
            if (product.IsChoseAdditionalOption) {
              newPromotionCartLines.push({
                ProductId: product.ProductId,
                CatalogName: 'OSG',
                VariantId: '',
                RelationshipId: additionalOption.RelationshipId,
                Type: PROMO_ADDITIONAL_TYPE
              });
            }
          });
        });

        newSubLine.push({
          ProductId: item.ProductId,
          VariantId: item['Active Variant']?.VariantId,
          CatalogName: item.Catalog,
          Quantity: PROMO_DEFAULT_QUANTITY,
          PromotionCartLines: newPromotionCartLines
        });
      });
    }

    let promotionCartLine = [];

    SelectedFreeGift.forEach(item => {
      promotionCartLine.push({
        ProductId: item.ProductId,
        VariantId: item.VariantId,
        Type: 1,
        Slot: item.Slot,
        CatalogName: ''
      });
    });

    const queryParams = new URLSearchParams(window.location.search);
    const pdpPromoCode = queryParams.get('cCode');

    const params = {
      LineUniqueId: isEditing ? CartLineID : null,
      ProductId: dataFields.BundleId,
      CatalogName: 'OSG',
      Quantity: isEditing ? EditQuantity : PROMO_DEFAULT_QUANTITY,
      PromotionCartLines: promotionCartLine,
      SubLines: newSubLine,
      PdpPromotionCode: pdpPromoCode
    };

    if (isEditing) {
      if (isClientTelingUser) {
        const updateParams = {
          Lines: [{ ...params }]
        };

        dispatch(updateSOCartLine(updateParams));
      } else {
        dispatch(updateOneCartLine(params));
      }
    } else {
      if (isClientTelingUser) {
        dispatch(addToSOCart({...params, soCartStatuses}));
      } else {
        dispatch(addToCart(params));
      }
    }
  };

  const onClickAddToCartBtn = (DataLastest) => {
    setFirstLoading(false);

    setCheckValidate(true);

    // const onGetProductsAreNotSelectedAdditionalOptions = (data) => {
    //   return data.filter((options) => {
    //     return options.AdditionalOptions.some((additionalOption) => {
    //       return additionalOption.Products.every((option) => {
    //         return option.IsChoseAdditionalOption === false;
    //       });
    //     });
    //   });
    // };

    // const onCheckProductIsSelectedAllAdditionalOptions = (data) => {
    //   return data.AdditionalOptions.every((additionalOption) => {
    //     return additionalOption.Products.some((product) => {
    //       return product.IsChoseAdditionalOption === true;
    //     });
    //   });
    // };

    if (passValidate) {
      onAddToCartHandler(DataLastest);
    } else {
      const boHeaderHeight = 64;
      const targetProductUniqueId = dataFields['Validate Product Pass'].filter(item => !item.isProductPass)[0]?.UniqueProductId;
      // const additionalOptionsProduct = onGetProductsAreNotSelectedAdditionalOptions(dataFields.Products);

      if (targetProductUniqueId) {
        const targetProduct = dataFields['Products'].filter(product => product.UniqueProductId === targetProductUniqueId)[0];
        let validateList = {};

        if (targetProduct.AdditionalOptions.length) {
          validateList = {
            [`${targetProduct['UniqueProductId']}VariantID`]: targetProduct.isChoseVariant,
            [`${targetProduct['UniqueProductId']}UniqueAddID`]: targetProduct.isChoseAdd,
            [`${targetProduct['UniqueProductId']}WarrantyID`]: targetProduct.isChoseWarranty,
          };
        } else {
          validateList = {
            [`${targetProduct['UniqueProductId']}VariantID`]: targetProduct.isChoseVariant,
            [`${targetProduct['UniqueProductId']}WarrantyID`]: targetProduct.isChoseWarranty,
          };
        }

        const keys = Object.keys(validateList).filter(key => validateList[key] === false)[0];

        const listRefs = refs.current;

        for (const property in listRefs) {
          if (!listRefs[property]?.current) {
            delete listRefs[property];
          }
        }

        window.scrollTo({
          top: listRefs[keys].current.getBoundingClientRect().top + window.scrollY - boHeaderHeight,
          behavior: 'smooth'
        });

        // if (onCheckProductIsSelectedAllAdditionalOptions(targetProduct)) {
        //   const validateList = {
        //     [`${targetProduct['UniqueProductId']}VariantID`] : targetProduct.isChoseVariant,
        //     [`${targetProduct['UniqueProductId']}WarrantyID`]: targetProduct.isChoseWarranty,
        //   };

        //   console.log('validateList: ', validateList);

        //   const keys = Object.keys(validateList).filter(key => validateList[key] === false)[0];

        //   const listRefs = refs.current;

        //   window.scrollTo({
        //     top: listRefs[keys].current.getBoundingClientRect().top + window.scrollY - boHeaderHeight,
        //     behavior: 'smooth'
        //   });
        // } else {
        //   const validateList = {
        //     [`${targetProduct['UniqueProductId']}VariantID`] : targetProduct.isChoseVariant,
        //     [`${targetProduct['UniqueProductId']}AdditionalOptionsID`] : onCheckProductIsSelectedAllAdditionalOptions(targetProduct),
        //     [`${targetProduct['UniqueProductId']}WarrantyID`]: targetProduct.isChoseWarranty,
        //   };

        //   const keys = Object.keys(validateList).filter(key => validateList[key] === false)[0];

        //   const listRefs = refs.current;

        //   window.scrollTo({
        //     top: listRefs[keys].current.getBoundingClientRect().top + window.scrollY - boHeaderHeight,
        //     behavior: 'smooth'
        //   });

        //   console.log('validateList: ', Object.keys(validateList));
        // }
      } else if (SelectedFreeGift.length !== dataBundle['Chosen Bundle'].FreeGift.length) {
        window.scrollTo({
          top: freeGiftRef.current.getBoundingClientRect().top + window.scrollY - boHeaderHeight,
          behavior: 'smooth'
        });
      }
    }
  };

  const sumTotalBundleFunction = (freeItemId, additionalOptionsPrice) => {
    let totalSellingPrice;

    let totalListPrice;

    const totalWarranty = sumTotal(dataFields['Active Warranty All'], 'SellingPrice', true);

    if (isDynamic === true) {
      const totalSellingPriceVariant = !dataFields.IsFixedPrice ?
        sumTotal(dataFields['Active Variant All'].filter(item => item.UniqueProductId !== freeItemId), 'SellingPrice')
        : dataFields.Price.SellingPrice;

      const totalListPriceVariant = sumTotal(dataFields['Active Variant All'], 'ListPrice');

      totalSellingPrice = totalSellingPriceVariant + totalWarranty + additionalOptionsPrice;

      totalListPrice = totalListPriceVariant + totalWarranty + additionalOptionsPrice;
    } else {
      totalSellingPrice = dataFields.Price['SellingPrice'] !== 0 ? dataFields.Price['SellingPrice'] + totalWarranty + additionalOptionsPrice : 0;

      totalListPrice = dataFields.Price['ListPrice'] !== 0 ? dataFields.Price['ListPrice'] + totalWarranty + additionalOptionsPrice : 0;
    }

    const totalSellingPriceText = `${global.formatDynamicCurrency(totalSellingPrice, dataBundle.Currency?.Symbol, dataBundle.Currency?.SymbolPosition, dataBundle.Currency?.CurrencyNumberFormatCulture, dataBundle.Currency?.NumberFormat?.CurrencyDecimalDigits)}`;

    const totalListPriceText = `${global.formatDynamicCurrency(totalListPrice, dataBundle.Currency?.Symbol, dataBundle.Currency?.SymbolPosition, dataBundle.Currency?.CurrencyNumberFormatCulture, dataBundle.Currency?.NumberFormat?.CurrencyDecimalDigits)}`;

    setPrice({
      totalSellingPrice: totalSellingPriceText,
      totalListingPrice: totalListPriceText,
      totalSellingPriceNumber: totalSellingPrice || totalListPrice
    });
  };

  const renderStatusLabel = (data) => {
    const newData = data.filter(item => item !== null);

    if (newData.some(item => item.Stock.FinalStatus.DeliveryLabel === 'Out Stock Label')) {
      setBundleDeliveryStatus({
        'Delivery Status': {
          value: dataLabel['Out Stock Label']
        }
      });
    } else if (newData.some(item => item.Stock.FinalStatus.DeliveryLabel === 'In Stock With PreOrder Label')) {
      setBundleDeliveryStatus({
        'Delivery Status': { value: dataLabel['In Stock With PreOrder Label'] }
      });
    } else if (newData.some(item => item.Stock.FinalStatus.DeliveryLabel === 'In Stock With BackOrder Label')) {
      setBundleDeliveryStatus({
        'Delivery Status': { value: dataLabel['In Stock With BackOrder Label'] }
      });
    } else {
      setBundleDeliveryStatus({
        'Delivery Status': { value: dataLabel['In Stock Label'] }
      });
    }
  };

  const onFilterAdditionalOptionsListHandler = (products, relationshipsList) => {
    let tempRelationshipsList = {};
    let tempAdditionalOptionsList = { ...products };

    relationshipsList['Additional Options Setting'].fields['Allowed Options'].forEach((allowedOption) => {
      tempRelationshipsList[allowedOption?.fields?.Relationship?.fields?.['Field Name']?.value] = allowedOption;
    });

    setAllowedOptions(tempRelationshipsList);

    tempAdditionalOptionsList.Products.forEach((product) => {
      product.AdditionalOptions.forEach((additionalOption, index) => {
        if (!tempRelationshipsList[additionalOption.RelationshipId]) {
          product.AdditionalOptions.splice(index, 1);
        }
      });
    });

    return tempAdditionalOptionsList || [];
  };

  const onSelectAdditionalOptionsHandler = (additionalOptionId, relationshipId, productId, uniqueProductId, itemIndex) => {
    let tempDataFields = { ...dataFields };

    tempDataFields.Products.forEach((product, productIndex) => {
      if (product.ProductId === productId && productIndex == itemIndex.charAt(0)) {
        product.AdditionalOptions.forEach((additionalOption, additionalOptionIndex) => {
          if (additionalOption.RelationshipId === relationshipId) {
            additionalOption.Products.forEach((childProduct, childProductIndex) => {
              if (childProduct.ProductId === additionalOptionId && `${productIndex}${additionalOptionIndex}${childProductIndex}` === itemIndex) {
                childProduct.IsChoseAdditionalOption = true;
              } else {
                childProduct.IsChoseAdditionalOption = false;
              }
            });
          }
        });

        product.isChoseAdd = true;
      }
    });

    const productIndex = dataFields.Products.findIndex(obj => obj.UniqueProductId == uniqueProductId);

    const newProductPass = tempDataFields.Products[productIndex].isChoseVariant && tempDataFields.Products[productIndex].isChoseWarranty && tempDataFields.Products[productIndex].isChoseAdd ? true : false;

    tempDataFields.Products[productIndex].isProductPass = newProductPass;

    tempDataFields['Validate Product Pass'][productIndex].isProductPass = newProductPass;

    setDataFields({ ...tempDataFields });
  };

  return (
    <Fragment>
      {dataFields && dataTextFields && allowedOptions ? (
        <>
          {isError ?
            <ErrorComponent>
              {errorMsg}
            </ErrorComponent>
            : (
              <Fragment>
                <BundleBOHeader
                  bundleName={dataFields['BundleName']}
                  totalSellingPriceText={Price.totalSellingPrice}
                  totalListPriceText={Price.totalListingPrice}
                >
                  <div className='buying-option-header__btn'>
                    <BundleBOAddToCartButton
                      dataFields={dataFields} textDataFields={dataBundle['Text Data']}
                      textFields={isEditing ? dataTextFields['Edit Cart Line Text'].value : dataTextFields['Add To Cart Text'].value}
                      onClickAddHandler={onClickAddToCartBtn}
                    />
                  </div>
                </BundleBOHeader>
                <div className='animated-slow fadeIn delay-100'>
                  <div className='chosen-bundle-buying-options'>
                    <div className={'buying-option'}>
                      <div className='container'>
                        <div className='buying-option__bundle-wrap'>
                          <a href={dataFields['Url']} className='btn-link-back'>
                            {dataTextFields['Back To Product Detail Label'].value}
                          </a>
                          {dataFields['Products'].map((item, itemIdx, arr) => {
                            if (arr.length - 1 === itemIdx) {
                              // last one
                              return (
                                <div className='buying-option__bundle-wrap__item' key={itemIdx}>
                                  <div className='row'>
                                    <div className='col-lg-6 buying-option__left'>
                                      <BundleBOImages
                                        productId={item.ProductId}
                                        variantActive={item['Active Variant']}
                                      />
                                    </div>
                                    <div className='col-lg-6 buying-option__right'>
                                      <h4 className='buying-option__right__productname'>
                                        {item.ProductName}
                                        {isDynamic && !dataFields.IsFixedPrice
                                          ? (
                                            <span className={'free-text' + `${item.UniqueProductId === freeItemId ? ' active' : ''}`}>
                                              {messageObj?.['LBL-Free'] ? `(${messageObj['LBL-Free']})` : ''}</span>
                                          )
                                          : null}
                                      </h4>
                                      <div className='buying-option__group-info'>
                                        <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                          ref={refs.current[`${item['UniqueProductId']}VariantID`]}>
                                          <BundleBOVariants
                                            dataItem={item}
                                            checkValidate={checkValidate}
                                            onSelectVariantHandler={onSelectVariantHandler}
                                            dataText={dataTextFields}
                                            variantSelected={combinedItems(deepCopy(variantSelected))}
                                          />
                                        </div>
                                        {item?.AdditionalOptions.map((option, index) => {
                                          return (
                                            <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                              key={index}
                                              ref={refs.current[`${item['UniqueProductId']}UniqueAddID`]}
                                            >
                                              <BundleBOAdditionalOptions
                                                dataItem={option}
                                                dataFromLayout={allowedOptions}
                                                dataText={dataTextFields}
                                                onSelectAdditionalOptionsHandler={onSelectAdditionalOptionsHandler}
                                                productId={item.ProductId}
                                                checkValidate={checkValidate}
                                                UniqueProductId={item.UniqueProductId}
                                                additionalOptionIndex={`${itemIdx}${index}`}
                                                disposalPolicyLabel={item.DisposalPolicyLabel}
                                                disposalPolicyPage={item.DisposalPolicyPage}
                                              />
                                            </div>
                                          );
                                        })}
                                        <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                          ref={refs.current[`${item['UniqueProductId']}WarrantyID`]}>
                                          <BundleBOWarranty
                                            dataItem={item}
                                            checkValidate={checkValidate}
                                            onSelectWarrantyHandler={onSelectWarrantyHandler}
                                            dataText={dataTextFields}
                                          />
                                        </div>
                                        <div className='buying-option__right__item buying-option__group-info__line-bottom freegift-buying'
                                          ref={freeGiftRef}>
                                          <BundleBOFreeGifts
                                            dataItem={dataBundle['Chosen Bundle'].FreeGift}
                                            checkValidate={checkValidate}
                                            onSelectFreeGiftHandler={onSelectFreeGiftHandler}
                                            freeGiftSelected={SelectedFreeGift}
                                            dataText={dataTextFields}
                                            isEditing={isEditing}
                                          />
                                        </div>
                                      </div>
                                      {!isClientTelingUser ? (
                                        <>
                                          <BundleBOPaymentInfo
                                            dataTextFields={dataTextFields}
                                            totalSellingPriceText={Price.totalSellingPrice}
                                            totalListPriceText={Price.totalListingPrice}
                                            deliveryOptions={dataFields.DeliveryOptions}
                                            bundleDeliveryStatus={bundleDeliveryStatus}
                                            isDynamic={isDynamic}
                                            totalSellingPrice={Price.totalSellingPriceNumber}
                                            paymentGateways={dataBundle?.['Payment Gateways']}
                                          />
                                          <div className='buying-option__btn text-center'>
                                            <div className='buying-option__btn__item'>
                                              <BundleBOAddToCartButton
                                                dataFields={dataFields} textDataFields={dataBundle['Text Data']}
                                                textFields={isEditing ? dataTextFields['Edit Cart Line Text'].value : dataTextFields['Add To Cart Text'].value}
                                                onClickAddHandler={onClickAddToCartBtn}
                                              />
                                            </div>
                                            <div className='buying-option__btn__item'>
                                              <Link field={dataTextFields['CTA Button']} className='buying-option__btn__continue-shopping btn-link'>
                                                <Text field={dataTextFields['CTA Button Label']}></Text>
                                              </Link>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  {isClientTelingUser ? (
                                    <div className='buying-option-cp__wrapper__content'>
                                      <BundleBOPaymentInfo
                                        dataTextFields={dataTextFields}
                                        totalSellingPriceText={Price.totalSellingPrice}
                                        totalListPriceText={Price.totalListingPrice}
                                        deliveryOptions={dataFields.DeliveryOptions}
                                        bundleDeliveryStatus={bundleDeliveryStatus}
                                        isDynamic={isDynamic}
                                        totalSellingPrice={Price.totalSellingPriceNumber}
                                        paymentGateways={dataBundle?.['Payment Gateways']}
                                        onAddToCartHandler={() => onClickAddToCartBtn(dataFields)}
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <div className='buying-option__bundle-wrap__item' key={itemIdx}>
                                  <div className='row'>
                                    <div className='col-lg-6 buying-option__left'>
                                      <BundleBOImages
                                        productId={item.ProductId}
                                        variantActive={item['Active Variant']}
                                      />
                                    </div>
                                    <div className='col-lg-6 buying-option__right'>
                                      <h4 className='buying-option__right__productname'>
                                        {item.ProductName}
                                        {isDynamic && !dataFields.IsFixedPrice
                                          ? (
                                            <span className={'free-text' + `${item.UniqueProductId === freeItemId ? ' active' : ''}`}>
                                              {messageObj?.['LBL-Free'] ? `(${messageObj['LBL-Free']})` : ''}</span>
                                          )
                                          : null}
                                      </h4>
                                      <div className='buying-option__group-info'>
                                        <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                          ref={refs.current[`${item['UniqueProductId']}VariantID`]}>
                                          <BundleBOVariants
                                            dataItem={item}
                                            checkValidate={checkValidate}
                                            onSelectVariantHandler={onSelectVariantHandler}
                                            dataText={dataTextFields}
                                            variantSelected={combinedItems(deepCopy(variantSelected))}
                                          />
                                        </div>
                                        {item?.AdditionalOptions.map((option, index) => {
                                          return (
                                            <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                              key={index}
                                              // ref={refs.current[`${option.RelationshipId}`]}
                                              ref={refs.current[`${item['UniqueProductId']}UniqueAddID`]}
                                            >
                                              <BundleBOAdditionalOptions
                                                dataItem={option}
                                                dataFromLayout={allowedOptions}
                                                dataText={dataTextFields}
                                                onSelectAdditionalOptionsHandler={onSelectAdditionalOptionsHandler}
                                                productId={item.ProductId}
                                                checkValidate={checkValidate}
                                                UniqueProductId={item.UniqueProductId}
                                                additionalOptionIndex={`${itemIdx}${index}`}
                                                disposalPolicyLabel={item.DisposalPolicyLabel}
                                                disposalPolicyPage={item.DisposalPolicyPage}
                                              />
                                            </div>
                                          );
                                        })}
                                        <div className='buying-option__right__item buying-option__group-info__line-bottom'
                                          ref={refs.current[`${item['UniqueProductId']}WarrantyID`]}>
                                          <BundleBOWarranty
                                            dataItem={item}
                                            checkValidate={checkValidate}
                                            onSelectWarrantyHandler={onSelectWarrantyHandler}
                                            dataText={dataTextFields}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  isLoading ? <LoadingMask parent={BundleBuyingOptionLayout}></LoadingMask> : null
                }
              </Fragment>
            )
          }
        </>
      ) : (
        <Fragment>
          {firstLoading ? (
            <LoadingShimmer itemNumb={1}>
              <BundleBuyingOptionLayoutShimmer></BundleBuyingOptionLayoutShimmer>
            </LoadingShimmer>
          ) : (
            <LoadingMask parent={BundleBuyingOptionLayout}></LoadingMask>
          )}
        </Fragment>
      )
      }
    </Fragment >
  );

};

BundleBuyingOptionLayout.propTypes = {
  dataBundle: Proptypes.any,
  isEditing: Proptypes.any,
  EditQuantity: Proptypes.any,
  isLoading: Proptypes.any,
  isActionSuccess: Proptypes.any,
  isToggleChangePage: Proptypes.any,
  isFindCartLine: Proptypes.any,
  cartLineIdToSuccessPage: Proptypes.any,
  errorMsg: Proptypes.any,
  isError: Proptypes.any,
  editSelectedFreeGift: Proptypes.any,
  soCartStatuses: Proptypes.object
};

const mapStateToProps = (state) => {
  const bundleBOReducer = state.bundleBOReducer;
  const soCartReducer = state.productReducer.cartDetailReducer;

  return {
    EditQuantity: bundleBOReducer.EditQuantity || soCartReducer.EditQuantity || null,
    isLoading: bundleBOReducer.isLoading || soCartReducer.isLoading || false,
    isActionSuccess: bundleBOReducer.isActionSuccess || soCartReducer.isDoneAddToSOCart || false,
    isToggleChangePage: bundleBOReducer.isToggleChangePage || false,
    isFindCartLine: bundleBOReducer.isFindCartLine || null,
    cartLineIdToSuccessPage: bundleBOReducer.cartLineIdToSuccessPage || null,
    errorMsg: bundleBOReducer.errorMsg || '',
    isError: bundleBOReducer.isError || false,
    editSelectedFreeGift: bundleBOReducer.editSelectedFreeGift || null,
    soCartStatuses: state.cpSaleOrderStatusesReducer.soCartStatuses
  };
};


export default connect(mapStateToProps)(BundleBuyingOptionLayout);
