import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { HK_SITE_NAME, MAXIMUM_ADDRESS_LENGTH, TH_SITE_NAME, TW_SITE_NAME } from '@utils/constant';

import { addressKeys } from '../SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage/configs';

// const requireAddressPropertiesList = ['FirstName', 'LastName', 'Address1', 'PhoneNumber', 'Postcode'];

export const useCheckAddressValid = () => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const userLogged = SitecoreContextFactoryService.getValueContextItem('user');
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const requiredAddressFields = useMemo(() => settingGlobal?.RequiredAddressFields || [], [settingGlobal]);

  return useCallback(
    (addressData) => {
      // const requireAddressKeysList = [...requireAddressPropertiesList];
      // if (currentSiteName === HK_SITE_NAME) {
      //   const indexOfPostCodeProperty = requireAddressKeysList.indexOf('Postcode');
      //
      //   requireAddressKeysList.splice(indexOfPostCodeProperty, 1);
      //
      //   requireAddressKeysList.push('Address2', 'City');
      //
      //   if (userLogged) requireAddressKeysList.push('AddressName');
      //   if (addressData?.CityCode !== 'Macau') {
      //     requireAddressKeysList.push('Area', 'District', 'SubDistrict');
      //   }
      // }
      // if (currentSiteName === MY_SITE_NAME) {
      //   requireAddressKeysList.push('State', 'City');
      // }
      // if (currentSiteName === TH_SITE_NAME) {
      //   requireAddressKeysList.push('City');
      // }

      return addressData
        ? Object.entries(addressData).every(([key, value]) => {
          if (currentSiteName !== TH_SITE_NAME) {
            if (addressKeys.includes(key) && (
              /** INFO: ignore checking Address1 character length for HK and TW site */
              (
                currentSiteName === TW_SITE_NAME || currentSiteName === HK_SITE_NAME
              ) && key !== addressKeys[0]
            )) {
              return (
                value?.length ?? 0
              ) <= MAXIMUM_ADDRESS_LENGTH;
            }
          }
          if (requiredAddressFields.includes(key)) {
            return Boolean(value);
          }

          return true;
        })
        : false;
    },
    [currentSiteName, userLogged, requiredAddressFields]
  );
};

export const useCheckDeliveryAddressValid = () => {
  const checkAddressValid = useCheckAddressValid();

  const {
    isLoadingCustomCart,
    currentCartCustom
  } = useSelector((state) => state.productReducer.cartDetailReducer);

  const {
    isLoading: isLoadingClientSOCart,
    customData: clientSOCartCustomData
  } =
  useSelector((state) => state.personalizeOfferReducer.getClientSOCartReducer) || null;

  const cartData = useMemo(() => {
    return clientSOCartCustomData || currentCartCustom;
  }, [clientSOCartCustomData, currentCartCustom]);

  const isLoading = useMemo(() => {
    return isLoadingClientSOCart || isLoadingCustomCart;
  }, [isLoadingClientSOCart, isLoadingCustomCart]);

  return useMemo(() => {
    if (!isLoading || cartData) {
      const isBillingAddressValid = checkAddressValid(cartData?.BillingAddress);
      const deliveryAddress = cartData?.Delivery?.[0]?.Address;
      const isAddressValid = deliveryAddress ? checkAddressValid(deliveryAddress) : true;

      return {
        isValid: isBillingAddressValid && isAddressValid,
        isAddressValid,
        isBillingAddressValid
      };
    }
  }, [isLoading, cartData]);
};
