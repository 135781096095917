import './MyRewardsWidget.scss';

import { any } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyRewardWidgetInfoStart } from '@redux/actions/loyalty';
import { getPosCustomerId } from '@redux/actions/loyalty/getPosCustomerId';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { LOYALTY_DEFAULT_TIER_VALUE } from '@utils/constant';

import Loading from '../Share/Loading';
import IsMemberLoyalty from './IsMemberLoyalty';
import IsNotMemberLoyalty from './IsNotMemberLoyalty';
import MyRewardsWidgetModel from './models/MyRewardsWidgetModel';

const MyRewardsWidget = ({ fields }) => {
  const dispatch = useDispatch();
  const { isLoading, dataCrm } = useSelector((state) => state.loyaltyReducer.getCrmCustomerReducer);
  const [layoutData, setLayoutData] = useState(null);
  const countryCode = SitecoreContextFactoryService.getValueContextItem('site');

  useEffect(() => {
    let model = new MyRewardsWidgetModel();

    setLayoutData(model.getData(fields || {}));
  }, []);

  useEffect(() => {
    // dispatch(getMyRewardWidgetInfoStart());

    dispatch(
      getPosCustomerId({
        country: countryCode.name.replace('O', '')
      })
    );
  }, []);

  const isMember = useMemo(() => {
    if (Object.keys(dataCrm).length) {
      const crmDataDetails = dataCrm.Data;

      return !!crmDataDetails && crmDataDetails?.crm_tier !== LOYALTY_DEFAULT_TIER_VALUE;
    }

    return false;
  }, [dataCrm]);

  return layoutData ? (
    <div className='container my-reward-widget'>
      <div className='my-account-section my-reward-widget__section'>
        <div className='my-account-section__heading my-reward-widget__heading animated-slow fadeIn delay-100'>
          <div className='my-account-section__group'>
            <Text tag='h3' field={layoutData['Title']} className='my-account-section__heading__title' />
            {layoutData['Manage Label'].value ? (
              <Link className='my-account-section__btn-link' field={layoutData['Manage Link']}>
                <Text field={layoutData['Manage Label']} />
              </Link>
            ) : (
              <Link className='my-account-section__btn-link' field={layoutData['Manage Link']} />
            )}
          </div>
        </div>
        {!isLoading ? isMember ? <IsMemberLoyalty layoutData={layoutData} /> :
          <IsNotMemberLoyalty layoutData={layoutData} /> : <Loading />}
      </div>
    </div>
  ) : (
    <></>
  );
};

MyRewardsWidget.propTypes = {
  fields: any
};

export default MyRewardsWidget;
