import './CustomerProductPage.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { deepCopy } from '@utils/utility';

import Loading from '../../Share/Loading';
import CPPageHeader from '../CPPageHeader';
import ProductItemViewAll from '../GenericComponents/ProductItemViewAll/index';
import CustomerProductPageModel from './Models/CustomerProductPageModel';

const CustomerProductPage = ({ fields }) => {
  const [productFields, setProductFields] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const customerProducts = useSelector((state) => state.clientTellingPortalCustomer?.customerProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    const productPage = new CustomerProductPageModel();

    setProductFields(productPage.getData(deepCopy(fields || {})));

    let url = new URL(window.location.href);

    dispatch(
      getCPCustomer({
        id: url.searchParams.get('contactId'),
        fromURL: true,
        isGetCustomerProduct: true
      })
    );
  }, []);

  const renderProductItem = () =>
    customerProducts?.map((productItem, index) => (
      <ProductItemViewAll isWishList={false} ProductFields={productFields} ProductsItem={productItem} key={index} />
    ));

  return (
    productFields &&
    (clientTellingPortalCustomer.isLoading ? (
      <LoadingMask parent={CustomerProductPage} />
    ) : (
      <>
        <CPPageHeader
          title={{
            value: productFields['Section Title'].value.replace(
              '{customer-name}',
              `${clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.FirstName || ''} ${
                clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.LastName || ''
              }`
            )
          }}
          description={{ value: productFields['Description'].value.replace('{products-total}', `${customerProducts?.length}`) }}
        />
        <div className='customer-product'>
          <div className='container tablet-container'>
            <div className='customer-product__list'>{renderProductItem()}</div>
          </div>
        </div>
      </>
    ))
  );
};

CustomerProductPage.propTypes = {
  fields: any
};

export default CustomerProductPage;
