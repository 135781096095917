import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { mediaTrackingGoalFailed, mediaTrackingGoalSuccess } from '@redux/actions/trackingGoal/mediaTracking';
import { MEDIA_START_TRACKING_GOAL } from '@redux/actions/trackingGoal/mediaTracking/mediaTrackingTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

export const mediaTrackingGoalEpic = (action$) =>
  action$.pipe(
    ofType(MEDIA_START_TRACKING_GOAL),
    switchMap((action) => {
      return postAsObservable(config.API_TRACKING_GOAL, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return mediaTrackingGoalSuccess({
              ...action.payload,
              success: true
            });
          } else {
            return mediaTrackingGoalFailed('Failed');
          }
        }),
        catchError((error) => of(mediaTrackingGoalFailed(error)))
      );
    })
  );