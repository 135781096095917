import './MyReviews.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyReviewsStart } from '@redux/actions/myReviews';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ProductReviewContent from '../ReviewPage/ProductReviewContent';
import LoadingMask from '../Share/LoadingMask';
import MyReviewsModel from './Models/MyReviewsModel';

const MyReviews = ({ fields }) => {
  const dispatch = useDispatch();
  const { isLoading, myReviewsData, totalRecord } = useSelector((state) => state.getMyReviewsReducer);

  const PAGE_SIZE_STEP = 10;
  const LIMIT_DISPLAY_OF_REVIEW = 10;

  const [layoutData, setLayoutData] = useState(null);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  useEffect(() => {
    let model = new MyReviewsModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    dispatch(getMyReviewsStart(currentPageSize));
  }, []);

  const handleClickChangePage = () => {
    const totalPage = Math.ceil(totalRecord / PAGE_SIZE_STEP);
    const nextPageSize = currentPageSize + PAGE_SIZE_STEP;
    const currentPage = Math.ceil(nextPageSize / PAGE_SIZE_STEP);

    if (totalPage >= currentPage) {
      dispatch(getMyReviewsStart(nextPageSize));

      setCurrentPageSize(nextPageSize);
    }
  };

  return layoutData ? (
    <div className='productReviewListing product-review-view-all'>
      <div className='container'>
        <div className='product-review__body'>
          {myReviewsData?.map((reviewItem, index) => (
            <div key={index} className='productReviewListing__content product-review__body-container'>
              <div className='product-review__body-left'>
                <div className='product-image'>
                  <div className='product-image__wrapper'>
                    <img src={reviewItem.ProductImage} alt={reviewItem.ProductName} />
                  </div>
                </div>
                <p className='product-name'>{reviewItem.ProductName}</p>
              </div>
              <div className='review-listing review-listing--full'>
                <div className='review-listing__list'>
                  <div className='review-listing__item'>
                    <ProductReviewContent reviewItem={reviewItem} layoutData={layoutData} isNavigateToProductDetail={false} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {totalRecord > LIMIT_DISPLAY_OF_REVIEW && (
            <div className='review-listing__btn text-center'>
              <button
                className={`review-listing__btn__item review-listing__btn__item--large btn btn-outline-CTA2 ${
                  currentPageSize >= totalRecord ? 'disable-event' : ''
                }`}
                onClick={handleClickChangePage}
              >
                <i className='icon-chevron-down-big'></i>
                <Text tag='span' field={layoutData['Show More Review Text']} />
              </button>
            </div>
          )}
          {isLoading && <LoadingMask parent={MyReviews} />}
        </div>
      </div>
    </div>
  ) : (
    <LoadingMask parent={MyReviews} />
  );
};

MyReviews.propTypes = {
  fields: Proptypes.any
};

export default MyReviews;
