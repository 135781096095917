import '../UserAddressComponent/AddressComponent.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import AddNewAddress from '@components/SlicingPage/MyAccount/MyAddress/AddNewAddress';
import { setAddressValidErrorMessage,updateBillingAddress } from '@redux/actions/checkoutProcess';
import {
  checkAddressErrorMessageSelector
} from '@redux/reducers/checkoutProcess/setAddressValidErrorMessage/selector';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import { addressErrorMessagePlaced } from '../../configs';
import { BILLING_ADDRESS_COMPONENT, BILLING_ADDRESS_SECTION_ID } from '../../ContinuePaymentBtn/configs';
import { useCheckAddressValid } from '../../hooks';
import SelectAddressPopup from '../UserAddressComponent/SelectAddressPopup';
import { behaviorUserAddressComponent } from '../UserAddressComponent/UserAddressComponentBehavior';

const BillingAddressComponent = ({ userAddress, layoutData, page, isLogin, cartData, showError, isLoading, isMyOrder }) => {
  const dispatch = useDispatch();
  const checkAddressValid = useCheckAddressValid();
  const [showPopup, setShowPopup] = useState(false);
  const [listShowAddress, setListShowAddress] = useState(null);
  const [sameAsDelivery, setSameAsDelivery] = useState(false);
  const [sameAsDeliveryBySFExpress, setSameAsDeliveryBySFExpress] = useState(false);
  const [billingAddresCheckbox, setBillingAddressCheckbox] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState({});
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const { section: sectionToShowMessage, isValid: isDeliveryAddressValid } = useSelector(checkAddressErrorMessageSelector);

  const [inValidAddress, setInValidAddress] = useState({
    isShow: false,
    placement: addressErrorMessagePlaced.RIGHT_PLACE
  });

  useEffect(() => {
    const behaviorUserAddressComponentSubscription = behaviorUserAddressComponent.subscribe((params) => {
      setBillingAddressCheckbox(params);
    });

    return () => {
      behaviorUserAddressComponentSubscription.unsubscribe();
    };
  }, [behaviorUserAddressComponent]);

  useDidUpdateEffect(() => {
    if (billingAddresCheckbox) {
      setSameAsDelivery(false);

      setSameAsDeliveryBySFExpress(false);
    }
  }, [billingAddresCheckbox]);

  useEffect(() => {
    if (!cartData.BillingAddress && userAddress && userAddress.length > 0 && page !== 'review') {
      const billingAddress = userAddress.find((item) => {
        return item.IsBilling;
      });

      if (billingAddress) {
        dispatch(updateBillingAddress(removeRedundantField(billingAddress)));
      }
    }
  }, [cartData, userAddress]);

  useEffect(() => {
    if (userAddress && userAddress.length > 0) {
      const listShowAddress = [];

      const billingDefault = userAddress.find((item) => {
        return item.IsBilling;
      });

      setDefaultAddress(billingDefault);

      const selectedAddress = userAddress.find((item) => {
        return item.ExternalId === cartData.BillingAddress?.ExternalId;
      });

      if (billingDefault) {
        listShowAddress.push(billingDefault);
      }
      if (selectedAddress && selectedAddress.ExternalId !== billingDefault?.ExternalId) {
        listShowAddress.push(selectedAddress);
      }

      userAddress.forEach((item) => {
        if (item.ExternalId !== billingDefault?.ExternalId && item.ExternalId !== selectedAddress?.ExternalId && listShowAddress.length < 3) {
          listShowAddress.push(item);
        }
      });

      setListShowAddress(listShowAddress);
    }
  }, [userAddress, cartData?.BillingAddress]);

  useEffect(() => {
    if (cartData.Party && sameAsDelivery && cartData.Party.ExternalId !== cartData.BillingAddress?.ExternalId) {
      dispatch(updateBillingAddress(removeRedundantFieldForUpdateBillingAddressAPI(cartData?.Delivery?.[0]?.Address)));
    }
  }, [cartData?.Delivery?.[0]?.Address?.ExternalId]);

  useDidUpdateEffect(() => {
    if (sameAsDeliveryBySFExpress) {
      dispatch(updateBillingAddress(removeRedundantFieldForUpdateBillingAddressAPI(cartData?.DeliveryBySFExpress?.[0]?.Address)));
    }
  }, [cartData?.DeliveryBySFExpress?.[0]?.Address?.ExternalId]);

  const defaultSetFulfillmentPayload = {
    AreaCode: '',
    CityCode: '',
    ProvinceCode: '',
    Postcode: '',
    DistrictCode: '',
    SubDistrictCode: '',
    StateCode: '',
    AddressName: '',
    Address1: '',
    Address2: '',
    PhoneNumber: ''
  };

  const GAAddBillingInfo = () => {
    new GoogleAnalytic().ggGateWay('event', 'checkout_progress', {
      checkout_step: 2,
      checkout_option: 'billing info'
    });
  };

  const onClosePopupHandler = () => {
    setShowPopup(false);
  };

  const removeRedundantField = (item) => {
    const tempObj = deepCopy(item);

    delete tempObj.FullAddress;

    return tempObj;
  };

  const removeRedundantFieldForUpdateBillingAddressAPI = (item) => {
    const tempObj = {
      ExternalId: item.ExternalId,
      FirstName: item.FirstName,
      LastName: item.LastName,
      AddressName: item.AddressName,
      Address1: item.Address1,
      Address2: item.Address2,
      Address3: item.Address3,
      Country: item.Country,
      CountryCode: item.CountryCode,
      State: item.State,
      StateCode: item.StateCode,
      City: item.City,
      CityCode: item.CityCode,
      Area: item.Area,
      AreaCode: item.AreaCode,
      District: item.District,
      DistrictCode: item.DistrictCode,
      SubDistrict: item.SubDistrict,
      SubDistrictCode: item.SubDistrictCode,
      Suburb: item.Suburb,
      IsPrimary: item.IsPrimary,
      PhoneNumber: item.PhoneNumber,
      Postcode: item.Postcode,
      IsBilling: false
    };

    return tempObj;
  };

  const setBillingAddress = (event, type) => {
    if (!event.currentTarget.checked) {
      setSameAsDelivery(false);

      setSameAsDeliveryBySFExpress(false);

      if (userAddress && userAddress.length > 0) {
        const defaultBilling = userAddress?.find((item) => {
          return item.IsBilling;
        });
        if (defaultBilling) {
          GAAddBillingInfo();

          dispatch(updateBillingAddress(removeRedundantField(defaultBilling)));
        }
      }
    } else {
      // if (cartData.Party) {
      GAAddBillingInfo();

      // dispatch(updateBillingAddress({ SameAsDeliveryAddress: true }));
      const invalidAddressMsgParmas = {
        isShow: true,
        placement: addressErrorMessagePlaced.LEFT_PLACE
      };
      if (type == 'delivery') {
        const updateAddressParams = removeRedundantFieldForUpdateBillingAddressAPI(cartData?.Delivery?.[0]?.Address);
        if (checkAddressValid(updateAddressParams)) {
          dispatch(updateBillingAddress(updateAddressParams));

          setSameAsDelivery(true);

          setSameAsDeliveryBySFExpress(false);

          invalidAddressMsgParmas.isShow = false;
        }
      }
      if (type == 'delivery-by-sf-express') {
        const updateAddressParams = removeRedundantFieldForUpdateBillingAddressAPI(cartData?.DeliveryBySFExpress?.[0]?.Address);
        if (checkAddressValid(updateAddressParams)) {
          dispatch(updateBillingAddress(updateAddressParams));

          setSameAsDeliveryBySFExpress(true);

          setSameAsDelivery(false);

          invalidAddressMsgParmas.isShow = false;
        }
      }

      // INFO: set invalid address message state
      setInValidAddress(invalidAddressMsgParmas);
      // }
    }
  };

  const setAddAddressEvtHandler = (value, isBilling) => {
    const setFulfillmentPayload = Object.assign(defaultSetFulfillmentPayload, value);

    if (isBilling) {
      GAAddBillingInfo();

      dispatch(updateBillingAddress(setFulfillmentPayload));

      setSameAsDelivery(false);
    }
  };

  const selectFromListAddress = (item, placementError = addressErrorMessagePlaced.RIGHT_PLACE) => {
    if (Object.keys(item).length !== 0) {
      GAAddBillingInfo();
      const isAddressValid = checkAddressValid(item);
      if (isAddressValid) {
        dispatch(updateBillingAddress(removeRedundantField(item)));

        setInValidAddress((prevState) => ({ ...prevState, isShow: false }));

        setSameAsDelivery(false);

        // INFO: dispatch to hide error message display when address valid
        dispatch(setAddressValidErrorMessage({ section: '', isValid: true }));

        onClosePopupHandler();
      } else {
        setInValidAddress((prevState) => ({
          ...prevState,
          placement: placementError,
          isShow: true
        }));
      }
    }
  };

  const isShowBillingAddressInvalidMsg = useMemo(() => {
    return !(showError && !cartData?.BillingAddress)
      ? (inValidAddress.isShow && inValidAddress.placement === addressErrorMessagePlaced.RIGHT_PLACE) ||
          (sectionToShowMessage === BILLING_ADDRESS_COMPONENT && !isDeliveryAddressValid)
      : false;
  }, [inValidAddress, sectionToShowMessage, showError, cartData, isDeliveryAddressValid]);

  return (
    <div id={BILLING_ADDRESS_SECTION_ID} className='address-component'>
      <h3 className='delivery-item-title'>
        <Text field={layoutData['Billing Address Label']} />
      </h3>
      <div className='delivery-row address-component__row'>
        <div className='delivery-col-left'>
          {page === 'review' || !cartData.IsDeliveryFee ? (
            ''
          ) : (
            <div className='address-component__line1'>
              <div className='auto-address checkout-checkbox'>
                <input
                  type='checkbox'
                  checked={sameAsDelivery}
                  // onClick={() => setBillingAddress()}
                  onChange={(event) => setBillingAddress(event, 'delivery')}
                  id='auto-address'
                  className='auto-address__input checkout-checkbox__input'
                />
                <label htmlFor='auto-address' className='checkout-checkbox__label'>
                  <Text field={layoutData['Billing Same as Delivery Address Label']} />
                </label>
              </div>
            </div>
          )}
          {page === 'review' || !cartData.IsDeliveryBySFExpressFee ? (
            ''
          ) : (
            <div className='address-component__line1'>
              <div className='auto-address checkout-checkbox'>
                <input
                  type='checkbox'
                  checked={sameAsDeliveryBySFExpress}
                  // onClick={() => setBillingAddress()}
                  onChange={(event) => setBillingAddress(event, 'delivery-by-sf-express')}
                  id='auto-address-sf'
                  className='auto-address__input checkout-checkbox__input'
                />
                <label htmlFor='auto-address-sf' className='checkout-checkbox__label'>
                  <Text field={layoutData['Billing Same as Delivery SF Express Address Label']} />
                </label>
              </div>
            </div>
          )}
          {cartData.BillingAddress && cartData.BillingAddress.FirstName ? (
            <div className='address-component__line2'>
              <strong>
                {isMyOrder ? '' : cartData.BillingAddress.AddressName}
                {cartData.BillingAddress.ExternalId === defaultAddress?.ExternalId ? (
                  <span className='address-default'>
                    (<Text field={layoutData['Default Label']} />)
                  </span>
                ) : (
                  ''
                )}
              </strong>
              {/* <span>{`${settingGlobal?.FullNameFormat.replace('{LastName}', cartData.BillingAddress.LastName).replace( */}
              <span>{`${settingGlobal?.FullNameFormat.replace('{LastName}', '').replace(
                '{FirstName}',
                cartData.BillingAddress.FirstName
              )}`}</span>
              <span dangerouslySetInnerHTML={{ __html: cartData.BillingAddress.FullAddress }}></span>
              <span>
                <Text field={layoutData['Phone Number']} />: {cartData.BillingAddress.PhoneNumber}
              </span>
            </div>
          ) : (
            <p className='address-component__no-address'>
              <Text field={layoutData['No Address Message']} />
            </p>
          )}
          {inValidAddress.isShow && inValidAddress.placement === addressErrorMessagePlaced.LEFT_PLACE ? (
            <Text tag='span' field={layoutData?.['Incomplete Address Message']} className='error-message invalid-address-msg' />
          ) : (
            <></>
          )}
        </div>
        {page === 'review' ? (
          ''
        ) : (
          <div className='delivery-col-right delivery-col-right--padding-left'>
            {showError && !cartData.BillingAddress ? (
              <p className='warning-message'>
                <Text field={layoutData['Please Select Address Message']} />
              </p>
            ) : (
              ''
            )}
            {isLogin && listShowAddress && listShowAddress.length > 0 ? (
              <>
                <ul className='address-component__list'>
                  {listShowAddress.map((item, ind) => {
                    return (
                      <li
                        onClick={() => selectFromListAddress(item)}
                        className={`address-component__list__item ${item.ExternalId === cartData.BillingAddress?.ExternalId ? 'selected' : ''}`}
                        key={ind}
                      >
                        <span className='item-name'>{`${item.AddressName ? item.AddressName : ''}, ${settingGlobal?.FullNameFormat.replace(
                          '{LastName}',
                          item.LastName
                        ).replace('{FirstName}', item.FirstName)}, ${item?.FullAddress ? item?.FullAddress.replace(/<br\/>/g, ', ') : ''}, ${
                          layoutData['Phone Number'].value
                        }: ${item.PhoneNumber ? item.PhoneNumber : ''}`}</span>
                        {item.IsBilling ? (
                          <small className='item-default'>
                            <Text field={layoutData['Default Label']} />
                          </small>
                        ) : (
                          ''
                        )}
                      </li>
                    );
                  })}
                </ul>
                {userAddress && userAddress.length > 3 ? (
                  <p onClick={() => setShowPopup(true)} className='address-component__button-see-more'>
                    <Text field={layoutData['Billing See More Address Label']} />
                  </p>
                ) : (
                  ''
                )}
              </>
            ) : null}
            <div className='address-component__button-add-new'>
              <AddNewAddress
                setAddAddressEvt={(value, isBilling) => setAddAddressEvtHandler(value, isBilling)}
                dataSources={layoutData}
                withSetBilling={true}
                toggleSameAsDeliveryEvt={() => setSameAsDelivery(false)}
                isAddNew={true}
                addressCurrent={null}
              />
            </div>
            {isLoading ? <LoadingMask parent={BillingAddressComponent}></LoadingMask> : ''}
            {isShowBillingAddressInvalidMsg ? (
              <Text tag='span' field={layoutData?.['Incomplete Address Message']} className='error-message invalid-address-msg' />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      <ModalComponent
        isShow={showPopup}
        titlePopup={<Text field={layoutData['PopUp Select Address Label']} tag='span' />}
        onCloseHandler={onClosePopupHandler}
      >
        <SelectAddressPopup
          idSelected={cartData.BillingAddress?.ExternalId}
          type='user'
          isDelivery={false}
          selectAddress={(event) => selectFromListAddress(event, addressErrorMessagePlaced.POPUP_PLACE)}
          listAddress={userAddress}
          layoutData={layoutData}
        ></SelectAddressPopup>
        {inValidAddress.isShow && inValidAddress.placement === addressErrorMessagePlaced.POPUP_PLACE ? (
          <Text tag='span' field={layoutData?.['Incomplete Address Message']} className='error-message invalid-address-msg' />
        ) : (
          <></>
        )}
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userAddress: state.checkoutProcesstReducer.addressListReducer?.userAddressList || [],
  isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin || false,
  guestData: state.checkoutProcesstReducer.dataCheckoutReducer.infoUser || {},
  userData: state.singleSignOnReducer.userInfoReducer.accountUser || {},
  isLoading: state.checkoutProcesstReducer.updateCartReducer.isLoadingBillingAddress
});

BillingAddressComponent.propTypes = {
  type: PropTypes.string,
  userAddress: PropTypes.array,
  layoutData: PropTypes.object,
  page: PropTypes.string,
  isLogin: PropTypes.bool,
  guestData: PropTypes.any,
  userData: PropTypes.any,
  cartData: PropTypes.any,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMyOrder: PropTypes.bool
};

export default connect(mapStateToProps)(BillingAddressComponent);
