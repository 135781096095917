import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { cpStoreSelector } from '@redux/actions/cPStoreSelector';
import { cpLogout } from '@redux/actions/singleSignOn';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CPAccountQuickAccessModels from '../Models/CPAccountQuickAccessModels';

const CPAccountQuickAccess = ({rendering, fields, isCPLogoutLoading, isSuccess, isCPLogoutSuccess}) => {
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  const cpLoginPageLink = SitecoreContextFactoryService.getValueContextItem('cpLoginPageLink');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isShowInforPopup, setIsShowInforPopup] = useState(false);
  const [cPAccountQuickAccessFields, setCPAccountQuickAccessFields] = useState(null);
  const [storeSelect, setStoreSelect] = useState(null);

  useEffect(() => {
    const cPAccountQuickAccessModels = new CPAccountQuickAccessModels();

    setCPAccountQuickAccessFields(cPAccountQuickAccessModels.getData(deepCopy(fields || {})));
  }, []);
  
  useEffect(() => {
    const getCPUserData = JSON.parse(window.localStorage.getItem('cPUserData')) || null;
    if(cPAccountQuickAccessFields) {
      let welcomeMessageTmp = cPAccountQuickAccessFields['Welcome Message'].value;

      if (welcomeMessageTmp) {
        welcomeMessageTmp = welcomeMessageTmp.replace('{user-first-name}', getCPUserData?.FullName || '');

        setWelcomeMessage(welcomeMessageTmp);
      }
    }
  }, [cPAccountQuickAccessFields]);

  const getDefaultStore = () => {
    const defaultStore = rendering.placeholders['cp-store']?.[0]?.fields?.['Default Store'];
    const firstStore = rendering.placeholders['cp-store']?.[0]?.fields?.Stores[0];

    return defaultStore || firstStore;
  };

  useEffect(() => {
    setStoreSelect(getDefaultStore());
  }, []);

  useDidUpdateEffect(() => {  
    if(!loginReducer.isCPLogin) {
      const getCPUserData = window.localStorage.getItem('cPUserData');
      const getUrlPage = window.sessionStorage.getItem('urlPage');

      if (getCPUserData && getUrlPage) {
        window.localStorage.removeItem('cPUserData');

        window.sessionStorage.removeItem('urlPage');
      }

      if (cpLoginPageLink) {
        window.location.href = cpLoginPageLink;
      }
    } 
  }, [loginReducer.isCPLogin]);

  useDidUpdateEffect(() => {
    if (isSuccess) {
      reloadPage();
    }
  }, [isSuccess]);

  useDidUpdateEffect(() => {
    if (isCPLogoutSuccess) {
      window.localStorage.removeItem('cPUserData');

      window.localStorage.removeItem('POSOrderIdLocal');
    }
  }, [isCPLogoutSuccess]);

  const handleSelectStore = (store) => {
    dispatch(cpStoreSelector({
      storeId: store.StoreId
    }));

    setStoreSelect(store);
  };

  const reloadPage = ()  => {
    window.location.reload();
  };

  useEffect(() => {
    if (rendering) {
      window.localStorage.setItem('storeList', JSON.stringify(rendering.placeholders['cp-store'][0].fields?.Stores));
    } else {
      window.localStorage.removeItem('storeList');
    }
  }, [rendering]);
  
  return cPAccountQuickAccessFields && (
    <>
      <div className='cp-header__col account-quick-access content-right'>
        <div 
          className='user-infor'
          onClick={() => setIsShowInforPopup(!isShowInforPopup)}>
          <div className='group-infor'>
            <p title={welcomeMessage} className='user-infor__name'>{welcomeMessage}</p>
            <p className='user-infor__store-name' title={currentStore?.storeName || storeSelect?.StoreName}>
              {currentStore?.storeName || storeSelect?.StoreName}
            </p>
          </div>
          <span className={`arrow-icon-down ${isShowInforPopup? 'popup-show':''}`}></span>
          {
            isShowInforPopup ? (
              <div className='overlay-transparent' onClick={() => setIsShowInforPopup(!isShowInforPopup)}></div>
            ) : ''
          }
          <div className={`cp-popup infor-popup ${isShowInforPopup? 'popup-show':''}`}>
            <Placeholder 
              name='cp-store' 
              rendering={rendering} 
              selectStoreLabel={cPAccountQuickAccessFields['Select Store Label']}
              handleSelectStore={handleSelectStore}
            />
            {/* <div className='group-name'>
              <Link field={cPAccountQuickAccessFields['Change Password Link']}>
                <Text className='group-name__link popup-sub-title' tag='h3' field={cPAccountQuickAccessFields['Change Password Label']} />
              </Link>
            </div> */}
            <div className='group-name' onClick={() => dispatch(cpLogout())}>
              <span>
                <Text className='group-name__link popup-sub-title' tag='h3' field={cPAccountQuickAccessFields['Log Out Label']} />
              </span>
            </div>
          </div>
        </div>
      </div>
      {isCPLogoutLoading ? <LoadingMask parent={CPAccountQuickAccess}/> : ''}
    </>
  );
};

CPAccountQuickAccess.propTypes = {
  fields: PropTypes.any,
  rendering: PropTypes.any,
  isCPLogoutLoading: PropTypes.bool,
  isCPLogoutSuccess: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isCPLogoutLoading: state.singleSignOnReducer.loginReducer?.isCPLogoutLoading,
  isCPLogoutSuccess: state.singleSignOnReducer.loginReducer?.isCPLogoutSuccess,
  isSuccess: state.cpStoreSelectorReducer?.isSuccess,
  isLoading: state.cpStoreSelectorReducer?.isLoading,
});

export default connect(mapStateToProps)(CPAccountQuickAccess);
