import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { SPIN_URL_SESSION_STORAGE_NAME } from '@components/SpinAndWin/configs';
import { EMAIL_USER_SPIN_SESSION_STORAGE_NAME } from '@components/SpinAndWin/configs';
import { logout } from '@redux/actions/singleSignOn';
import { IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const LogoutComponent = (props) => {
  const dispatch = useDispatch();
  const isLogin = useSelector(state => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const isLoading = useSelector(state => state.singleSignOnReducer.loginReducer.isLoading);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useDidUpdateEffect(() => {  
    const locationSpinPage = JSON.parse(window.sessionStorage.getItem(SPIN_URL_SESSION_STORAGE_NAME)) || '';

    if(settingGlobal && !isLogin) {
      if(props.isSpinWinPage){
      //INFO: Case logout from SpinAndWin page
        window.location.href = `${settingGlobal.LoginLink}?from=${locationSpinPage}`;
      } else {
        window.location.href = settingGlobal.LoginLink;
      }

      //INFO: Remove email to autofill in SpinAndWin page after login
      const emailLogin = JSON.parse(window.sessionStorage.getItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME));
      if(emailLogin){
        window.sessionStorage.removeItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME);
      }

      /** INFO: remove isUserEnterOldPasswordPolicy variable after logout */
      const isUserEnterOldPasswordPolicy = JSON.parse(window.sessionStorage.getItem(
        IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY) || '');
      if (isUserEnterOldPasswordPolicy) window.sessionStorage.removeItem(IS_USER_ENTER_OLD_PASSWORD_POLICY_SESSION_KEY);
    } 
  }, [isLogin, settingGlobal]);
  
  return (
    <Fragment>
      {!isLogin ? <span className='message-successful'><Message messageCode='Msg_006'/></span> : ''}
      <button type='button' className={props.isSpinWinPage ? 'btn btn-primary': 'btn btn-outline-CTA2' } onClick={() => dispatch(logout())}>
        {props.children}
      </button>
      {isLoading ? <LoadingMask parent={LogoutComponent}/> : ''}
    </Fragment>
    
  );
};

LogoutComponent.propTypes = {
  isLogin: PropTypes.bool,
  children: PropTypes.any,
  setLogoutSuccessEvt: PropTypes.func,
  isSpinWinPage: PropTypes.any,
};

export default LogoutComponent;
