import './MyAppointment.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import LoadingMask from '../../Share/LoadingMask';
import CancelMyAppointmentModal from './CancelMyAppointmentModal';
import MyAppointmentModel from './models/MyAppointmentModel';

const MyAppointment = ({ fields }) => {
  const { isLoading } = useSelector((state) => state.eventBookingReducer.cancelEventBookingReducer);
  const [layoutData, setLayoutData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    let model = new MyAppointmentModel();

    setLayoutData(model.getData(fields));
  }, []);

  const handleCloseCancelConfirmModal = () => setIsShowModal(false);

  return layoutData ? (
    <div className='my-appointment'>
      <div className='my-appointment__banner'>
        <div className='my-appointment-banner__wrapper'>
          <Image field={layoutData['Banner Image']} />
        </div>
      </div>
      <div className='my-appointment__body'>
        <div className='container'>
          <Text tag='h2' field={layoutData['Title']} className='title' />
          <div className='schedule'>
            <Text tag='span' field={layoutData['Event Date']} className='schedule__date' />
            <Text tag='span' field={layoutData['Time Slots']} className='schedule__time' />
            <span className='schedule__guest'>
              <Text field={{ value: layoutData['Number Of Guest'] }} /> <Text field={layoutData['Guest Label']} />
            </span>
          </div>
          <div className='location'>
            <p className='location__name'>{layoutData['Location']?.Title}</p>
            <RichText field={{ value: layoutData['Location']?.['Address Details'] }} className='location__address' />
          </div>
          {layoutData?.IsPassed?.value == false && layoutData?.['Event Canceled Message']?.value == '' ? (
            <div className='button'>
              <Link field={{ href: layoutData?.['Change Button Link']?.value }} className='button__change-schedule btn btn-outline-CTA1'>
                <Text field={layoutData['Change Button Text']} />
              </Link>
              <button type='button' className='button__cancel-appointment' onClick={() => setIsShowModal(true)}>
                <Text field={layoutData['Cancel Button Text']} />
              </button>
            </div>
          ) : (
            <></>
          )}
          {layoutData?.['Event Canceled Message']?.value != '' ? (
            <div className='mesage'>
              <Text field={{ value: layoutData?.['Event Canceled Message']?.value.replace('#xxxxxx', layoutData?.['EventId']?.value) }} />
            </div>
          ) : (
            <></>
          )}
          <div className='contact'>
            <Text tag='h3' field={layoutData['Your Contact Text']} className='contact__title' />
            <div className='contact__details'>
              <Text tag='p' field={layoutData['User Name']} className='name' />
              <Text tag='p' field={layoutData['User Email']} className='mail' />
              <Text tag='p' field={layoutData['User Phone']} className='phone' />
            </div>
          </div>
          <div className='question-answer'>
            <Text tag='h3' field={layoutData['QAndA Text']} className='question-answer__title' />
            {layoutData['Question and Answer'].map((qa) => (
              <div key={qa.Id} className='question-answer__details'>
                <p className='question'>{qa.Question}</p>
                <p className='answer'>{qa.Answers.Answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='my-appointment__learn-more'>
        <a href={layoutData['Learn More Button Link']?.value}>
          <Text field={layoutData['Learn More Button Text']} />
        </a>
      </div>
      <CancelMyAppointmentModal layoutData={layoutData} isShow={isShowModal} onCloseModal={handleCloseCancelConfirmModal} />
      {isLoading && <LoadingMask parent={MyAppointment} />}
    </div>
  ) : (
    <></>
  );
};

MyAppointment.propTypes = {
  fields: object
};

export default MyAppointment;
