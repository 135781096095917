import './ProductReviewContent.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ReviewMedia from '@components/ReviewPage/ReviewMedia';
import ReviewStarRating from '@components/ReviewPage/ReviewStarRating';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import LoadingMask from '../../Share/LoadingMask';

const ProductReviewContent = ({ isShowReadMore = true, layoutData, reviewItem, isNavigateToProductDetail }) => {
  const limitedCharacter = 300;
  const [showReadMore, setShowReadmore] = useState(isShowReadMore);

  const mediaData = reviewItem?.MediaFiles;
  const reviewSubmitDate = new Date(Number.parseInt(reviewItem?.ReviewSubmitDate?.replace(/Date|\(|\/|\)/gi, '')));
  const convertReviewSubmitDate = Global.customDate(reviewSubmitDate, false);

  const DATA_IS_NULL = 'null';
  const DATA_IS_TRUE = 'true';

  const onClickDetailsLink = () => {
    if (layoutData['Go To My Product Detail Link']?.value?.href) {
      window.location.href = layoutData['Go To My Product Detail Link']?.value?.href +
      (reviewItem?.ReviewId ? '?ReviewId=' + reviewItem.ReviewId : '') + 
      // (reviewItem?.OrderId ? '&OrderId=' + reviewItem.OrderId : '') +
      // (reviewItem?.ReferenceId ? '&ExternalCartLineId=' + reviewItem.ReferenceId : '') +
      // (reviewItem?.References ? '&ProductType=' + reviewItem.References : '');
      (reviewItem?.MyProductId ? '&MyProductId=' + reviewItem.MyProductId : '');
    }
  };

  return (
    layoutData ? (
      <div className='product-review-content'>
        <div className='product-review-content__star-rating'>
          <ReviewStarRating disableRating defaultRate={reviewItem?.ReviewRate} />
        </div>
        <p className='product-review-content__review-title'>{reviewItem?.ReviewTitle}</p>
        <div className='product-review-content__info'>
          <span className='product-review-content__info__author'>{reviewItem?.ReviewerName}</span>
          <span className='product-review-content__info__date'>{convertReviewSubmitDate}</span>
        </div>
        {reviewItem?.ReviewDescription?.toString().toLowerCase() !== DATA_IS_NULL &&
          !reviewItem?.ReviewDescription.trim() === false && (
            <div className='product-review-content__review-detail'>
              {/* <span className='product-review-content__review-detail__quote-icon quote-icon'></span> */}
              {reviewItem?.ReviewDescription.length > limitedCharacter ? (
                <>
                  {showReadMore ? (
                    <>
                      <p>{reviewItem?.ReviewDescription.slice(0, limitedCharacter) + '...'}</p>
                      <span
                        className='btn-link-text'
                        onClick={() => {
                          if (isNavigateToProductDetail) {
                            onClickDetailsLink();
                          } else {
                            setShowReadmore(!showReadMore);
                          }
                        }}
                      >
                        <Text field={layoutData['Read More Text']} />
                      </span>
                    </>
                  ) : (
                    <>
                      <p>{reviewItem?.ReviewDescription}</p>
                      {
                        isShowReadMore ? (
                          <span className='btn-link-text' onClick={() => setShowReadmore(!showReadMore)}>
                            <Text field={layoutData['Read Less Text']} />
                          </span>
                        ) : '' 
                      }
                    </>
                  )}
                </>
              ) : (
                <p>{reviewItem?.ReviewDescription}</p>
              )}
            </div>
          )}
        {mediaData.length ? (
          <div className='product-review-content__media'>
            <ReviewMedia mediaData={mediaData} reviewItem={reviewItem} isShowFull={!isShowReadMore} />
          </div>
        ) : (
          ''
        )}
        {/* {reviewItem?.IsRecommend?.toString() !== DATA_IS_NULL && (
          <div className='product-review-content__review-recommend'>
            {reviewItem?.IsRecommend?.toString() === DATA_IS_TRUE ? (
              <>
                <span className='product-review-content__review-recommend__icon recommend-icon'></span>
                <Text tag='span' className='product-review-content__review-recommend__text' field={layoutData['Recommend Text']} />
              </>
            ) : (
              <>
                <span className='product-review-content__review-recommend__icon recommend-icon recommend-icon--fail'></span>
                <Text tag='span' className='product-review-content__review-recommend__text' field={layoutData['Not Recommend Text']} />
              </>
            )}
          </div>
        )} */}
        {reviewItem?.IsRecommend?.toString()?.toLowerCase() === DATA_IS_TRUE && (
          <div className='product-review-content__review-recommend'>
            <>
              <span className='product-review-content__review-recommend__icon recommend-icon'></span>
              <Text tag='span' className='product-review-content__review-recommend__text' field={layoutData['Recommend Text']} />
            </>
          </div>
        )}
      </div>
    ) : (
      <LoadingMask parent={ProductReviewContent}/>
    )
  );
};

ProductReviewContent.propTypes = {
  isShowReadMore: PropTypes.bool,
  layoutData: PropTypes.any,
  reviewItem: PropTypes.any,
  isNavigateToProductDetail: PropTypes.bool
};

export default ProductReviewContent;