import produce from 'immer';

import {
  MEDIA_START_TRACKING_GOAL,
  MEDIA_TRACKING_GOAL_FAILED,
  MEDIA_TRACKING_GOAL_SUCCESS
} from '@redux/actions/trackingGoal/mediaTracking/mediaTrackingTypes';

const initialState = {
  isLoading: false,
  error: null,
  success: false
};

const mediaTrackingGoalReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case MEDIA_START_TRACKING_GOAL:
    draft.isLoading = true;
    break;

  case MEDIA_TRACKING_GOAL_SUCCESS:
    draft.isLoading = false;

    draft.success = action.payload.success;
    break;

  case MEDIA_TRACKING_GOAL_FAILED:
    draft.isLoading = false;

    draft.error = action.payload;
    break;
  }

  return draft;
});

export default mediaTrackingGoalReducer;