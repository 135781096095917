import './ChangeScheduleModal.scss';

import * as propType from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

const ChangeScheduleModel = (props) => {
  const { layoutData, result, isUpdated } = props;
  const formatDate = useFormatDate();

  return layoutData ? (
    <div className='change-schedule-modal'>
      <div className='change-schedule-modal__background'>
        <ResponsiveImage
          imageMobile={layoutData?.BackgroundMobile}
          srcSet={[{ mw: 768, image: layoutData?.BackgroundDesktop }]}
        />
      </div>
      <div className='change-schedule-modal__content'>
        <div className='container change-schedule-modal__content__container'>
          <div className='change-schedule-modal__content__wrap'>
            {isUpdated && (
              <>
                <Image field={layoutData?.['Icon Successfully Change Schedule']} className='change-schedule-modal__content__wrap__icon' />
                <Text field={layoutData?.['Successfully Change Schedule Label']} tag='h2' className='change-schedule-modal__content__wrap__title' />
                <Text field={{ value: layoutData?.campaign?.['Uid'] }} className='change-schedule-modal__content__wrap__uid' tag='span' />
                <div className='change-schedule-modal__content__wrap__time'>
                  <Text field={{ value: formatDate(result?.Date) }} tag='span' />
                  {/*<Text field={{ value: `${result?.NumberOfGuests} Guest(s)` }} tag='span' />*/}
                </div>
                <div className='change-schedule-modal__content__wrap__address'>
                  <Text field={{ value: result?.Store?.Name }} tag='h3' className='change-schedule-modal__content__wrap__address__title' />
                  <Text field={{ value: result?.Store?.Address?.Address }} tag='p' className='change-schedule-modal__content__wrap__address__description' />
                  <Text field={{ value: result?.Store?.Address?.PostalCode }} tag='p' className='change-schedule-modal__content__wrap__address__description' />
                  {result?.Store?.PhoneNumber ? (
                    <Text field={{ value: `+${result?.Store?.PhoneNumber}` }} tag='p' className='change-schedule-modal__content__wrap__address__description' />
                  ) : (<></>)}
                </div>
                <Text field={layoutData?.['Confirm Your Reschedule Label']} tag='div' className='change-schedule-modal__content__wrap__excerpts' />
                <Text field={layoutData?.['See You Soon']} tag='p' className='change-schedule-modal__content__wrap__text' />
                <Link
                  field={{ href: `${layoutData?.['Button Appointment Details Link']?.value?.href}?campaign_id=${layoutData?.appointment?.Campaign?.Id}&appointment_id=${layoutData?.appointment?.Id}` }}
                  className='change-schedule-modal__content__wrap__link-view'
                >
                  <Text field={layoutData?.['Button Appointment Details Text']} />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (<></>);
};

ChangeScheduleModel.propTypes = {
  layoutData: propType.object,
  result: propType.object,
  isUpdated: propType.bool
};

const mapStateToProps = (state) => ({
  result: state.myAppointmentReducer.updateAppointmentReducer.result,
  isUpdated: state.myAppointmentReducer.updateAppointmentReducer.isUpdated,
  isUpdateFailed: state.myAppointmentReducer.updateAppointmentReducer.isUpdateFailed
});

export default connect(mapStateToProps)(ChangeScheduleModel);