import './PasswordInput.scss';

import { bool, element, func, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import PasswordHint from '../PasswordHint';

const PasswordInput = ({ type, errorMessageEl, isDisplayPasswordHint = true, onBlur, onFocus, onChange, ...props }) => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const [passwordType, setPasswordType] = useState(() => type);
  const [isShowPasswordHint, setIsShowPasswordHint] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  const handleInputFocus = useCallback((event) => {
    setIsShowPasswordHint(true);
    if (onFocus) onFocus(event);
  }, [onFocus]);

  const handleInputBlur = useCallback((event) => {
    setIsShowPasswordHint(false);
    if (onBlur) onBlur(event);
  }, [onBlur]);

  const handleInputChange = useCallback((event) => {
    setPasswordValue(event.target.value);
    if (onChange) onChange(event);
  }, [onChange]);

  const handleTogglePasswordType = useCallback(
    () => setPasswordType(prevState => prevState === type ? 'text' : type),
    [type]
  );

  return (
    <>
      <div className='password-input'>
        <input {...props} type={passwordType}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {settingGlobal?.ShowPasswordIcon && settingGlobal?.HidePasswordIcon ? (
          <button
            type='button'
            className={['password-input__icons', passwordType === 'text' ? 'active' : ''].join(' ')}
            onClick={handleTogglePasswordType}
          >
            <img
              src={settingGlobal.HidePasswordIcon}
              alt='invisible'
              className='eye-icon eye-icon__invisible'
            />
            <img
              src={settingGlobal.ShowPasswordIcon}
              alt='visible'
              className='eye-icon eye-icon__visible'
            />
          </button>
        ) : (
          <></>
        )}
      </div>
      {errorMessageEl}
      {isDisplayPasswordHint ? <PasswordHint isShow={isShowPasswordHint} passwordValue={passwordValue} /> : <></>}
    </>
  );
};

PasswordInput.propTypes = {
  onFocus: func,
  onBlur: func,
  onChange: func,
  type: string,
  errorMessageEl: element,
  isDisplayPasswordHint: bool
};

export default PasswordInput;