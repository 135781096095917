import '@components/CheckoutProcess/AccountChecking/AccountChecking.scss';
import './FormReturningCustomer.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { REGISTER_TAB_KEY } from '@components/SingleSignOn/RegisterLogin';
import { login } from '@redux/actions/singleSignOn';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

export const FROM_CHECKOUT_PAGE_QUERY_STRING_NAME = '?from=';

const FormReturningCustomer = (props) => {
  const { inputData, isLoginSuccess, isLoading, error } = props;
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const dispatch = useDispatch();
  const location = useLocation();

  const backToRegisterLink = useMemo(() => {
    const registerLinkObject = inputData?.['Register Account Button Link'];
    const checkoutLinkValue = inputData?.['Continue to Delivery Button Link']?.value?.href;
    let linkConverted = null;
    if (registerLinkObject) {
      const fromPage = (checkoutLinkValue || location.pathname) + location.search;

      linkConverted = {
        ...registerLinkObject,
        value: {
          ...registerLinkObject?.value,
          href: registerLinkObject?.value?.href + FROM_CHECKOUT_PAGE_QUERY_STRING_NAME + fromPage + `|${REGISTER_TAB_KEY}`
        }
      };
    }

    return linkConverted;
  }, [inputData, location]);

  const formik = useFormik({
    initialValues: {
      emailLogin: '',
      passwordLogin: ''
    },
    validationSchema: Yup.object({
      emailLogin: Yup.string()
        .email(<Message messageCode='Msg_001.3' />)
        .required(<Message messageCode='Msg_001.3' />)
        .trim(),
      passwordLogin: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .trim()
    }),
    onSubmit: (values) => {
      dispatch(
        login({
          UserName: values.emailLogin,
          PassWord: values.passwordLogin
        })
      );
    },
    validateOnMount: true
  });

  return (
    <form onSubmit={formik.handleSubmit} id='returning-customer-account-checking__form' className='account-checking__form'>
      <Text className='account-checking__form__text' field={inputData['Please Login Message']} tag='p' />
      {error && !isLoginSuccess ? (
        <span className='error-validate error-validate--custom'>
          {error === 'Failed' ? <Message messageCode='Msg_003' /> : <RichText field={{ value: error }} />}
        </span>
      ) : (
        ''
      )}
      <div className='account-checking__form__group'>
        <div className={formik.errors.emailLogin && formik.touched.emailLogin ? 'form-group input-error-validate' : 'form-group'}>
          <input
            type='text'
            className={formik.values.emailLogin ? ' form-control form-control-lg input-valid' : ' form-control form-control-lg'}
            placeholder={inputData['Customer Email Label'].value}
            onChange={formik.handleChange}
            value={formik.values.emailLogin}
            id='returning-customer-email'
            name='emailLogin'
          />
          <Text htmlFor='emailLogin' className='form-group__label' field={inputData['Email Label']} tag='label' />
          {formik.errors.emailLogin && formik.touched.emailLogin && <span className='error-validate'>{formik.errors.emailLogin}</span>}
        </div>
      </div>
      <div className='account-checking__form__group'>
        <div className={formik.errors.passwordLogin && formik.touched.passwordLogin ? 'form-group input-error-validate' : 'form-group'}>
          <input
            type='password'
            className={formik.values.passwordLogin ? ' form-control form-control-lg input-valid' : ' form-control form-control-lg'}
            placeholder={inputData['Customer Password Label'].value}
            onChange={formik.handleChange}
            value={formik.values.passwordLogin}
            id='returning-customer-password'
            name='passwordLogin'
          />
          <Text htmlFor='passwordLogin' className='form-group__label' field={inputData['Customer Password Label']} tag='label' />
          {formik.errors.passwordLogin && formik.touched.passwordLogin && (
            <span className='error-validate'>{formik.errors.passwordLogin}</span>
          )}
        </div>
        {inputData['Forgot Password Label'].value ? (
          <Link className='forgot-password-link small-description' field={inputData['Forgot Password Link']}>
            <Text field={inputData['Forgot Password Label']} />
          </Link>
        ) : (
          ''
        )}
      </div>
      <div className='account-checking__form__button'>
        {inputData['Continue to Delivery Button Text'].value ? (
          <div className='account-checking__form__button__submit text-center'>
            <button type='submit' className='btn btn-primary'>
              <Text field={inputData['Continue to Delivery Button Text']} />
            </button>
          </div>
        ) : (
          ''
        )}

        {inputData['Back to Cart Button Text'].value ? (
          <div className='account-checking__form__button__link text-center'>
            <Link className='btn-link btn-link--back' field={backToRegisterLink}>
              <Text field={inputData['Register Account Button Text']} />
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      {isLoading ? <LoadingMask parent={FormReturningCustomer} /> : ''}
    </form>
  );
};

FormReturningCustomer.propTypes = {
  inputData: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.any,
  errors: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  validateStatusEvt: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  error: state.singleSignOnReducer.loginReducer?.error?.errorMessage,
  isLoading: state.singleSignOnReducer.loginReducer?.isLoading
});

FormReturningCustomer.propTypes = {
  isLoginSuccess: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(FormReturningCustomer);
