import moment from 'moment/moment';
import { useCallback } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { HK_SITE_NAME, MY_SITE_NAME, SG_SITE_NAME, TW_SITE_NAME } from '@utils/constant';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const useAppendBookingBlockDay = () => {
  const siteAllowedBlockDays = [TW_SITE_NAME, SG_SITE_NAME, MY_SITE_NAME, HK_SITE_NAME];
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  return useCallback(
    /** @param {Object} payload
     * @param {Array} payload.excludedDates
     * @param {Number} payload.blockDayBookingNumber
     * @param {String} payload.defaultDate*/
    ({
      excludedDates,
      blockDayBookingNumber,
      defaultDate
    }) => {
      let appendExcludedDatesList = [...excludedDates];
      if (blockDayBookingNumber && siteAllowedBlockDays.includes(currentSiteName)) {
        const addBlockBookingDate = [
          ...(
            Array.from(new Array(blockDayBookingNumber)).map((_, index) => moment().add(
              index,
              'days'
            ).toDate())
          )
        ];
        if (!defaultDate) {
          appendExcludedDatesList = [
            ...appendExcludedDatesList,
            ...addBlockBookingDate
          ];
        } else {
          appendExcludedDatesList = [
            ...appendExcludedDatesList,
            ...addBlockBookingDate
          ];

          if (moment(moment(defaultDate).format(DATE_FORMAT)).isAfter(moment().format(DATE_FORMAT))) {
            appendExcludedDatesList = appendExcludedDatesList.filter((dateItem) => !moment(moment(defaultDate)
              .format(DATE_FORMAT)).isSame(moment(dateItem).format(DATE_FORMAT)));
          }
        }
      }

      return appendExcludedDatesList;
    }, [currentSiteName]);
};