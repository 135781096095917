import './RemoveProductCTA.scss';

import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { removeMyProduct } from '@redux/actions/productWarranty';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import LoadingMask from '../../Share/LoadingMask';
import RemoveProductCTAModel from './models/RemoveProductCTAModel';

const RemoveProductCTA = ({ fields }) => {
  const dispatch = useDispatch();

  const { productDetails, isRemoveMyProductSuccess, isRemoveLoading, removeMyProductErrorMessage } = useSelector(
    (state) => state.productWarrantyReducer.getUserProductDetails
  );
  const [layoutData, setLayoutData] = useState(null);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    const model = new RemoveProductCTAModel();

    setLayoutData(model.getData({ ...fields } || {}));
  }, []);

  useDidUpdateEffect(() => {
    if (isRemoveMyProductSuccess && fields?.['My Products Link']?.value?.href) {
      window.location.href = fields['My Products Link'].value.href;
    }
  }, [isRemoveMyProductSuccess]);

  const handleClosePopup = useCallback(() => {
    setShow(false);
  }, []);

  const handleRemoveMyProduct = useCallback(() => {
    if (productDetails) {
      setShow(false);

      dispatch(
        removeMyProduct({
          myProductId: productDetails?.MyProductId,
          warrantyId: productDetails?.Warranty?.Id
        })
      );
    }
  }, [productDetails]);

  return (
    layoutData && (
      <>
        <div className='product-warranty__detail-remove__item'>
          <Text tag='p' field={layoutData?.['Remove Product Label']} onClick={() => setShow(true)} className='remove-item-btn' />
          {removeMyProductErrorMessage ? <span className='error-invalid'>{removeMyProductErrorMessage}</span> : <></>}
        </div>
        <ModalComponent
          isShow={isShow}
          classNameCustom='custom-class'
          titlePopup={<Text field={layoutData['Popup Title']} />}
          onCloseHandler={handleClosePopup}
        >
          <div className='confirm-remove-product'>
            <RichText
              className='confirm-remove-product__text'
              field={{ value: layoutData['Popup Description']?.value.replace('{NameProduct}', productDetails?.ProductName) }}
            />
            <button className='btn-remove-product btn btn-outline-primary' onClick={handleRemoveMyProduct}>
              <Text field={layoutData['Remove Button Label']} />
            </button>
            <button className='btn-cancel-remove-product' onClick={handleClosePopup}>
              <Text field={layoutData['Cancel Button Label']} />
            </button>
          </div>
        </ModalComponent>
        {isRemoveLoading ? <LoadingMask parent={RemoveProductCTA} /> : <></>}
      </>
    )
  );
};

RemoveProductCTA.propTypes = {
  fields: any
};

export default RemoveProductCTA;
