import './ProductWarrantyRegistrationForm.scss';

import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingMask from '@components/Share/LoadingMask';
import { getProductForRegisterWarrantyData } from '@redux/actions/productForRegisterWarranty';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { useCurrentWarrantyRegistrationStep } from '../hooks/useCurrentWarrantyRegistrationStep';
import RegistrationErrorPopup from '../RegistrationErrorPopup';
import { SELECT_PRODUCT_STEP } from '../WarrantyGeneral';
import CategorySelect from './CategorySelect';
import DatePurchase from './DatePurchase';
import useWarrantyRegistrationFormik, { initialValues } from './hooks/useWarrantyRegistrationFormik';
import WarrantyRegistrationModel from './models/WarrantyRegistrationModel';
import OrderNumberInput from './OrderNumberInput';
import ProductWarrantySelect from './ProductWarrantySelect';
import SerialNumberInput from './SerialNumberInput';

export const MY_PRODUCT_ID_SEARCH_PARAMS_KEY = 'MyProductId';

const WarrantyRegistration = ({ fields }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { isLoading, isSuccess, error, addWarrantyProductSuccessRes } = useSelector((state) => state.addWarrantyReducer);
  const currentStep = useCurrentWarrantyRegistrationStep();
  const [layoutData, setLayoutData] = useState(null);
  const formik = useWarrantyRegistrationFormik(layoutData);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);

  useEffect(() => {
    const model = new WarrantyRegistrationModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useDidUpdateEffect(() => {
    if (isSuccess) {
      const myProductId = addWarrantyProductSuccessRes?.MyProduct?.MyProductId;

      query.set(MY_PRODUCT_ID_SEARCH_PARAMS_KEY, myProductId);

      query.set('product', formik.values.product.productId);

      history.push({
        pathname: layoutData?.['Warranty Registration Thank You Link']?.value?.href,
        search: query.toString()
      });
    }
  }, [isSuccess, layoutData, formik.values, addWarrantyProductSuccessRes]);

  useDidUpdateEffect(() => {
    if (error) setIsOpenErrorPopup(true);
  }, [error]);

  const handleSelectCategoryHandler = useCallback(
    (category) => {
      // INFO: reset current form value before select new category
      formik.resetForm(initialValues);

      formik.setFieldValue('categoryItemId', category.value);

      dispatch(getProductForRegisterWarrantyData(category.value));
    },
    [formik]
  );

  const handleSelectProduct = useCallback(
    (product) => {
      formik.setFieldValue('product', {
        productId: product.value,
        hasSerialNumber: product.HasSerialNumber
      });
    },
    [formik]
  );

  const handleClickOnPrimaryBtn = useCallback(() => {
    window.location.href = layoutData?.['My Products Button Link']?.value?.href;
  }, [layoutData]);

  const handleClickOnSecondaryBtn = useCallback(() => {
    setIsOpenErrorPopup(false);
  }, [layoutData]);

  return layoutData ? (
    <>
      {currentStep === SELECT_PRODUCT_STEP ? (
        <>
          <div className='container warranty-registration__container'>
            <div className='warranty-registration__form'>
              <div className='warranty-registration__form-header'>
                <h5 className='warranty-registration__form-header__text'>
                  <RichText field={layoutData['Description']} />
                </h5>
              </div>
              <form onSubmit={formik.handleSubmit} className='warranty-registration__form-body'>
                <div className='warranty-registration__form-select__item'>
                  <CategorySelect
                    defaultValue={formik.values.categoryItemId}
                    onChange={handleSelectCategoryHandler}
                    placeholder={<Text field={layoutData['Category Label']} />}
                  />
                  {formik.touched.categoryItemId && formik.errors.categoryItemId ? (
                    <span className='error-validate'>{formik.errors.categoryItemId}</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='warranty-registration__form-select__item'>
                  <ProductWarrantySelect
                    defaultValue={formik.values.product.productId}
                    placeholder={<Text field={layoutData['Product Label']} />}
                    isDisabled={!formik.values.categoryItemId}
                    onChange={handleSelectProduct}
                  />
                  {formik.touched.product?.productId && formik.errors.product?.productId ? (
                    <span className='error-validate'>{formik.errors.product?.productId}</span>
                  ) : (
                    <></>
                  )}
                </div>
                {formik.values.product.productId ? (
                  <>
                    <SerialNumberInput
                      hasSerialNumber={formik.values.product.hasSerialNumber}
                      layoutData={layoutData}
                      onChange={formik.handleChange}
                      value={formik.values.serialNumber}
                      className={Global.renderDynamicClass(formik.errors.serialNumber, 'form-control input-item', 'input-error-validate')}
                      errorMsg={formik.touched.serialNumber && formik.errors.serialNumber ? formik.errors.serialNumber : ''}
                    />
                    <OrderNumberInput
                      onChange={formik.handleChange}
                      value={formik.values.orderNumber}
                      inputLabel={layoutData['Order Number Label']}
                    />
                    <DatePurchase
                      label={layoutData['Date Of Purchase Label']}
                      onChange={(date) => formik.setFieldValue('datePurchase', date)}
                      value={formik.values.datePurchase}
                    />
                  </>
                ) : (
                  <></>
                )}
                <div className='warranty-registration__form-submit__item'>
                  <button
                    type='submit'
                    className='osim-account-form__form__btn__item btn btn-primary'
                    disabled={!formik.values.categoryItemId || !formik.values.product.productId}
                  >
                    <Text field={layoutData['Register Warranty Button Label']} />
                  </button>
                </div>
              </form>
              {/*{error ? <span className='error-validate text-center'>{error}</span> : <></>}*/}
            </div>
          </div>
          {isLoading ? <LoadingMask parent={WarrantyRegistration} /> : <></>}
        </>
      ) : (
        <></>
      )}
      <RegistrationErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={() => setIsOpenErrorPopup(false)}
        errorMessage={error}
        primaryButton={{ btnText: layoutData?.['My Products Button Text'], btnOnClick: handleClickOnPrimaryBtn }}
        secondaryButton={{ btnText: layoutData?.['Cancel Button Text'], btnOnClick: handleClickOnSecondaryBtn }}
      />
    </>
  ) : (
    <></>
  );
};

WarrantyRegistration.propTypes = {
  fields: any
};

export default WarrantyRegistration;
