import './CancelAppointmentModal.scss';

import { useFormik } from 'formik';
import * as propType from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { cancelAppointment } from '@redux/actions/myAppointment';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CancelAppointmentModal = (props) => {
  const {layoutData, onCloseModal, isLoading, userInfo, isCancelFailed, statusCode} = props;
  const [isShowReason, setIsShowReason] = useState(false);
  const [appointmentId, setAppointmentId] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleOpenListReason = () => setIsShowReason(true);

  useEffect(() => {
    setAppointmentId(layoutData?.appointment?.Id);
  }, [layoutData]);

  const initialValues = { 
    'AppointmentId': appointmentId,
    'ReasonCancel': '',
    'Email': userInfo?.Email
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      ReasonCancel: Yup.string().required().trim()
    }),
    onSubmit: (values) => {
      dispatch(cancelAppointment({
        AppointmentId: values?.AppointmentId,
        ReasonCancel: values?.ReasonCancel
      }));
    }
  });

  return !isLoading ? (
    <form onSubmit={formik.handleSubmit} className='form-cancel'>
      <div className={`form-cancel__head ${!isShowReason ? 'show' : ''}`}>
        <Text field={ layoutData?.['Description Cancel Appointment Model'] } className='form-cancel__head__ques h4' tag='span' />
        <div className='form-cancel__head__btn-group'>
          <button type='button' onClick={onCloseModal} className='btn btn-outline-CTA2 form-cancel__head__btn-group__button'>
            <Text field={ layoutData?.['Button Do Not Cancel Modal Text'] } />
          </button>
          <button type='button' onClick={handleOpenListReason} className='btn btn-primary form-cancel__btn-group__button'>
            <Text field={ layoutData?.['Button Yes Cancel Booking Text']} />
          </button>
        </div>
      </div>
      <div className={`form-cancel__list-reason ${isShowReason ? 'show' : ''}`}>
        <Text field={ layoutData?.['Description Reason Cancel'] } className='form-cancel__list-reason__title h4' tag='span' />
        <div className='form-cancel__list-reason__list'>
          {layoutData?.['List Reason Cancel']?.map((reason) => (
            <div key={reason?.id} className='form-cancel__list-reason__list__item osim-radio osim-radio--mg-bottom'>
              <input
                type='radio'
                name='ReasonCancel'
                value={reason?.fields?.['Question']?.value}
                className='osim-radio-input'
                readOnly
                onChange={formik.handleChange}
                checked={formik?.values?.ReasonCancel.toLowerCase() === reason?.fields?.['Question']?.value.toLowerCase()}
              />
              <label
                className='osim-radio-label osim-radio-label--small'
                onClick={() => {
                reason?.fields?.['Question']?.value.toLowerCase() === 'other:'
                  && inputRef.current.focus();

                formik.setFieldValue('ReasonCancel', reason?.fields?.['Question']?.value);
                }}
              />
              <label
                className='osim-radio-title osim-radio-title--gray'
                onClick={() => {
                reason?.fields?.['Question']?.value.toLowerCase() === 'other:'
                  && inputRef.current.focus();

                formik.setFieldValue('ReasonCancel', reason?.fields?.['Question']?.value);
                }}
              >
                {reason?.fields?.['Question']?.value}
              </label>
              {reason?.fields?.['Question']?.value.toLowerCase() === 'other:' && (
                <input 
                  type='text' 
                  className='form-control'
                  ref={inputRef}
                  onChange={ev => formik.setFieldValue('ReasonCancel', `${reason?.fields?.['Question']?.value} ${ev.target.value}`)}
                />
              )}

            </div>
          ))}
        </div>
        {formik.errors.ReasonCancel && formik.touched.ReasonCancel && 
          <span className='error-validate'>{formik.errors.ReasonCancel}</span>}
        {isCancelFailed && statusCode && statusCode !== 0 && 
            <span className='error-message'>
              <Message messageCode='Msg_042' />
            </span>}
        <div className='form-cancel__list-reason__btn-group'>
          <button type='button' onClick={onCloseModal} className='btn btn-outline-CTA2 form-cancel__list-reason__btn-group__button'>
            <Text field={ layoutData?.['Button Do Not Cancel Modal Text'] } />
          </button>
          <button type='submit' className='btn btn-primary form-cancel__btn-group__button'>
            <Text field={ layoutData?.['Button Cancel Appointment Text']} />
          </button>
        </div>
      </div>
    </form>
  ) : (
    <LoadingMask parent={CancelAppointmentModal} />
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.myAppointmentReducer?.cancelAppointmentReducer?.isLoading,
  isCancelFailed: state.myAppointmentReducer?.cancelAppointmentReducer?.isCancelFailed,
  statusCode: state.myAppointmentReducer?.cancelAppointmentReducer?.statusCode,
  userInfo: state.singleSignOnReducer.userInfoReducer?.accountUser
});

CancelAppointmentModal.propTypes = {
  layoutData: propType.object,
  isLoading: propType.bool,
  isCancelFailed: propType.bool,
  statusCode: propType.bool,
  userInfo: propType.object,
  onCloseModal: propType.func
};

export default connect(mapStateToProps)(CancelAppointmentModal);