import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootEpic } from '../epic';
import { rootReducer } from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'productReducer',
    'storeLocatorReducer',
    'wellnessReducer',
    'buyingOptionReducer',
    'checkoutProcesstReducer',
    'singleSignOnReducer',
    'unsubscribeReducer',
    'myAddressReducer',
    'searchBoxReducer',
    'commonSearchReducer',
    'sfExpressReducer',
    'bundleBOReducer',
    'myOrderReducer',
    'couponReducer',
    'settingGlobalReducer',
    'dynamicBundleReducer',
    'settingGlobalURLReducer',
    'couponWalletReducer',
    'clientTellingPortalCustomer',
    'clientTellingPortalQuickAccess',
    'productReviewReducer',
    'spinAndWinReducer',
    'compareProductReducer',
    'priorityDeliveryReducer',
    'getFreeGiftReducer',
    'controlSelectingFreeGiftPopupReducer',
    'eventBookingReducer',
    // 'loyaltyReducer',
    'subscribeNewsletterReducer',
    'campaignRegisterReducer',
    'myAppointmentReducer',
    'cpUpdateAppointmentReducer',
    'cpAppointmentDetailReducer',
    'cpNotificationPopupReducer',
    'layoutElementStyleReducer',
    'bookingExperienceReducer',
    'addFreeGiftToCartReducer',
    'updateFreeGiftToCartReducer',
    'cpNotificationPopupReducer',
    'addWarrantyReducer',
    'productWarrantyReducer',
    'cpCartDetailsReducer',
    'cpSaleOrderStatusesReducer',
    'getSOCartsReducer',
    'cpSendSaleOrderReducer',
    'personalizeOfferReducer',
    'taxMessageDetailReducer',
    'cpCheckContactDuplicateReducer',
    'mediaTrackingGoalReducer',
    'cPStartDemoReducer'
  ]
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);
const epicMiddleware = createEpicMiddleware();

export const configureStore = (editing) => {
  let reducer = null;
  if (editing) {
    reducer = rootReducer;
  } else {
    reducer = persistReducer(persistConfig, rootReducer);
  }
  const middlewares = [epicMiddleware];

  if (process.env.NODE_ENV === 'development') {
    // middlewares.push(createLogger());
  }

  const composeEnhancers = composeWithDevTools({});

  const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  return store;
};
