import { useMemo } from 'react';

const useCookie = () => {
  return useMemo(() => {
    try {
      let cookieValues = {
        get: function(key) {
          return this[key] ?? null;
        },
        remove: function(removeKey) {
          if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            try {
              document.cookie =`${removeKey}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
            } catch (error) {
              console.error('Unable to remove cookie key: ', error);
            }
          }
        }
      };
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const cookieString = document.cookie;
        if (cookieString) {
          const splitCookieString = cookieString.split('; ') || [];
          for (const item of splitCookieString) {
            const [key, value] = item.split('=');
            if (key) cookieValues[`${key}`] = value ?? '';
          }
        }
      }

      return cookieValues;
    } catch (error) {
      console.error('Unable to get cookie value: ', error);
    }
  }, []);
};

export default useCookie;