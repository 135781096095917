import './CustomerAcknowledgePopup.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ReactSignatureCanvas from 'react-signature-canvas';

import Loading from '@components/Share/Loading';
import LoadingMask from '@components/Share/LoadingMask';
import { uploadBOTradeInFile } from '@redux/actions/buyingOptions';
import { updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { getContactPersonalInformation } from '@redux/actions/clientTellingPortalCustomer';
import { checkContactDuplicateStart } from '@redux/actions/cpCheckContactDuplicate';
import { cpCheckContactDuplicateSelector } from '@redux/reducers/cpCheckContactDuplicate/selector';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_API_VALUE,HK_SITE_NAME, MACAU_PREFIX_CODE_VALUE, REGEX_CHECK_MACAU_PHONE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { formatDateToUTC } from '@utils/dateFormatV2';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { getUserFullNameWithoutFormat } from '../../../SlicingPage/MyAccount/utils';
import CPDOB from '../../CPDOB';
import { TYPE_CHECKBOX, TYPE_RADIO, TYPE_TEL, TYPE_TEXT } from '../../GenericComponents/CPForm/FormConstant';
import QuickAccessPopup from '../QuickAccessPopup';
import AcknowledgeEmailInput from './AcknowledgeEmailInput';
import CustomerInformationWarningPopup from './CustomerInformationWarningPopup';
import ExistingCustomerInformation from './ExistingCustomerInformation';
import { useGetCheckedPDPA, useMappingCustomerInfoData } from './hooks';

const CustomerAcknowledgePopup = ({ closeAccessPopup, isCenter, layoutData }) => {
  const [isShowWarningPopup, setShowWarningPopup] = useState(null);
  const [referPOSOrder, setReferPOSOrder] = useState(false);
  const [massageOwnerShip, setMassageOwnerShip] = useState(false);
  const [dirtyMassageOwnerShip, setDirtyMassageOwnnerShip] = useState(false);
  const [selectedMassageOwnerShip, setSelectedMassageOwnerShip] = useState([]);
  const [signatureImage, setSignatureImage] = useState(false);

  const [touchedAgreement, setTouchedAgreement] = useState(false);
  const [disabledAgreement, setDisabledAgreement] = useState(true);
  const [agreementCheckbox, setAgreementCheckbox] = useState(false);

  const [touchedSMS, setTouchedSMS] = useState(false);
  const [disabledSMS, setDisabledSMS] = useState(true);
  const [SMSCheckbox, setSMSCheckbox] = useState(false);

  const [touchedMail, setTouchedMail] = useState(false);
  const [disabledMail, setDisabledMail] = useState(true);
  const [mailCheckbox, setMailCheckbox] = useState(false);

  const [disabledDirectMail, setDisabledDirectMail] = useState(false);
  const [directMailCheckbox, setDirectMailCheckbox] = useState(false);
  const [isShowSignatureLabel, setIsShowSignatureLabel] = useState(true);

  const clientTellingPortalCustomerStates = useSelector((state) => state.clientTellingPortalCustomer);
  const mappingCustomerInfoData = useMappingCustomerInfoData();
  const clientTellingPortalCustomer = useMemo(() => mappingCustomerInfoData(clientTellingPortalCustomerStates), [clientTellingPortalCustomerStates]);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  const dispatch = useDispatch();

  const FILE_SIZE = 4000;
  const isSignatureHidden = !(layoutData?.['Is Signature Hidden']?.value);
  let sigCanvas = {};
  const [options, setOptions] = useState([]);

  const [valueObject, setValueObject] = useState({
    ['PDPA-firstName']: '',
    // ['PDPA-lastName']: '',
    ['PDPA-email']: '',
    ['PDPA-prefixNumber']: null,
    ['PDPA-phoneNumber']: '',
    ['PDPA-DOB']: '',
    ['PDPA-signature']: '',
    ['PDPA-agreement']: false,
    ['PDPA-receiveSMS']: false,
    ['PDPA-receiveMail']: false,
    ['PDPA-receiveDirectMailing']: false,
    ['PDPA-referPOS']: false
  });

  const [objectValidation, setObjectValidation] = useState({
    ['PDPA-firstName']: {
      empty: true,
      isValid: true,
      touched: false
    },
    // ['PDPA-lastName']: {
    //   empty: true,
    //   isValid: true,
    //   touched: false
    // },
    ['PDPA-email']: {
      empty: true,
      isValid: true,
      touched: false
    },
    ...(isSignatureHidden ? {
      ['PDPA-signature']: {
        empty: true,
        isValid: true,
        touched: false
      }
    } : {}),
    ['PDPA-marketingChannels']: {
      empty: true,
      isValid: true,
      touched: false
    },
    ['PDPA-communicationChannels']: {
      empty: true,
      isValid: true,
      touched: false
    },
    ['PDPA-phoneNumber']: {
      empty: true,
      isValid: true,
      touched: false
    }
  });

  const [marketingChannelsValidation, setMarketingChannelsValidation] = useState({
    ['PDPA-receiveSMS']: {
      empty: true
    },
    ['PDPA-receiveMail']: {
      empty: true
    },
    ['PDPA-receiveDirectMailing']: {
      empty: true
    }
  });

  const [communicationChannels, setCommunicationChannels] = useState({
    ['PDPA-messageButton']: {
      empty: true,
      isChecked: false
    },
    ['PDPA-dialButton']: {
      empty: true,
      isChecked: false
    },
    ['PDPA-SMSButton']: {
      empty: true,
      isChecked: false
    }
  });

  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    ['PDPA-phoneNumber']: {
      empty: true,
      isValid: true,
      touched: false
    }
  });

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [isUpdatedValueObjectState, setIsUpdatedValueObjectState] = useState(true);
  const isCheckedPDPAContact = useGetCheckedPDPA();

  const contactType = useMemo(() => {
    return clientTellingPortalCustomer.contacts?.[0]?.contact_type;
  }, [clientTellingPortalCustomer]);

  const isCustomerInfoViewMode = useMemo(() => {
    return (
      ((contactType == 'PDPA lead' || contactType == 'Existing customer') && isCheckedPDPAContact) ||
      clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS
    );
  }, [clientTellingPortalCustomer, isCheckedPDPAContact, contactType]);

  const profilePhone = useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.PhoneNumber || '';
  }, [clientTellingPortalCustomer]);

  const profileDob = useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.DOB || '';
  }, [clientTellingPortalCustomer]);

  const isShowFullMarkValue = useMemo(() => {
    return (contactType === 'PDPA lead' || contactType === 'Existing customer') && (profilePhone || profileDob) && !isCheckedPDPAContact;
  }, [clientTellingPortalCustomer, contactType, profilePhone, profileDob]);

  const isSMSCommunicationHidden = useMemo(() => {
    return layoutData?.['Is Communication Method Hidden']?.value ?? false;
  }, [layoutData]);

  const isCommunicationRequired = useMemo(() => {
    return layoutData?.['Is Communication Method Required']?.value ?? true;
  }, [layoutData]);

  const isDirectMailingHidden = useMemo(() => {
    return layoutData?.['Direct Mailing Hidden']?.value ?? false;
  }, [layoutData]);
  const { isChecking, contactFound } = useSelector(cpCheckContactDuplicateSelector);

  const handleCheckPhoneNumberValid = useCallback(
    (phoneNumber='') => {
      let isPhoneValid=true;
      const phoneNumberFormat = settingGlobal?.PhoneNumberFormat;
      const phoneNumberMinimumMust = settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust;
      const phoneNumberMust = settingGlobal?.PhoneNumberMust;
      if(phoneNumberFormat || phoneNumberMinimumMust || phoneNumberMust){
        const phoneNumberLength = phoneNumber?.toString()?.length || 0;
        if (phoneNumberMinimumMust && phoneNumberLength < phoneNumberMinimumMust) isPhoneValid = false;
        if (phoneNumberMust && phoneNumberLength > phoneNumberMust) isPhoneValid = false;
        if (phoneNumberFormat && !new RegExp(phoneNumberFormat).test(phoneNumber)) isPhoneValid = false;
      }

      return isPhoneValid;
    },
    [settingGlobal]
  );

  const onHandleValidate = (type) => {
    let tempObj = deepCopy(valueObject);

    if (type == 'agreement') {
      if (!touchedAgreement) {
        setTouchedAgreement(true);

        let tempObj = deepCopy(objectValidation);
        for (const key in tempObj) {
          tempObj[key].touched = true;

          if (key == 'PDPA-communicationChannels') {
            tempObj[key].touched = isCommunicationRequired ? !phoneNumberValidation['PDPA-phoneNumber'].empty : false;

            tempObj[key].empty = isCommunicationRequired
              ? Object.keys(communicationChannels).every((key) => communicationChannels[key].empty)
              : false;
          }

          if (key == 'PDPA-marketingChannels') {
            tempObj[key].touched = Object.keys(marketingChannelsValidation).every((key) => marketingChannelsValidation[key].empty);


            tempObj[key].empty = Object.keys(marketingChannelsValidation).every((key) => marketingChannelsValidation[key].empty);
          }
        }

        setObjectValidation(tempObj);
      }

      disabledAgreement ? setAgreementCheckbox(false) : setAgreementCheckbox(!agreementCheckbox);
    }

    if (type == 'sms') {
      if (!touchedSMS) {
        setTouchedSMS(true);

        let tempPhoneNumber = deepCopy(phoneNumberValidation);

        tempPhoneNumber['PDPA-phoneNumber'].touched = true;

        setPhoneNumberValidation(tempPhoneNumber);
      }

      if (!disabledSMS) {
        let tempValiedObj = deepCopy(marketingChannelsValidation);

        tempValiedObj['PDPA-receiveSMS'].empty = !tempValiedObj['PDPA-receiveSMS'].empty;

        tempObj['PDPA-receiveSMS'] = !SMSCheckbox;

        setMarketingChannelsValidation(tempValiedObj);

        setSMSCheckbox(!SMSCheckbox);
      } else {
        setSMSCheckbox(false);
      }
    }

    if (type == 'mail') {
      if(disabledMail) {
        if (!touchedMail) {
          setTouchedMail(true);

          let tempObj = deepCopy(objectValidation);

          tempObj['PDPA-email'].touched = true;

          if (!referPOSOrder) tempObj['PDPA-email'].empty = true;

          setObjectValidation(tempObj);
        }
      }

      if (!disabledMail) {
        let tempValiedObj = deepCopy(marketingChannelsValidation);

        tempValiedObj['PDPA-receiveMail'].empty = !tempValiedObj['PDPA-receiveMail'].empty;

        tempObj['PDPA-receiveMail'] = !mailCheckbox;

        setMarketingChannelsValidation(tempValiedObj);

        setMailCheckbox(!mailCheckbox);
      } else {
        setMailCheckbox(false);
      }
    }

    if (type == 'directMailing') {
      if (disabledDirectMail) {
        setDirectMailCheckbox(false);
      } else {
        let tempValiedObj = deepCopy(marketingChannelsValidation);
        let tempObj = deepCopy(objectValidation);

        tempValiedObj['PDPA-receiveDirectMailing'].empty = !tempValiedObj['PDPA-receiveDirectMailing'].empty;

        tempObj['PDPA-receiveDirectMailing'] = !directMailCheckbox;

        setMarketingChannelsValidation(tempValiedObj);

        setDirectMailCheckbox(!directMailCheckbox);
      }
    }

    setValueObject(tempObj);
  };

  const onHandleCommunicationChannels = (type) => {
    let tempObj = deepCopy(communicationChannels);

    tempObj[`${type}`].empty = !tempObj[`${type}`].empty;

    tempObj[`${type}`].isChecked = !tempObj[`${type}`].isChecked;

    tempObj[`${type}`].isValid = !tempObj[`${type}`].isValid;

    setCommunicationChannels(tempObj);
  };

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await window.fetch(dataUrl);
    const blob = await res.blob();

    return new window.File([blob], fileName, { type: 'image/png' });
  };

  const generateDropdownOption = (data) => {
    let arr = data.map((item) => {
      return { value: item.fields.Answer.value, label: item.fields.Answer.value };
    });

    return arr;
  };

  const onSelectMassageOwnership = (option) => {
    setSelectedMassageOwnerShip(option);

    let tempObj = deepCopy(valueObject);

    tempObj['MassageOwnership'] = option?.map((item) => item.value).join(', ') || '';

    setValueObject(tempObj);
  };

  /** @param {Object} data
   * @param {String} data.field
   * @param {String} data.value
   * @param {String} data.type
   * @param {String} [data.answerId]*/
  const onHandleChange = (data) => {
    let tempObj = deepCopy(valueObject);
    let tempValidationObj = deepCopy(objectValidation);
    let tempMarketingChannelsValidation = deepCopy(marketingChannelsValidation);
    let tempPhoneNumberValidation = deepCopy(phoneNumberValidation);

    if (data.type == 'text' || data.type == 'dropdown' || data.type == 'checkbox') {
      tempObj[`${data.field}`] = data.value;

      if (data.field in tempValidationObj) {
        if (data.value) {
          // if (data.field == 'PDPA-firstName' || data.field == 'PDPA-lastName') {
          if (data.field == 'PDPA-firstName') {
            tempValidationObj['PDPA-firstName'].empty = false;

            // tempValidationObj['PDPA-lastName'].empty = false;
          } else if (data.field == 'PDPA-email' || data.field == 'PDPA-phoneNumber') {
            tempValidationObj['PDPA-email'].empty = false;

            tempValidationObj['PDPA-phoneNumber'].empty = false;

            if (data.field == 'PDPA-email') {
              tempValidationObj[`${data.field}`].isValid = new RegExp(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
              ).test(data.value);

              if (
                !new RegExp(
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                ).test(data.value)
              ) {
                tempMarketingChannelsValidation['PDPA-receiveMail'].empty = true;
              }
            }
          } else tempValidationObj[`${data.field}`].empty = false;
        } else {
          // if (data.field == 'PDPA-firstName' && data.field == 'PDPA-lastName') {
          if (data.field == 'PDPA-firstName') {
            tempValidationObj['PDPA-firstName'].empty = true;

            // tempValidationObj['PDPA-lastName'].empty = true;
          } else if (data.field == 'PDPA-email' && data.field == 'PDPA-phoneNumber') {
            tempValidationObj[`${data.field}`].empty = true;

            tempValidationObj[`${data.field}`].isValid = true;

            tempMarketingChannelsValidation['PDPA-receiveMail'].empty = true;

            tempObj['PDPA-receiveMail'] = false;
          } else tempValidationObj[`${data.field}`].empty = true;
        }
      }

      if (data.field in tempPhoneNumberValidation) {
        setTouchedSMS(false);
        if (data.value) {
          tempPhoneNumberValidation[`${data.field}`].empty = false;

          tempPhoneNumberValidation[`${data.field}`].isValid = handleCheckPhoneNumberValid(data.value);

          if (!handleCheckPhoneNumberValid(data.value)) {
            tempMarketingChannelsValidation['PDPA-receiveSMS'].empty = true;

            tempObj['PDPA-receiveSMS'] = false;
          } else {
            tempValidationObj['PDPA-communicationChannels'].touched = touchedAgreement;
          }
        } else {
          tempPhoneNumberValidation[`${data.field}`].empty = true;

          tempPhoneNumberValidation[`${data.field}`].isValid = true;

          tempPhoneNumberValidation[`${data.field}`].touched = false;

          tempMarketingChannelsValidation['PDPA-receiveSMS'].empty = true;

          tempObj['PDPA-receiveSMS'] = false;

          tempValidationObj['PDPA-communicationChannels'].touched = false;
        }
      }
    }

    if (data.type == 'question-single') {
      tempObj[`${data.field}`].value = data.value;

      if (data.field == 'MassageOwnershipType') {
        tempObj.MassageOwnership = '';

        setDirtyMassageOwnnerShip(true);

        data.value == 'No' ? setMassageOwnerShip(false) : setMassageOwnerShip(true);

        setSelectedMassageOwnerShip([]);
      }

      if (data.field in objectValidation) {
        if (data.value) {
          tempValidationObj[`${data.field}`].empty = false;
        } else {
          tempValidationObj[`${data.field}`].empty = true;
        }
      }
    }

    if (data.type == 'question-dropdown') {
      tempObj[`${data.field}`].value = data.value;
      if (data.field in objectValidation) {
        tempValidationObj[`${data.field}`].empty = !data.value.value;
      }
    }

    data.type == 'question-multiple' && (tempObj[`${data.field}`].value = Global.onSetValueMutiSelect(tempObj[`${data.field}`].value, data.value));

    if (data.type == 'get-signature') {
      if (data.value.size > FILE_SIZE) {
        if (!referPOSOrder) tempValidationObj[`${data.field}`].empty = false;
      }
    }

    if (data.type == 'remove-signature') {
      if (!referPOSOrder) {
        tempValidationObj[`${data.field}`].empty = true;

        tempObj[`${data.field}`] = ''; // INFO: remove this code when do not want to remove signature when click Clear button
      }
    }

    if (data.answerId) {
      setSelectedAnswerId(prevState => (
        {
          ...prevState,
          [`${data.field}Id`]: data.answerId
        }
      ));
    }

    setValueObject(tempObj);

    setObjectValidation(tempValidationObj);

    setMarketingChannelsValidation(tempMarketingChannelsValidation);

    setPhoneNumberValidation(tempPhoneNumberValidation);
    // INFO: re-check phone input when change prefix code
    if (data.field === 'PDPA-prefixNumber') handleCheckInputValid(null, 'PDPA-phoneNumber', tempObj['PDPA-phoneNumber'], tempObj['PDPA-prefixNumber'].value);
  };

  const generateContactFormData = useCallback(() => {
    let formData = new window.FormData();
    // INFO: set dob hours become 0h0m0s0ms
    let dobValue = valueObject['PDPA-DOB'] || DEFAULT_API_VALUE;

    if (dobValue?.trim()) {
      dobValue = formatDateToUTC(dobValue);
    }

    const params = {
      ContactID: valueObject.ContactID,
      Email: valueObject['PDPA-email'] || DEFAULT_API_VALUE,
      PhoneNumber: valueObject['PDPA-phoneNumber'] || DEFAULT_API_VALUE,
      PrefixPhoneNumber: valueObject['PDPA-prefixNumber'].value || DEFAULT_API_VALUE,
      DOB: dobValue,
      PersonalInformation: {
        FirstName: valueObject['PDPA-firstName'] || DEFAULT_API_VALUE,
        // LastName: valueObject['PDPA-lastName'] || DEFAULT_API_VALUE,
        Gender: valueObject['Gender'].value || DEFAULT_API_VALUE
      },
      CPCustomerInfo: {
        MaritalStatus: valueObject?.['MaritalStatus']?.value || DEFAULT_API_VALUE,
        // Age: valueObject?.['Age']?.value || '',
        // JobNature: valueObject?.['JobNature']?.value || '',
        // HealthIssues: valueObject?.['HealthIssues']?.value || '',
        // HouseholdSize: valueObject?.['HouseholdSize']?.value?.value || '',
        ReferPOS: valueObject?.['PDPA-referPOS'] || DEFAULT_API_VALUE,
        // MassageOwnershipType: valueObject?.['MassageOwnershipType']?.value || '',
        // MassageOwnership: valueObject?.['MassageOwnership'] || '',
        AllowDirectMail: valueObject['PDPA-receiveDirectMailing'] || false,
        AllowSms: valueObject['PDPA-receiveSMS'] || false,
        AllowEmail: valueObject['PDPA-receiveMail'] || false,
        PDPAAgreement: valueObject['PDPA-agreement'] || false,
        SignatureImageUrl: isSignatureHidden ? valueObject['PDPA-signature'] || DEFAULT_API_VALUE : '',
        CommunicationChannels: [
          {
            PhoneNumber: valueObject['PDPA-phoneNumber'] || DEFAULT_API_VALUE,
            AllowWeChat: communicationChannels['PDPA-messageButton'].isChecked,
            AllowWhatsapp: communicationChannels['PDPA-dialButton'].isChecked,
            AllowSMS: communicationChannels['PDPA-SMSButton'].isChecked
          }
        ],
        ...selectedAnswerId
      }
      // CPCustomerInterest: {
      //   Products: valueObject.CPCustomerInterest.Products || ' '
      // }
    };

    for (let keyParams in params) {
      if (keyParams == 'PersonalInformation') {
        for (let keyChild in params[keyParams]) {
          formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInfo') {
        for (let keyChild in params[keyParams]) {
          if (keyChild === 'CommunicationChannels') {
            params[keyParams][keyChild].forEach((item, index) => {
              for (let keyItem in item) {
                formData.append(`CPCustomerInfo[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
              }
            });
          } else {
            formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
          }
        }
      } else if (keyParams == 'CPCustomerInterest') {
        for (let keyChild in params[keyParams]) {
          params[keyParams][keyChild].forEach((item, index) => {
            for (let keyItem in item) {
              if (keyItem == 'Questions') {
                params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                  for (let keyChildItem in childItem) {
                    formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
                      childItem[keyChildItem]
                    );
                  }
                });
              } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
            }
          });
        }
      } else {
        formData.append(keyParams, params[keyParams]);
      }
    }

    return formData;
  }, [valueObject, communicationChannels, isSignatureHidden, selectedAnswerId]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    // INFO: allow to submit when phone valid
    if (phoneNumberValidation['PDPA-phoneNumber'].isValid) {
      const formData = generateContactFormData();

      // INFO: update from ticket 5816 using seperate api to check duplicate contact
      dispatch(checkContactDuplicateStart(formData));

      clearDataAfterConfirm();
    }
  };

  useEffect(() => {
    if (isShowFullMarkValue) {
      if (profilePhone)
        dispatch(
          getContactPersonalInformation({
            id: clientTellingPortalCustomer.customerInformation?.ContactID,
            type: 'PhoneNumber'
          })
        );
    }
  }, [isShowFullMarkValue, profilePhone]);

  useEffect(() => {
    // INFO: fetch dob data after fetched phone number data
    if (isShowFullMarkValue && !clientTellingPortalCustomer?.DOB) {
      if (profileDob && profilePhone && clientTellingPortalCustomer?.PhoneNumber)
        dispatch(
          getContactPersonalInformation({
            id: clientTellingPortalCustomer.customerInformation?.ContactID,
            type: 'DOB'
          })
        );
    }
  }, [isShowFullMarkValue, profileDob, profilePhone, clientTellingPortalCustomer?.DOB, clientTellingPortalCustomer.PhoneNumber]);

  useEffect(() => {
    let tempObj = deepCopy(valueObject);

    layoutData.Questions.forEach((quest) => {
      if (quest['Display Type'] == 'Drop Down') {
        return (tempObj[`${quest['Question Code']}`] = {
          value: generateDropdownOption(quest.Answers)[0],
          type: quest['Display Type']
        });
      } else return (tempObj[`${quest['Question Code']}`] = { value: '', type: quest['Display Type'] });
    });

    setValueObject(tempObj);
  }, [layoutData]);

  useEffect(() => {
    let tempObj = deepCopy(objectValidation);

    layoutData.Questions.filter((quest) => {
      return quest['Is Required'] == '1';
    }).forEach((quest) => {
      tempObj[`${quest['Question Code']}`] = {
        empty: true,
        isValid: true,
        touched: false
      };
    });

    setObjectValidation(tempObj);
  }, [layoutData]);

  useDidUpdateEffect(() => {
    let tempObj = deepCopy(objectValidation);

    if (touchedSMS || touchedMail || directMailCheckbox) {
      // tempObj['PDPA-communicationChannels'].empty = false;

      tempObj['PDPA-communicationChannels'].touched = true;

      // tempObj['PDPA-communicationChannels'].isValid = false;
    }

    if (!phoneNumberValidation['PDPA-phoneNumber'].empty && phoneNumberValidation['PDPA-phoneNumber'].isValid) {
      !referPOSOrder && setDisabledSMS(false);

      tempObj['PDPA-communicationChannels'].touched = true;

      tempObj['PDPA-communicationChannels'].empty = isCommunicationRequired
        ? Object.keys(communicationChannels).every((key) => communicationChannels[key].empty)
        : false;
    } else {
      !referPOSOrder && setDisabledSMS(true);

      tempObj['PDPA-communicationChannels'].empty = false;

      setSMSCheckbox(false);
    }

    tempObj['PDPA-marketingChannels'].empty = Object.keys(marketingChannelsValidation).every((key) => marketingChannelsValidation[key].empty);
    if (!referPOSOrder) {
      if (!valueObject['PDPA-firstName']) {
        tempObj['PDPA-firstName'].empty = true;

        objectValidation['PDPA-firstName'].empty = tempObj['PDPA-firstName'].empty;
      }
      // else if (valueObject['PDPA-firstName'] && !valueObject['PDPA-lastName']) {
      //   tempObj['PDPA-lastName'].empty = false;
      //
      //   objectValidation['PDPA-lastName'].empty = tempObj['PDPA-lastName'].empty;
      // } else if (!valueObject['PDPA-firstName'] && !valueObject['PDPA-lastName']) {
      //   tempObj['PDPA-lastName'].empty = true;
      //
      //   tempObj['PDPA-firstName'].empty = true;
      //
      //   objectValidation['PDPA-firstName'].empty = tempObj['PDPA-firstName'].empty;
      //
      //   objectValidation['PDPA-lastName'].empty = tempObj['PDPA-lastName'].empty;
      // }

      if (!valueObject['PDPA-email'] && valueObject['PDPA-phoneNumber']) {
        tempObj['PDPA-email'].empty = false;

        // objectValidation['PDPA-email'].empty = tempObj['PDPA-email'].empty;
      } else if (valueObject['PDPA-email'] && !valueObject['PDPA-phoneNumber']) {
        tempObj['PDPA-phoneNumber'].empty = false;

        // objectValidation['PDPA-phoneNumber'].empty = tempObj['PDPA-phoneNumber'].empty;
      } else if (!valueObject['PDPA-email'] && !valueObject['PDPA-phoneNumber']) {
        tempObj['PDPA-phoneNumber'].empty = true;

        tempObj['PDPA-email'].empty = true;

        objectValidation['PDPA-email'].empty = tempObj['PDPA-email'].empty;

        objectValidation['PDPA-phoneNumber'].empty = tempObj['PDPA-phoneNumber'].empty;
      }
      if (valueObject['PDPA-phoneNumber']) tempObj['PDPA-phoneNumber'].empty = false;
    }

    let tempEnableAgreement = Object.entries(tempObj).every((obj) => {
      return !obj[1].empty && obj[1].isValid;
    });

    if (!tempEnableAgreement) {
      setAgreementCheckbox(false);

      setValueObject((prevState) => ({ ...prevState, 'PDPA-agreement': false }));
    }

    !referPOSOrder && setDisabledAgreement(!tempEnableAgreement);

    if (!objectValidation['PDPA-email'].empty && objectValidation['PDPA-email'].isValid) {
      if (!referPOSOrder) {
        if (valueObject['PDPA-email']) {
          setDisabledMail(false);
        } else {
          setTouchedMail(false);
        }
      }
      // !referPOSOrder && setDisabledMail(false);
    } else {
      !referPOSOrder && setDisabledMail(true);

      setMailCheckbox(false);
    }
  }, [objectValidation, referPOSOrder]);

  useDidUpdateEffect(() => {
    let tempObj = deepCopy(valueObject);

    let tempValidationObj = deepCopy(objectValidation);

    let tempValidationPhoneNumber = deepCopy(phoneNumberValidation);

    if (referPOSOrder) {
      tempObj['PDPA-firstName'] = '';

      // tempObj['PDPA-lastName'] = '';

      tempObj['PDPA-email'] = '';

      tempObj['PDPA-phoneNumber'] = '';

      tempObj['PDPA-agreement'] = false;

      tempObj['PDPA-receiveSMS'] = false;

      tempObj['PDPA-receiveMail'] = false;

      tempObj['PDPA-receiveDirectMailing'] = false;

      tempValidationObj['PDPA-firstName'].empty = false;

      // tempValidationObj['PDPA-lastName'].empty = false;

      tempValidationObj['PDPA-email'].empty = false;

      tempValidationObj['PDPA-marketingChannels'].empty = false;

      if(isSignatureHidden) {
        tempValidationObj['PDPA-signature'].empty = false;
      }

      layoutData.Questions.forEach((item) => {
        item['Is Required'] == '1' && (tempValidationObj[`${item['Question Code']}`].empty = false);

        tempObj[`${item['Question Code']}`] && (tempObj[`${item['Question Code']}`].value = '');
      });

      tempValidationObj['PDPA-communicationChannels'].empty = false;

      tempValidationPhoneNumber['PDPA-phoneNumber'].empty = false;

      setDisabledAgreement(true);

      setAgreementCheckbox(false);

      setDisabledMail(true);

      setMailCheckbox(false);

      setDisabledSMS(true);

      setSMSCheckbox(false);

      setDisabledDirectMail(true);

      setDirectMailCheckbox(false);
    } else {
      tempValidationObj['PDPA-firstName'].empty = true;

      // tempValidationObj['PDPA-lastName'].empty = true;

      tempValidationObj['PDPA-email'].empty = true;

      tempValidationObj['PDPA-communicationChannels'].empty = isCommunicationRequired;

      tempValidationObj['PDPA-marketingChannels'].empty = true;

      layoutData.Questions.forEach((item) => {
        item['Is Required'] == '1' && (tempValidationObj[`${item['Question Code']}`].empty = true);
      });

      tempValidationPhoneNumber['PDPA-phoneNumber'].empty = true;

      if (isSignatureHidden && tempValidationObj['PDPA-signature'].touched) tempValidationObj['PDPA-signature'].empty = true;

      setDisabledAgreement(false);

      setDisabledMail(false);

      setDisabledSMS(false);

      setDisabledDirectMail(false);
    }

    setValueObject(tempObj);

    setObjectValidation(tempValidationObj);

    setPhoneNumberValidation(tempValidationPhoneNumber);
  }, [referPOSOrder]);

  useDidUpdateEffect(() => {
    let tempObj = deepCopy(objectValidation);

    let tempMarketingChannelsValidation = deepCopy(marketingChannelsValidation);

    tempObj['PDPA-marketingChannels'].empty = Object.entries(tempMarketingChannelsValidation).every((obj) => {
      return obj[1].empty;
    });

    setObjectValidation(tempObj);
  }, [marketingChannelsValidation]);

  useDidUpdateEffect(() => {
    let tempObj = deepCopy(objectValidation);

    let tempCommunicationChannels = deepCopy(communicationChannels);

    tempObj['PDPA-communicationChannels'].empty = isCommunicationRequired
      ? Object.entries(tempCommunicationChannels).every((obj) => {
        return obj[1].empty;
      })
      : false;

    setObjectValidation(tempObj);
  }, [communicationChannels, isCommunicationRequired]);

  useDidUpdateEffect(() => {
    if (clientTellingPortalCustomer.errorData?.length || contactFound.length) {
      setShowWarningPopup(true);
    }
  }, [clientTellingPortalCustomer.errorData, contactFound]);

  useDidUpdateEffect(() => {
    if (isUpdatedValueObjectState && !clientTellingPortalCustomer.isLoading) setIsUpdatedValueObjectState(false);
  }, [clientTellingPortalCustomer]);

  useDidUpdateEffect(() => {
    if(!isUpdatedValueObjectState){
      let tempObj = deepCopy(valueObject);
      let tmpPhoneValid = deepCopy(phoneNumberValidation);
      let tmpObjValid = deepCopy(objectValidation);
      let tempCommunicationChannels = deepCopy(communicationChannels);
      const firstName = clientTellingPortalCustomer.customerInformation?.PersonalInformation?.FirstName || tempObj['PDPA-firstName'];
      const lastName = clientTellingPortalCustomer.customerInformation?.PersonalInformation?.LastName || tempObj['PDPA-lastName'];

      tempObj.ContactID = clientTellingPortalCustomer.customerInformation.ContactID || '';

      tempObj.CPCustomerInterest = clientTellingPortalCustomer.customerInformation.CPCustomerInterest || [];

      tempObj['PDPA-firstName'] = getUserFullNameWithoutFormat({firstName, lastName});

      // tempObj['PDPA-lastName'] = clientTellingPortalCustomer.customerInformation?.PersonalInformation?.LastName || tempObj['PDPA-lastName'];

      tempObj['PDPA-email'] = clientTellingPortalCustomer.customerInformation?.Email || tempObj['PDPA-email'];

      tempObj['PDPA-prefixNumber'] = clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber
        ? {
          value: clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber || tempObj['PDPA-prefixNumber'].value,
          label: clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber || tempObj['PDPA-prefixNumber'].value
        }
        : {
          value: tempObj['PDPA-prefixNumber']?.value || layoutData['Phone Country Code'][0].fields.Code.value,
          label: tempObj['PDPA-prefixNumber']?.value || layoutData['Phone Country Code'][0].fields.Code.value
        };

      tempObj['PDPA-phoneNumber'] =
        (isShowFullMarkValue ? clientTellingPortalCustomer.PhoneNumber : clientTellingPortalCustomer.customerInformation?.PhoneNumber) ||
        tempObj['PDPA-phoneNumber'];

      tempObj['PDPA-DOB'] = isShowFullMarkValue
        ? clientTellingPortalCustomer?.DOB
        : clientTellingPortalCustomer.customerInformation?.DOB?.includes('*')
          ? ''
          : clientTellingPortalCustomer.customerInformation?.DOB || tempObj['PDPA-DOB'];

      tempObj['PDPA-agreement'] = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.PDPAAgreement || tempObj['PDPA-agreement'];

      tempObj['PDPA-receiveSMS'] = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowSms || tempObj['PDPA-receiveSMS'];

      tempObj['PDPA-receiveMail'] = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowEmail || tempObj['PDPA-receiveMail'];

      tempObj['PDPA-receiveDirectMailing'] =
        clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowDirectMail || tempObj['PDPA-receiveDirectMailing'];

      tempObj['PDPA-referPOS'] = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || tempObj['PDPA-referPOS'];

      if (isSignatureHidden) {
        tempObj['PDPA-signature'] = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl || tempObj['PDPA-signature'];
      }

      tempCommunicationChannels['PDPA-messageButton'].isChecked =
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWeChat ||
        tempCommunicationChannels['PDPA-messageButton'].isChecked;

      tempCommunicationChannels['PDPA-messageButton'].empty = !tempCommunicationChannels['PDPA-messageButton'].isChecked;

      tempCommunicationChannels['PDPA-dialButton'].isChecked =
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWhatsapp ||
        tempCommunicationChannels['PDPA-dialButton'].isChecked;

      tempCommunicationChannels['PDPA-dialButton'].empty = !tempCommunicationChannels['PDPA-dialButton'].isChecked;

      tempCommunicationChannels['PDPA-SMSButton'].isChecked =
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowSMS ||
        tempCommunicationChannels['PDPA-SMSButton'].isChecked;

      tempCommunicationChannels['PDPA-SMSButton'].empty = !tempCommunicationChannels['PDPA-SMSButton'].isChecked;

      layoutData.Questions.forEach((quest) => {
        if (quest['Display Type'] == 'Drop Down') {
          tempObj[`${quest['Question Code']}`] = {
            value: {
              value: clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${quest['Question Code']}`] || '',
              label: clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${quest['Question Code']}`] || ''
            },
            type: quest['Display Type']
          };
        } else if (quest['Question Code'] == 'Gender') {
          if (quest['Is Required'] == '1' && clientTellingPortalCustomer?.customerInformation?.PersonalInformation?.Gender?.length) {
            tmpObjValid[`${quest['Question Code']}`].empty = false;
          }

          tempObj[`${quest['Question Code']}`] = {
            value:
              clientTellingPortalCustomer?.customerInformation?.PersonalInformation?.[`${quest['Question Code']}`] ||
              tempObj[`${quest['Question Code']}`].value,
            type: quest['Display Type']
          };
        } else {
          if (quest['Is Required'] == '1' && clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${quest['Question Code']}`]?.length) {
            tmpObjValid[`${quest['Question Code']}`].empty = false;
          }

          tempObj[`${quest['Question Code']}`] = {
            value:
              clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${quest['Question Code']}`] ||
              tempObj[`${quest['Question Code']}`].value,
            type: quest['Display Type']
          };
        }
      });

      if (isSignatureHidden && clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl?.trim()) {
        setSignatureImage(true);

        tmpObjValid['PDPA-signature'].empty = false;
      }

      setValueObject(tempObj);

      setCommunicationChannels(tempCommunicationChannels);

      setMailCheckbox(clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowEmail || mailCheckbox);

      setSMSCheckbox(clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowSms || SMSCheckbox);

      setDirectMailCheckbox(clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.AllowDirectMail || directMailCheckbox);

      setAgreementCheckbox(clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.PDPAAgreement || agreementCheckbox);

      if (
        clientTellingPortalCustomer.customerInformation?.PersonalInformation?.FirstName?.length
      ) {
        tmpObjValid['PDPA-firstName'].empty = false;

        // tmpObjValid['PDPA-lastName'].empty = false;
      }

      if (clientTellingPortalCustomer.customerInformation?.Email?.length || clientTellingPortalCustomer.customerInformation?.PhoneNumber?.length) {
        tmpObjValid['PDPA-email'].empty = false;

        tmpObjValid['PDPA-email'].isValid = true;

        tmpPhoneValid['PDPA-phoneNumber'].empty = false;
        if (clientTellingPortalCustomer.customerInformation?.PhoneNumber?.length) {
          tmpPhoneValid['PDPA-phoneNumber'].isValid =
            handleCheckPhoneNumberValid(tempObj['PDPA-phoneNumber']);

          tmpObjValid['PDPA-phoneNumber'].empty = false;
        }
      }

      // if (clientTellingPortalCustomer.customerInformation?.PhoneNumber?.length) {
      //   tmpPhoneValid['PDPA-phoneNumber'].empty = false;
      //
      //   tmpPhoneValid['PDPA-phoneNumber'].isValid = handleCheckPhoneNumberValid(tempObj['PDPA-phoneNumber'];
      // }

      if (
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowDirectMail ||
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowEmail ||
        clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowSms
      ) {
        let tmpMKT = deepCopy(marketingChannelsValidation);

        tmpMKT['PDPA-receiveSMS'].empty = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowSms ? false : true;

        tmpMKT['PDPA-receiveMail'].empty = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowEmail ? false : true;

        tmpMKT['PDPA-receiveDirectMailing'].empty = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.AllowDirectMail ? false : true;

        setMarketingChannelsValidation(tmpMKT);
      }

      setObjectValidation(tmpObjValid);

      setPhoneNumberValidation(tmpPhoneValid);

      setReferPOSOrder(clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || false);

      setIsUpdatedValueObjectState(true);
    }
  }, [clientTellingPortalCustomer.customerInformation, clientTellingPortalCustomer?.PhoneNumber, isShowFullMarkValue, isUpdatedValueObjectState, clientTellingPortalCustomer?.DOB, settingGlobal]);

  useDidUpdateEffect(() => {
    // INFO: update phone number state
    if (isUpdatedValueObjectState) {
      let tempObj = deepCopy(valueObject);
      let tmpPhoneValid = deepCopy(phoneNumberValidation);
      let objectValidationTemp = deepCopy(objectValidation);

      tempObj['PDPA-phoneNumber'] =
        (isShowFullMarkValue ? clientTellingPortalCustomer.PhoneNumber : clientTellingPortalCustomer.customerInformation?.PhoneNumber) ||
        tempObj['PDPA-phoneNumber'];

      setValueObject(tempObj);
      if (clientTellingPortalCustomer.customerInformation?.PhoneNumber?.length) {
        tmpPhoneValid['PDPA-phoneNumber'].empty = false;

        tmpPhoneValid['PDPA-phoneNumber'].isValid = handleCheckPhoneNumberValid(tempObj['PDPA-phoneNumber']);

        objectValidationTemp['PDPA-phoneNumber'].empty = false;
      }

      setObjectValidation(objectValidationTemp);

      setPhoneNumberValidation(tmpPhoneValid);
    }
  }, [
    isShowFullMarkValue,
    isUpdatedValueObjectState,
    clientTellingPortalCustomer?.PhoneNumber,
    clientTellingPortalCustomer.customerInformation?.PhoneNumber,
    settingGlobal
  ]);

  useDidUpdateEffect(() => {
    // INFO: update DOB state
    if (isUpdatedValueObjectState) {
      let tempObj = deepCopy(valueObject);

      tempObj['PDPA-DOB'] = isShowFullMarkValue
        ? clientTellingPortalCustomer?.DOB
        : clientTellingPortalCustomer.customerInformation?.DOB?.includes('*')
          ? ''
          : clientTellingPortalCustomer.customerInformation?.DOB || tempObj['PDPA-DOB'];

      setValueObject(tempObj);
    }
  }, [
    isShowFullMarkValue,
    isUpdatedValueObjectState,
    clientTellingPortalCustomer?.DOB,
    clientTellingPortalCustomer.customerInformation?.DOB
  ]);

  useDidUpdateEffect(() => {
    // INFO: update signature state
    if(isUpdatedValueObjectState){
      let tempObj = { ...valueObject };
      let tmpObjValid = { ...objectValidation };
      if (isSignatureHidden) {
        tempObj['PDPA-signature'] = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.SignatureImageUrl || tempObj['PDPA-signature'];
        if (clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.SignatureImageUrl?.trim()) {
          setSignatureImage(true);

          tmpObjValid['PDPA-signature'].empty = false;
        }
      }

      setObjectValidation(tmpObjValid);

      setValueObject(tempObj);
    }
  }, [clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl, isSignatureHidden, isUpdatedValueObjectState]);

  useEffect(() => {
    if (!options.length && settingGlobal?.UseAreaCode && settingGlobal?.AreaCodes?.length) {
      setOptions(() =>
        settingGlobal?.AreaCodes.map((item) => {
          return {
            value: item?.value,
            label: item?.label
          };
        })
      );
    }
  }, [settingGlobal]);

  const clearDataAfterConfirm = () => {
    let tempObjValidation = deepCopy(objectValidation);

    tempObjValidation['PDPA-firstName'].touched = false;

    // tempObjValidation['PDPA-firstName'].empty = true;

    tempObjValidation['PDPA-firstName'].isValid = true;

    // tempObjValidation['PDPA-lastName'].touched = false;

    tempObjValidation['PDPA-email'].touched = false;

    if(isSignatureHidden) {
      tempObjValidation['PDPA-signature'].touched = false;
    }

    tempObjValidation['PDPA-marketingChannels'].touched = false;

    tempObjValidation['PDPA-communicationChannels'].touched = false;

    if ('Gender' in tempObjValidation) tempObjValidation['Gender'].touched = false;

    if ('MaritalStatus' in tempObjValidation) tempObjValidation['MaritalStatus'].touched = false;

    setTouchedAgreement(false);

    setObjectValidation(tempObjValidation);
  };

  // const currentDOBValue = useMemo(() => {
  //   return clientTellingPortalCustomer.isGettingContactPersonalInfo
  //     ? ''
  //     : isShowFullMarkValue
  //       ? valueObject['PDPA-DOB']
  //       : ['0001'].every((item) => clientTellingPortalCustomer?.tmpCustomerInformation?.DOB?.includes(item))
  //         ? clientTellingPortalCustomer?.tmpCustomerInformation?.DOB?.replace('0001', '')
  //         : clientTellingPortalCustomer?.tmpCustomerInformation?.DOB;
  // }, [isShowFullMarkValue, clientTellingPortalCustomer, valueObject]);

  const currentDOBValue = useMemo(() => {
    return clientTellingPortalCustomer.isGettingContactPersonalInfo
      ? ''
      : ['0001'].every((item) => clientTellingPortalCustomer?.tmpCustomerInformation?.DOB?.includes(item))
        ? clientTellingPortalCustomer?.tmpCustomerInformation?.DOB?.replace('0001', '')
        : clientTellingPortalCustomer?.tmpCustomerInformation?.DOB;
  }, [isShowFullMarkValue, clientTellingPortalCustomer, valueObject]);

  function handleCheckInputValid (event, fieldName, fieldValue, prefixCode) {
    const inputName = event?.target?.name ?? fieldName;
    const inputValue = event?.target?.value ?? fieldValue;

    if (inputValue) {
      const phoneValidateTemp = deepCopy(phoneNumberValidation);
      const objectValidationTemp = deepCopy(objectValidation);
      const prefixCodeValue = prefixCode ?? valueObject['PDPA-prefixNumber']?.value;

      objectValidationTemp[inputName].empty = false;

      objectValidationTemp[inputName].touched = true;
      if (inputName === 'PDPA-phoneNumber') {
        let isPhoneNumberValid = handleCheckPhoneNumberValid(inputValue);
        if (currentSiteName === HK_SITE_NAME && prefixCodeValue === MACAU_PREFIX_CODE_VALUE) {
          isPhoneNumberValid = new RegExp(REGEX_CHECK_MACAU_PHONE).test(inputValue);
        }

        objectValidationTemp[inputName].isValid = isPhoneNumberValid;

        phoneValidateTemp[inputName].empty = false;

        phoneValidateTemp[inputName].isValid = isPhoneNumberValid;

        phoneValidateTemp[inputName].touched = true;
      }

      setPhoneNumberValidation(phoneValidateTemp);

      setObjectValidation(objectValidationTemp);
    }
  }

  useDidUpdateEffect(() => {
    if (clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo) {
      const cpCustomerInfo = clientTellingPortalCustomer.customerInformation.CPCustomerInfo;

      setSelectedAnswerId((prevState) => (
        {
          ...prevState,
          GenderId: cpCustomerInfo.GenderId,
          MaritalStatusId: cpCustomerInfo.MaritalStatusId
        }
      ));
    }
  }, [JSON.stringify(clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo)]);

  return (
    <QuickAccessPopup closeAccessPopup={closeAccessPopup} isCenter={isCenter} layoutData={layoutData}>
      <div className='customer-acknowledge'>
        {clientTellingPortalCustomer.isLoading ? (
          <Loading />
        ) : (
          <>
            {isCustomerInfoViewMode ? (
              <ExistingCustomerInformation
                layoutData={layoutData}
                data={clientTellingPortalCustomer}
                isDirectMailingHidden={isDirectMailingHidden}
                isSMSCommunicationHidden={isSMSCommunicationHidden}
              />
            ) : (
              <form className='customer-acknowledge__form cp-form' onSubmit={onHandleSubmit}>
                <div className='cp-form__wrap'>
                  <div className='cp-form__left'>
                    <div className='form-group'>
                      <div className='cp-input'>
                        <input
                          disabled={referPOSOrder}
                          type={TYPE_TEXT}
                          id='PDPA-firstName'
                          name='PDPA-firstName'
                          placeholder={layoutData['First Name Label'].value}
                          onChange={(e) =>
                            onHandleChange({
                              field: e.target.name,
                              value: e.target.value,
                              type: 'text'
                            })
                          }
                          value={referPOSOrder ? '' : valueObject['PDPA-firstName']}
                        />
                      </div>
                      {objectValidation['PDPA-firstName'].touched && objectValidation['PDPA-firstName'].empty ? (
                        <Text tag='span' className='error-validate' field={layoutData['First Name Validation Text']} />
                      ) : (
                        <></>
                      )}
                    </div>
                    <AcknowledgeEmailInput
                      layoutData={layoutData}
                      objectValidation={objectValidation}
                      disabled={referPOSOrder}
                      onChange={(e) =>
                        onHandleChange({
                          field: e.target.name,
                          value: e.target.value,
                          type: 'text'
                        })
                      }
                      value={referPOSOrder ? '' : valueObject['PDPA-email']}
                    />
                    <div className='form-group form-group--mb-30 d-flex d-flex--space-between'>
                      <div className='cp-form__checkbox w-70'>
                        <div className='osim-checkbox'>
                          <input
                            type='checkbox'
                            className='osim-checkbox-input'
                            id='pos-order'
                            name='PDPA-referPOS'
                            checked={valueObject['PDPA-referPOS']}
                            readOnly
                          />
                          <label
                            className={`osim-checkbox-label ${
                              clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS ? 'disable-posOder' : ''
                            }`}
                            htmlFor='pos-order'
                            onClick={() => {
                              let tmpObj = deepCopy(valueObject);

                              tmpObj['PDPA-referPOS'] = !tmpObj['PDPA-referPOS'];

                              setValueObject(tmpObj);

                              setReferPOSOrder(!referPOSOrder);
                            }}
                          >
                            <Text field={layoutData['Refer POS Label']} />
                          </label>
                        </div>
                      </div>
                      {/* <span className='cp-form__add-new'>
                        <Text field={layoutData['Add New']} />
                      </span> */}
                    </div>
                    <div className='form-group d-flex'>
                      <div className='cp-form__icon-left'>
                        <Image field={layoutData['Birthday Icon']} />
                      </div>
                      <div className='cp-form__group select-small'>
                        <CPDOB
                          currentDOB={currentDOBValue}
                          setFieldValue={(data) => {
                            onHandleChange({ field: 'PDPA-DOB', value: data.dob, type: 'text' });
                          }}
                          disabled={referPOSOrder || clientTellingPortalCustomer.isGettingContactPersonalInfo}
                          isUseForCP={true}
                        />
                      </div>
                    </div>
                    {layoutData.Questions.map((q, iq) => {
                      return (
                        <Fragment key={iq}>
                          {iq % 2 != 0 ? (
                            <>
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'>
                                  <Image field={q.Image} />
                                </div>
                                <div className='cp-form__group'>
                                  {q['Display Type'] == 'Drop Down' ? (
                                    <>
                                      <Select
                                        options={generateDropdownOption(q.Answers)}
                                        className='cp-select'
                                        classNamePrefix='cp-select'
                                        value={valueObject[`${q['Question Code']}`]?.value}
                                        onChange={(e) =>
                                          onHandleChange({
                                            field: q['Question Code'],
                                            value: e,
                                            type: 'question-dropdown'
                                          })
                                        }
                                        isSearchable={false}
                                        disabled={referPOSOrder}
                                      />
                                      {q['Is Required'] == '1' &&
                                      objectValidation[`${q['Question Code']}`]?.touched &&
                                      objectValidation[`${q['Question Code']}`]?.empty ? (
                                          <span className='error-validate'>{`${q['Question']} is required`}</span>
                                        ) : (
                                          <></>
                                        )}
                                    </>
                                  ) : (
                                    <>
                                      {q.Answers.map((a, ia) => {
                                        return (
                                          <div className='cp-form__group__item' key={ia}>
                                            <div
                                              className={`cp-input ${
                                                q['Display Type'] == 'Single Select' || q['Display Type'] == 'Multi Select' ? 'type-select' : ''
                                              } --rectangle ${
                                                q['Question Code'] == 'MaritalStatus'
                                                //  && referPOSOrder ? 'disable-event-noneOpacity' : ''
                                              }`}
                                            >
                                              <input
                                                type={
                                                  q['Display Type'] == 'Single Select'
                                                    ? TYPE_RADIO
                                                    : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                                }
                                                id={a.id}
                                                name={q['Question Code']}
                                                onChange={(e) =>
                                                  onHandleChange({
                                                    field: e.target.name,
                                                    value: a.fields.Answer.value,
                                                    type:
                                                      q['Display Type'] == 'Multi Select'
                                                        ? 'question-multiple'
                                                        : q['Display Type'] == 'Single Select' && 'question-single',
                                                    answerId: a.name
                                                  })
                                                }
                                                checked={selectedAnswerId?.[`${q['Question Code']}Id`] === a.name}
                                                // disabled={referPOSOrder}
                                                disabled={q['Question Code'] !== 'MaritalStatus' ? referPOSOrder : false}
                                              />
                                              <label htmlFor={a.id} className='cp-input__label'>
                                                <Text field={a.fields.Answer} />
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {q['Is Required'] == '1' &&
                                        objectValidation[`${q['Question Code']}`]?.touched &&
                                        objectValidation[`${q['Question Code']}`]?.empty && (
                                        <Text tag='span' className='error-validate' field={layoutData['Marital Status Validation Text']} />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                                <div className='form-group d-flex'>
                                  <div className='cp-form__icon-left'></div>
                                  <div className='cp-form__group'>
                                    <Select
                                      options={layoutData['Massage Ownership Types'].map((item) => {
                                        return {
                                          value: item.fields.Value.value,
                                          label: item.fields.Value.value
                                        };
                                      })}
                                      className='cp-select'
                                      classNamePrefix='cp-select'
                                      value={selectedMassageOwnerShip}
                                      onChange={(option) => onSelectMassageOwnership(option)}
                                      isSearchable={true}
                                      isMulti
                                      disabled={massageOwnerShip ? false : true}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <Fragment key={iq} />
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className='cp-form__right'>
                    {/*<div className='form-group'>*/}
                    {/*  <div className='cp-input'>*/}
                    {/*    <input*/}
                    {/*      disabled={referPOSOrder}*/}
                    {/*      type={TYPE_TEXT}*/}
                    {/*      id='PDPA-lastName'*/}
                    {/*      name='PDPA-lastName'*/}
                    {/*      placeholder={layoutData['Last Name Label'].value}*/}
                    {/*      onChange={(e) =>*/}
                    {/*        onHandleChange({*/}
                    {/*          field: e.target.name,*/}
                    {/*          value: e.target.value,*/}
                    {/*          type: 'text'*/}
                    {/*        })*/}
                    {/*      }*/}
                    {/*      value={referPOSOrder ? '' : valueObject['PDPA-lastName']}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/* {objectValidation['PDPA-lastName'].touched && objectValidation['PDPA-lastName'].empty && (
                          <span className='error-validate'>Last Name is required</span>
                        )} */}
                    {/*</div>*/}
                    <div className='form-group d-flex'>
                      <div className='cp-form__phone'>
                        <div className='cp-form__phone__code select-small'>
                          <Select
                            options={options}
                            className='cp-select'
                            classNamePrefix='cp-select'
                            value={valueObject['PDPA-prefixNumber']}
                            onChange={(e) =>
                              onHandleChange({
                                field: 'PDPA-prefixNumber',
                                value: e,
                                type: 'dropdown'
                              })
                            }
                            isSearchable={false}
                            isDisabled={referPOSOrder}
                          />
                        </div>
                        <div className='cp-form__phone__number'>
                          <div className='cp-input'>
                            <input
                              disabled={referPOSOrder || clientTellingPortalCustomer.isGettingContactPersonalInfo}
                              type={TYPE_TEL}
                              id='PDPA-phoneNumber'
                              name='PDPA-phoneNumber'
                              placeholder={layoutData['Phone Number Label'].value}
                              onChange={(e) =>
                                onHandleChange({
                                  field: e.target.name,
                                  value: e.target.value,
                                  type: 'text'
                                })
                              }
                              value={referPOSOrder || clientTellingPortalCustomer.isGettingContactPersonalInfo ? '' : valueObject['PDPA-phoneNumber']}
                              onBlur={handleCheckInputValid}
                            />
                          </div>

                          {phoneNumberValidation['PDPA-phoneNumber'].touched && phoneNumberValidation['PDPA-phoneNumber'].empty && (
                            <Text tag='span' className='error-validate' field={layoutData['Phone Validation Text']} />
                          )}
                          {phoneNumberValidation['PDPA-phoneNumber'].touched && !phoneNumberValidation['PDPA-phoneNumber'].isValid && (
                            <Text tag='span' className='error-validate' field={layoutData['Phone Validation Text']} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='form-group form-group--mb-30'>
                      <ul className='cp-form__social-icon'>
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <input
                            defaultChecked={clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWeChat}
                            type='checkbox'
                            className='cp-checkbox-icon__input'
                            id='PDPA-messageButton'
                            disabled={referPOSOrder || clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS}
                          />
                          <label
                            className='cp-checkbox-icon__icon'
                            htmlFor='PDPA-messageButton'
                            onClick={() => onHandleCommunicationChannels('PDPA-messageButton')}
                          >
                            <Image field={layoutData['Message Button Icon']} />
                          </label>
                        </li>
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <input
                            defaultChecked={clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWhatsapp}
                            type='checkbox'
                            className='cp-checkbox-icon__input'
                            id='PDPA-dialButton'
                            disabled={referPOSOrder || clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS}
                          />
                          <label
                            className='cp-checkbox-icon__icon'
                            htmlFor='PDPA-dialButton'
                            onClick={() => onHandleCommunicationChannels('PDPA-dialButton')}
                          >
                            <Image field={layoutData['Dial Button Icon']} />
                          </label>
                        </li>
                        {!isSMSCommunicationHidden ? (
                          <li className='cp-form__social-icon__item cp-checkbox-icon'>
                            <input
                              defaultChecked={clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowSMS}
                              type='checkbox'
                              className='cp-checkbox-icon__input'
                              id='PDPA-SMSButton'
                              disabled={referPOSOrder || clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS}
                            />
                            <label
                              className='cp-checkbox-icon__icon'
                              htmlFor='PDPA-SMSButton'
                              onClick={() => onHandleCommunicationChannels('PDPA-SMSButton')}
                            >
                              <Image field={layoutData['SMS Icon']} />
                            </label>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                      {objectValidation['PDPA-communicationChannels'].touched && objectValidation['PDPA-communicationChannels'].empty && (
                        <Text tag='p' className='error-validate' field={layoutData['Communication Channel Validation Text']} />
                      )}
                    </div>
                    {layoutData.Questions.map((q, iq) => {
                      return (
                        <Fragment key={iq}>
                          {iq % 2 == 0 ? (
                            <>
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'>
                                  <Image field={q.Image} />
                                </div>
                                <div className='cp-form__group'>
                                  {q['Display Type'] == 'Drop Down' ? (
                                    <>
                                      <Select
                                        options={generateDropdownOption(q.Answers)}
                                        className='cp-select'
                                        classNamePrefix='cp-select'
                                        value={valueObject[`${q['Question Code']}`]?.value}
                                        onChange={(e) =>
                                          onHandleChange({
                                            field: q['Question Code'],
                                            value: e,
                                            type: 'question-dropdown'
                                          })
                                        }
                                        isSearchable={false}
                                        isDisabled={referPOSOrder}
                                      />
                                      {q['Is Required'] == '1' && objectValidation[`${q['Question Code']}`]?.touched && (
                                        <span className='error-validate'>{`${q['Question']} is required`}</span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {q.Answers.map((a, ia) => {
                                        return (
                                          <div className='cp-form__group__item' key={ia}>
                                            <div
                                              className={`cp-input ${
                                                q['Display Type'] == 'Single Select' || q['Display Type'] == 'Multi Select' ? 'type-select' : ''
                                              } --rectangle cp-input__${q['Question Code']}`}
                                            >
                                              <input
                                                type={
                                                  q['Display Type'] == 'Single Select'
                                                    ? TYPE_RADIO
                                                    : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                                }
                                                id={a.id}
                                                name={q['Question Code']}
                                                onChange={(e) =>
                                                  onHandleChange({
                                                    field: e.target.name,
                                                    value: a.fields.Answer.value,
                                                    type:
                                                      q['Display Type'] == 'Multi Select'
                                                        ? 'question-multiple'
                                                        : q['Display Type'] == 'Single Select' && 'question-single',
                                                    answerId: a.name
                                                  })
                                                }
                                                checked={selectedAnswerId?.[`${q['Question Code']}Id`] === a.name}
                                                disabled={referPOSOrder}
                                              />
                                              <label htmlFor={a.id} className='cp-input__label'>
                                                <Text field={a.fields.Answer} />
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {q['Is Required'] == '1' &&
                                        objectValidation[`${q['Question Code']}`]?.touched &&
                                        objectValidation[`${q['Question Code']}`]?.empty && (
                                        <Text tag='span' className='error-validate' field={layoutData['Marital Status Validation Text']} />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                                <div className='form-group d-flex'>
                                  <div className='cp-form__icon-left'></div>
                                  <div className='cp-form__group'>
                                    <Select
                                      options={layoutData['Massage Ownership Types'].map((item) => {
                                        return {
                                          value: item.fields.Value.value,
                                          label: item.fields.Value.value
                                        };
                                      })}
                                      className='cp-select'
                                      classNamePrefix='cp-select'
                                      value={selectedMassageOwnerShip}
                                      onChange={(option) => onSelectMassageOwnership(option)}
                                      isSearchable={true}
                                      isMulti
                                      disabled={massageOwnerShip ? false : true}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <Fragment key={iq}></Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                {isSignatureHidden && (
                  <div className='form-group'>
                    {referPOSOrder ? (
                      <div className='form-control form-control-lg form-control__canvas' />
                    ) : (
                      <>
                        {signatureImage ? (
                          <div className='form-control form-control-lg form-control__canvas'>
                            <img src={clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl} alt='' />
                          </div>
                        ) : (
                          <div className='customer-acknowledge__form__signature__area'>
                            <ReactSignatureCanvas
                              canvasProps={{ className: 'form-control form-control-lg form-control__canvas' }}
                              ref={(ref) => {
                                sigCanvas = ref;
                              }}
                              onBegin={() => setIsShowSignatureLabel(false)}
                            />
                            {isShowSignatureLabel && (
                              <Text
                                tag='h6'
                                field={layoutData['Signature Here Label']}
                                className='customer-acknowledge__form__signature__area__label'
                              />
                            )}
                          </div>
                        )}
                      </>
                    )}
                    <div className='cp-form__buttonSignature'>
                      {signatureImage ? (
                        <button
                          className='cp-form__buttonSignature__item btn btn-os-orange btn-os-orange--text-primary'
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          disabled={referPOSOrder}
                        >
                          <Text field={layoutData['Signature Trim Label']} />
                        </button>
                      ) : (
                        <button
                          className='cp-form__buttonSignature__item btn btn-os-orange btn-os-orange--text-primary'
                          onClick={(e) => {
                            e.preventDefault();

                            dataUrlToFile(sigCanvas.getCanvas().toDataURL(/(^image\/)*(png|img|jpe?g|gif)/), 'signature.png').then((file) => {
                              onHandleChange({ field: 'PDPA-signature', value: file, type: 'get-signature' });

                              if (file.size > FILE_SIZE) {
                                dispatch(uploadBOTradeInFile([file]));
                              }
                            });
                          }}
                          disabled={referPOSOrder}
                        >
                          <Text field={layoutData['Signature Trim Label']} />
                        </button>
                      )}
                      {signatureImage ? (
                        <button
                          className='cp-form__buttonSignature__item btn btn-outline-primary'
                          onClick={(e) => {
                            e.preventDefault();

                            setSignatureImage(false);

                            setIsShowSignatureLabel(true);
                            let tmpObj = deepCopy(objectValidation);
                            let tmpValueObj = deepCopy(valueObject); // INFO: remove this code when do not want to remove signature when click Clear button

                            tmpObj['PDPA-signature'].empty = true;

                            tmpValueObj['PDPA-signature'] = ''; // INFO: remove this code when do not want to remove signature when click Clear button

                            setObjectValidation(tmpObj);

                            setValueObject(tmpValueObj); // INFO: remove this code when do not want to remove signature when click Clear button
                          }}
                          disabled={referPOSOrder}
                        >
                          <Text field={layoutData['Signature Clear Label']} />
                        </button>
                      ) : (
                        <button
                          className='cp-form__buttonSignature__item btn btn-outline-primary'
                          onClick={(e) => {
                            e.preventDefault();

                            sigCanvas.clear();

                            setIsShowSignatureLabel(true);

                            onHandleChange({ field: 'PDPA-signature', type: 'remove-signature' });
                          }}
                          disabled={referPOSOrder}
                        >
                          <Text field={layoutData['Signature Clear Label']} />
                        </button>
                      )}
                    </div>
                    {objectValidation['PDPA-signature'].touched && objectValidation['PDPA-signature'].empty && (
                      <Text tag='span' className='error-validate' field={layoutData['Signature Validation Text']} />
                    )}
                  </div>
                )}
                <div className='form-group form-group--checkboxes'>
                  <label className='osim-checkbox-label form-group--checkboxes__label--custom'>
                    <Text field={layoutData['Confirm Marketing Agreement Message']} />
                  </label>
                  <div className='form-group--checkboxes__list form-group--checkbox-pd-left'>
                    <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='PDPA-receiveSMS'
                        name='PDPA-receiveSMS'
                        className='osim-checkbox-input'
                        onChange={(e) =>
                          onHandleChange({
                            field: e.target.name,
                            value: !e.target.checked,
                            type: 'checkbox'
                          })
                        }
                        disabled={disabledSMS}
                        checked={SMSCheckbox}
                      />
                      <label className='osim-checkbox-label' htmlFor='PDPA-receiveSMS' onClick={() => onHandleValidate('sms')}>
                        <Text field={layoutData['SMS Label']} />
                      </label>
                    </div>
                    <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='PDPA-receiveMail'
                        name='PDPA-receiveMail'
                        className='osim-checkbox-input'
                        onChange={(e) =>
                          onHandleChange({
                            field: e.target.name,
                            value: !e.target.checked,
                            type: 'checkbox'
                          })
                        }
                        disabled={disabledMail}
                        checked={mailCheckbox}
                      />
                      <label className='osim-checkbox-label' htmlFor='PDPA-receiveMail' onClick={() => onHandleValidate('mail')}>
                        <Text field={layoutData['Mail Label']} />
                      </label>
                    </div>
                    {!isDirectMailingHidden ? (
                      <div className='osim-checkbox'>
                        <input
                          type='checkbox'
                          id='PDPA-receiveDirectMailing'
                          name='PDPA-receiveDirectMailing'
                          className='osim-checkbox-input'
                          onChange={(e) =>
                            onHandleChange({
                              field: e.target.name,
                              value: !e.target.checked,
                              type: 'checkbox'
                            })
                          }
                          disabled={disabledDirectMail}
                          checked={directMailCheckbox}
                        />
                        <label className='osim-checkbox-label' htmlFor='PDPA-receiveDirectMailing' onClick={() => onHandleValidate('directMailing')}>
                          <Text field={layoutData['Direct Mailing Label']} />
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {objectValidation['PDPA-marketingChannels'].touched && objectValidation['PDPA-marketingChannels'].empty ? (
                    <Text tag='span' className='error-validate' field={layoutData['PDPA Validation Text']} />
                  ) : (
                    <></>
                  )}
                </div>
                <div className='form-group'>
                  <div className='cp-form__checkbox'>
                    <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='PDPA-agreement'
                        className='osim-checkbox-input'
                        name='PDPA-agreement'
                        onChange={(e) =>
                          onHandleChange({
                            field: e.target.name,
                            value: !e.target.checked,
                            type: 'checkbox'
                          })
                        }
                        disabled={disabledAgreement}
                        checked={agreementCheckbox}
                      />
                      <label
                        className='osim-checkbox-label'
                        htmlFor='PDPA-agreement'
                        onClick={() => {
                          !referPOSOrder && onHandleValidate('agreement');
                        }}
                      >
                        <RichText tag='div' field={layoutData['PDPA Agreement Message']} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='cp-form__button cp-form__button--center'>
                  {!clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS ? (
                    <button type='submit' className='cp-form__button__item btn btn-primary'>
                      <Text field={layoutData['Confirm Button Label']} />
                    </button>
                  ) : (
                    <span onClick={() => closeAccessPopup()} className='cp-form__button__item btn btn-primary'>
                      <Text field={layoutData['Close Button Label']} />
                    </span>
                  )}
                </div>
              </form>
            )}
          </>
        )}
      </div>
      <CustomerInformationWarningPopup
        layoutData={layoutData}
        isShowWarningPopup={isShowWarningPopup}
        setShowWarningPopup={() => setShowWarningPopup(false)}
        generateContactFormData={generateContactFormData}
      />
      {clientTellingPortalCustomer.isUpdateContactLoading || isChecking ? <LoadingMask parent={CustomerAcknowledgePopup} /> : <></>}
    </QuickAccessPopup>
  );
};

CustomerAcknowledgePopup.propTypes = {
  closeAccessPopup: PropTypes.func,
  isCenter: PropTypes.bool,
  layoutData: PropTypes.object
};

export default CustomerAcknowledgePopup;
