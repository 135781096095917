import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { useCampaignContext } from '../useCampaignContext';
import LoginForm from './LoginForm';
import Subtitle from './Subtitle';

const LoginModalForm = (props) => {
  const { isLoginSuccess, isLoading } = props;
  const { layoutData, isOpenLoginModal, setIsOpenLoginModal } = useCampaignContext();
  const handleOpenModal = () => setIsOpenLoginModal(true);
  const handleCloseModal = () => setIsOpenLoginModal(false);

  useDidUpdateEffect(() => {
    if (isLoginSuccess) handleCloseModal();
  }, [isLoginSuccess]);

  return !isLoading ? (
    <>
      {!isLoginSuccess ? (
        <Subtitle text={layoutData.registerEventScreenSubtitle.value} onOpenModal={handleOpenModal} />
      ) : (
        <Text tag='h5' field={layoutData?.registerEventScreenSubtitleAfterLogin} className='subtitle-text' />
      )}
      <ModalComponent isShow={isOpenLoginModal} onCloseHandler={handleCloseModal} titlePopup={layoutData.loginTitle.value}>
        <div className='login-modal-form'>
          <LoginForm />
        </div>
      </ModalComponent>
    </>
  ) : (
    <LoadingMask parent={LoginModalForm} />
  );
};

LoginModalForm.propTypes = {
  isLoginSuccess: PropTypes.bool,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  isLoading: state.singleSignOnReducer.loginReducer?.isLoading
});

export default connect(mapStateToProps)(LoginModalForm);
