import './NewPassword.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { changePassword } from '@redux/actions/singleSignOn';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import Global from '@utils/global';

import PasswordInput from '../PasswordInput';
import { useGeneratePasswordRegex } from '../PasswordInput/hooks';
import NewPasswordModel from './models/NewPasswordModel';

const NewPassword = ({ fields }) => {
  const [inputData, setInputData] = useState(null);
  const dispatch = useDispatch();
  const isRenewPassword = useSelector((state) => state.singleSignOnReducer.renewPasswordReducer.changePasswordReducer.isRenewPassword);
  const errorMessage = useSelector((state) => state.singleSignOnReducer.renewPasswordReducer.changePasswordReducer.errorMessage);
  const isLoading = useSelector((state) => state.singleSignOnReducer.renewPasswordReducer.changePasswordReducer.isLoading);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { regexSummary } = useGeneratePasswordRegex();

  useEffect(() => {
    const dataLayoutService = new NewPasswordModel().getData(fields || {});
    if (dataLayoutService.NewpasswordInfo[0]?.Url) {
      //INFO: Return 404 page if token expired
      window.location.href = dataLayoutService.NewpasswordInfo[0].Url;
    } else {
      setInputData(dataLayoutService);
    }
  }, []);

  const onRedirectHomePageHandler = () => {
    window.location.href = !isLoginSuccess ? settingGlobal?.LoginLink || '/login' : '/';
  };

  // useDidUpdateEffect(() => {
  //   if (isRenewPassword) {
  //     //INFO: Return to homepage
  //     window.location.href = !isLoginSuccess ? settingGlobal?.LoginLink || '/login' : '/';
  //   }
  // }, [isRenewPassword]);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(regexSummary, messageObj?.['Msg_001.5']),
      passwordConfirmation: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .oneOf([Yup.ref('password'), null], <Message messageCode='Msg_001.8' />)
    }),
    onSubmit: (values) => {
      dispatch(
        changePassword({
          token: inputData.NewpasswordInfo ? inputData.NewpasswordInfo[0].token : null,
          newPassword: values.password
        })
      );
    },
    validateOnMount: true
  });

  return (
    inputData && (
      <Fragment>
        {!isRenewPassword ? (
          <div className='reset-password osim-account'>
            {/* <div className='reset-password__heading osim-account__heading'>
            <div className='container'>
              <h2 className=' osim-account__heading__title page-title'>Reset Password</h2>
            </div>
          </div> */}
            <div className='container tab-content no-padding-mobile'>
              <form onSubmit={formik.handleSubmit} className='reset-password__form'>
                {errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
                {/* <p className='osim-account-form__title'>
                <Text field={inputData['Instruction']} />
              </p> */}
                {inputData['Instruction'].value ? (
                  <p className='osim-account-form__title'>
                    <Text field={inputData['Instruction']} />
                  </p>
                ) : (
                  <></>
                )}
                
                <div className={Global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group reset-password__form__input', 'input-error-validate')}>
                  <PasswordInput
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    type='password'
                    className={Global.renderDynamicClass(formik.values['password'], 'form-control form-control-lg', 'input-valid')}
                    name='password'
                    placeholder={inputData['New Password Label'].value}
                    errorMessageEl={formik.errors.password && formik.touched.password && <span className='error-validate'>{formik.errors.password}</span>}
                  />
                  <label htmlFor='password' className='form-group__label'>
                    <Text field={inputData['New Password Label']} />
                  </label>
                </div>
                <div className={Global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group reset-password__form__input', 'input-error-validate')}>
                  <PasswordInput
                    onChange={formik.handleChange}
                    value={formik.values.passwordConfirmation}
                    type='password'
                    className={Global.renderDynamicClass(formik.values['passwordConfirmation'], 'form-control form-control-lg', 'input-valid')}
                    name='passwordConfirmation'
                    placeholder={inputData['Confirm New Password Label'].value}
                    errorMessageEl={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
                      <span className='error-validate'>{formik.errors.passwordConfirmation}</span>
                    )}
                  />
                  <label htmlFor='passwordConfirmation' className='form-group__label'>
                    <Text field={inputData['Confirm New Password Label']} />
                  </label>
                </div>
                <div className='osim-account-form__form__btn'>
                  <button className='osim-account-form__form__btn__item btn btn-primary'>
                    <Text field={inputData['Reset Password Button Label']} />
                  </button>
                </div>
              </form>
            </div>
            {isLoading ? <LoadingMask parent={NewPassword} /> : ''}
          </div>
        ) : (
          <div className='reset-password osim-account'>
            <div className='container'>
              <div className='add-to-cart__body'>
                <RichText field={inputData['Reset Password Successful Instruction']} tag='p' className='add-to-cart__body__success-text' />
              </div>
            </div>
            <div className='contact-info__wrap'>
              <div className='contact-info__form text-center'>
                <Text field={inputData['Reset Password Successful Description']} tag='p' className='security-verification__info text-center' />
                <div className='osim-account-form__form__btn'>
                  <button className='osim-account-form__form__btn__item btn btn-primary' onClick={() => onRedirectHomePageHandler()}>
                    <Text field={inputData['Back To Login Text']} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  );
};

NewPassword.propTypes = {
  fields: PropTypes.object
};

export default NewPassword;
