import './SpinCouponInfoPopup.scss';

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import SpinWinContext from '../SpinAndWinContext';

const SpinCouponInfoPopup = ({ 
  layoutData,
  coupon,
  applyOfferButtonText, 
  isChanceToWin 
}) => {
  const {handleApplyCoupon} = useContext(SpinWinContext);
  const couponReducer = useSelector(state => state.couponReducer);
  const couponDetail = couponReducer?.couponDetail?.Promotion;
  const isLoading = couponReducer?.isLoadingMask;

  const validityFrom = new Date(Number.parseInt(couponDetail?.ValidityFrom?.replace(/Date|\(|\/|\)/gi, '')));
  const convertValidityFrom = Global.customDate(validityFrom);

  const ValidityTo = new Date(Number.parseInt(couponDetail?.ValidityTo?.replace(/Date|\(|\/|\)/gi, '')));
  const convertValidityTo = Global.customDate(ValidityTo);

  return ( isLoading ? <LoadingMask parent={SpinCouponInfoPopup} /> :
    <>
      <div className='spin-coupon-info-popup osim-popup__body coupon-popup__info'>
        <h4 className='coupon-popup__info-title'>{coupon?.Title}</h4>
        {coupon?.Code && (
          <div className='text-center'>
            {/* <span className='cart-promotion-code-item__hint-text'>Coupon code: </span> */}
            <Text tag='span' className='cart-promotion-code-item__hint-text' field={layoutData['Coupon Code Label']} />
            <span className='cart-promotion-code-item__code'>{coupon?.Code}</span>
          </div>
        )}
        <div className='cart-promotion-code-item__description'>{coupon?.Description}</div>
        <span className='coupon-popup__info-validity'>
          {/* <strong>Valid From: </strong> */}
          <Text tag='strong' field={layoutData['Valid From Label']} />
          {' ' + convertValidityFrom + ' ' + layoutData?.['To Label']?.value + ' ' + convertValidityTo}
        </span>
      </div>
      <div className='osim-popup__footer coupon-popup__action'>
        {!isChanceToWin && (
          <button className='btn btn-primary' onClick={()=> handleApplyCoupon(coupon)}>
            {applyOfferButtonText}
          </button>
        )}
      </div>
    </>
  );
};

SpinCouponInfoPopup.propTypes = {
  layoutData: PropTypes.any,
  coupon: PropTypes.object,
  applyOfferButtonText: PropTypes.string,
  isChanceToWin: PropTypes.bool
};

export default SpinCouponInfoPopup;
