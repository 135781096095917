import './MyAppointmentsWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import { useTimeSlotGenerator } from '../MyAppointments/useTimeSlotGenerator';
import MyAppointmentsWidgetModel from './models/MyAppointmentsWidgetModel';

const MyAppointmentsWidget = ({ fields }) => {
  const timeSlotGenerator = useTimeSlotGenerator();
  const formatDate = useFormatDate();
  const [dataSource, setDataSource] = useState(null);
  const [linkViewDetail, setLinkViewDetail] = useState();
  const [labelViewDetail, setLabelViewDetail] = useState();

  useEffect(() => {
    setDataSource(new MyAppointmentsWidgetModel().getData(fields));
  }, []);

  useEffect(() => {
    if (dataSource) {
      if (dataSource?.myAppointment?.IsLoyaltyEvent) {
        setLinkViewDetail(`${dataSource?.['View Loyalty Link']?.value?.href}?eventId={${dataSource?.myAppointment?.Campaign?.Id}}`);
    
        setLabelViewDetail(dataSource?.['View Loyalty Label']?.value);
      } else {
        setLinkViewDetail(
          `${dataSource?.['View Link']?.value?.href}?campaign_id=${dataSource?.myAppointment?.Campaign?.Id}&appointment_id=${dataSource?.myAppointment?.Id}`
        );

        setLabelViewDetail(dataSource?.['View Label']?.value);
      }
    }
  }, [dataSource]);

  const timeSlotValue = useMemo(() => {
    return timeSlotGenerator(dataSource?.myAppointment?.TimeSlot?.Id, dataSource?.myAppointment?.Store?.TimeSlots?.TimeSlots) || '';
  }, [dataSource]);
  
  const numberOfGuestText = useMemo(() => {
    return dataSource?.['Guests Label']?.value?.replace('{numberOfGuest}', dataSource?.myAppointment?.NumberOfGuests);
  }, [dataSource]);

  return dataSource ? (
    <div className='my-account-section appointments-widget'>
      <div className='my-account-section__heading'>
        <div className='appointments-widget__head'>
          <Text field={dataSource['Title']} tag='h3' className='my-account-section__heading__title appointments-widget__head__title' />
          <Link field={dataSource?.['View All Link']} className='my-account-section__btn-link appointments-widget__head__btn-link'>
            <Text field={dataSource?.['View All Label']} />
          </Link>
          {/* {
            dataSource?.myAppointment?.Id ? (
              <Link field={dataSource?.['View All Link']} className='my-account-section__btn-link appointments-widget__head__btn-link'>
                <Text field={dataSource?.['View All Label']}/>
              </Link>
            ): (
              <Text field={dataSource?.['View All Label']} className='my-account-section__btn-link appointments-widget__head__btn-link disable' tag='span'/>
            )
          } */}
        </div>
        {dataSource?.myAppointment?.Id ? (
          <></>
        ) : (
          <Text field={dataSource?.['No Appointment Text']} className='my-account-section__heading__label' tag='p' />
        )}
      </div>
      {dataSource?.myAppointment?.Id ? (
        <div className='appointments-widget__content'>
          <div className='appointments-widget__content__time'>
            <span>{formatDate(dataSource?.myAppointment?.Date)}</span>
            {timeSlotValue ? <span>{timeSlotValue}</span> : <></>}
            <span>{numberOfGuestText}</span>
          </div>
          <Text field={{ value: dataSource?.myAppointment?.Store?.Name }} tag='h4' className='appointments-widget__content__title' />
          <div className='appointments-widget__content__description'>
            <RichText field={{ value: dataSource?.myAppointment?.Store?.Address?.Address }} tag='p' className='item'></RichText>
            <Text field={{ value: dataSource?.myAppointment?.Store?.Address?.PostalCode }} tag='p' className='item'></Text>
            {dataSource?.myAppointment?.Store?.PhoneNumber ? (
              <Text field={{ value: `+${dataSource?.myAppointment?.Store?.PhoneNumber}` }} tag='p' className='item' />
            ) : (
              <></>
            )}
          </div>
          <Link field={{ href: linkViewDetail }} className='appointments-widget__content__btn-link'>
            <Text field={{ value: labelViewDetail }} />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

MyAppointmentsWidget.propTypes = {
  fields: PropTypes.object
};

export default MyAppointmentsWidget;