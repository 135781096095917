import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { cpVerifyLogin, resendLoginValidateCode } from '@redux/actions/singleSignOn';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPVerifyLoginModel from '../Models/CPVerifyLoginModel';

const CPVerifyLogin = ({fields}) => {
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.singleSignOnReducer.loginReducer);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [cPVerifyLoginFields, setCPVerifyLoginFields] = useState(null);
  const [isShowResend, setIsShowResend] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required(<Message messageCode='Msg_002.9' />).trim(),
    }),
    onSubmit: (values) => {
      const getLocalBrowserId = window.localStorage.getItem('LocalBrowserId') || '';

      dispatch(cpVerifyLogin({
        UserName: JSON.parse(window.localStorage.getItem('cPUserData'))?.Username || '',
        VerifyCode: values.otp.trim(),
        DeviceId: getLocalBrowserId
      })
      );
    }
  });
  
  const actionResendLoginOTP = () => {
    dispatch(resendLoginValidateCode({
      UserName: JSON.parse(window.localStorage.getItem('cPUserData'))?.Username || '',
    })
    );
  };

  const getTimeSecond = () => Number(fields?.['First Time Disable Resend Button']?.value || 0)*1000 || 0;

  useEffect(() => {
    if (isClientTelingUser && cPVerifyLoginFields) {
      window.location.href = cPVerifyLoginFields['Verify Success Link']?.value.href;
    }
  }, [cPVerifyLoginFields]);

  useEffect(() => {
    const cPVerifyLoginModel = new CPVerifyLoginModel();

    setCPVerifyLoginFields(cPVerifyLoginModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    if (loginReducer.isCPVerifyLoginSuccess && cPVerifyLoginFields && cPVerifyLoginFields['Verify Success Link'].value.href) {
      window.location.href = cPVerifyLoginFields['Verify Success Link'].value.href;
    }
  }, [loginReducer.isCPVerifyLoginSuccess, cPVerifyLoginFields]);

  useEffect(() => {
    const timeShowResend = setTimeout(() => {
      setIsShowResend(true);
    }, getTimeSecond());

    return () => clearTimeout(timeShowResend);
  }, []);

  useEffect(() => {
    if (loginReducer.cPVerifyLoginError) {
      setIsShowError(true);
    }
  }, [loginReducer.cPVerifyLoginError]);

  useEffect(() => {
    if (loginReducer.cPResendLoginOTPSuccess) {
      setIsShowResend(false);

      setTimeout(() => {
        setIsShowResend(true);
      }, getTimeSecond());
    }
  }, [loginReducer.cPResendLoginOTPSuccess]);

  return cPVerifyLoginFields && (
    <>
      <header className='cp-login-header cp-header'>
        <div className='cp-header__wrapper'>
          <div className='cp-header__container'>
            <span className='cp-login-header__logo'>
              <Image field={cPVerifyLoginFields.Logo} />
            </span>
          </div>
        </div>
      </header>
      <div className='cp-login-content'>
        <div className='cp-login-content__background'>
          <Image field={cPVerifyLoginFields['Background Image']} />
        </div>
        <div className='cp-login-content__form-wrap'>
          <form onSubmit={formik.handleSubmit} className='cp-login-content__form'>
            <Text className='cp-login-content__form__title' tag='h2' field={cPVerifyLoginFields['Title']} />
            {/* Resend OTP */}
            <div className='cp-login-content__form__main'>
              <div className='form-group'>
                <input 
                  type='text' 
                  id='otp' 
                  className='form-control form-control-lg' 
                  name='otp'
                  placeholder={cPVerifyLoginFields['Verify Code Text Placeholder'].value}
                  onChange={(e) => {
                    setIsShowError(false);

                    formik.handleChange(e);
                  }
                  }
                  value={formik.values.otp}
                />
                <label htmlFor='otp' className='form-group__label'>{cPVerifyLoginFields['Verify Code Text Placeholder'].value}</label>
                {formik.errors.otp && formik.touched.otp ? (
                  <span className='error-validate'>
                    {formik.errors.otp}
                  </span>
                ) : (
                  isShowError
                    ? <span className='error-validate 222'>
                      <Message messageCode='Msg_002.9' />
                    </span> 
                    : ''
                )}
                
              </div>
              <div className='form-group cp-login-content__form__btn'>
                <button type='submit' className='cp-login-content__form__btn__item btn btn-primary'>
                  <Text field={cPVerifyLoginFields['Verify Code Button Text']} />
                </button>
                {
                  isShowResend ? (
                    <span className='cp-login-content__form__btn__item os-btn-link'
                      onClick={() => actionResendLoginOTP()}>
                      <Text field={cPVerifyLoginFields['Send Code Button Text']} />
                    </span>
                  ) : ''
                }
              </div>
            </div>

            {/* Sign In locked */}
            {/* <p className='cp-login-content__form__info'>Your account is temporarily locked. Please contact your manager to unlock it.</p>
            <div className='cp-login-content__form__main'>
              <div className='form-group cp-login-content__form__btn'>
                <button className='cp-login-content__form__btn__item btn btn-primary'>back to login</button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      {loginReducer.isCPLogin ? <LoadingMask parent={CPVerifyLogin}/> : ''}
    </>
  );
};

CPVerifyLogin.propTypes = {
  fields: PropTypes.any
};

export default CPVerifyLogin;