import './PasswordHint.scss';

import { bool, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGeneratePasswordRegex } from '../PasswordInput/hooks';

const PasswordHint = ({ isShow, passwordValue }) => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const {
    minLength,
    atLeastOneNumberRegex,
    atLeastOneSpecialCharacterRegex,
    upperAndLowercaseRegex
  } = useGeneratePasswordRegex();

  const passwordHintList = useMemo(() => (
    [
      {
        text: settingGlobal?.CharacterLengthText || '',
        minLength: minLength,
        isActive: function() {
          return (
            passwordValue.length >= this.minLength
          );
        }
      },
      {
        text: settingGlobal?.UpperAndLowercaseText || '',
        regex: new RegExp(upperAndLowercaseRegex),
        isActive: function() {
          return this.regex.test(passwordValue);
        }
      },
      {
        text: settingGlobal?.AtLeastOneNumberText || '',
        regex: new RegExp(atLeastOneNumberRegex),
        isActive: function() {
          return this.regex.test(passwordValue);
        }
      },
      {
        text: settingGlobal?.NonAlphanumericCharactersText || '',
        regex: new RegExp(atLeastOneSpecialCharacterRegex),
        isActive: function() {
          return this.regex.test(passwordValue);
        }
      }
    ]
  ), [passwordValue, settingGlobal]);

  return isShow ? (
    <div className='password-hint'>
      <p className='password-hint__title'>{settingGlobal?.PasswordHintText || ''}</p>
      <ul className='password-hint__list'>
        {passwordHintList.map((item) => (
          <li key={item.text} className={['password-hint__item', item.isActive() ? 'active' : ''].join(' ')}>
            {settingGlobal?.IncorrectPasswordIcon && settingGlobal?.CorrectPasswordIcon ? (
              <i className='password-hint__item-icon'>
                <img
                  src={settingGlobal.IncorrectPasswordIcon}
                  alt='checkmark--v1' className='icon-img icon-inactive'
                />
                <img
                  src={settingGlobal.CorrectPasswordIcon}
                  alt='checkmark--v1'
                  className='icon-img icon-active'
                />
              </i>
            ) : (
              <></>
            )}
            <span className='password-hint__item-text'>{item.text}</span>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  );
};

PasswordHint.propTypes = {
  isShow: bool.isRequired,
  passwordValue: string.isRequired
};

export default PasswordHint;