import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import addFreeGiftToCartReducer from './addFreeGiftToCart';
import addPriorityToCartReducer from './addPriorityToCart';
import addWarrantyReducer from './addWarranty';
import { bookingExperienceReducer } from './bookingExperience';
import { buyingOptionReducer } from './buyingOption';
import bundleBOReducer from './buyingOptionsBundle';
import { campaignRegisterReducer } from './campaignRegister';
import categoryForRegisterWarrantyReducer from './categoryForRegisterWarranty';
import { checkoutProcesstReducer } from './checkoutProcess';
import clientTellingPortalCustomer from './clientTellingPortalCustomer';
import clientTellingPortalQuickAccess from './clientTellingPortalQuickAccess';
import { commonSearchReducer } from './commonSearch';
import compareProductReducer from './compareProduct';
import controlSelectingFreeGiftPopupReducer from './controlSelectingFreeGiftPopup';
import couponReducer from './coupon';
import couponWalletReducer from './couponWallet';
import cpAppointmentDetailReducer from './cpAppointmentDetail';
import cpAppointmentListingReducer from './cpAppointmentListing';
import cpCartDetailsReducer from './cpCartDetails';
import cpCheckContactDuplicateReducer from './cpCheckContactDuplicate';
import cpLeadManagementReducer from './cpLeadManagement';
import { cpNotificationPopupReducer } from './cpNotificationPopup';
import cpOverviewReducer from './cpOverview';
import cpProductInterestDataReducer from './cpProductInterestData';
import cpSaleOrderStatusesReducer from './cpSaleOrderStatuses';
import cpSendSaleOrderReducer from './cpSendSaleOrder';
import cPStartDemoReducer from './cpStartDemo';
import cpStoreSelectorReducer from './cpStoreSelector';
import cpUpdateAppointmentReducer from './cpUpdateAppointment';
import dynamicBundleReducer from './dynamicBundle';
import { eventBookingReducer } from './eventBooking';
import getFreeGiftReducer from './getFreeGiftData';
import getSOCartsReducer from './getSOCarts';
import { helpMeDecideReducer } from './helpMeDecide';
import layoutElementStyleReducer from './layoutElementStyle';
import { loyaltyReducer } from './loyalty';
import mediaTrackingGoalReducer from './mediaTrackingGoal';
import getMessageReducer from './message';
import myAddressReducer from './myAddress';
import myAppointmentReducer from './myAppointment';
import myOrderReducer from './myOrders';
import getMyReviewsReducer from './myReviews';
import { personalizeOfferReducer } from './personalizeOffer';
import priorityDeliveryReducer from './priorityDelivery';
import { productReducer } from './product';
import productCountdownTimerReducer from './product/productCountdownTimer';
import getProductCatalogueReducer from './productCatalogue';
import productForRegisterWarrantyReducer from './productForRegisterWarranty';
import productReviewReducer from './productReview';
import getCatalogHeaderNavigationReducer from './productReview/getCatalogHeaderNavigation';
import productWarrantyReducer from './productWarranty';
import setProductWishlistCurrentReducer from './productWishlistCurrent';
import searchBoxReducer from './searchBox';
import settingGlobalReducer from './settingGlobal';
import settingGlobalURLReducer from './settingGlobalURL';
import sfExpressReducer from './SFexpress';
import { singleSignOnReducer } from './singleSignOn';
import spinAndWinReducer from './spinAndWin';
import staticBundleDetailReducer from './staticBundleDetail';
import { storeLocatorReducer } from './storeLocator';
import submitPurchaseOnSpotReducer from './submitPurchaseOnSpot';
import { subscribeNewsletterReducer } from './subscribeNewsletter';
import { taxMessageDetailReducer } from './taxMessageDetail';
import testReducer from './test';
import unsubscribeReducer from './unsubscribe';
import updateFreeGiftToCartReducer from './updateFreeGiftToCart';
import usersReducer from './user';

export const appReducer = combineReducers({
  usersReducer,
  testReducer,
  buyingOptionReducer,
  productReducer: persistReducer(
    {
      key: 'productReducer',
      storage: storage,
      blacklist: [
        'notifyProductStatusReducer',
        'productDetailOverviewReducer',
        'productDetailHeaderReducer',
        'productListReducer',
        'cartDetailReducer',
        'productWishlistReducer',
        'freeGiftReducer'
      ]
    },
    productReducer
  ),

  checkoutProcesstReducer: persistReducer(
    {
      key: 'checkoutProcesstReducer',
      storage: storage,
      whitelist: ['dataCheckoutReducer']
    },
    checkoutProcesstReducer
  ),

  singleSignOnReducer: persistReducer(
    {
      key: 'singleSignOnReducer',
      storage: storage,
      whitelist: ['userInfoReducer']
    },
    singleSignOnReducer
  ),

  getMessageReducer,
  storeLocatorReducer,
  unsubscribeReducer,
  couponReducer,
  searchBoxReducer,
  commonSearchReducer,
  myAppointmentReducer,
  helpMeDecideReducer,
  myOrderReducer,
  sfExpressReducer,
  staticBundleDetailReducer,
  dynamicBundleReducer,
  couponWalletReducer,
  productWarrantyReducer,
  productReviewReducer,
  spinAndWinReducer,
  clientTellingPortalCustomer,
  clientTellingPortalQuickAccess,
  submitPurchaseOnSpotReducer,

  myAddressReducer: persistReducer(
    {
      key: 'myAddressReducer',
      storage: storage,
      blacklist: ['addressDatasourcesReducer']
    },
    myAddressReducer
  ),
  bundleBOReducer,
  settingGlobalReducer: persistReducer(
    {
      key: 'settingGlobalReducer',
      storage: sessionStorage
    },
    settingGlobalReducer
  ),
  settingGlobalURLReducer,
  setProductWishlistCurrentReducer: persistReducer(
    {
      key: 'setProductWishlistCurrentReducer',
      storage: sessionStorage
    },
    setProductWishlistCurrentReducer
  ),
  cpAppointmentListingReducer,
  cpOverviewReducer: persistReducer(
    {
      key: 'cpOverviewReducer',
      storage: storage,
      whitelist: ['data']
    },
    cpOverviewReducer
  ),
  getSOCartsReducer,
  cpStoreSelectorReducer,
  cPStartDemoReducer,
  cpAppointmentDetailReducer,
  cpUpdateAppointmentReducer,
  cpLeadManagementReducer,
  cpProductInterestDataReducer,
  getCatalogHeaderNavigationReducer,
  categoryForRegisterWarrantyReducer,
  productForRegisterWarrantyReducer,
  productCountdownTimerReducer,
  compareProductReducer,
  getMyReviewsReducer,
  subscribeNewsletterReducer,
  priorityDeliveryReducer,
  getFreeGiftReducer,
  addFreeGiftToCartReducer,
  updateFreeGiftToCartReducer,
  controlSelectingFreeGiftPopupReducer,
  eventBookingReducer,
  loyaltyReducer,
  campaignRegisterReducer,
  cpNotificationPopupReducer,
  bookingExperienceReducer,
  addPriorityToCartReducer,
  layoutElementStyleReducer,
  addWarrantyReducer,
  cpSendSaleOrderReducer,
  cpCartDetailsReducer,
  cpSaleOrderStatusesReducer,
  personalizeOfferReducer,
  getProductCatalogueReducer,
  taxMessageDetailReducer,
  cpCheckContactDuplicateReducer,
  mediaTrackingGoalReducer
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
