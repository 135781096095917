import { func } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import useCookie from '@utils/customsHook/useCookie';

const SEND_WELCOME_EMAIL_OR_SMS_ACTION_KEY = 'SendWelcomeEmailOrSmsAction';

const FormSubmitErrorPopup = ({ onClose }) => {
  const cookie = useCookie();
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const welcomePopupTitleText = useMemo(() => messageObj?.['LBL-WelcomePopupTitle'] || '', [messageObj]);
  const closePopupButtonText = useMemo(() => messageObj?.['LBL-WelcomePopupButton'] || '', [messageObj]);

  const errorMessage = useMemo(() => {
    return cookie.get(SEND_WELCOME_EMAIL_OR_SMS_ACTION_KEY);
  }, [cookie]);

  useEffect(() => {
    if (errorMessage && welcomePopupTitleText && closePopupButtonText) {
      setIsOpenPopup(true);
    }
  }, [errorMessage, welcomePopupTitleText, closePopupButtonText]);

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);

    /** INFO: remove cookie value after close the modal, ensure the popup show only one */
    cookie.remove(SEND_WELCOME_EMAIL_OR_SMS_ACTION_KEY);

    if (onClose) onClose();
  }, [onClose]);

  return (
    <ModalComponent
      isShow={isOpenPopup}
      onCloseHandler={handleClosePopup}
      titlePopup={welcomePopupTitleText}
    >
      <div className='login-register-modal form-submit-error-popup'>
        <p className='login-register-modal__text'>{errorMessage}</p>
        <button className='btn btn-primary' onClick={handleClosePopup}>{closePopupButtonText}</button>
      </div>
    </ModalComponent>
  );
};

FormSubmitErrorPopup.propTypes = {
  onClose: func
};

export default FormSubmitErrorPopup;