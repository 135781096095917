import './ForgotPassword.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { forgotPassword } from '@redux/actions/singleSignOn';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import ForgotPasswordModel from './models/ForgotPasswordModel';

const ForgotPassword = (props) => {
  const { fields, errMessage, isMessage, isForgotPassword, isLoading } = props;
  const [inputData, setInputData] = useState(null);
  const dispatch = useDispatch();
 

  useEffect(() => {
    setInputData(new ForgotPasswordModel().getData(fields || {}));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(<Message messageCode='Msg_001.3'/>).required(<Message messageCode='Msg_001.3'/>)
    }),
    onSubmit: values => {
      dispatch(forgotPassword({
        Email: values.email
      }));
    },
    validateOnMount: true
  });

  return (
    inputData && (
      <div className='forgot-password osim-account'>
        {/* <div className='forgot-password__heading osim-account__heading'>
          <div className='container'>
            <h2 className=' osim-account__heading__title page-title'>Forgot Password</h2>
          </div>
        </div> */}
        <div className='container tab-content no-padding-mobile'>
          <form onSubmit={formik.handleSubmit} className='forgot-password__form'>
            {inputData['Instruction'].value ? (
              <p className='osim-account-form__title'>
                <Text field={inputData['Instruction']} />
              </p>) : <></>
            }
            {isMessage ? (
              isForgotPassword ? (
                <span className='message-successful text-center'>
                  <Message messageCode='Msg_004'/>
                </span>
              ) : <span className='error-message'>{errMessage}</span>
            ) : ''}
            <div className={Global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group', 'input-error-validate')}>
              <input
                onChange={formik.handleChange}
                id='email'
                value={formik.values.email}
                type='text'
                name='email'
                className={Global.renderDynamicClass(formik.values['email'], 'form-control form-control-lg', 'input-valid')}
                placeholder={inputData['Email Label'].value}
              />
              <label htmlFor='email' className='form-group__label'>
                <Text field={inputData['Email Label']} />
              </label>
              {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
            </div>
            <div className='osim-account-form__form__btn'>
              <button className=' osim-account-form__form__btn__item btn btn-primary'>
                <Text field={inputData['Reset Password Button Label']} />
              </button>
            </div>
            <p className='forgot-password__form__desc'>
              <Text field={inputData['Second Instruction']} />
            </p>
          </form>
        </div>
        {isLoading ? <LoadingMask parent={ForgotPassword}/> : ''}
      </div>
    )
  );
};

ForgotPassword.propTypes = {
  fields: PropTypes.object,
  isForgotPassword: PropTypes.bool,
  isMessage: PropTypes.bool,
  isLoading: PropTypes.bool,
  errMessage: PropTypes.string
};

const mapStateToProps = (state) => ({
  isForgotPassword: state.singleSignOnReducer.renewPasswordReducer.forgotPasswordReducer.isForgotPassword,
  isMessage: state.singleSignOnReducer.renewPasswordReducer.forgotPasswordReducer.isMessage,
  errMessage: state.singleSignOnReducer.renewPasswordReducer.forgotPasswordReducer.errMessage,
  isLoading: state.singleSignOnReducer.renewPasswordReducer.forgotPasswordReducer.isLoading,
});

export default connect(mapStateToProps)(ForgotPassword);
