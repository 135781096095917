import { bool, object } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import PasswordInput from '../../../SingleSignOn/PasswordInput';
import { useCampaignContext } from '../../useCampaignContext';
import useSelectStoreAndDateContext from '../useSelectStoreAndDateContext';

const CreateAccountCheckbox = ({ checkboxText, isLoginSuccess, isEmailExist }) => {
  const { formik, isDisableFormSection } = useSelectStoreAndDateContext();
  const { layoutData } = useCampaignContext();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isRegisterSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew);

  useEffect(() => {
    if (isRegisterSuccess || isLoginSuccess) {
      formik.setFieldValue('allowCreateAccount', false);
    }
  }, [isLoginSuccess, isRegisterSuccess]);

  const handleSelectGender = (value) => {
    formik.setFieldValue('gender', value);
  };

  const handleToggleCreateAccountCheckbox = useCallback(
    (event) => {
      const checked = event.target.checked;
      if (!checked) {
        formik.setFieldValue('email','');

        formik.setFieldValue('password','');

        formik.setFieldValue('dob','');
      }

      formik.setFieldValue('allowCreateAccount', event.target.checked);
    },
    [formik]
  );

  return !isLoginSuccess && !isEmailExist ? (
    <>
      <div className='form-group'>
        <div className='osim-checkbox'>
          <input
            type='checkbox'
            id='createAccount'
            name='createAccount'
            className='osim-checkbox-input'
            readOnly
            onChange={handleToggleCreateAccountCheckbox}
            disabled={isDisableFormSection}
          />
          <Text
            field={checkboxText}
            tag='label'
            htmlFor='createAccount'
            className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
          />
        </div>
      </div>
      {formik.values.allowCreateAccount ? (
        <>
          <div className={global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group', 'input-error-validate')}>
            <input
              onChange={formik.handleChange}
              value={formik.values.email}
              type='text'
              id='email'
              className={global.renderDynamicClass(formik.values.email, 'form-control form-control-lg', 'input-valid')}
              name='email'
              placeholder={layoutData.emailPlaceholderText.value}
            />
            <label htmlFor='email' className='form-group__label'>
              <Text field={layoutData.emailPlaceholderText} />
            </label>
            {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
          </div>

          <div className={global.renderDynamicClass(formik.errors.password && formik.touched.password, 'form-group', 'input-error-validate')}>
            <PasswordInput
              onChange={formik.handleChange}
              value={formik.values.password}
              type='password'
              className={global.renderDynamicClass(formik.values['passwordLogin'], 'form-control form-control-lg', 'input-valid')}
              id='password'
              placeholder={layoutData.passwordPlaceholderText.value}
              autoComplete='off'
              errorMessageEl={formik.errors.password && formik.touched.password && <span className='error-validate'>{formik.errors.password}</span>}
            />
            <label htmlFor='password' className='form-group__label'>
              <Text field={layoutData.passwordPlaceholderText} />
            </label>
          </div>

          <div className={global.renderDynamicClass(formik.errors.dob && formik.touched.dob, 'form-group', 'input-error-validate')}>
            <DateOfBirth
              setFieldValue={formik.setFieldValue}
              dobLabel={layoutData.dateOfBirthLabel}
              isRegister={true}
              defaultValue={formik.values.dob}
            />
            {formik.errors.dob && formik.touched.dob && <span className='error-validate'>{formik.errors.dob}</span>}
          </div>

          {settingGlobal?.GenderDatasource.length ? (
            <div
              className={global.renderDynamicClass(
                formik.errors.gender && formik.touched.gender,
                'form-group form-group--gender',
                'form-group--gender input-error-validate'
              )}
            >
              <div>
                {settingGlobal?.GenderDatasource.map((item) => {
                  return (
                    <div className='osim-radio' key={item.Key}>
                      <input
                        type='radio'
                        name='gender'
                        value={item.Key}
                        className='osim-radio-input'
                        checked={formik.values.gender === item.Key}
                        readOnly
                      />
                      <label className='osim-radio-label' onClick={() => handleSelectGender(item.Key)} />
                      <label className='osim-radio-title' onClick={() => handleSelectGender(item.Key)}>
                        {item.Value}
                      </label>
                    </div>
                  );
                })}
              </div>
              {formik.errors.gender && formik.touched.gender && <span className='error-validate'>{formik.errors.gender}</span>}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

CreateAccountCheckbox.propTypes = {
  checkboxText: object,
  isLoginSuccess: bool,
  isEmailExist: bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  isEmailExist: state.checkoutProcesstReducer.accountCheckingReducer?.isCheckEmailExistBp
});

export default connect(mapStateToProps)(CreateAccountCheckbox);
