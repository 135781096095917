import { any,bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {WarningPopup} from '@components/ClientPortal/GenericComponents/CPHeader/DemoSession';
import * as config from '@components/ClientPortal/GenericComponents/CPHeader/DemoSession/config';
import ModalComponent from '@components/Share/ModalComponent';
import { cPStartDemo } from '@redux/actions/cPStartDemo';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import useCheckDisableStartDemoBtn from '@utils/customsHook/useCheckDisableStartDemoBtn';

const QAWarningPopup = ({ warningPopup, setWarningPopup, dataModel }) => {
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  //INFO: get cpCustomerId to know is runing startDemo
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const dispatch = useDispatch();
  const [isWarningStartDemo, setIsWarningStartDemo] = useState(false);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const isDisabledStartDemoBtn = useCheckDisableStartDemoBtn();

  const handleStartDemo = () => {
    if (currentStore && currentStore.storeId !== config.ALL_STORES_ID) {
      dispatch(cPStartDemo({
        ContactId: new URL(window.location.href).searchParams.get(config.CONTACT_ID) || cpCustomerId || '',
        currentSessionContactId: clientTellingPortalCustomer?.contacts?.[0]?.contactId,
        sessionId: SitecoreContextFactoryService.getValueContextItem('cpSessionId'),
      })
      );
    } else {
      setWarningPopup(false);

      setIsWarningStartDemo(true);
    }
  };

  return dataModel && (
    <>
      <ModalComponent
        cssClass='warning-popup'
        isShow={warningPopup}
        onCloseHandler={() => {
          setWarningPopup(false);
        }}
      >
        <div className='warning-popup__content'>
          <Text tag='h4' className='warning-popup__content__title' field={dataModel['Session Not Started Warning Title']} />
          <Text tag='p' className='warning-popup__content__desc' field={dataModel['Session Not Started Warning Description']} />
          <div className='warning-popup__content__btn'>
            <span
              className={[
                'warning-popup__content__btn__item btn btn-primary',
                isDisabledStartDemoBtn ? 'disabled' : ''
              ].join(' ')}
              onClick={() => handleStartDemo()}
            >
              <Text field={dataModel['Start Session Button Text']} />
            </span>
            <span className='warning-popup__content__btn__item os-btn-link' onClick={() => setWarningPopup(false)}>
              <Text field={dataModel['Back Button']}/>
            </span>
          </div>
        </div>
      </ModalComponent>
      {
        isWarningStartDemo ? (
          <WarningPopup 
            isShow={isWarningStartDemo}
            onCloseModalHandler={() => setIsWarningStartDemo(false)}
            popupTitle={dataModel['Session Not Started Warning Title']}
            popupContents={dataModel['Store Selection Warning Description']}
            buttonText={dataModel['Back Button']}
          />
        ) : ''
      }
    </>
  );
};

QAWarningPopup.propTypes = {
  warningPopup: bool,
  setWarningPopup: func,
  dataModel: any
};

export default QAWarningPopup;
