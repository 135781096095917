import './LeadManagement.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CPPaging from '@components/ClientPortal/CPPaging';
import { LEAD_MANAGEMENT_PAGESZ } from '@components/ClientPortal/LeadManagement/configs';
import LoadingMask from '@components/Share/LoadingMask';
import { managerLeadManagement, staffLeadManagement } from '@redux/actions/cpLeadManagement';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import LeadInfo from './LeadInfo';
import LeadManagementNoResult from './LeadManagementNoResult';
import LeadSearch from './LeadSearch';
import LeadManagementModel from './Models/LeadManagementModel';

const LeadManagement = ({ fields }) => {
  const MINRANGE = Number(fields?.['Min Total Spent']?.value || 0);
  const MAXRANGE = Number(fields?.['Max Total Spent']?.value || 0);
  const PAGE_DEFAULT = 1;
  const API_DEFAULT = 'default';
  const API_SEARCH = 'search';
  const API_SEARCH_ALL = 'search-all';
  const API_FILTER = 'filter';
  const SHOW_ALL_DATA_HISTORY = true; //INFO: this variable use to show or
  // not show all data
  const dispatch = useDispatch();
  const cpLeadManagementReducer = useSelector((state) => state.cpLeadManagementReducer);
  const isCPManager = SitecoreContextFactoryService.getValueContextItem('isCPManager');
  const [leadManagementModelFields, setLeadManagementModelFields] = useState(null);
  const [isResetSearch, setIsResetSearch] = useState(false);
  const [filterParam, setFilterParam] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [isActionFilter, setIsActionFilter] = useState(false);
  const [isActionSearch, setIsActionSearch] = useState(false);
  const [isResetPaging, setIsResetPaging] = useState(false);

  useEffect(() => {
    const leadManagementModel = new LeadManagementModel();

    setLeadManagementModelFields(leadManagementModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    if (SHOW_ALL_DATA_HISTORY) {
      apiLeadManagement(API_SEARCH_ALL);
    } else {
      apiLeadManagement();
    }
  }, []);

  const apiLeadManagement = (type = API_DEFAULT, filterParams = null, searchData = '', pageNum = PAGE_DEFAULT) => {
    switch (type) {
    case API_DEFAULT:
      if (isCPManager) {
        dispatch(
          managerLeadManagement({
            PageNumber: pageNum,
            Date: customDateAPI(new Date()),
            MinTotalSpent: MINRANGE,
            MaxTotalSpent: MAXRANGE
          })
        );
      } else {
        dispatch(
          staffLeadManagement({
            PageNumber: pageNum,
            Date: customDateAPI(new Date()),
            MinTotalSpent: MINRANGE,
            MaxTotalSpent: MAXRANGE
          })
        );
      }
      break;

    case API_SEARCH:
      if (isCPManager) {
        dispatch(
          managerLeadManagement({
            PageNumber: pageNum,
            Term: searchData
          })
        );
      } else {
        dispatch(
          staffLeadManagement({
            PageNumber: pageNum,
            Term: searchData
          })
        );
      }
      break;

    case API_SEARCH_ALL:
      if (isCPManager) {
        dispatch(
          managerLeadManagement({
            PageNumber: pageNum
          })
        );
      } else {
        dispatch(
          staffLeadManagement({
            PageNumber: pageNum
          })
        );
      }
      break;

    case API_FILTER:
      if (filterParams) {
        if (isCPManager) {
          dispatch(
            managerLeadManagement({
              PageNumber: pageNum,
              ...filterParams
            })
          );
        } else {
          dispatch(
            staffLeadManagement({
              PageNumber: pageNum,
              ...filterParams
            })
          );
        }
      }

      if (searchData) {
        if (isCPManager) {
          dispatch(
            managerLeadManagement({
              PageNumber: pageNum,
              Term: searchData
            })
          );
        } else {
          dispatch(
            staffLeadManagement({
              PageNumber: pageNum,
              Term: searchData
            })
          );
        }
      }
      break;
    default:
    }
  };

  const checkNumber = (number) => (number < 10 ? '0' + number : number);

  const customDateAPI = (apiDate) => {
    if (apiDate) {
      const datePicker = new Date(apiDate);
      const year = datePicker.getFullYear();
      const month = checkNumber(datePicker.getMonth() + 1);
      const day = checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  };

  const onFilterSubmit = (params) => {
    setFilterParam(params);

    setIsActionSearch(false);

    setIsActionFilter(true);

    apiLeadManagement(API_FILTER, params);

    setIsResetSearch(true);

    setIsResetPaging(!isResetPaging);
  };

  const onSearchLeadManagement = (dataSearch) => {
    setIsActionSearch(true);

    setIsActionFilter(false);

    setSearchValue(dataSearch);

    if (dataSearch) {
      apiLeadManagement(API_SEARCH, null, dataSearch);
    } else {
      apiLeadManagement(API_SEARCH_ALL);
    }

    setIsResetPaging(!isResetPaging);
  };

  const onChangeSearch = () => setIsResetSearch(false);

  const onChangePageHandler = (pageNum) => {
    if (!isActionFilter && !isActionSearch) {
      if (SHOW_ALL_DATA_HISTORY) {
        apiLeadManagement(API_SEARCH_ALL, null, '', pageNum);
      } else {
        apiLeadManagement(API_DEFAULT, null, '', pageNum);
      }
    } else if (isActionFilter && !isActionSearch) {
      apiLeadManagement(API_FILTER, filterParam, '', pageNum);
    } else if (!isActionFilter && isActionSearch) {
      if (searchValue) {
        apiLeadManagement(API_FILTER, null, searchValue, pageNum);
      } else {
        apiLeadManagement(API_SEARCH_ALL, null, '', pageNum);
      }
    }
  };

  return (
    leadManagementModelFields && (
      <>
        <CPPageHeader
          title={leadManagementModelFields['Title Text']}
          type='filter'
          filterFields={leadManagementModelFields}
          onFilterSubmit={onFilterSubmit}
        />
        <LeadSearch
          leadManagementModelFields={leadManagementModelFields}
          onSearchLeadManagement={onSearchLeadManagement}
          isResetSearch={isResetSearch}
          onChangeSearch={onChangeSearch}
        />
        <div className='lead-management'>
          <div className='container tablet-container'>
            {cpLeadManagementReducer?.leadManagements?.Items?.length ? (
              <>
                <div className='lead-management__list'>
                  {cpLeadManagementReducer.leadManagements.Items.map((item, index) => (
                    <LeadInfo key={item.CustomerId || index} leadManagementModelFields={leadManagementModelFields} isCPManager={isCPManager} leadManagementData={item} />
                  ))}
                </div>
                <CPPaging
                  isReset={isResetPaging}
                  itemPerPage={LEAD_MANAGEMENT_PAGESZ}
                  totalItem={cpLeadManagementReducer?.leadManagements?.TotalRecords}
                  onChangePageHandler={onChangePageHandler}
                />
              </>
            ) : (
              <>
                <LeadManagementNoResult layoutData={leadManagementModelFields} />
              </>
            )}
          </div>
        </div>
        {cpLeadManagementReducer.isLoading ? <LoadingMask parent={LeadManagement} /> : ''}
      </>
    )
  );
};

LeadManagement.propTypes = {
  fields: PropTypes.any
};

export default LeadManagement;
