import './AddToSalesOrderPopup.scss';

import { any, bool, func } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { cPStartDemo } from '@redux/actions/cPStartDemo';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import useCheckDisableStartDemoBtn from '@utils/customsHook/useCheckDisableStartDemoBtn';

const AddToSalesOrderPopup = (props) => {
  const { isShow, onCloseHandler, ondataSources} = props;
  const dispatch = useDispatch();
  const isDisabledStartDemoBtn = useCheckDisableStartDemoBtn();

  const handleStartSession = () => {
    dispatch(cPStartDemo({
      ContactID: '',
      currentSessionContactId: '',
      sessionId: ''
    }));
  };

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup=''>
      <div className='add-to-sales-order-popup'>
        <Text field={ondataSources?.['Start Session Prompt Text']} className='add-to-sales-order-popup__message' tag='p' />
        <div className='add-to-sales-order-popup__btn'>
          <button
            className={['btn btn-primary', isDisabledStartDemoBtn ? 'disabled' : ''].join(' ')}
            onClick={handleStartSession}
            disabled={isDisabledStartDemoBtn}
          >
            <Text field={ondataSources?.['Start Session Text']} className='add-to-sales-order-popup__message' />
          </button>
        </div>
        <Text field={ondataSources?.['Back Text']} tag='span' className='btn-link add-to-sales-order-popup__back' onClick={onCloseHandler} />
      </div>
    </ModalComponent>
  );
};

AddToSalesOrderPopup.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  ondataSources: any,
  onCpCustomerId: any
};

export default AddToSalesOrderPopup;
