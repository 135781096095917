import './CartSummary.scss';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { behaviorSubjectCheckout } from '@components/SlicingPage/CartDetails/cartDetailSubject';
import { blockInstallment, getCartDelivery, setCartFullfillment, validateInstallmentAndCheckout } from '@redux/actions/cartDetail';
import { getErrorMsg } from '@redux/actions/coupon';
import { clearAddressDatasources } from '@redux/actions/myAddress';
import { saveSOCart } from '@redux/actions/product';
import { getEstimateShippingFee } from '@redux/actions/storeLocator';
import { getCartNameValue } from '@redux/epic/product/cartDetail/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { DELIVERY_OPTION, TW_SITE_NAME } from '@utils/constant';
import { HK_SITE_NAME } from '@utils/constant';
import { REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDepositValidationCart } from '@utils/customsHook/useDepositValidationCart';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility.js';

import { SEARCH_PARAMS_CART_NAME_KEY } from '../../../ClientPortal/config';
import useCheckDisablePurchaseButton from '../../../PersonalizedOffer/hooks/useCheckDisablePurchaseButton';
import OmnichatTracking from '../../../Share/OmnichatTracking';
import TaxMessageEl from '../../../TaxMessageDetail/TaxMessageEl';
import CartDetailModal from '../CartDetailModal/index';
import CartSummaryModel from './model/CartSummaryModel';
import OfferAppliedModal from './OfferAppliedModal';
import SelectArealModalContentData from './SelectArealModalContentData';
import DeleteSaleOrderButton from './SelectArealModalContentData/DeleteSaleOrderButton';

const EXPIRE_DATE_AFTER_DAY = 14;

const CartsTotalAmount = ({
  fields,
  cartData,
  shippingFee,
  appliedPromotion,
  isLogin,
  cartSellerStatus,
  isLoading,
  defaultShippingAddressStatus,
  showInstallmentBlocker,
  isBlockInstallment,
  isClientSOCart,
  clientSOCartStatus
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [layoutData, setLayoutData] = useState(null);
  const [showSelectArea, setShowSelectArea] = useState(false);
  const [showPromotion, setShowPromotion] = useState(false);
  const [isShowSelectWarning, setIsShowSelectCheckOut] = useState(false);
  const [ShowEstimatedDeliveryFee, setShowEstimatedDeliveryFee] = useState(false);
  const [isOutOfStock, setOutOfStock] = useState(false);
  const [altAppliedCouponMsg, setAltAppliedCouponMsg] = useState(null);
  const [popUpDetailCouponList, setPopUpDetailCouponList] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const [blockInstallmentProductNames, setBlockInstallmentProductNames] = useState([]);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');
  const isGA4 = Global.getIsGA4();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const COUNTRY_REPLACE = '[Country]';
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);

  const [isShow, setShow] = useState(false);
  const currentCartCustom = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom);
  const CartStatus = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom?.CartStatus);
  const isSavingSO = useSelector((state) => state?.productReducer?.cartDetailReducer?.isLoading);
  const isSaveSO = useSelector((state) => state?.productReducer?.cartDetailReducer?.isSaveSO);
  const [isPopup, setIsPopup] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [soCartStartDate, setSoCartStartDate] = useState(new Date());
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);
  const checkDisablePurchaseSOCart = useCheckDisablePurchaseButton();
  const isValidateDepositCart = useDepositValidationCart();

  useEffect(() => {
    const isDepositFreeGift = currentCartCustom?.NormalListProduct?.every((item) => item.IsDepositFreeGift);

    if (currentCartCustom?.NormalListProduct?.length > 0 && currentCartCustom?.DepositListProduct?.length > 0 && isValidateDepositCart) {
      if (isDepositFreeGift == true) {
        setIsPopup(false);
      } else {
        setIsPopup(true);
      }
    } else if (currentCartCustom?.DepositListProduct?.length > 1) {
      setIsPopup(true);
    } else {
      setIsPopup(false);
    }
  }, [currentCartCustom, isValidateDepositCart]);

  useEffect(() => {
    if (cartData?.Lines && cartData?.Lines?.length > 0) {
      setIsDeposit(checkAllLinesIsPosit(cartData.Lines));
    }
  }, [cartData]);

  const checkAllLinesIsPosit = (data) => {
    return data.every((item) => item.Deposit);
  };


  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    const behaviorSubjectCheckoutSubscription = behaviorSubjectCheckout.subscribe((params) => {
      setIsShowSelectCheckOut(params?.isForceSelectArea ?? false);
    });

    return () => {
      behaviorSubjectCheckoutSubscription.unsubscribe();
    };
  }, [behaviorSubjectCheckout]);

  useEffect(() => {
    let hasInstallmentCartLevel,
      hasInstallmentProductLevel,
      hasInstallmentProductAndCartLevel = false;

    if (cartData?.PaymentConfiguration?.Gateways?.length) {
      let installmentCartLevel = cartData?.PaymentConfiguration?.Gateways.filter(
        (gateway) =>
          cartData?.Lines?.length &&
          gateway?.SupportInstallment &&
          gateway?.InstallmentCartLevel &&
          !gateway?.InstallmentProductLevel &&
          gateway?.InstallmentPeriods?.length &&
          ((!gateway?.InstallmentMaxCartAmount && cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount) ||
            (gateway?.InstallmentMaxCartAmount &&
              cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount &&
              cartData?.TotalAmount < gateway?.InstallmentMaxCartAmount))
      );

      hasInstallmentCartLevel = installmentCartLevel && installmentCartLevel.length > 0;

      setInstallmentCartLevel(installmentCartLevel);
      if (cartData?.Lines?.some((line) => line?.InstallmentPeriods?.[0]?.length)) {
        let installmentProductLevel = cartData?.PaymentConfiguration?.Gateways.filter(
          (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel
        );

        let installmentProductAndCartLevel = cartData?.PaymentConfiguration?.Gateways.filter(
          (gateway) =>
            gateway?.SupportInstallment &&
            gateway?.InstallmentProductLevel &&
            gateway?.InstallmentCartLevel &&
            ((!gateway?.InstallmentMaxCartAmount && cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount) ||
              (gateway?.InstallmentMaxCartAmount &&
                cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount &&
                cartData?.TotalAmount < gateway?.InstallmentMaxCartAmount))
        );

        setInstallmentProductLevel(installmentProductLevel);

        setInstallmentCartLevelAndProductLevel(installmentProductAndCartLevel);

        hasInstallmentProductLevel = installmentProductLevel && installmentProductLevel.length > 0;

        hasInstallmentProductAndCartLevel = installmentProductAndCartLevel && installmentProductAndCartLevel.length > 0;
      } else {
        setInstallmentProductLevel(null);

        setInstallmentCartLevelAndProductLevel(null);
      }
      const hasBlockInstallmentItem = cartData.Lines.some((l) => l.BlockInstallment);

      const hasInstallment =
        cartData?.Lines?.filter((line) => line?.InstallmentPeriods?.[0]?.length && !line.BlockInstallment).length > 0 &&
        (hasInstallmentProductLevel || hasInstallmentProductAndCartLevel || hasInstallmentCartLevel);

      if (hasInstallment && hasBlockInstallmentItem) {
        setBlockInstallmentProductNames(cartData.Lines.filter((l) => l.BlockInstallment).map((m) => m.DisplayName));

        dispatch(blockInstallment({ block: true }));
      } else {

        dispatch(blockInstallment({ block: false }));
      }
    }
  }, [cartData]);

  const handleOnClickCheckOut = () => {
    if (!cartData.Party) {
      setIsShowSelectCheckOut(true);

      let element = document.getElementById('area-error');
      const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

      window.scrollTo({ top: y, behavior: 'smooth' });
    } else setIsShowSelectCheckOut(false);
    if (!cartSellerStatus) {
      let element = document.getElementById('cart-seller');

      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const onHandleTrackingCode = () => {
    if (currentCart) {
      //Old Logic GA
      // INF: Google analytics
      // if (window['google_tag_manager']) {
      //   let products = [];

      //   window.dataLayer = window.dataLayer || [];

      //   if (currentCart.Lines.length) {
      //     currentCart.Lines.forEach((line) => {
      //       products.push({
      //         name: line?.DisplayName,
      //         id: line?.ProductId,
      //         price: line?.LineFinalTotalAmount.toString(),
      //         category: line?.CategoryName,
      //         quantity: parseInt(line?.Quantity)
      //       });
      //     });
      //   }

      //   let obj = {
      //     ecommerce: {
      //       checkout: {
      //         actionField: { step: 1 },
      //         products: products
      //       }
      //     },
      //     event: 'checkout',
      //     eventCallback: function () {
      //       window.location = !isLogin ? layoutData['Checkout Link'].Link.value.href : layoutData['Fulfillment Link'].Link.value.href;
      //     },
      //     eventTimeout: 2000
      //   };

      //   window.dataLayer.push(obj);
      //   // new GoogleAnalytic().ggGateWay('event', 'checkout', obj);
      // }

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const cartLines = currentCart?.Lines.map((line) => ({
          ...line,
          itemListName:
            Global.getGA4Params()?.[line.VariantId] || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value
        }));

        const params = {
          ecommerce: {
            currency: currentCart?.Currency || '',
            value: currentCart?.TotalAmount,
            items: Global.getItems(cartLines || [], currentCart?.PromoCodes?.toString() || '', '', currentCart?.Currency || '')
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'begin_checkout', params);
      } else {
        if (window['google_tag_manager']) {
          let products = [];

          window.dataLayer = window.dataLayer || [];

          if (currentCart.Lines.length) {
            currentCart.Lines.forEach((line) => {
              products.push({
                name: line?.DisplayName,
                id: line?.ProductId,
                price: line?.LineFinalTotalAmount.toString(),
                category: line?.CategoryName,
                quantity: parseInt(line?.Quantity)
              });
            });
          }

          let obj = {
            ecommerce: {
              checkout: {
                actionField: { step: 1 },
                products: products
              }
            },
            event: 'checkout',
            eventCallback: function () {
              window.location = !isLogin ? layoutData['Checkout Link'].Link.value.href : layoutData['Fulfillment Link'].Link.value.href;
            },
            eventTimeout: 2000
          };

          window.dataLayer.push(obj);
        }
      }
    }
  };

  const handleCheckoutTracking = () => {
    // INFO: Omnichat Tracing for HK or TW site
    if ((currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat) && currentCart) {
      let products = [];

      if (currentCart.Lines.length > 0) {
        currentCart.Lines.forEach((line) => {
          products.push({
            id: line?.ProductId || '',
            name: line?.DisplayName || '',
            brand: 'Osim',
            category: line?.CategoryName || '',
            variant: line.VariantId || '',
            quantity: parseInt(line?.Quantity) || '',
            price: line?.LineFinalTotalAmount.toString()
          });
        });
      }

      new OmnichatTracking().tracking('event', 'checkout', { items: products });
    }
  };

  const renderBlockInstallmentText = () => {
    const message = messageObj['Msg_033_1'];

    return message.replace('{product_name}', blockInstallmentProductNames.join(','));
  };

  const setShowInstallmentBlocker = (show) => {
    dispatch(validateInstallmentAndCheckout(show));
  };

  const onHandleCheckoutTracking = () => {
    // INFO: Google analytics
    onHandleTrackingCode();

    // INFO: Omnichat Tracing for HK site
    handleCheckoutTracking();
  };

  const handleCheckoutButton = useCallback(
    (checkoutLink, fulfilmentLink) => {
      // INFO: func tracking
      onHandleCheckoutTracking();
      //INFO: redirect url
      if (!isLogin) {
        if (settingGlobal?.IsDisableGuestCheckout) {
          query.set(REDIRECT_CHECKOUT_PARAM, checkoutLink.value.href);

          return (window.location.href = `${settingGlobal?.LoginLink}?${query.toString()}`);
        } else {
          return (window.location.href = `${checkoutLink?.value?.href}?${query.toString()}`);
        }
      } else {
        return (window.location.href = `${fulfilmentLink?.value?.href}?${query.toString()}`);
      }
    },
    [isLogin, settingGlobal?.IsDisableGuestCheckout, settingGlobal?.LoginLink, currentCart, query]
  );

  const renderCheckOUtButton = () => {
    const generateLink = (link) => {
      return {
        ...link,
        value: {
          ...link?.value,
          href: `${link?.value?.href}?${query.toString()}`
        }
      };
    };

    return isPopup ? (
      <>
        <button className='btn btn-primary' onClick={() => onShowHandler()}>
          <Text field={!isLogin ? layoutData['Checkout Link'].Title : layoutData['Fulfillment Link'].Title} />
        </button>
        <CartDetailModal isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={layoutData} />
      </>
    ) : layoutData['Checkout Link'].Title.value || layoutData['Fulfillment Link'].Title.value ? (
      !cartSellerStatus ? (
        <button className='btn btn-primary' onClick={() => handleOnClickCheckOut()}>
          <Text field={!isLogin ? layoutData['Checkout Link'].Title : layoutData['Fulfillment Link'].Title} />
        </button>
      ) : isBlockInstallment ? (
        <button className='btn btn-primary' onClick={() => setShowInstallmentBlocker(true)}>
          <Text field={!isLogin ? layoutData['Checkout Link'].Title : layoutData['Fulfillment Link'].Title} />
        </button>
      ) : (
        <button
          className='btn btn-primary'
          onClick={() => handleCheckoutButton(layoutData['Checkout Link'].Link, layoutData['Fulfillment Link'].Link)}
        >
          <Text field={!isLogin ? layoutData['Checkout Link'].Title : layoutData['Fulfillment Link'].Title} />
        </button>
      )
    ) : !cartSellerStatus ? (
      <button className='btn btn-primary' onClick={() => handleOnClickCheckOut()} />
    ) : (
      <Link
        className='btn btn-primary'
        field={!isLogin ? generateLink(layoutData['Checkout Link'].Link) : generateLink(layoutData['Fulfillment Link'].Link)}
        onClick={() => onHandleCheckoutTracking()}
      />
    );
  };

  useEffect(() => {
    const CartSummary = new CartSummaryModel();
    if (fields) {
      setLayoutData(CartSummary.getData(deepCopy(fields)));
    }
  }, []);

  useDidUpdateEffect(() => {
    dispatch(getErrorMsg(layoutData['Coupon Require Text']?.value));
  }, [layoutData]);

  useDidUpdateEffect(() => {
    let temp = deepCopy(layoutData['Applied Coupons Message']);

    temp.value = `${temp.value.replace('{coupon-count}', appliedPromotion.length)}`;

    setAltAppliedCouponMsg(deepCopy(temp));
  }, [appliedPromotion.length]);

  useDidUpdateEffect(() => {
    if (cartData.Lines?.length) {
      let couter = 0;

      cartData.Lines.map((lineItem) => {
        const lineStatus = lineItem.LineStatus.find((item) => item.Code === 'OutOfStock');
        if (lineStatus) {
          couter = couter + 1;
        }
      });

      setOutOfStock(couter ? true : false);
    }
  }, [cartData]);

  useDidUpdateEffect(() => {
    if (layoutData['Enable Default Delivery'] && defaultShippingAddressStatus && cartData.Lines?.some((line) => !line?.Address?.ExternalId)) {
      let cartLineIdArr = [];

      cartData.Lines.forEach((line) => {
        if ((line?.IsDelivery || line?.IsDeliveryBySFExpress) && !line?.Address?.ExternalId) {
          cartLineIdArr.push(line?.ExternalCartLineId);
        }
      });
      if (cartLineIdArr.length > 0) {
        dispatch(
          getCartDelivery({
            cartLineIds: cartLineIdArr.length ? cartLineIdArr : '',
            deliveryOptionId:
              cartData?.Lines?.[0]?.DeliveryOptions.find((option) => option?.ShippingOptionType.Value == DELIVERY_OPTION.ShipItem)?.ExternalId || ''
          })
        );
      }
    }
  }, [defaultShippingAddressStatus, cartData]);

  const onEstimateShippingFeeHandler = (params) => {
    const cartId = query.get('cartId');

    if (cartId) {
      params = {
        ...params,
        cartId
      };
    }

    dispatch(getEstimateShippingFee(params));
  };

  const onConfirmSelection = useCallback((params) => {
    setShowSelectArea(false);
    const cartId = query.get('cartId') || currentCart?.Name || '';
    const setFulfillmentPayload = { ...params };
    if (cartId) setFulfillmentPayload.CartId = cartId;

    dispatch(
      setCartFullfillment({
        setFulfillmentPayload: {
          ...setFulfillmentPayload
        },
        reloadPromotions: true
      })
    );
  }, [currentCart, query]);

  const onCloseModalShippingFeeHandler = () => {
    setShowSelectArea(false);

    dispatch(clearAddressDatasources());
  };

  const onAppliedOffersModalComponentHandler = (status, data) => {
    setPopUpDetailCouponList(status);

    setShowPromotion(!status);

    setPopupDetail(data);
  };

  const handleSaveSODraft = () => {
    let cartStatusString = soCartStatuses[CartStatus] || 'Unknown';

    dispatch(
      saveSOCart({
        ExpiredDate: Global.formartDateIOS8601UTC(soCartStartDate) || '',
        Status: cartStatusString
      })
    );
  };

  const getCurrentDate = useMemo(() => new Date(), []);

  const generateExpireDateAfterDay = useCallback((dayRemain) => moment().add(dayRemain, 'd').toDate(), []);

  useEffect(() => {
    if (isSaveSO) {
      const cartName = getCartNameValue();
      if (cartName) query.set(SEARCH_PARAMS_CART_NAME_KEY, cartName);

      window.location.href = `${layoutData?.['Save Sales Order As Draft Link']?.value?.href}?${query.toString()}`;
    }
  }, [isSaveSO]);

  useEffect(() => {
    if (cartData && cartData?.ExpiredDate) {
      const soCartExpiredDate = Global.convertJSONDateToNumber(cartData?.ExpiredDate);

      if (soCartExpiredDate) {
        setSoCartStartDate(soCartExpiredDate);
      } else {
        const expireDate = generateExpireDateAfterDay(EXPIRE_DATE_AFTER_DAY);
        if (expireDate) setSoCartStartDate(expireDate);
      }
    } else {
      const expireDate = generateExpireDateAfterDay(EXPIRE_DATE_AFTER_DAY);
      if (expireDate) setSoCartStartDate(expireDate);
    }
  }, [cartData]);

  const isDisablePurchaseSOCart = useMemo(() => {
    let disable = false;
    if (clientSOCartStatus) disable = checkDisablePurchaseSOCart(clientSOCartStatus);

    return disable;
  }, [clientSOCartStatus, checkDisablePurchaseSOCart]);

  return layoutData && cartData ? (
    <>
      <div className='cart-summary' id='area-error'>
        <div className='cart-summary__total-amount'>
          <ul className='cart-summary__group'>
            <li className='group-contents'>
              <Text field={layoutData['Subtotal Label']} tag='label' />
              <div className='text-right'>{cartData.Subtotal}</div>
            </li>
            <li className='group-contents'>
              <div className='group-contents--has-discount'>
                <Text field={layoutData['Discount Label']} tag='label' />
                {appliedPromotion.length > 0 ? (
                  altAppliedCouponMsg ? (
                    <Text className='discount-applied' tag='span' field={altAppliedCouponMsg} onClick={() => setShowPromotion(true)} />
                  ) : (
                    <></>
                  )
                ) : null}
              </div>
              <div className='text-right'>-{cartData.Discount}</div>
            </li>
            {layoutData['Enable Default Delivery'] && cartData.IsDeliveryFee ? (
              <>
                <li className='group-contents'>
                  <Text field={layoutData['Estimated Delivery Fee Label']} />
                  <div className='text-right'>{cartData.DeliveryTotalAmount > 0 ? cartData.DeliveryTotal : <Message messageCode='LBL-Free' />}</div>
                </li>
                <li className='group-contents'>
                  <div className='contents-left contents-left--select-area'>
                    <p className='contents-left__description'>
                      <span className='contents-left__description__text'>
                        {layoutData['Shipping Text'].value.replace(
                          COUNTRY_REPLACE,
                          cartData.Party?.AreaFullAddress ? cartData.Party?.AreaFullAddress : ''
                        )}
                      </span>
                      <span className='select-area' onClick={() => setShowEstimatedDeliveryFee(true)}>
                        <Text field={layoutData['Learn More Shipping Label']} />
                      </span>
                    </p>
                  </div>
                </li>
              </>
            ) : null}
            {layoutData['Enable Default Delivery'] && cartData.IsDeliveryPickupFee ? (
              <li className='group-contents'>
                <Text field={layoutData['SF Express Pick Up Fee Label']} />
                <div className='text-right'>{cartData.SFExpressTotalAmount > 0 ? cartData.SFExpressTotal : <Message messageCode='LBL-Free' />}</div>
              </li>
            ) : null}
            {cartData.IsDeliveryFee && !layoutData['Enable Default Delivery'] ? (
              <>
                <li className='group-contents group-contents--has-sub'>
                  <Text field={layoutData['Delivery Fee Label']} tag='label' />
                  {cartData.IsFreeship ? (
                    <div className='text-right'>
                      <Message messageCode='LBL-Free' />
                    </div>
                  ) : (
                    <div className='text-right'>{cartData.DeliveryTotal}</div>
                  )}
                </li>
                {layoutData.showSelectArea ? (
                  <li className='group-contents'>
                    <div className='contents-left contents-left--select-area'>
                      {cartData.Party ? (
                        <>
                          <p dangerouslySetInnerHTML={{ __html: cartData.Party.AreaFullAddress }} className='contents-left__description'></p>
                          <span className='select-area' onClick={() => setShowSelectArea(true)}>
                            <Text field={layoutData['Change Area Label']} />
                          </span>
                        </>
                      ) : (
                        <>
                          <p className='contents-left__description'>
                            <span className='contents-left__description__text'>{layoutData['Select Area Text'].value}</span>
                            <span className='select-area' onClick={() => setShowSelectArea(true)}>
                              <Text field={layoutData['Select Area Label']} />
                            </span>
                          </p>
                          {isShowSelectWarning ? (
                            <p className='contents-left__warning-message'>
                              <Text field={layoutData['Please Select Area Label']} />
                            </p>
                          ) : null}
                        </>
                      )}
                    </div>
                  </li>
                ) : null}
                {isLoading ? <LoadingMask parent={CartsTotalAmount}></LoadingMask> : ''}
              </>
            ) : null}
            {cartData.IsDeliveryPickupFee && !layoutData['Enable Default Delivery'] ? (
              <li className='group-contents'>
                <Text field={layoutData['Pick Up Fee Label']} />
                <div className='text-right'>{cartData.SFExpressTotal}</div>
              </li>
            ) : (
              ''
            )}
            {layoutData['Show Sales Tax'] && !layoutData['Enable Default Delivery'] ? (
              <li className='group-contents'>
                <Text field={layoutData['Tax Label']} tag='label' />
                <div className='text-right'>{cartData.TaxTotal}</div>
              </li>
            ) : (
              ''
            )}
          </ul>
          <ul className='cart-summary__total-amount__last-list'>
            <li className='group-contents group-contents--has-sub cart-summary__total-amount__total-price'>
              <Text field={layoutData['Total Label']} tag='label' />
              <div className='text-right'>{cartData.Total}</div>
            </li>
            <TaxMessageEl />
            {currentCartCustom?.DepositListProduct?.length < 1 && (
              <>
                <li className='group-contents group-contents--align-baseline' style={{ justifyContent: isDeposit ? 'flex-end' : '' }}>
                  {!isDeposit && (
                    <div className='contents-left'>
                      {installmentCartLevel?.length ? (
                        <div className='installment-info'>
                          {installmentCartLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                      {installmentProductLevel?.length ? (
                        <div className='installment-info'>
                          {installmentProductLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  Global.onGetMinInstallmentPeriodsInCartAndReview(cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length))
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        Global.onGetMinInstallmentPeriodsInCartAndReview(
                                          cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length)
                                        )) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                      {installmentCartLevelAndProductLevel?.length ? (
                        <div className='installment-info'>
                          {installmentCartLevelAndProductLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  Global.onGetMinInstallmentPeriodsInCartAndReview(cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length))
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        Global.onGetMinInstallmentPeriodsInCartAndReview(
                                          cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length)
                                        )) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <div className='text-right text-line-through link-text link-text--gray-darker'>{cartData.OriginalTotal}</div>
                </li>
                <li className='group-contents'>
                  <Text field={layoutData['Total Saving Label']} tag='label' />
                  <div className='text-right'>{cartData.SavingTotal}</div>
                </li>
              </>
            )}
            {isClientTelingUser && isEditingSOCart ? (
              <li className='group-contents-cp'>
                <Text field={layoutData?.['Expiration Date Label']} tag='label' />
                <div className='group-contents-cp__wrapper'>
                  <div className='cp-page-header__time-select group-contents-cp__date'>
                    <DatePicker
                      selected={soCartStartDate}
                      onChange={(date) => setSoCartStartDate(date)}
                      format='dd MMM yyyy'
                      dayPlaceholder='DD'
                      monthPlaceholder='MM'
                      yearPlaceholder='YYYY'
                      value={soCartStartDate}
                      viewMode='months'
                      minViewMode='months'
                      minDate={getCurrentDate}
                    />
                  </div>
                  {/*<div className='group-contents-cp__btn'>*/}
                  {/*  <Link*/}
                  {/*    field={layoutData['Purchase On Spot Link']}*/}
                  {/*    className='btn btn-primary group-contents-cp__btn__text'*/}
                  {/*  >*/}
                  {/*    <Text field={layoutData['Purchase On Spot Text']} />*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                  <div className='group-contents-cp__btn'>
                    <button className='btn btn-outline-primary group-contents-cp__btn__text' onClick={handleSaveSODraft}>
                      <Text field={layoutData['Save Sales Order As Draft Text']} />
                    </button>
                  </div>
                  {!!cartData?.CartStatus || !!cartData?.SalesOrderId ? (
                    <div className='group-contents-cp__btn'>
                      <Link
                        field={{ href: `${layoutData['Send Order To Customer Link']?.value?.href}?${query.toString()}` }}
                        className='btn btn-outline-primary group-contents-cp__btn__text'
                      >
                        <Text field={layoutData['Send Order To Customer Text']} />
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                  <DeleteSaleOrderButton layoutData={layoutData} />
                  <div className='group-contents-cp__link'>
                    {layoutData['Additional Link'].Title.value ? (
                      <Link className='cart-summary__button__link text-center btn-link' field={layoutData['Additional Link'].Link}>
                        <Text field={layoutData['Additional Link'].Title} />
                      </Link>
                    ) : (
                      <Link className='cart-summary__button__link text-center btn-link' field={layoutData['Additional Link'].Link} />
                    )}
                  </div>
                </div>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
        {!isClientTelingUser ? (
          <div className='cart-summary__button'>
            <div className='container cart-summary__button__container'>
              <div className='cart-summary__button__inner flex-between'>
                {cartData?.Lines?.length && !isOutOfStock && !isDisablePurchaseSOCart ? (
                  renderCheckOUtButton()
                ) : (
                  <button className='cart-head-info__link btn btn-disable'>
                    <Text field={!isLogin ? layoutData['Checkout Link'].Title : layoutData['Fulfillment Link'].Title} />
                  </button>
                )}
                {!isClientSOCart ? (
                  layoutData['Additional Link'].Title.value ? (
                    <Link className='cart-summary__button__link text-center btn-link' field={layoutData['Additional Link'].Link}>
                      <Text field={layoutData['Additional Link'].Title} />
                    </Link>
                  ) : (
                    <Link className='cart-summary__button__link text-center btn-link' field={layoutData['Additional Link'].Link} />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <ModalComponent
          isShow={showSelectArea}
          titlePopup={<Text field={layoutData['Delivery Zone Title']} tag='span' />}
          onCloseHandler={onCloseModalShippingFeeHandler}
        >
          <div className='store-locator__modal'>
            <div className='delivery-zone-instruction'>
              <RichText field={layoutData['Delivery Zone Instruction']} tag='label' />
            </div>
            <SelectArealModalContentData
              shippingFee={shippingFee}
              setEstimateShippingFeeEvt={onEstimateShippingFeeHandler}
              dataFields={layoutData}
              setSubmitAddressEvt={onConfirmSelection}
              addressCurrent={cartData.Party}
            />
          </div>
        </ModalComponent>
        <ModalComponent
          isShow={showPromotion}
          titlePopup={<Text field={layoutData['Applied Offers Title']} tag='span' />}
          onCloseHandler={() => setShowPromotion(false)}
        >
          <OfferAppliedModal
            dataFields={layoutData}
            listPromotion={appliedPromotion}
            onAppliedOffersModalComponentHandler={onAppliedOffersModalComponentHandler}
          ></OfferAppliedModal>
        </ModalComponent>
        <ModalComponent
          isShow={ShowEstimatedDeliveryFee}
          titlePopup={<Text field={layoutData['Popup Title Label']} tag='span' />}
          onCloseHandler={() => setShowEstimatedDeliveryFee(false)}
        >
          <RichText field={layoutData['Delivery Fee Information']} tag='p' />
        </ModalComponent>
        <ModalComponent
          isShow={popUpDetailCouponList}
          onCloseHandler={() => onAppliedOffersModalComponentHandler(false, popupDetail)}
          titlePopup={<Text field={layoutData['Coupon Detail Title']} />}
        >
          <div className='osim-popup__body coupon-popup__info'>
            <h4 className='coupon-popup__info-title'>{popupDetail.Title}</h4>
            <div className='text-center'>
              <Text tag='span' className='coupon-popup__hint-text' field={layoutData['Hint Text']} />
              <span className='coupon-popup__code'>{popupDetail.Code}</span>
            </div>
            <div className='coupon-popup__description' dangerouslySetInnerHTML={{ __html: popupDetail.Description }}></div>
            {popUpDetailCouponList ? (
              <span className='coupon-popup__info-validity'>
                {/* fixed coupon display 12/10/2022 */}
                <Text field={layoutData['Valid Until Label']} tag='strong' /> {Global.formatDateCoupon(popupDetail.ValidityTo)}
              </span>
            ) : (
              <></>
            )}
          </div>
        </ModalComponent>
        <ModalComponent
          isShow={showInstallmentBlocker}
          titlePopup={<Text field={{ value: layoutData['Installment Warning Title']?.value || 'Installment Warning' }} tag='span' />}
          onCloseHandler={() => setShowInstallmentBlocker(false)}
        >
          {renderBlockInstallmentText()}
          <div className='popup-email-already__action'>
            <button
              className='popup-email-already__action__btn popup-email-already__action__btn--login btn btn-outline-CTA1'
              onClick={() => setShowInstallmentBlocker(false)}
            >
              {layoutData['Edit Cart Label']?.value || 'Edit Cart'}
            </button>
            <Link
              className='popup-email-already__action__btn popup-email-already__action__btn--link'
              field={!isLogin ? layoutData['Checkout Link'].Link : layoutData['Fulfillment Link'].Link}
              onClick={() => onHandleTrackingCode()}
            >
              {layoutData['Continue To Checkout Label']?.value || 'Continue To Checkout'}
            </Link>
          </div>
        </ModalComponent>
      </div>
      {isClientTelingUser && isSavingSO ? <LoadingMask parent={CartsTotalAmount} /> : <></>}
    </>
  ) : (
    <></>
  );
};

CartsTotalAmount.propTypes = {
  fields: PropTypes.any,
  cartData: PropTypes.any,
  areaOptions: PropTypes.any,
  cityOptions: PropTypes.any,
  provinceOptions: PropTypes.any,
  zipcodeOptions: PropTypes.any,
  shippingFee: PropTypes.any,
  appliedPromotion: PropTypes.array,
  isLogin: PropTypes.bool,
  cartSellerStatus: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultShippingAddressStatus: PropTypes.bool,
  showInstallmentBlocker: PropTypes.bool,
  isBlockInstallment: PropTypes.bool,
  isClientSOCart: PropTypes.bool,
  clientSOCartStatus: PropTypes.bool
};

const mapStateToProps = (state) => {
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;

  return {
    cartData: clientSOCartData || currentDefaultCartData,
    defaultShippingAddressStatus: state.productReducer.cartDetailReducer?.defaultShippingAddressStatus,
    shippingFee: state.storeLocatorReducer.estimateShippingFeeReducer.shippingFee?.ShippingPrice || 0,
    appliedPromotion: state.couponReducer?.appliedPromotionList || [],
    isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin || false,
    cartSellerStatus: state.productReducer.cartDetailReducer.cartSellerStatus,
    isLoading: state.checkoutProcesstReducer.updateCartReducer.isLoadingUserAddress,
    showInstallmentBlocker: state.productReducer.cartDetailReducer.showInstallmentBlocker,
    isBlockInstallment: state.productReducer.cartDetailReducer.blockInstallment,
    isClientSOCart: Boolean(clientSOCartData),
    clientSOCartStatus: clientSOCartData ? clientSOCartData?.CartStatus : null
  };
};

export default connect(mapStateToProps)(CartsTotalAmount);
