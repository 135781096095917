import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import ApplyOfferPopup from '@components/SpinAndWin/ApplyOfferPopup';
import SpinWinBanner from '@components/SpinAndWin/Spin&WinBanner';
import ViewMyPrizesButton from '@components/SpinAndWin/ViewMyPrizesButton';
import { applyCouponCode } from '@redux/actions/coupon';
import { getAvailableLuckyDraw } from '@redux/actions/spinAndWin';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import { COUPON_CODE_SESSION_STORAGE_NAME, EMAIL_USER_SPIN_SESSION_STORAGE_NAME, SPIN_URL_SESSION_STORAGE_NAME } from '../configs';
import SpinAndWinModels from '../Models/SpinAndWinModels';
import SpinWinContext from '../SpinAndWinContext';
import RequireLoginPopup from '../SpinWinPopup/RequireLoginPopup';

const SpinAndWinComponent = ({ fields, isSpinAndWinPopUp, couponWalletData, couponDetailData }) => {
  const dispatch = useDispatch();
  const infoUserLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser) || null;
  const luckyDrawReducer = useSelector((state) => state.spinAndWinReducer.getAvailableLuckyDrawReducer);
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const luckyDraw = luckyDrawReducer?.luckyDrawData;
  const isLoading = luckyDrawReducer?.isLoading;
  const [isHiddenSpin, setIsHiddenSpin] = useState(false);

  const [spinAndWinFields, setSpinAndWinFields] = useState(null);
  const [useSpinAndWin, setUseSpinAndWin] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const luckyDrawId = spinAndWinFields?.['LuckyDrawID']?.id;

  useEffect(() => {
    const spinAndWinModels = new SpinAndWinModels();

    setSpinAndWinFields(spinAndWinModels.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (luckyDrawId && !luckyDraw) {
      dispatch(
        getAvailableLuckyDraw({
          luckyDrawId: luckyDrawId
        })
      );
    }
  }, [luckyDrawId, luckyDraw]);

  useEffect(() => {
    const locationSpinPage = window.location.href;

    window.sessionStorage.setItem(SPIN_URL_SESSION_STORAGE_NAME, JSON.stringify(decodeURI(locationSpinPage)));
  }, []);

  const quantityCheck = (arr) => {
    return arr.every((item) => item.Quantity == 0);
  };

  useDidUpdateEffect(() => {
    if (luckyDraw) {
      if (luckyDraw.Id) {
        if (quantityCheck(luckyDraw.Slices)) {
          setIsHiddenSpin(true);
        } else {
          setIsHiddenSpin(false);
        }
      } else {
        setIsHiddenSpin(true);
      }
    } else {
      setIsHiddenSpin(true);
    }
  }, [luckyDraw]);

  useDidUpdateEffect(() => {
    if (couponWalletData?.redirectLink) {
      window.location.href = couponWalletData?.redirectLink;
    } else if (couponWalletData?.errors?.[0]) {
      window.location.href = fields?.['Products Link']?.value?.href || '#';
    }

    // INFO: remove coupon code when applied fail
    handleRemoveCouponUserEmailSessionStorageValue();
  }, [couponWalletData?.errors, couponWalletData?.redirectLink]);

  useDidUpdateEffect(() => {
    if (couponDetailData?.success) {
      // INFO: remove coupon code when applied success
      handleRemoveCouponUserEmailSessionStorageValue();

      window.location.href = fields?.['You Won Apply Offer Button Link']?.value?.href || '#';
    }
  }, [couponDetailData?.success]);

  const handleRemoveCouponUserEmailSessionStorageValue = useCallback(() => {
    window.sessionStorage.removeItem(COUPON_CODE_SESSION_STORAGE_NAME);

    window.sessionStorage.removeItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME);
  }, []);

  const handleApplyCoupon = (data) => {
    if (data) {
      // if (!IsAuthenticated) {
      //   window.location.href = `/login?from=${window.location.href}`;
      // } else {
      //Check The customer logs in by the email different from the email used to spin.
      if (IsAuthenticated && useSpinAndWin?.Email === infoUserLogin?.Email) {
        dispatch(applyCouponCode({ code: data.Code, reCallPromotionsApplied: false }));
      } else {
        setShowPopup(true);

        // INFO: add coupon code when open popup require login (using for auto add coupon when log in success)
        window.sessionStorage.setItem(COUPON_CODE_SESSION_STORAGE_NAME, JSON.stringify(data.Code));

        if (useSpinAndWin?.Email) window.sessionStorage.setItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME, JSON.stringify(useSpinAndWin?.Email || ''));
      }
      // }
    }
  };

  const handleClosePopup = () => {
    // INFO: remove coupon code when close the popup
    handleRemoveCouponUserEmailSessionStorageValue();

    setShowPopup(false);
  };

  return (
    spinAndWinFields &&
    (isLoading ? (
      <LoadingMask parent={SpinAndWinComponent} />
    ) : isHiddenSpin ? (
      <Text tag='h2' className='spin-error-lucky-draw text-center' field={spinAndWinFields['Error Lucky Draw']} />
    ) : luckyDraw ? (
      <>
        <SpinWinContext.Provider
          value={{
            handleApplyCoupon
          }}
        >
          <SpinWinBanner
            layoutData={spinAndWinFields}
            luckyDrawId={luckyDrawId}
            userSpin={(data) => setUseSpinAndWin(data)}
            isSpinAndWinPopUp={isSpinAndWinPopUp}
          />
          {!isSpinAndWinPopUp && <ViewMyPrizesButton layoutData={spinAndWinFields} />}

          <ModalComponent isShow={showPopup} onCloseHandler={handleClosePopup}>
            {IsAuthenticated ? (
              <ApplyOfferPopup
                onCancelPopup={(data) => setShowPopup(data)}
                layoutData={spinAndWinFields}
                userIsSpinning={useSpinAndWin}
                infoUserLogin={infoUserLogin}
              />
            ) : (
              <RequireLoginPopup emailRequire={useSpinAndWin?.Email} onClose={handleClosePopup} layoutData={spinAndWinFields} />
            )}
          </ModalComponent>
        </SpinWinContext.Provider>
        {couponDetailData?.isLoading ? <LoadingMask parent={SpinAndWinComponent} /> : <></>}
      </>
    ) : (
      <></>
    ))
  );
};

const mapStateToProps = (state) => ({
  couponWalletData: state.couponWalletReducer || {},
  couponDetailData: state.couponReducer || {}
});

SpinAndWinComponent.propTypes = {
  fields: PropTypes.any,
  couponWalletData: PropTypes.object,
  couponDetailData: PropTypes.object,
  isSpinAndWinPopUp: PropTypes.any
};

export default connect(mapStateToProps)(SpinAndWinComponent);
