import { Form, Formik } from 'formik';
import { any, func, object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';
import { register } from '@redux/actions/singleSignOn';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import PasswordInput from '../../../SingleSignOn/PasswordInput';
import { useGeneratePasswordRegex } from '../../../SingleSignOn/PasswordInput/hooks';

const WarrantyRegisterForm = (props) => {
  const dispatch = useDispatch();
  const { layoutData, isLoading, errorMessage, settingGlobal } = props;
  const fieldPhoneCode = useRef('');
  const [gender, setGender] = useState(null);
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [receiveDirectMailing, setReceiveDirectMailing] = useState(false);
  const recaptchaRef = useRef(null);
  const [captchaErr, setCaptchaErr] = useState(false);
  const [confirm, setConfirm] = useState(settingGlobal?.DefaultSubscription);
  const formikRef = useRef();
  const { regexSummary } = useGeneratePasswordRegex();
  const messageObject = useSelector(state => state.getMessageReducer.objMessages);

  const [schema, setSchema] = useState(
    Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .matches(regexSummary, messageObject?.['Msg_001.5']),
      firstName: Yup.string()
        .required(<Message messageCode='Msg_001.1' />)
        .trim(),
      lastName: Yup.string()
        .required(<Message messageCode='Msg_001.2' />)
        .trim(),
      email: Yup.string()
        .required(<Message messageCode='Msg_001.3' />)
        .email(<Message messageCode='Msg_001.3' />),
      dob: Yup.string()
        .required(<Message messageCode='Msg_001.4' />)
        .trim(),
      gender: Yup.string()
        .required(<Message messageCode='Msg_019' />)
        .trim(),
      captcha: Yup.string()
        .required(<Message messageCode='Msg_001.6' />)
        .trim(),
      confirm: Yup.boolean().oneOf([true], <Message messageCode='Msg_015' />)
    })
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    dob: '',
    gender: '',
    password: '',
    captcha: '',
    receiveSms: null,
    receiveMail: null,
    receiveDirectMail: null,
    confirm: confirm
  };

  const handleSubmit = (values) => {
    dispatch(
      register({
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: values.email,
        PhoneNumber: `${fieldPhoneCode.current || ''}${values.phone}`,
        Password: values.password,
        ReCaptchaRequest: values.captcha,
        Gender: gender?.Key || '',
        DOB: values.dob,
        ReceiveSms: receiveSMS ? receiveSMS : null,
        ReceiveEmail: receiveEmail ? receiveEmail : null,
        ReceiveDirectMail: receiveDirectMailing ? receiveDirectMailing : null,
        Subscribed: confirm
      })
    );
  };

  useEffect(() => {
    setGender(
      settingGlobal?.GenderDatasource?.find((item) => {
        return item.Key === settingGlobal?.DefaultGender;
      }) || ''
    );
  }, [settingGlobal?.DefaultGender]);

  useDidUpdateEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('gender', gender?.Key || '');
    }
  }, [gender]);

  useEffect(() => {
    if (settingGlobal && settingGlobal?.PhoneNumberMust) {
      setSchema(
        Yup.object({
          password: Yup.string()
            .required(<Message messageCode='Msg_001.5' />)
            .matches(regexSummary, messageObject?.['Msg_001.5']),
          firstName: Yup.string()
            .required(<Message messageCode='Msg_001.1' />)
            .trim(),
          lastName: Yup.string()
            .required(<Message messageCode='Msg_001.2' />)
            .trim(),
          email: Yup.string()
            .required(<Message messageCode='Msg_001.3' />)
            .email(<Message messageCode='Msg_001.3' />),
          dob:
            settingGlobal.IsDoBRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_001.4' />)
              .trim(),
          gender:
            settingGlobal.IsGenderRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_019' />)
              .trim(),
          phone: !settingGlobal.PhoneNumberMust
            ? settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .min(settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
                .max(settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
              : Yup.string()
                .min(settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
                .max(settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
            : settingGlobal.IsPhoneNumberRequired
              ? Yup.string()
                .required(<Message messageCode='Msg_020' />)
                .trim()
                .min(settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />)
              : Yup.string().min(settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust, <Message messageCode='Msg_001.7' />),
          captcha:
            settingGlobal.IsReCaptchaRequired &&
            Yup.string()
              .required(<Message messageCode='Msg_001.6' />)
              .trim(),
          confirm: settingGlobal.IsSubscriptionRequired && Yup.boolean().oneOf([true], <Message messageCode='Msg_015' />)
        })
      );

      fieldPhoneCode.current = settingGlobal.AreaCodes[0].value;
    }
  }, [settingGlobal]);

  useDidUpdateEffect(() => {
    if (receiveSMS || receiveEmail || receiveDirectMailing) {
      setConfirm(true);

      formikRef.current.setFieldValue('confirm', true);
    }
  }, [receiveSMS, receiveEmail, receiveDirectMailing]);

  useDidUpdateEffect(() => {
    if (!confirm) {
      setReceiveSMS(null);

      setReceiveEmail(null);

      setReceiveDirectMailing(null);
    }
  }, [confirm]);

  return gender !== null ? (
    <div className='osim-account-form osim-account-form--register'>
      <div className='osim-account-form__social-login text-center'>
        <FacebookSSO isLogin={false} dataFields={layoutData?.['Facebook Login Settings']?.fields} />
        <GoogleSSO isLogin={false} dataFields={layoutData?.['Google Login Settings']?.fields} />
      </div>
      {layoutData['Register Introduction'].value ? (
        <p className='osim-account-form__title'>
          <Text field={layoutData['Register Introduction']} />
        </p>
      ) : (
        ''
      )}
      {errorMessage ? <span className='error-message'>{errorMessage}</span> : ''}
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)} innerRef={formikRef}>
        {({ errors, touched, handleChange, values, setFieldValue }) => {
          const onHandleTelephoneChange = (evt, fieldName) => {
            let telephone = evt.target.value;
            if (!telephone || new RegExp(settingGlobal.PhoneNumberFormat).test(telephone)) {
              setFieldValue(fieldName, telephone);
            }
          };

          return (
            <Form className='osim-account-form__form' id='osim-account-form__form'>
              {settingGlobal?.FormatName === 'First Name First' ? (
                <>
                  <div className={Global.renderDynamicClass(errors.email && touched.email, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={values.firstName}
                      type='text'
                      className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                      id='firstName'
                      name='firstName'
                      placeholder={layoutData['Register First Name Label'].value}
                    />
                    <label htmlFor='firstName' className='form-group__label'>
                      <Text field={layoutData['Register First Name Label']} />
                    </label>
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                  <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={handleChange}
                      value={values.lastName}
                      type='text'
                      className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                      id='lastName'
                      name='lastName'
                      placeholder={layoutData['Register Last Name Label'].value}
                    />
                    <label htmlFor='lastName' className='form-group__label'>
                      <Text field={layoutData['Register Last Name Label']} />
                    </label>
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                </>
              ) : (
                <>
                  <div className={Global.renderDynamicClass(errors.lastName && touched.lastName, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={handleChange}
                      value={values.lastName}
                      type='text'
                      className={Global.renderDynamicClass(values['lastName'], 'form-control form-control-lg', 'input-valid')}
                      id='lastName'
                      name='lastName'
                      placeholder={layoutData['Register Last Name Label'].value}
                    />
                    <label htmlFor='lastName' className='form-group__label'>
                      <Text field={layoutData['Register Last Name Label']} />
                    </label>
                    {errors.lastName && touched.lastName && <span className='error-validate'>{errors.lastName}</span>}
                  </div>
                  <div className={Global.renderDynamicClass(errors.email && touched.email, 'form-group', 'input-error-validate')}>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={values.firstName}
                      type='text'
                      className={Global.renderDynamicClass(values['firstName'], 'form-control form-control-lg', 'input-valid')}
                      id='firstName'
                      name='firstName'
                      placeholder={layoutData['Register First Name Label'].value}
                    />
                    <label htmlFor='firstName' className='form-group__label'>
                      <Text field={layoutData['Register First Name Label']} />
                    </label>
                    {errors.firstName && touched.firstName && <span className='error-validate'>{errors.firstName}</span>}
                  </div>
                </>
              )}
              <div className={Global.renderDynamicClass(errors.email && touched.email, 'form-group', 'input-error-validate')}>
                <input
                  onChange={handleChange}
                  value={values.email}
                  type='text'
                  className={Global.renderDynamicClass(values['email'], 'form-control form-control-lg', 'input-valid')}
                  id='email'
                  name='email'
                  placeholder={layoutData['Register Email Label'].value}
                />
                <label htmlFor='email' className='form-group__label'>
                  <Text field={layoutData['Register Email Label']} />
                </label>
                {errors.email && touched.email && <span className='error-validate'>{errors.email}</span>}
              </div>
              <div className={Global.renderDynamicClass(errors.dob && touched.dob, 'form-group', 'input-error-validate')}>
                <DateOfBirth setFieldValue={setFieldValue} dobLabel={layoutData['Register DoB Label']} isRegister={true} />
                {errors.dob && touched.dob && <span className='error-validate'>{errors.dob}</span>}
              </div>
              {settingGlobal?.GenderDatasource.length ? (
                <div
                  className={Global.renderDynamicClass(
                    errors.gender && touched.gender,
                    'form-group form-group--gender',
                    'form-group--gender input-error-validate'
                  )}
                >
                  <div>
                    {settingGlobal?.GenderDatasource.map((item) => {
                      return (
                        <div className='osim-radio' key={item.Key}>
                          <input
                            type='radio'
                            name='gender'
                            value={item.Key}
                            className='osim-radio-input'
                            checked={gender?.Key?.toLowerCase() === item.Key.toLowerCase()}
                            readOnly
                          />
                          <label
                            className='osim-radio-label'
                            onClick={() => {
                              setGender(item);

                              setFieldValue('gender', item.Key);
                            }}
                          />
                          <label
                            className='osim-radio-title'
                            onClick={() => {
                              setGender(item);

                              setFieldValue('gender', item.Key);
                            }}
                          >
                            {item.Value}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.gender && touched.gender && <span className='error-validate'>{errors.gender}</span>}
                </div>
              ) : (
                <></>
              )}
              <div
                className={
                  settingGlobal?.UseAreaCode
                    ? `${Global.renderDynamicClass(
                      errors.phone && touched.phone,
                      'form-group',
                      'input-error-validate'
                    )} form-group--group-select-input`
                    : Global.renderDynamicClass(errors.phone && touched.phone, 'form-group', 'input-error-validate')
                }
              >
                {settingGlobal?.UseAreaCode ? (
                  <PhoneNumberSelect setPhoneNumberCodeEvt={(code) => (fieldPhoneCode.current = code)} optionValues={settingGlobal.AreaCodes} />
                ) : (
                  ''
                )}
                <div className='form-group--input'>
                  <input
                    onChange={(evt) => onHandleTelephoneChange(evt, 'phone')}
                    value={values.phone}
                    type='text'
                    className={Global.renderDynamicClass(values['phone'], 'form-control form-control-lg', 'input-valid')}
                    id='phone'
                    name='phone'
                    placeholder={layoutData['Register Phone Label'].value}
                  />
                  <label htmlFor='phone' className='form-group__label'>
                    <Text field={layoutData['Register Phone Label']} />
                  </label>
                  {errors.phone && touched.phone && <span className='error-validate'>{errors.phone}</span>}
                </div>
              </div>
              <div className={Global.renderDynamicClass(errors.password && touched.password, 'form-group', 'input-error-validate')}>
                <PasswordInput
                  onChange={handleChange}
                  value={values.password}
                  type='password'
                  className={Global.renderDynamicClass(values['password'], 'form-control form-control-lg', 'input-valid')}
                  id='password'
                  name='password'
                  placeholder={layoutData['Register Password Label'].value}
                  errorMessageEl={errors.password && touched.password && (
                    <span className='error-validate'>
                      {errors.password}
                    </span>
                  )}
                />
                <label htmlFor='password' className='form-group__label'>
                  <Text field={layoutData['Register Password Label']} />
                </label>
              </div>
              <div className={Global.renderDynamicClass(errors.confirm && touched.confirm, 'form-group', 'input-error-validate')}>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    onClick={() => setConfirm(!confirm)}
                    id='confirm'
                    name='confirm'
                    className='osim-checkbox-input'
                    checked={confirm}
                    readOnly
                  />
                  <RichText
                    tag='label'
                    field={layoutData['Register Subscription Label']}
                    className='osim-checkbox-label osim-account-form__form__subscription'
                    onClick={() => {
                      setConfirm(!confirm);

                      setFieldValue('confirm', !confirm);
                    }}
                  />
                </div>
                {errors.confirm && touched.confirm && (
                  <span className='error-validate'>
                    <Message messageCode='Msg_015' />
                  </span>
                )}
              </div>
              {layoutData['Enable Subscription'].value ? (
                <div className='form-group form-group--checkboxes form-group--checkbox-pd-left'>
                  <Text tag='span' className='form-group--checkboxes__label' field={layoutData['Subscription Description']} />
                  <div className='form-group--checkboxes__list'>
                    <div className='osim-checkbox'>
                      <input type='checkbox' id='receiveSms' name='receiveSms' className='osim-checkbox-input' checked={receiveSMS} readOnly />
                      <Text
                        field={layoutData['SMS Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveSms', !receiveSMS);

                          setReceiveSMS(!receiveSMS);
                        }}
                      />
                    </div>
                    <div className='osim-checkbox'>
                      <input type='checkbox' id='receiveMail' name='receiveMail' className='osim-checkbox-input' checked={receiveEmail} readOnly />
                      <Text
                        field={layoutData['Email Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveMail', !receiveEmail);

                          setReceiveEmail(!receiveEmail);
                        }}
                      />
                    </div>
                    <div className='osim-checkbox'>
                      <input
                        type='checkbox'
                        id='receiveDirectMail'
                        name='receiveDirectMail'
                        className='osim-checkbox-input'
                        checked={receiveDirectMailing}
                        readOnly
                      />
                      <Text
                        field={layoutData['Direct Mailing Subscription Label']}
                        tag='label'
                        className='osim-checkbox-label'
                        onClick={() => {
                          setFieldValue('receiveDirectMail', !receiveDirectMailing);

                          setReceiveDirectMailing(!receiveDirectMailing);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {settingGlobal?.IsReCaptchaRequired && settingGlobal['CaptchaPublicKey'].length ? (
                <div className='form-group form-group--captcha'>
                  <ReCAPTCHA
                    sitekey={settingGlobal['CaptchaPublicKey']}
                    onChange={(value) => setFieldValue('captcha', value || '')}
                    ref={recaptchaRef}
                  />
                  {errors.captcha && touched.captcha && captchaErr && <span className='error-validate'>{errors.captcha}</span>}
                </div>
              ) : (
                <></>
              )}
              <div className='form-group osim-account-form__form__btn'>
                <button
                  className='osim-account-form__form__btn__item btn btn-primary'
                  onClick={() => {
                    settingGlobal?.IsReCaptchaRequired && values.captcha ? setCaptchaErr(false) : setCaptchaErr(true);

                    settingGlobal?.IsReCaptchaRequired && values.captcha && recaptchaRef.current.reset();

                    settingGlobal?.IsReCaptchaRequired &&
                      values.captcha &&
                      setTimeout(() => {
                        setFieldValue('captcha', '');
                      }, 1000);
                  }}
                  type='submit'
                >
                  <Text field={layoutData['Register Button Label']} />
                </button>
              </div>
              <div className='form-group'>
                <p className='text-center'>
                  <Text field={layoutData['Register Switch To Login Text']} />
                  <a href='#' onClick={() => props.changeTabEvt()}>
                    <Text field={layoutData['Login Button Text']} />
                  </a>
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
      {isLoading ? <LoadingMask parent={WarrantyRegisterForm} /> : ''}
    </div>
  ) : (
    <></>
  );
};

WarrantyRegisterForm.propTypes = {
  layoutData: object,
  changeTabEvt: func,
  setEmailRegisterEvt: func,
  errorMessage: string,
  settingGlobal: any
};

const mapStateToProps = (state) => ({
  errorMessage: state.singleSignOnReducer.registerReducer.errorMessage,
  settingGlobal: state.settingGlobalReducer.settingGlobal
});

export default connect(mapStateToProps)(WarrantyRegisterForm);
