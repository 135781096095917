import '../../ProductInterestPopup/ProductInterestPopup.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import {
  dirtyProductInterest,
  keepLifeStyleData,
  removeCpProductInterestStart,
  storeQuestionProductInProductInterest,
  triggerPOSChangeQuestions,
  updateCPCustomerContactInterest
} from '@redux/actions/clientTellingPortalCustomer';
import { getCpProductInterestData } from '@redux/actions/cpProductInterestData';
import { removeCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { ANSWER_TO_CHECK, ANSWER_TYPE, ANSWER_WITH_BRACKET_TYPE, QUESTION_TYPE } from '../../../config';
import CPInput from '../../../GenericComponents/CPForm/CPInput';
import CPSelect from '../../../GenericComponents/CPForm/CPSelect';
import { QUESTION_TYPE_EDIT_UPSELL, TYPE_CHECKBOX, TYPE_RADIO, TYPE_TEXT } from '../../../GenericComponents/CPForm/FormConstant';

const UPSELL_TO_QUESTION_CODE = 'UpsellFrom';
const ANSWER_OTHER_DISPLAY_NAME='Others';

export const CPAccordion = ({
  title,
  secondTitle,
  children,
  nameClass,
  expandedProductId,
  tabName,
  setActiveTab,
  isDirtyAddNewAndEditProductInterest,
  showWarningUnsavePopup, isHaveRemoveIcon, removeIcon, onRemoveIconBtnClick
}) => {
  return (
    <div className={`product-interest__accordion__item ${nameClass || ''} ${expandedProductId == tabName ? 'collapsed' : ''}`}>
      <div className='accordion-header__container d-flex justify-space-between'>
        <div className={`accordion-header ${isHaveRemoveIcon && 'custom-second__title'}`} onClick={() => setActiveTab(tabName)}>
          {title}
          {secondTitle}
        </div>
        {isHaveRemoveIcon && (
          <div className='accordion-header-remove__icon' onClick={onRemoveIconBtnClick}>
            <Image media={removeIcon}/>
          </div>
        )}
      </div>
      <div className='accordion-content'>{children}</div>
      <div
        className='accordion-arrow'
        onClick={() => {
          isDirtyAddNewAndEditProductInterest ? showWarningUnsavePopup() : expandedProductId == tabName ? setActiveTab(null) : setActiveTab(tabName);
        }}
      >
        <i className='icon-chevron-down-big'></i>
      </div>
    </div>
  );
};

const ProductInterestAccordion = ({
  layoutData,
  addNewProductLabel,
  saveLabel,
  capturedLabel,
  editLabel,
  cancelLabel,
  posLabel,
  componentName = ''
}) => {
  const cpSessionId = SitecoreContextFactoryService.getValueContextItem('cpSessionId') || '';
  const [questionData, setQuestionData] = useState(null);
  const [questionPOS, setQuestionPOS] = useState(false);
  const [options, setOptions] = useState(null);
  const [productIsBeingEdited, setProductIsBeingEdited] = useState(null);
  const [productInterest, setProductInterest] = useState([]);
  const [expandedProductId, setExpandedProductId] = useState(null);

  const [newProduct, setNewProduct] = useState({
    SessionId: cpSessionId,
    ProductId: '',
    ProductPageId: '',
    Questions: []
  });
  const [selectedProductStatus, setSelectedProductStatus] = useState(true);
  const [showWarningUnsavePopup, setShowWarningUnsavePopup] = useState(false);
  const [isNewProductBeingEdited, setIsNewProductBeingEdited] = useState(null);
  const [showWarningPOSPopup, setShowWarningPOSPopup] = useState(false);
  const [showConfirmRemoveProductPopup, setShowConfirmRemoveProductPopup] = useState(false);
  const [productIsEditedQuestions, setProductIsEditedQuestions] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [productsHaveOtherAnswer, setProductHaveOthersAnswer] = useState([]);

  const [isPos, setIsPos] = useState({
    productId: null,
    isPos: null
  });
  const [isAddNewProduct, setIsAddNewProduct] = useState(true);
  const [isEnteredOtherInput, setIsEnteredOtherInput] = useState(true);

  const [upSellProducts, setUpSellProduct] = useState([]);
  const [upSellProductSelected, setUpSellProductSelected] = useState(null);
  const [isDisableUpsell, setIsDisableUpsell] = useState(false);

  const [selectedRemoveProductId, setSelectedRemoveProductId] = useState(null);

  const [accessoriesData] = useState(
    layoutData?.['Accessories Product']
      ? layoutData?.['Accessories Product'].map((item) => {
        return {
          ProductName: item.fields.Name.value,
          CommerceProductId: item.id
        };
      })
      : []
  );

  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  // const productList = useSelector((state) => state.commonSearchReducer.productSearchReducer.productList);
  const productList = useSelector((state)=>state.cpProductInterestDataReducer.cpProductInterestData);

  const dispatch = useDispatch();
  const layoutQuestionsList = useMemo(() => layoutData?.Questions || [], [layoutData]);

  const onChangeSelect = (data) => {
    if (!clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest) {
      dispatch(dirtyProductInterest(true));
    }

    // Click select product drop-down of add new product in Product Interest
    if (data.questionType == 'Add-New-Product-Interest') {
      // If selected product is being existed in Product Interest, go to edit selected product
      if (
        clientTellingPortalCustomer.customerInformation?.CPCustomerInterest?.Products?.length &&
        clientTellingPortalCustomer.customerInformation?.CPCustomerInterest?.Products.some(
          (product) =>
            Global.onFormatProductId(product.ProductId) == Global.onFormatProductId(options.find((option) => option.value == data.value).productId)
        )
      ) {
        setProductIsBeingEdited(Global.onFormatProductId(options.find((option) => option.value == data.value).productId));

        setExpandedProductId(Global.onFormatProductId(options.find((option) => option.value == data.value).productId));

        setIsAddNewProduct(false);
      }
      // If selected product is not existed in Product Interest, got to add new product tab
      else {
        setSelectedProductStatus(true);

        setIsNewProductBeingEdited(true);

        setIsAddNewProduct(true);

        let product = productList?.concat(accessoriesData).find((product) => product.ProductName == data.value);

        setNewProduct({
          ...newProduct,
          ProductId: Global.onFormatProductId(product.CommerceProductId),
          ProductPageId: Global.onFormatProductId(product.ProductPageId)
        });
      }
    } else {
      const answeringQuestionData = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === data?.questionCode);

      const isSelectingOtherOption =
          answeringQuestionData?.Answers?.find((answerItem) => answerItem?.id === data?.answerId)?.displayName === ANSWER_OTHER_DISPLAY_NAME;

      // If add new questions for New Product in Product Interest
      if (data.isAddNew) {
        setIsNewProductBeingEdited(true);

        let tempArr = newProduct.Questions;
        let tempObj = deepCopy(productsHaveOtherAnswer);

        if (data.questionType === QUESTION_TYPE) {
          if (isSelectingOtherOption) {
            if (!tempObj.find((item) => item.questionCode === data.questionCode && item.productId === data.productId)) {
              tempObj.push({
                otherText: '',
                questionCode: data.questionCode,
                productId: data.productId,
                questionType: data.questionType
              });
            } else {
              const indexOfItem = tempObj.findIndex((item) => item.questionCode === data.questionCode && item.productId === data.productId);

              tempObj.splice(indexOfItem, 1);
            }
          }
        } else {
          if (isSelectingOtherOption) {
            !tempObj.find((item) => item.questionCode === data.questionCode && item.productId === data.productId) &&
              tempObj.push({
                otherText: '',
                questionCode: data.questionCode,
                productId: data.productId,
                questionType: data.questionType
              });
          } else {
            tempObj = tempObj.filter((val) => val.questionCode !== data.questionCode);
          }
        }

        setProductHaveOthersAnswer(tempObj);

        if (data.questionType == QUESTION_TYPE) {
          const questionData = tempArr.find((item) => item.QuestionCode === data.questionCode);

          questionData.Answer = Global.onSetValueMultiSelect(questionData.Answer, data.value);

          questionData.AnswerId = Global.onSetValueMultiSelect(questionData.AnswerId, data.answerId);
        } else {
          const selectingQuestion = tempArr.find((item) => item.QuestionCode === data.questionCode);

          selectingQuestion.Answer = data.value;

          selectingQuestion.AnswerId = data.answerId;
        }
        let product;
        if (selectedProduct) {
          product = productList?.concat(accessoriesData).find((product) => product.ProductName == selectedProduct.value);
        }

        setNewProduct({
          ...newProduct,
          Questions: tempArr,
          ProductId: selectedProduct
            ? Global.onFormatProductId(product?.CommerceProductId)
            : '',
          ProductPageId: selectedProduct
            ? Global.onFormatProductId(product?.ProductPageId)
            : ''
        });
      }
      // If edit answer of question of Existed Product in Product Interest
      else {
        setIsNewProductBeingEdited(false);

        let tempObj = deepCopy(productsHaveOtherAnswer);

        if (data.questionType === QUESTION_TYPE) {
          if (isSelectingOtherOption) {
            if (!tempObj.find((item) => item.questionCode === data.questionCode && item.productId === data.productId)) {
              tempObj.push({
                otherText: '',
                questionCode: data.questionCode,
                productId: data.productId,
                questionType: data.questionType
              });

              dispatch(
                storeQuestionProductInProductInterest({
                  key: data.questionCode,
                  value: data.value,
                  type: data.questionType,
                  productId: data.productId,
                  questionValue: data.questionValue,
                  answerId: data.answerId
                })
              );
            } else {
              dispatch(
                storeQuestionProductInProductInterest({
                  key: data.questionCode,
                  value: tempObj.find((item) => item.productId === data.productId && item.questionCode === data.questionCode)?.otherText,
                  type: data.questionType,
                  productId: data.productId,
                  questionValue: data.questionValue,
                  answerId: data.answerId
                })
              );
            }
          } else {
            dispatch(
              storeQuestionProductInProductInterest({
                key: data.questionCode,
                value: data.value,
                type: data.questionType,
                productId: data.productId,
                questionValue: data.questionValue,
                answerId: data.answerId
              })
            );
          }
        } else {
          if (isSelectingOtherOption) {
            !tempObj.find((item) => item.questionCode === data.questionCode && item.productId === data.productId) &&
            tempObj.push({
              otherText: '',
              questionCode: data.questionCode,
              productId: data.productId,
              questionType: data.questionType
            });
          } else {
            tempObj = tempObj.filter((val) => val.questionCode !== data.questionCode);
          }

          dispatch(
            storeQuestionProductInProductInterest({
              key: data.questionCode,
              value: data.value,
              type: data.questionType,
              productId: data.productId,
              questionValue: data.questionValue,
              answerId: data.answerId
            })
          );
        }

        setProductHaveOthersAnswer(tempObj);
      }
    }
  };

  const generateQuestion = (questions) => {
    let rows = Math.ceil(questions.length / 2);

    let tempArr = [];

    for (let i = 1; i <= rows; i++) {
      let tempObj = {};

      questions.forEach((item, index) => {
        if (!('IsRequired' in item)) {
          layoutData['Questions'].forEach((val) => {
            if (val.Question === item.Question) {
              item = { ...item, IsRequired: val['Is Required'] === '1' };
            }
          });
        }
        if (i === Math.ceil((index + 1) / 2)) {
          !((index + 1) % 2) ? (tempObj.right = item) : (tempObj.left = item);
        }
      });

      tempArr.push(tempObj);
    }

    return tempArr;
  };

  const onGetCorrespondingLSQuestion = (apiQuest) => {
    return layoutQuestionsList.filter((quest) => quest?.['Question Code'] === apiQuest)?.[0];
  };

  const onSaveProduct = (productType) => {
    if (productType == 'NewProduct') {
      if (newProduct.ProductId) {
        setProductIsBeingEdited(null);

        setExpandedProductId(null);

        dispatch(dirtyProductInterest(false));

        let object = deepCopy(clientTellingPortalCustomer);

        newProduct.IsCaptured = checkIsCaptured(newProduct);

        newProduct.IsPos = questionPOS;

        productsHaveOtherAnswer.length > 0 &&
          productsHaveOtherAnswer.map((val) => {
            if (val.questionType === QUESTION_TYPE) {
              let otherAnswerText='';

              const findItemHasSameQuestionCode = newProduct.Questions.filter((quest) => {
                const answeringQuestionData = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === quest?.QuestionCode);
                let isSelectingOtherOption = false;
                for (const item of answeringQuestionData?.Answers) {
                  if (quest?.AnswerId?.split(', ')?.includes(item?.id) && item?.displayName === ANSWER_OTHER_DISPLAY_NAME) {
                    otherAnswerText = item?.fields?.Answer?.value || ANSWER_TYPE;

                    isSelectingOtherOption = true;
                  }
                }

                return isSelectingOtherOption;
              })?.find((item) => item.QuestionCode === val.questionCode);

              if (findItemHasSameQuestionCode) {
                if (newProduct.ProductId === val.productId) {
                  findItemHasSameQuestionCode.Answer = findItemHasSameQuestionCode.Answer.replace(
                    otherAnswerText || ANSWER_TYPE,
                    `{${ANSWER_TYPE}}: ${val.otherText?.trim()}`
                  );
                }
              }
            } else {
              const findItemHasSameQuestionCode = newProduct.Questions.filter(
                (quest) => {
                  const answeringQuestionData = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === quest?.QuestionCode);

                  return answeringQuestionData?.Answers?.find((answerItem) => answerItem?.id === quest?.AnswerId)?.displayName === ANSWER_OTHER_DISPLAY_NAME;
                }
              )?.find((item) => item.QuestionCode === val.questionCode);

              if (findItemHasSameQuestionCode) {
                if (newProduct.ProductId === val.productId) {
                  findItemHasSameQuestionCode.Answer = `{${ANSWER_TYPE}}: ${val.otherText?.trim()}`;
                }
              }
            }
          });

        if (upSellProductSelected) {
          newProduct.Questions.filter((quest) => {
            if (quest.QuestionCode === upSellProductSelected.questionCode) {
              const questionsList = newProduct.Questions.find((quest) => quest.QuestionCode === upSellProductSelected.questionCode);

              questionsList.Answer = upSellProductSelected.value;

              questionsList.AnswerId = upSellProductSelected.productPageId;
            }
          });
        }

        object.customerInformation.CPCustomerInterest.Products.push(newProduct);

        const params = {
          ContactID: clientTellingPortalCustomer.customerInformation?.ContactID,
          SessionId: cpSessionId,
          // Email: ' ',
          // PhoneNumber: ' ',
          // PrefixPhoneNumber: ' ',
          // DOB: ' ',
          // PersonalInformation: {
          //   FirstName: ' ',
          //   LastName: ' ',
          //   Gender: ' '
          // },
          // CPCustomerInfo: {
          //   MaritalStatus: ' ',
          //   Age: ' ',
          //   JobNature: ' ',
          //   HealthIssues: ' ',
          //   HouseholdSize: ' ',
          //   MassageOwnershipType: ' ',
          //   MassageOwnership: ' ',
          //   AllowDirectMail: ' ',
          //   AllowSms: ' ',
          //   AllowEmail: ' ',
          //   PDPAAgreement: ' ',
          // ReferPOS: clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || false
          // },
          CPCustomerInterest: {
            Products:
              object?.customerInformation?.CPCustomerInterest?.Products.map((product) => {
                return {
                  ...product
                };
              }) || ' '
          }
        };

        let formData = new window.FormData();

        for (let keyParams in params) {
          if (keyParams == 'PersonalInformation') {
            for (let keyChild in params[keyParams]) {
              formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
            }
          } else if (keyParams == 'CPCustomerInfo') {
            for (let keyChild in params[keyParams]) {
              formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
            }
          } else if (keyParams == 'CPCustomerInterest') {
            for (let keyChild in params[keyParams]) {
              params[keyParams][keyChild].forEach((item, index) => {
                for (let keyItem in item) {
                  if (keyItem == 'Questions') {
                    params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                      for (let keyChildItem in childItem) {
                        formData.append(
                          `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
                          childItem[keyChildItem]
                        );
                      }
                    });
                  } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
                }
              });
            }
          } else {
            formData.append(keyParams, params[keyParams]);
          }
        }

        dispatch(updateCPCustomerContactInterest(formData));
      } else {
        setSelectedProductStatus(false);
      }

      onGenerateDefaultQuestions();

      setShowWarningUnsavePopup(false);
    } else {
      setProductIsBeingEdited(null);

      setExpandedProductId(null);

      dispatch(dirtyProductInterest(false));

      let formData = new window.FormData();
      let tempObj = deepCopy(clientTellingPortalCustomer.customerInformation?.CPCustomerInterest?.Products);

      let params = {
        ContactID: clientTellingPortalCustomer.customerInformation?.ContactID,
        SessionId: cpSessionId,
        // Email: ' ',
        // PhoneNumber: ' ',
        // PrefixPhoneNumber: ' ',
        // DOB: ' ',
        // PersonalInformation: {
        //   FirstName: ' ',
        //   LastName: ' ',
        //   Gender: ' '
        // },
        // CPCustomerInfo: {
        //   MaritalStatus: ' ',
        //   Age: ' ',
        //   JobNature: ' ',
        //   HealthIssues: ' ',
        //   HouseholdSize: ' ',
        //   AllowDirectMail: ' ',
        //   AllowSms: ' ',
        //   AllowEmail: ' ',
        //   SignatureImageUrl: ' ',
        //   PDPAAgreement: ' ',
        // ReferPOS: clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || false
        // },
        CPCustomerInterest: {
          Products:
            tempObj?.map((tempItem) => {
              productsHaveOtherAnswer.length > 0 &&
                productsHaveOtherAnswer.map((val) => {
                  if (val.questionType === QUESTION_TYPE) {
                    let otherAnswerText='';
                    let otherAnswerIdsList = [];

                    const findItemHasSameQuestionCode = tempItem.Questions.filter((quest) => {
                      const answeringQuestionData = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === quest?.QuestionCode);
                      let isSelectingOtherOption = false;
                      for (const item of answeringQuestionData?.Answers) {
                        if (quest?.AnswerId?.split(', ')?.includes(item?.id) && item?.displayName === ANSWER_OTHER_DISPLAY_NAME) {
                          otherAnswerText= item?.fields?.Answer?.value || ANSWER_TYPE;

                          isSelectingOtherOption = true;

                          otherAnswerIdsList.push(item.id);
                        }
                      }

                      return isSelectingOtherOption;
                    })?.find((item) => item.QuestionCode === val.questionCode);

                    if (findItemHasSameQuestionCode && tempItem.ProductId === val.productId) {
                      const splitAnswerIdToList = findItemHasSameQuestionCode.AnswerId.split(', ');
                      const splitAnswerToList = findItemHasSameQuestionCode.Answer.split(', ');
                      const indexOfOtherAnswer = splitAnswerIdToList.findIndex((item) => otherAnswerIdsList.includes(item));
                      if (indexOfOtherAnswer >= 0) {
                        splitAnswerToList[indexOfOtherAnswer] = `${ANSWER_WITH_BRACKET_TYPE}${val.otherText?.trim()}`;
                      }

                      findItemHasSameQuestionCode.Answer = splitAnswerToList.join(', ');
                    }
                  } else {
                    const findItemHasSameQuestionCode = tempItem.Questions.filter(
                      (quest) => {
                        const answeringQuestionData = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === quest?.QuestionCode);

                        return answeringQuestionData?.Answers?.find((answerItem) => answerItem?.id
                          === quest?.AnswerId)?.displayName === ANSWER_OTHER_DISPLAY_NAME;
                      }
                    )?.find((item) => item.QuestionCode === val.questionCode);

                    if (findItemHasSameQuestionCode && tempItem.ProductId === val.productId) {
                      findItemHasSameQuestionCode.Answer = `${ANSWER_WITH_BRACKET_TYPE}${val.otherText}`;
                    }
                  }
                });

              if (upSellProductSelected) {
                tempItem.Questions.filter((quest) => {
                  if (quest.QuestionCode === upSellProductSelected.questionCode) {
                    const questionsList = tempItem.Questions.find((quest) => quest.QuestionCode === upSellProductSelected.questionCode);

                    questionsList.Answer = upSellProductSelected.value;

                    questionsList.AnswerId = upSellProductSelected.productPageId;
                  }
                });
              }

              return {
                SessionId: tempItem.SessionId,
                ProductId: tempItem?.ProductId,
                ProductPageId: tempItem?.ProductPageId,
                Questions: tempItem?.Questions,
                IsCaptured: checkIsCaptured(tempItem),
                IsPos: isPos.productId === tempItem.ProductId ? isPos.isPos : tempItem.IsPos
              };
            }) || ' '
        }
      };

      for (let keyParams in params) {
        if (keyParams == 'PersonalInformation') {
          for (let keyChild in params[keyParams]) {
            formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
          }
        } else if (keyParams == 'CPCustomerInfo') {
          for (let keyChild in params[keyParams]) {
            formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
          }
        } else if (keyParams == 'CPCustomerInterest') {
          for (let keyChild in params[keyParams]) {
            params[keyParams][keyChild].forEach((item, index) => {
              for (let keyItem in item) {
                if (keyItem == 'Questions') {
                  params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                    for (let keyChildItem in childItem) {
                      formData.append(
                        `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
                        childItem[keyChildItem]
                      );
                    }
                  });
                } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
              }
            });
          }
        } else {
          formData.append(keyParams, params[keyParams]);
        }
      }

      dispatch(updateCPCustomerContactInterest(formData));

      setShowWarningUnsavePopup(false);
    }

    setProductHaveOthersAnswer([]);

    dispatch(keepLifeStyleData());

    setQuestionPOS(false);
  };

  const onGenerateDefaultQuestions = () => {
    // Generate default questions position in ProductInterest
    let questions = [];
    if (!questionPOS) {
      questions = layoutData.Questions.filter((quest) => quest['Question Type'] === 'Non-POS' || quest['Question Type'] === 'Both');
    } else {
      questions = layoutData.Questions.filter((quest) => quest['Question Type'] === 'POS' || quest['Question Type'] === 'Both');
    }

    setQuestionData(generateQuestion(questions));

    // Generate default questions for add new product in ProductInterest
    let tempQuestions = [];

    questions.forEach((quest) => {
      tempQuestions.push({
        Question: quest.Question,
        Answer: '',
        AnswerId: '',
        IsPosRelated: quest['POS Related'] === '1' ? true : false,
        QuestionCode: quest['Question Code'],
        QuestionType: quest['Question Type'],
        IsRequired: quest['Is Required'] === '1'
      });
    });

    let product;
    if (selectedProduct)
      product = productList?.concat(accessoriesData).find((product) => product.ProductName == selectedProduct.value);

    setNewProduct({
      SessionId: cpSessionId,
      ProductId: selectedProduct
        ? Global.onFormatProductId(product?.CommerceProductId)
        : '',
      ProductPageId: selectedProduct
        ? Global.onFormatProductId(product?.ProductPageId)
        : '',
      Questions: tempQuestions
    });
  };

  const checkIsCaptured = (product) => {
    let requiredQuestions = {};
    let requiredQuestionsHaveAnswer = {};

    const questionsAfterClearEmptyObj = Global.removeEmptyObj(product.Questions);

    questionsAfterClearEmptyObj.forEach((val) => {
      if (val?.IsRequired) {
        requiredQuestions['count'] = (requiredQuestions['count'] || 0) + 1;
        if (val?.Answer?.length) {
          requiredQuestionsHaveAnswer['count'] = (requiredQuestionsHaveAnswer['count'] || 0) + 1;
        }
      }
    });

    return 'count' in requiredQuestions ? requiredQuestions.count === requiredQuestionsHaveAnswer.count : false;

    // Global.removeEmptyObj(product?.Questions).every((quest) => {
    //   // let requiredQuestionsHaveAnswer = 0;
    //   if (quest?.IsRequired) {
    //     requiredQuestions['count'] = (requiredQuestions['count'] || 0) + 1;
    //   }
    // });
  };

  useEffect(() => {
    // Get Product List
    dispatch(
      getCpProductInterestData({
        page: 1,
        pageSz: 999999
      })
    );
  }, []);

  useEffect(() => {
    layoutData && onGenerateDefaultQuestions();
  }, [layoutData, questionPOS]);

  useDidUpdateEffect(() => {
    if (clientTellingPortalCustomer.contacts?.[0]?.json_facet?.CPCustomerInterest?.Products?.length && productList?.concat(accessoriesData)?.length) {
      // let products = productList.concat(clientTellingPortalCustomer.contacts?.[0]?.json_facet?.CPCustomerInterest?.Products).concat(accessoriesData);
      let products = productList.concat(accessoriesData);

      setOptions(
        products?.map((product) => {
          return {
            value: product.ProductName || 'N/A',
            label: product.ProductName || 'N/A',
            productId: product.CommerceProductId
          };
        })
      );
    } else {
      setOptions(
        productList?.concat(accessoriesData)?.map((product) => {
          return {
            value: product.ProductName || 'N/A',
            label: product.ProductName || 'N/A',
            productId: product.CommerceProductId
          };
        })
      );
    }
  }, [clientTellingPortalCustomer.contacts, productList, accessoriesData]);

  useEffect(() => {
    const productInterestData = clientTellingPortalCustomer.customerInformation?.CPCustomerInterest?.Products;

    // if (productInterestData?.length > 0) {
    setProductInterest(productInterestData);
    // }
  }, [clientTellingPortalCustomer.customerInformation?.CPCustomerInterest?.Products]);

  const onInputOtherReasonHandler = (e, questionCode, productId) => {
    let otherText = e.target.value;
    let tempObj = deepCopy(productsHaveOtherAnswer);

    tempObj.map((val) => {
      if (val.questionCode === questionCode && val.productId === productId) {
        val.otherText = otherText;
      }
    });

    setProductHaveOthersAnswer(tempObj);
  };

  useEffect(() => {
    let tempObj = deepCopy(productsHaveOtherAnswer);

    productInterest &&
      productInterest.map((val) => {
        val.Questions.filter((quest) =>{
          const questionType = `CPCustomerInfo-${onGetCorrespondingLSQuestion(quest.QuestionCode)?.['Display Type']}`;
          const checkItemExist = tempObj.find((tempItem) => tempItem.questionCode === quest.QuestionCode && tempItem.productId === val.ProductId);

          if (ANSWER_TO_CHECK.some((item) => quest.Answer?.includes(item))) {
            if (!checkItemExist) {
              tempObj.push({
                questionCode: quest.QuestionCode,
                otherText:
                  questionType === QUESTION_TYPE
                    ? quest.Answer.split(', ')
                      .filter((item) => item.includes(ANSWER_WITH_BRACKET_TYPE) || item.includes(ANSWER_TYPE))
                      .join('')
                    : quest.Answer,
                productId: val.ProductId,
                questionType: questionType
              });
            }
          }
        });
      });

    setProductHaveOthersAnswer(tempObj);
  }, [productInterest]);

  // use for add data to Upsell options
  useEffect(() => {
    let tempObj = deepCopy(upSellProducts);

    // if (productInterest?.length > 0) {
    //   if (productInterest.filter((val) => val.IsCaptured && val.SessionId === cpSessionId).length > 0) {
    //     setIsDisableUpsell(false);
    //
    //     tempObj = deepCopy(
    //       productInterest
    //         .filter((val) => val.IsCaptured && val.SessionId === cpSessionId)
    //         ?.map((item) => {
    //           return {
    //             value: item.ProductName || 'N/A',
    //             label: item.ProductName || 'N/A',
    //             productId: item.ProductId
    //           };
    //         })
    //     );
    //   } else {
    //     setIsDisableUpsell(true);
    //
    //     tempObj = deepCopy(
    //       productList?.concat(accessoriesData)?.map((product) => {
    //         return {
    //           value: product.ProductName || 'N/A',
    //           label: product.ProductName || 'N/A',
    //           productId: product.ProductId
    //         };
    //       })
    //     );
    //   }
    // } else {
    //   setIsDisableUpsell(true);
    // }
    tempObj = deepCopy(
      productList?.concat(accessoriesData)?.map((product) => {
        return {
          value: product.ProductName || 'N/A',
          label: product.ProductName || 'N/A',
          productId: product.ProductId,
          commerceProductId: product?.CommerceProductId,
          productPageId: product?.ProductPageId
        };
      })
    );

    setUpSellProduct(tempObj);
  }, [productInterest, productList]);

  const onRemoveProductInterestBtnClick = () => {
    const params = {
      ContactId: clientTellingPortalCustomer.customerInformation.ContactID,
      SessionId: cpSessionId,
      ProductId: selectedRemoveProductId,
      isRemovingProduct: true
    };

    dispatch(removeCpProductInterestStart(params));

    setShowConfirmRemoveProductPopup(false);
  };

  const generateAnswerValueText = useCallback(
    (questionCode = '', answerId = '', answerRawText = '') => {
      let answerResultTextsList = [];

      if (questionCode && answerId && answerRawText) {
        const questionDataByQuestionCode = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === questionCode);

        if (questionDataByQuestionCode) {
          const answerIdsList = answerId.split(', ');
          const answerRawTextsList = answerRawText.split(', ');
          if (questionCode !== UPSELL_TO_QUESTION_CODE) {
            const answerDataByAnswerId = questionDataByQuestionCode.Answers.filter((answerItem) => answerIdsList.includes(answerItem.id)) || [];

            if (answerDataByAnswerId.length) {
              for(const answerDataItem of answerDataByAnswerId){
                if (answerDataItem.displayName === ANSWER_OTHER_DISPLAY_NAME) {
                  const indexOfOtherAnswer = answerIdsList.findIndex((answerIdItem) => answerIdItem === answerDataItem.id);
                  if (answerRawTextsList?.[indexOfOtherAnswer]) {
                    answerResultTextsList.push(answerRawTextsList[indexOfOtherAnswer].replaceAll(ANSWER_WITH_BRACKET_TYPE, ''));
                  } else {
                    answerResultTextsList.push(answerDataItem?.fields?.Answer?.value);
                  }
                } else {
                  answerResultTextsList.push(answerDataItem.fields?.Answer?.value);
                }
              }
            }
          } else {
            const productData = upSellProducts?.filter((productItem) => answerIdsList.includes(productItem.productPageId)) || [];
            if (productData.length) productData.forEach((item) => answerResultTextsList.push(item.label));
          }
        }
      }

      return answerResultTextsList.join(', ');
    },
    [layoutQuestionsList, upSellProducts]
  );

  const defaultUpsellSelectValue = useCallback(
    (answerId) => {
      const selectedProductDataOption = {
        value: '',
        label: ''
      };
      if (upSellProducts.length) {
        const productSelectedData = upSellProducts.find((item) => item.productPageId === answerId);

        if (productSelectedData) {
          selectedProductDataOption.value = productSelectedData?.value;

          selectedProductDataOption.label = productSelectedData?.label;
        }
      }

      return selectedProductDataOption;
    },
    [upSellProducts]
  );

  const generateQuestionText = useCallback(
    (questionCode, defaultValue = '') => {
      if (layoutQuestionsList.length && questionCode) {
        const getQuestionDataByQuestionCode = layoutQuestionsList.find((questionItem) => questionItem?.['Question Code'] === questionCode);

        return getQuestionDataByQuestionCode ? getQuestionDataByQuestionCode?.Question || defaultValue : defaultValue;
      }

      return defaultValue || '';
    },
    [layoutQuestionsList]
  );

  const isOtherAnswer = useCallback(
    (questionCode = '', questionsListOfSelectedProduct = []) => {
      const selectingAnswerForQuestion = questionsListOfSelectedProduct.find((item) => item?.QuestionCode === questionCode);

      if (selectingAnswerForQuestion) {
        const questionDataFromLayout = layoutQuestionsList.find((item) => item?.['Question Code'] === selectingAnswerForQuestion.QuestionCode);
        const answersListSelected = questionDataFromLayout?.Answers || [];
        const answerIdRawData = selectingAnswerForQuestion.AnswerId || '';
        const splitAnswer = answerIdRawData.split(', ');

        if (answersListSelected.length && splitAnswer.every((item) => item)) {
          return splitAnswer.some(
            (answerIdItem) => answersListSelected.find((item) => item?.id === answerIdItem)?.displayName === ANSWER_OTHER_DISPLAY_NAME
          );
        }
      }

      return false;
    },
    [layoutQuestionsList]
  );

  const productNameGenerate = useCallback(
    (productId) => options?.find((item) => removeCurlyBrackets(item?.productId || '')?.toLowerCase() === productId)?.label || 'N/A',
    [options]
  );

  return (
    <div className='product-interest__accordion'>
      <CPAccordion
        secondTitle={addNewProductLabel}
        nameClass='product-interest__accordion__item--create'
        expandedProductId={expandedProductId}
        tabName={'New Product'}
        setActiveTab={(id) => {
          setExpandedProductId(id);

          setProductIsBeingEdited(null);
        }}
        isDirtyAddNewAndEditProductInterest={clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest}
        showWarningUnsavePopup={() => setShowWarningUnsavePopup(true)}
      >
        <div className='product-interest__form cp-form'>
          <div className='cp-form__wrap'>
            <div className='cp-form__left'>
              <div className='form-group'>
                <CPSelect
                  selectId='selectId'
                  onChangeSelect={(item) => {
                    onChangeSelect(item);

                    if (selectedProduct && isAddNewProduct) {
                      let tempObj = deepCopy(productsHaveOtherAnswer);

                      const newProductId = options
                        .filter((val) => val?.value === item?.value)?.[0]
                        ?.productId.replace(/[\{\}']+/g, '')
                        .toLowerCase();

                      const oldProductId = options
                        .filter((val) => val?.value === selectedProduct?.value)?.[0]
                        ?.productId.replace(/[\{\}']+/g, '')
                        .toLowerCase();

                      if (tempObj.filter((val) => val.productId === oldProductId).length > 0) {
                        tempObj.map((val) => {
                          if (val.productId === oldProductId) {
                            val.productId = newProductId;
                          }
                        });
                      }

                      setProductHaveOthersAnswer(tempObj);
                    }

                    setSelectedProduct(item);
                  }}
                  options={options}
                  selectPlaceholder={layoutData?.['Select Product Label']?.value}
                  questionCode=''
                  questionType='Add-New-Product-Interest'
                  isResetProductSelection={expandedProductId != 'New Product' ? true : false}
                />
                {!selectedProductStatus ? <Text tag='span' className='error-mess' field={layoutData['Require Product Warning Text']} /> : <></>}
              </div>
            </div>
            <div className='cp-form__right'>
              <div className='form-group cp-form__checkbox'>
                <div className='osim-checkbox'>
                  <input type='checkbox' id='cp-checkbox-1' className='osim-checkbox-input' checked={questionPOS} readOnly />
                  <label className='osim-checkbox-label' htmlFor='cp-checkbox-1' onClick={() => setShowWarningPOSPopup('NewProduct')}>
                    {posLabel}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {questionData?.map((q, iq) => {
            const generateOtherInputField = (sideNeedToGenerate) => {
              return sideNeedToGenerate === 'left' ? (
                <div className='cp-input cp-form__group--item-mt-18'>
                  <input
                    type={TYPE_TEXT}
                    placeholder={layoutData?.['Other Reason Label']?.value}
                    onChange={(e) => onInputOtherReasonHandler(e, q?.left?.['Question Code'], newProduct?.ProductId)}
                    value={
                      productsHaveOtherAnswer.find(
                        (val) => val.questionCode === (q?.left?.['Question Code'] && val.productId === newProduct.ProductId)
                      )?.otherText
                    }
                  />
                  {productsHaveOtherAnswer.filter(
                    (val) =>
                      val.productId === newProduct?.ProductId && val.questionCode === q?.left?.['Question Code'] && val.otherText?.trim() === ''
                  )?.length > 0 &&
                    !isEnteredOtherInput && <Text tag='span' className='error-mess' field={layoutData['Require Other Reason Text']} />}
                </div>
              ) : (
                <div className='cp-input cp-form__group--item-mt-18'>
                  <input
                    type={TYPE_TEXT}
                    placeholder={layoutData?.['Other Reason Label']?.value}
                    onChange={(e) => onInputOtherReasonHandler(e, q?.right?.['Question Code'], newProduct?.ProductId)}
                    value={
                      productsHaveOtherAnswer.find(
                        (val) => val.questionCode === (q?.right?.['Question Code'] && val.productId === newProduct.ProductId)
                      )?.otherText
                    }
                  />
                  {productsHaveOtherAnswer.filter(
                    (val) =>
                      val.productId === newProduct?.ProductId && val.questionCode === q?.right?.['Question Code'] && val.otherText?.trim() === ''
                  )?.length > 0 &&
                    !isEnteredOtherInput && <Text tag='span' className='error-mess' field={layoutData['Require Other Reason Text']} />}
                </div>
              );
            };

            return (
              <div className='cp-form__wrap' key={iq}>
                <div className='cp-form__left'>
                  {onGetCorrespondingLSQuestion(q.left?.['Question Code'])?.['Display Type'] === 'Drop Down' ? (
                    <div className='form-group'>
                      <p className='cp-form__label'>
                        {generateQuestionText(q?.left?.['Question Code'], q?.left?.Question)}
                        <span>{q?.left?.['Is Required'] === '1' && '*'}</span>
                      </p>
                      <CPSelect
                        selectId='selectId'
                        onChangeSelect={(item) => {
                          item.questionCode = onGetCorrespondingLSQuestion(q.left?.['Question Code'])?.['Question Code'];

                          setUpSellProductSelected(item);
                        }}
                        options={upSellProducts}
                        selectPlaceholder={layoutData?.['Upsell Select Product Label']?.value}
                        questionCode={q?.left?.['Question Code']}
                        questionType='Add-New-Product-Interest'
                        isResetProductSelection={expandedProductId != 'New Product' ? true : false}
                        isDisabled={isDisableUpsell}
                      />
                    </div>
                  ) : (
                    <div className='form-group'>
                      <p
                        className={`cp-form__label ${
                          q?.left?.['Is Required'] === '1' &&
                          !newProduct.Questions.find((quest) => quest.QuestionCode == q?.left?.['Question Code'])?.Answer?.trim() &&
                          'cp-form__label-required'
                        }`}
                      >
                        {generateQuestionText(q?.left?.['Question Code'], q?.left?.Question)}
                        <span>{q?.left?.['Is Required'] === '1' && ' *'}</span>
                      </p>
                      <div className='cp-form__group'>
                        {q.left.Answers.map((a) => {
                          return (
                            <div className='cp-form__group__item' key={a.id}>
                              <CPInput
                                inputName={`input-radio-${a.id}`}
                                isRectangle={true}
                                inputType={TYPE_CHECKBOX}
                                inputId={`${componentName}-add-new-product-input-radio-${a.id}`}
                                labelData={a.fields.Answer.value}
                                layoutServiceData={a.fields}
                                questionCode={q.left['Question Code']}
                                questionType={q.left['Display Type']}
                                inputChange={onChangeSelect}
                                productId={newProduct?.ProductId || ''}
                                questionValue={onGetCorrespondingLSQuestion(q.left?.['Question Code'])?.Question}
                                isAddNew={true}
                                isChecked={
                                  newProduct.Questions.find((quest) => quest.QuestionCode == q.left?.['Question Code'])
                                    .Answer.split(', ')
                                    .includes(a.fields.Answer.value)
                                    ? true
                                    : false
                                }
                                answerId={a?.id}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {isOtherAnswer(q.left?.['Question Code'], newProduct?.Questions) &&
                      productsHaveOtherAnswer.find((val) => val.questionCode === q?.left?.['Question Code']) &&
                      newProduct?.ProductId
                        ? generateOtherInputField('left')
                        : productsHaveOtherAnswer.find(
                          (val) => val.productId === newProduct.ProductId && val.questionCode === q?.left?.['Question Code']
                        ) && generateOtherInputField('left')}
                    </div>
                  )}
                </div>
                <div className='cp-form__right'>
                  {onGetCorrespondingLSQuestion(q.right?.['Question Code'])?.['Display Type'] === 'Drop Down' ? (
                    <div className='form-group'>
                      <p className='cp-form__label'>
                        {generateQuestionText(q?.right?.['Question Code'], q?.right?.Question)}
                        <span>{q?.right?.['Is Required'] === '1' && '*'}</span>
                      </p>
                      <CPSelect
                        selectId='selectId'
                        onChangeSelect={(item) => {
                          item.questionCode = onGetCorrespondingLSQuestion(q.right?.['Question Code'])?.['Question Code'];

                          setUpSellProductSelected(item);
                        }}
                        options={upSellProducts}
                        selectPlaceholder={layoutData?.['Upsell Select Product Label']?.value}
                        questionCode={q?.right?.['Question Code']}
                        questionType='Add-New-Product-Interest'
                        isResetProductSelection={expandedProductId != 'New Product' ? true : false}
                        isDisabled={isDisableUpsell}
                      />
                    </div>
                  ) : (
                    <div className='form-group'>
                      <p
                        className={`cp-form__label ${
                          q?.right?.['Is Required'] === '1' &&
                          !newProduct.Questions.find((quest) => quest.QuestionCode == q?.right?.['Question Code'])?.Answer?.trim() &&
                          'cp-form__label-required'
                        }`}
                      >
                        {generateQuestionText(q?.right?.['Question Code'], q?.right?.Question)}
                        <span>{q?.right?.['Is Required'] === '1' && ' *'}</span>
                      </p>
                      <div className='cp-form__group cp-form__group--item-w-50'>
                        {q?.right?.Answers.map((a) => {
                          return (
                            <div className='cp-form__group__item' key={a.id}>
                              <CPInput
                                inputName={`input-radio-${a.id}`}
                                isRectangle={true}
                                inputType={TYPE_CHECKBOX}
                                inputId={`${componentName}-add-new-product-input-radio-${a.id}`}
                                labelData={a.fields.Answer.value}
                                layoutServiceData={a.fields}
                                questionCode={q.right['Question Code']}
                                questionType={q.right['Display Type']}
                                inputChange={onChangeSelect}
                                questionValue={onGetCorrespondingLSQuestion(q?.right?.['Question Code'])?.Question}
                                isAddNew={true}
                                isChecked={
                                  newProduct.Questions.find((quest) => quest.QuestionCode == q?.right?.['Question Code'])
                                    .Answer.split(', ')
                                    .includes(a.fields.Answer.value)
                                    ? true
                                    : false
                                }
                                productId={newProduct?.ProductId || ''}
                                answerId={a?.id}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {isOtherAnswer(q.right?.['Question Code'], newProduct?.Questions) &&
                      productsHaveOtherAnswer.find((val) => val.questionCode === q?.right?.['Question Code'])
                        ? generateOtherInputField('right')
                        : productsHaveOtherAnswer.find(
                          (val) => val.productId === newProduct.ProductId && val.questionCode === q?.right?.['Question Code']
                        ) && generateOtherInputField('right')}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className='cp-form__button cp-form__button--center'>
            <button
              className='cp-form__button__item btn btn-primary'
              onClick={() => {
                if (productsHaveOtherAnswer.filter((val) => val.productId
                  === newProduct?.ProductId
                  && val.otherText
                  === '').length > 0) {
                  setIsEnteredOtherInput(false);
                } else {
                  onSaveProduct('NewProduct');
                }
              }}
            >
              {saveLabel}
            </button>
          </div>
        </div>
      </CPAccordion>
      {options &&
        productInterest?.map((p) => {
          return (
            <CPAccordion
              title={<span className='accordion-header__title'>{productNameGenerate(p?.ProductId)}</span>}
              secondTitle={(
                Global.removeEmptyObj(p?.Questions)?.length && checkIsCaptured(p)
              ) || p.IsCaptured ? capturedLabel : <></>}
              key={p?.ProductId}
              expandedProductId={expandedProductId}
              tabName={p?.ProductId}
              setActiveTab={(id) => {
                setExpandedProductId(id);

                setProductIsBeingEdited(null);
              }}
              isDirtyAddNewAndEditProductInterest={clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest}
              showWarningUnsavePopup={() => setShowWarningUnsavePopup(true)}
              isHaveRemoveIcon={true}
              removeIcon={layoutData?.['Remove Product Icon']}
              onRemoveIconBtnClick={() => {
                setSelectedRemoveProductId(p.ProductId);

                setShowConfirmRemoveProductPopup(true);
              }}
            >
              {checkIsCaptured(p) && !productIsBeingEdited ? capturedLabel : <></>}
              <div className='product-interest__form cp-form'>
                <div className='cp-form__wrap'>
                  <div className='cp-form__left'>
                    <div className='form-group'>
                      <span className='accordion-header__title'>{productNameGenerate(p?.ProductId)}</span>
                    </div>
                  </div>
                  <div className='cp-form__right'>
                    <div className='form-group cp-form__checkbox w-60'>
                      <div className='osim-checkbox'>
                        {productIsBeingEdited == p.ProductId ? (
                          <>
                            <input
                              type='checkbox'
                              id='cp-checkbox-1'
                              className='osim-checkbox-input'
                              // checked={p?.Questions?.some?.((quest) => quest?.IsPosRelated)}
                              checked={p?.IsPos}
                              defaultChecked={p?.IsPos}
                            />
                            <label
                              className='osim-checkbox-label'
                              htmlFor='cp-checkbox-1'
                              onClick={() => {
                                setShowWarningPOSPopup('CurrentProduct');

                                setIsPos({
                                  productId: p.ProductId,
                                  isPos: !p.IsPos
                                });

                                setProductIsEditedQuestions({
                                  questionsArr: p?.Questions?.some?.((quest) => quest?.IsPosRelated)
                                    ? layoutQuestionsList?.filter(
                                      (quest) => quest['Question Type'] == 'Non-POS' || quest['Question Type'] == 'Both'
                                    )
                                    : layoutQuestionsList?.filter((quest) => quest['Question Type'] == 'POS' || quest['Question Type'] == 'Both'),
                                  productId: p.ProductId
                                });
                              }}
                            >
                              {posLabel}
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              type='checkbox'
                              id='cp-checkbox-1'
                              className='osim-checkbox-input'
                              // checked={p?.Questions?.some?.((quest) => quest?.IsPosRelated)}
                              checked={p?.IsPos}
                              readOnly
                            />
                            <label className='osim-checkbox-label' htmlFor='cp-checkbox-1'>
                              {posLabel}
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {generateQuestion(p?.Questions)?.map((q, iq) => {
                  const checkOtherCheckBox = (questionSide) => {
                    const productQuestionType = `CPCustomerInfo-${onGetCorrespondingLSQuestion(q?.[`${questionSide}`]?.QuestionCode)?.['Display Type']}`;

                    return productQuestionType === QUESTION_TYPE
                      ? isOtherAnswer(q?.[questionSide]?.QuestionCode, p?.Questions)
                      : ANSWER_TO_CHECK.some((item) =>
                          p?.Questions.filter(
                            (quest) => quest?.QuestionCode === onGetCorrespondingLSQuestion(q?.[`${questionSide}`]?.QuestionCode)?.['Question Code']
                          )?.[0]?.Answer?.includes(item)
                      );
                  };

                  return (
                    <div className='cp-form__wrap' key={iq}>
                      <div className='cp-form__left'>
                        {onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Display Type'] === 'Drop Down' ? (
                          <div className='form-group'>
                            <p className='cp-form__label'>
                              {generateQuestionText(q?.left?.QuestionCode, q?.left?.Question)} <span>{q?.left?.IsRequired && '*'}</span>
                            </p>
                            {productIsBeingEdited === p.ProductId ? (
                              <CPSelect
                                selectId='selectId'
                                onChangeSelect={(item) => {
                                  item.questionCode = onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Question Code'];

                                  setUpSellProductSelected(item);
                                }}
                                options={upSellProducts}
                                selectPlaceholder={layoutData?.['Upsell Select Product Label']?.value}
                                questionCode={q?.left?.QuestionCode}
                                questionType={QUESTION_TYPE_EDIT_UPSELL}
                                isResetProductSelection={expandedProductId != 'New Product' ? true : false}
                                defaultValue={defaultUpsellSelectValue(q?.left?.AnswerId)}
                                isDisabled={isDisableUpsell}
                              />
                            ) : (
                              // <span className='cp-form__value-text'>{q.left.AnswerId}</span>
                              <span className='cp-form__value-text'>
                                {generateAnswerValueText(q?.left?.QuestionCode, q?.left?.AnswerId, q?.left?.Answer)}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className='form-group'>
                            <p
                              className={`cp-form__label ${
                                q?.left?.IsRequired &&
                                productIsBeingEdited === p.ProductId &&
                                !p?.Questions.filter(
                                  (quest) => quest?.QuestionCode == onGetCorrespondingLSQuestion(q?.left?.QuestionCode)?.['Question Code']
                                )?.[0]?.Answer?.trim() &&
                                'cp-form__label-required'
                              }`}
                            >
                              {generateQuestionText(q?.left?.QuestionCode, q?.left?.Question)}
                              <span>{q?.left?.IsRequired && ' *'}</span>
                            </p>
                            {productIsBeingEdited == p.ProductId ? (
                              <>
                                <div className='cp-form__group'>
                                  {onGetCorrespondingLSQuestion(q.left.QuestionCode)?.Answers.map((a, ia) => {
                                    return (
                                      <div className='cp-form__group__item' key={ia}>
                                        <CPInput
                                          inputName={
                                            onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Display Type'] == 'Single Select'
                                              ? `input-radio-${onGetCorrespondingLSQuestion(q.left.QuestionCode)?.Question}`
                                              : `input-radio-${a.id}`
                                          }
                                          isRectangle={true}
                                          inputType={
                                            onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Display Type'] == 'Single Select'
                                              ? TYPE_RADIO
                                              : onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Display Type'] == 'Multi Select'
                                                ? TYPE_CHECKBOX
                                                : ''
                                          }
                                          inputId={`${componentName}-current-product-input-radio-${a.id}`}
                                          labelData={a.fields.Answer.value}
                                          layoutServiceData={a.fields}
                                          inputChange={onChangeSelect}
                                          questionCode={onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Question Code']}
                                          questionType={onGetCorrespondingLSQuestion(q.left.QuestionCode)?.['Display Type']}
                                          questionValue={onGetCorrespondingLSQuestion(q.left.QuestionCode)?.Question}
                                          isChecked={
                                            Boolean(a?.displayName !== ANSWER_OTHER_DISPLAY_NAME
                                              ? p?.Questions.filter(
                                                (quest) =>
                                                    quest?.QuestionCode == onGetCorrespondingLSQuestion(q?.left?.QuestionCode)?.['Question Code']
                                              )?.[0]
                                                ?.AnswerId?.split(', ')
                                                ?.includes(a?.id)
                                              : isOtherAnswer(q?.left?.QuestionCode, p?.Questions))
                                          }
                                          productId={p?.ProductId}
                                          isAddNew={false}
                                          answerId={a?.id}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                {(q?.left?.Answer?.includes(ANSWER_WITH_BRACKET_TYPE) ||
                                  q?.left?.Answer?.includes(ANSWER_TYPE) ||
                                  q?.left?.Answer?.includes(ANSWER_TYPE) ||
                                  isOtherAnswer(q?.left?.QuestionCode, p?.Questions)) &&
                                  productsHaveOtherAnswer.find(
                                    (val) => val.questionCode === q?.left?.QuestionCode && val.productId === p?.ProductId
                                  ) && (
                                  <div className='cp-input cp-form__group--item-mt-18'>
                                    <input
                                      type={TYPE_TEXT}
                                      placeholder={layoutData?.['Other Reason Label']?.value}
                                      onChange={(e) => onInputOtherReasonHandler(e, q?.left?.QuestionCode, p?.ProductId)}
                                      value={productsHaveOtherAnswer
                                        .find((val) => val.questionCode === q?.left?.QuestionCode && val.productId === p?.ProductId)
                                        ?.otherText.replaceAll(ANSWER_WITH_BRACKET_TYPE, '')}
                                    />
                                    {productsHaveOtherAnswer.filter(
                                      (val) =>
                                        val.productId === p?.ProductId && val.questionCode === q?.left?.QuestionCode && val.otherText?.trim() === ''
                                    )?.length > 0 &&
                                        !isEnteredOtherInput && (
                                        <Text tag='span' className='error-mess' field={layoutData['Require Other Reason Text']} />
                                      )}
                                  </div>
                                )}
                              </>
                            ) : (
                              // <span className='cp-form__value-text'>{q.left.AnswerId?.replaceAll(ANSWER_WITH_BRACKET_TYPE, '')}</span>
                              <span className='cp-form__value-text'>
                                {generateAnswerValueText(q?.left?.QuestionCode, q?.left?.AnswerId, q?.left?.Answer)}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className='cp-form__right'>
                        {onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Display Type'] === 'Drop Down' ? (
                          <div className='form-group'>
                            <p className='cp-form__label'>
                              {generateQuestionText(q?.right?.QuestionCode, q?.right?.Question)}
                              <span>{q?.right?.IsRequired && '*'}</span>
                            </p>
                            {productIsBeingEdited === p.ProductId ? (
                              <CPSelect
                                selectId='selectId'
                                onChangeSelect={(item) => {
                                  item.questionCode = onGetCorrespondingLSQuestion(q.right.QuestionCode)?.['Question Code'];

                                  setUpSellProductSelected(item);
                                }}
                                options={upSellProducts}
                                selectPlaceholder={layoutData?.['Upsell Select Product Label']?.value}
                                questionCode={q?.right?.QuestionCode}
                                questionType={QUESTION_TYPE_EDIT_UPSELL}
                                isResetProductSelection={expandedProductId != 'New Product' ? true : false}
                                defaultValue={defaultUpsellSelectValue(q?.right?.AnswerId)}
                                isDisabled={isDisableUpsell}
                              />
                            ) : (
                              // <span className='cp-form__value-text'>{q.left.AnswerId}</span>
                              <span className='cp-form__value-text'>
                                {generateAnswerValueText(q?.right?.QuestionCode, q?.right?.AnswerId, q?.right?.Answer)}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className='form-group'>
                            <p
                              className={`cp-form__label ${
                                q?.right?.IsRequired &&
                                productIsBeingEdited === p.ProductId &&
                                !p?.Questions.filter(
                                  (quest) => quest?.QuestionCode == onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Question Code']
                                )?.[0]?.Answer?.trim() &&
                                'cp-form__label-required'
                              }`}
                            >
                              {generateQuestionText(q?.right?.QuestionCode, q?.right?.Question)}
                              <span>{q?.right?.IsRequired && ' *'}</span>
                            </p>
                            {productIsBeingEdited == p.ProductId ? (
                              <>
                                <div className='cp-form__group'>
                                  {onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.Answers.map((a, ia) => {
                                    return (
                                      <div className='cp-form__group__item' key={ia}>
                                        <CPInput
                                          inputName={
                                            onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Display Type'] == 'Single Select'
                                              ? `input-radio-${onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.Question}`
                                              : `input-radio-${a.id}`
                                          }
                                          isRectangle={true}
                                          inputType={
                                            onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Display Type'] == 'Single Select'
                                              ? TYPE_RADIO
                                              : onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Display Type'] == 'Multi Select'
                                                ? TYPE_CHECKBOX
                                                : ''
                                          }
                                          inputId={`${componentName}-current-product-input-radio-${a.id}`}
                                          labelData={a.fields.Answer.value}
                                          layoutServiceData={a.fields}
                                          inputChange={onChangeSelect}
                                          questionCode={onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Question Code']}
                                          questionType={onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Display Type']}
                                          questionValue={onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.Question}
                                          isChecked={
                                            Boolean(a?.displayName !== ANSWER_OTHER_DISPLAY_NAME
                                              ? p?.Questions.filter(
                                                (quest) =>
                                                    quest?.QuestionCode == onGetCorrespondingLSQuestion(q?.right?.QuestionCode)?.['Question Code']
                                              )?.[0]
                                                ?.AnswerId?.split(', ')
                                                ?.includes(a?.id)
                                              : isOtherAnswer(q?.right?.QuestionCode, p?.Questions))
                                          }
                                          productId={p?.ProductId}
                                          isAddNew={false}
                                          answerId={a?.id}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                {(q?.right?.Answer?.includes(ANSWER_WITH_BRACKET_TYPE) ||
                                  q?.right?.Answer?.includes(ANSWER_TYPE) ||
                                  q?.right?.Answer?.includes(ANSWER_TYPE) ||
                                  isOtherAnswer(q?.right?.QuestionCode, p?.Questions)) &&
                                  productsHaveOtherAnswer.find(
                                    (val) => val.questionCode === q?.right?.QuestionCode && val.productId === p?.ProductId
                                  ) && (
                                  <div className='cp-input cp-form__group--item-mt-18'>
                                    <input
                                      type={TYPE_TEXT}
                                      placeholder={layoutData?.['Other Reason Label']?.value}
                                      onChange={(e) => onInputOtherReasonHandler(e, q?.right?.QuestionCode, p?.ProductId)}
                                      value={productsHaveOtherAnswer
                                        .find((val) => val.questionCode === q?.right?.QuestionCode && val.productId === p?.ProductId)
                                        ?.otherText.replaceAll(ANSWER_WITH_BRACKET_TYPE, '')}
                                    />
                                    {productsHaveOtherAnswer.filter(
                                      (val) =>
                                        val.productId === p?.ProductId &&
                                          val.questionCode === q?.right?.QuestionCode &&
                                          val.otherText?.trim() === ''
                                    )?.length > 0 &&
                                        !isEnteredOtherInput && (
                                        <Text tag='span' className='error-mess' field={layoutData['Require Other Reason Text']} />
                                      )}
                                  </div>
                                )}
                              </>
                            ) : (
                              // <span
                              // className='cp-form__value-text'>{q?.right?.AnswerId?.replaceAll(ANSWER_WITH_BRACKET_TYPE,
                              // '')}</span>
                              <span className='cp-form__value-text'>
                                {generateAnswerValueText(q?.right?.QuestionCode, q?.right?.AnswerId, q?.right?.Answer)}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {productIsBeingEdited == p.ProductId ? (
                  <div className='cp-form__button cp-form__button--center'>
                    <button
                      className='cp-form__button__item btn btn-primary'
                      onClick={() => {
                        if (productsHaveOtherAnswer.filter((val) => val.productId
                          === p?.ProductId
                          && val.otherText
                          === '').length > 0) {
                          setIsEnteredOtherInput(false);
                        } else {
                          onSaveProduct('CurrentProduct');
                        }
                      }}
                    >
                      {saveLabel}
                    </button>
                  </div>
                ) : (
                  <div className='cp-form__button cp-form__button--center'>
                    <span
                      className='cp-form__button__item os-btn-link'
                      onClick={() => {
                        setProductIsBeingEdited(p.ProductId);
                      }}
                    >
                      {editLabel}
                    </span>
                  </div>
                )}
              </div>
            </CPAccordion>
          );
        })}
      <ModalComponent
        cssClass='warning-popup'
        isShow={showWarningUnsavePopup}
        onCloseHandler={() => setShowWarningUnsavePopup(false)}
        titlePopup={layoutData['Warning Title'].value}
      >
        <div className='warning-popup__content'>
          <Text
            tag='h4'
            className='warning-popup__content__title'
            field={layoutData['Have Not Save Warning Message']}
          />
          <div className='warning-popup__content__btn'>
            <button
              type='button'
              onClick={() => setShowWarningUnsavePopup(false)}
              className='warning-popup__content__btn__item btn btn-primary'
            >
              {cancelLabel}
            </button>
            <span
              className='warning-popup__content__btn__item os-btn-link link-nomal'
              // onClick={() => {
              //   setShowWarningUnsavePopup(false);

              //   setProductIsBeingEdited(null);

              //   setExpandedProductId(null);

              //   dispatch(dirtyProductInterest(false));

              //   if (isNewProductBeingEdited) {
              //     if (newProduct.ProductId) {
              //       let object = deepCopy(clientTellingPortalCustomer.customerInformation);

              //       object.CPCustomerInterest.Products.push(newProduct);

              //       const params = {
              //         ContactID: object.ContactID,
              //         // Email: object['PDPA-email'] || ' ',
              //         // PhoneNumber: object['PDPA-phoneNumber'] || ' ',
              //         // PrefixPhoneNumber: object['PDPA-prefixNumber'].value || ' ',
              //         // DOB: object['PDPA-DOB'] || ' ',
              //         // PersonalInformation: {
              //         //   FirstName: object['PDPA-firstName'] || ' ',
              //         //   LastName: object['PDPA-lastName'] || ' ',
              //         //   Gender: object['Gender'].value || ' '
              //         // },
              //         // CPCustomerInfo: {
              //         //   MaritalStatus: object?.['MaritalStatus']?.value || ' ',
              //         //   Age: object?.['Age']?.value || ' ',
              //         //   JobNature: object?.['JobNature']?.value || ' ',
              //         //   HealthIssues: object?.['HealthIssues']?.value || ' ',
              //         //   HouseholdSize: object?.['HouseholdSize']?.value?.value || ' ',
              //         //   MassageOwnershipType: object?.['MassageOwnershipType']?.value || ' ',
              //         //   MassageOwnership: object?.['MassageOwnership'] || ' ',
              //         //   AllowDirectMail: object['PDPA-receiveDirectMailing'],
              //         //   AllowSms: object['PDPA-receiveSMS'],
              //         //   AllowEmail: object['PDPA-receiveMail'],
              //         //   PDPAAgreement: object['PDPA-agreement']
              //         // },
              //         // CPCustomerInterest: {
              //         //   Products: object.CPCustomerInterest.Products || []
              //         // }
              //         CPCustomerInterest: {
              //           Products:
              //             object?.CPCustomerInterest?.Products.map((product) => {
              //               return {
              //                 ...product,
              //                 IsCaptured: checkIsCaptured(product)
              //               };
              //             }) || ' '
              //         }
              //       };

              //       let file = new window.File(
              //         [clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl ||
              // DEFAULT_API_VALUE], 'signature' );

              //       let formData = new window.FormData();

              //       for (let keyParams in params) {
              //         if (keyParams == 'PersonalInformation') {
              //           for (let keyChild in params[keyParams]) {
              //             formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
              //           }
              //         } else if (keyParams == 'CPCustomerInfo') {
              //           for (let keyChild in params[keyParams]) {
              //             if (keyChild == 'SignatureImageUrl') {
              //               formData.append(`CPCustomerInfo[${keyChild}]`, file);
              //             } else {
              //               formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
              //             }
              //           }
              //         } else if (keyParams == 'CPCustomerInterest') {
              //           for (let keyChild in params[keyParams]) {
              //             params[keyParams][keyChild].forEach((item, index) => {
              //               for (let keyItem in item) {
              //                 if (keyItem == 'Questions') {
              //                   params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
              //                     for (let keyChildItem in childItem) {
              //                       formData.append(
              //                         `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
              //                         childItem[keyChildItem]
              //                       );
              //                     }
              //                   });
              //                 } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
              //               }
              //             });
              //           }
              //         } else {
              //           formData.append(keyParams, params[keyParams]);
              //         }
              //       }

              //       dispatch(updateCPCustomerContactInterest(formData));
              //     } else {
              //       setSelectedProductStatus(false);
              //     }
              //   } else {
              //     let formData = new window.FormData();

              //     let object = deepCopy(clientTellingPortalCustomer.customerInformation);

              //     object.CPCustomerInterest.Products.push(newProduct);

              //     const params = {
              //       ContactID: object.ContactID,
              //       // Email: object['PDPA-email'] || ' ',
              //       // PhoneNumber: object['PDPA-phoneNumber'] || ' ',
              //       // PrefixPhoneNumber: object['PDPA-prefixNumber'].value || ' ',
              //       // DOB: object['PDPA-DOB'] || ' ',
              //       // PersonalInformation: {
              //       //   FirstName: object['PDPA-firstName'] || ' ',
              //       //   LastName: object['PDPA-lastName'] || ' ',
              //       //   Gender: object['Gender'].value || ' '
              //       // },
              //       // CPCustomerInfo: {
              //       //   MaritalStatus: object?.['MaritalStatus']?.value || ' ',
              //       //   Age: object?.['Age']?.value || ' ',
              //       //   JobNature: object?.['JobNature']?.value || ' ',
              //       //   HealthIssues: object?.['HealthIssues']?.value || ' ',
              //       //   HouseholdSize: object?.['HouseholdSize']?.value?.value || ' ',
              //       //   MassageOwnershipType: object?.['MassageOwnershipType']?.value || ' ',
              //       //   MassageOwnership: object?.['MassageOwnership'] || ' ',
              //       //   AllowDirectMail: object['PDPA-receiveDirectMailing'],
              //       //   AllowSms: object['PDPA-receiveSMS'],
              //       //   AllowEmail: object['PDPA-receiveMail'],
              //       //   PDPAAgreement: object['PDPA-agreement']
              //       // },
              //       // CPCustomerInterest: {
              //       //   Products: object.CPCustomerInterest.Products || ' '
              //       // }
              //       CPCustomerInterest: {
              //         Products:
              //           object?.CPCustomerInterest?.Products?.map((item) => {
              //             return {
              //               ProductId: item?.ProductId,
              //               Questions: item?.Questions,
              //               IsCaptured: checkIsCaptured(item)
              //             };
              //           }) || ' '
              //       }
              //     };

              //     let file = new window.File(
              //       [clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.SignatureImageUrl || DEFAULT_API_VALUE],
              //       'signature'
              //     );

              //     for (let keyParams in params) {
              //       if (keyParams == 'PersonalInformation') {
              //         for (let keyChild in params[keyParams]) {
              //           formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
              //         }
              //       } else if (keyParams == 'CPCustomerInfo') {
              //         for (let keyChild in params[keyParams]) {
              //           if (keyChild == 'SignatureImageUrl') {
              //             formData.append(`CPCustomerInfo[${keyChild}]`, file);
              //           } else {
              //             formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
              //           }
              //         }
              //       } else if (keyParams == 'CPCustomerInterest') {
              //         for (let keyChild in params[keyParams]) {
              //           params[keyParams][keyChild].forEach((item, index) => {
              //             for (let keyItem in item) {
              //               if (keyItem == 'Questions') {
              //                 params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
              //                   for (let keyChildItem in childItem) {
              //                     formData.append(
              //                       `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
              //                       childItem[keyChildItem]
              //                     );
              //                   }
              //                 });
              //               } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
              //             }
              //           });
              //         }
              //       } else {
              //         formData.append(keyParams, params[keyParams]);
              //       }
              //     }

              //     dispatch(updateCPCustomerContactInterest(formData));
              //   }
              // }}
              onClick={() => {
                if (isNewProductBeingEdited) {
                  onSaveProduct('NewProduct');
                } else {
                  onSaveProduct('CurrentProduct');
                }
              }}
            >
              {saveLabel}
            </span>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        cssClass='warning-popup'
        isShow={showWarningPOSPopup ? true : false}
        onCloseHandler={() => setShowWarningPOSPopup(false)}
        titlePopup={layoutData['Warning Title'].value}
      >
        <div className='warning-popup__content'>
          <RichText field={layoutData['Warning Message']} />
          <div className='warning-popup__content__btn'>
            <button type='button' onClick={() => setShowWarningPOSPopup(false)} className='warning-popup__content__btn__item btn btn-primary'>
              <Text field={layoutData['No Button Text']} />
            </button>
            <span
              className='warning-popup__content__btn__item os-btn-link link-nomal'
              onClick={() => {
                setShowWarningPOSPopup(false);

                setProductHaveOthersAnswer([]);

                if (showWarningPOSPopup == 'CurrentProduct') {
                  dispatch(triggerPOSChangeQuestions({ ...productIsEditedQuestions, isPos: isPos.isPos }));
                } else {
                  setQuestionPOS(!questionPOS);
                }
              }}
            >
              <Text field={layoutData['Yes Button Text']} />
            </span>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        cssClass='warning-popup'
        isShow={showConfirmRemoveProductPopup}
        onCloseHandler={() => setShowConfirmRemoveProductPopup(false)}
        titlePopup={layoutData['Warning Title'].value}
      >
        <div className='warning-popup__content'>
          <Text tag='h4' className='warning-popup__content__title' field={layoutData?.['Remove Product Message']} />
          <div className='warning-popup__content__btn'>
            <button
              type='button'
              onClick={() => setShowConfirmRemoveProductPopup(false)}
              className='warning-popup__content__btn__item btn btn-primary'
            >
              <Text field={layoutData['No Button Text']} />
            </button>
            <span className='warning-popup__content__btn__item os-btn-link link-nomal' onClick={onRemoveProductInterestBtnClick}>
              <Text field={layoutData['Yes Button Text']} />
            </span>
          </div>
        </div>
      </ModalComponent>
      {clientTellingPortalCustomer?.isUpdateContactInterestLoading || clientTellingPortalCustomer.isRemoveProductInterestLoading ? (
        <LoadingMask parent={CPAccordion} />
      ) : (
        ''
      )}
    </div>
  );
};

CPAccordion.propTypes = {
  title: PropTypes.any,
  secondTitle: PropTypes.any,
  nameClass: PropTypes.string,
  children: PropTypes.any,
  expandedProductId: PropTypes.any,
  isDirtyAddNewAndEditProductInterest: PropTypes.bool,
  showWarningUnsavePopup: PropTypes.func,
  tabName: PropTypes.any,
  setActiveTab: PropTypes.any,
  isHaveRemoveIcon: PropTypes.any,
  removeIcon: PropTypes.any,
  onRemoveIconBtnClick: PropTypes.func
};

ProductInterestAccordion.propTypes = {
  layoutData: PropTypes.object,
  addNewProductLabel: PropTypes.object,
  saveLabel: PropTypes.object,
  capturedLabel: PropTypes.object,
  editLabel: PropTypes.object,
  cancelLabel: PropTypes.any,
  posLabel: PropTypes.object,
  componentName: PropTypes.any
};

export default ProductInterestAccordion;
