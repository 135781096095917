import SignleSignOnModel from '@components/SingleSignOn/models/SignleSignOnModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class LoginModel {
  constructor() {
    this['Login Introduction'] = new SitecoreTextModel();

    this['Login Email Label'] = new SitecoreTextModel();

    this['Login Button Label'] = new SitecoreTextModel();

    this['Register Button Text'] = new SitecoreTextModel();

    this['Login Tab Label'] = new SitecoreTextModel();

    this['Login Password Label'] = new SitecoreTextModel();

    this['Login Switch To Register Text'] = new SitecoreTextModel();

    this['Forgot Password Label'] = new SitecoreTextModel();

    this['Forgot Password Link'] = new SitecoreLinkModel();

    this['Facebook Login Settings'] = new SignleSignOnModel();

    this['Google Login Settings'] = new SignleSignOnModel();

    this['Products Link'] = new SitecoreLinkModel();

    this['You Won Apply Offer Button Link'] = new SitecoreLinkModel();

    this['Change Password Page Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facebook Login Settings')) {
      this['Facebook Login Settings'].getData(dataSource['Facebook Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Google Login Settings')) {
      this['Google Login Settings'].getData(dataSource['Google Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Introduction')) {
      this['Login Introduction'].getData(dataSource['Login Introduction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Email Label')) {
      this['Login Email Label'].getData(dataSource['Login Email Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Button Text')) {
      this['Register Button Text'].getData(dataSource['Register Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Button Label')) {
      this['Login Button Label'].getData(dataSource['Login Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Password Label')) {
      this['Login Password Label'].getData(dataSource['Login Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Tab Label')) {
      this['Login Tab Label'].getData(dataSource['Login Tab Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Switch To Register Text')) {
      this['Login Switch To Register Text'].getData(dataSource['Login Switch To Register Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Forgot Password Label')) {
      this['Forgot Password Label'].getData(dataSource['Forgot Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Forgot Password Link')) {
      this['Forgot Password Link'].getData(dataSource['Forgot Password Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products Link')) {
      this['Products Link'].getData(dataSource['Products Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'You Won Apply Offer Button Link')) {
      this['You Won Apply Offer Button Link'].getData(dataSource['You Won Apply Offer Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Password Page Link')) {
      this['Change Password Page Link'].getData(dataSource['Change Password Page Link']);
    }

    return this;
  }
}
