import { array, string } from 'prop-types';
import React, { useMemo } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import LoyaltyTableContent from '../LoyaltyTableContent/index';

const LoyaltyTable = ({ fieldArr, tierArr, btnViewMoreText }) => {
  const fieldArrConvertData = useMemo(() => {
    return fieldArr?.length && tierArr?.length ? fieldArr.filter((fieldItem) => tierArr.some((tierItem) => tierItem?.[fieldItem?.key])) : [];
  }, [fieldArr, tierArr]);

  return (
    <div className='card-deck loyalty-table__card-deck'>
      <div className='card card--desktop'>
        <div className='card-header'></div>
        <div className='card-body'>
          <ul className='card-body__list'>
            {fieldArrConvertData?.map((rowData, i) => (
              <li className='card-body__list__item no-bold' key={i}>
                <span>{rowData?.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {tierArr.map((tierData, idx) => {
        return (
          <div key={idx} className={`card card--${tierData?.tier?.value.split(' ')[0].toLowerCase()}`}>
            <div className='card-header'>
              <Image field={tierData?.icon} className='card-header__image' />
              <Text field={tierData?.tier} tag='h4' className='card-header__title' />
            </div>
            <LoyaltyTableContent btnViewMoreText={btnViewMoreText} fieldArr={fieldArrConvertData} tierData={tierData} />
          </div>
        );
      })}
    </div>
  );
};

LoyaltyTable.propTypes = {
  fieldArr: array,
  tierArr: array,
  btnViewMoreText: string
};

export default LoyaltyTable;
