import './productListing.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { getProductList } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import FilterPopup from '../FilterPopup';
import LoginRegisterModal from '../LoginRegisterModal';
import ProductListingModel from './model/ProductListingModel';
import ProductListingItem from './ProductListingItem';
import ProductListingShimmer from './ProductListingShimmer';

const ProductListing = (props) => {
  const [inputData, setInputData] = useState(null);
  const [viewType, setViewType] = useState('category-landing--list-view');
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);
  const [isShowCTAButton, setIsShowCTAButton] = useState(false);
  const categoryId = SitecoreContextFactoryService.getValueContextItem('itemId');
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const isGA4 = Global.getIsGA4();
  const categoryName = props?.productsList?.[0]?.CategoryName;
  const [compareLink, setCompareLink] = useState(null);
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
  const [urlLoginPage, setUrlLoginPage] = useState({});

  const compareProductLink = useMemo(() => {
    const href = `${inputData?.['Compare Link']?.value?.href}?categoryPageId={${categoryId?.replace(/[{}]/g,'')}}`;

    return {
      ...inputData?.['Compare Link'],
      value: {
        ...inputData?.['Compare Link']?.value,
        href: href
      }
    };
  }, [inputData?.['Compare Link'], categoryId]);

  useEffect(() => {
    const productListingModel = new ProductListingModel();

    setInputData(productListingModel.getData(deepCopy(props.fields || {})));
  }, []);

  useEffect(() => {
    if (inputData) dispatch(getProductList({ sortByPiv: inputData['Sort By PIV']?.value }));
  }, [inputData]);

  //Old Logic GA
  // useDidUpdateEffect(() => {
  //   // INF: Google analytics
  //   setIsShowCTAButton(props.productsList.length > 0 && !props.productsList.some((item) => item.IsRentalProduct));

  //   const params = {
  //     items: getItemList(props.productsList)
  //   };

  //   new GoogleAnalytic().ggGateWay('event', 'view_item_list', params);
  // }, [props.productsList]);

  //New Logic ON/OFF GA, GA4
  useDidUpdateEffect(() => {
    setIsShowCTAButton(props.productsList.length > 0 && !props.productsList.some((item) => item.IsRentalProduct));
    if (isGA4) {
      const paramsGA4 = {
        ecommerce: {
          items: Global.handleGA4ViewItems(props.productsList)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'view_item_list', paramsGA4);
    } else {
      const params = {
        items: getItemList(props.productsList)
      };

      new GoogleAnalytic().ggGateWay('event', 'view_item_list', params);
    }
  }, [props.productsList]);

  const getItemList = (list) => {
    let itemList = [];

    list.forEach((item, index) => {
      itemList.push({
        id: item?.ProductId,
        name: item?.ProductName,
        list_name: 'Search Results',
        brand: 'Osim',
        category: item.CategoryName,
        variant: item?.Variants?.[0]?.Color,
        list_position: index + 1,
        quantity: item?.Stock?.StockQuantity,
        price: item?.Price?.SellingPrice
      });
    });

    return itemList;
  };

  const onViewTypeHandler = (type) => {
    setViewType(type);
  };

  const onShowModalHandler = () => {
    setShow(true);
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  const handleClosePopupLogin = () => setIsShowLoginPopup(false);

  const handleOpenPopupLogin = (buyNowLink) => {
    setIsShowLoginPopup(true);
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${inputData?.['Login Button Link']?.value?.href}?from=${buyNowLink.value.href}`;

    setUrlLoginPage(tempLink);
  };

  return inputData && props.productsList && !props.isLoading ? (
    <div className={`category-landing ${isClientTelingUser ? 'category-landing__client-teling' : ''} animated-slow fadeIn delay-100`}>
      <input type='hidden' id='category-landing-page' value={categoryId} />
      <div className={`container ${isClientTelingUser ? 'tablet-container' : ''}`}>
        {isShowCTAButton && !isClientTelingUser ? (
          <div className='product-action'>
            <div className='row'>
              <div className='col-6 two-column-CTA-Button__col'>
                <Link field={compareProductLink} className='product-action__compare product-action__item two-column-CTA-Button__contents'>
                  <div className='two-column-CTA-Button__contents__image'>
                    {inputData['Compare Icon']?.value?.src.length ? (
                      <Image field={inputData['Compare Icon']} alt='compare icon' />
                    ) : (
                      <img alt='compare icon' src={require('@assets/images/icon-compare.png')} />
                    )}
                  </div>
                  {inputData['Compare Text'].value ? (
                    <Text field={inputData['Compare Text']} tag='span' className='two-column-CTA-Button__contents__title' />
                  ) : (
                    <Link className='compare-product__link' field={compareProductLink} />
                  )}
                </Link>
              </div>
              <div className='col-6 two-column-CTA-Button__col'>
                <span className='product-action__filter product-action__item two-column-CTA-Button__contents' onClick={onShowModalHandler}>
                  <div className='two-column-CTA-Button__contents__image'>
                    {inputData['Filter Icon']?.value?.src.length ? (
                      <Image field={inputData['Filter Icon']} alt='compare icon' />
                    ) : (
                      <img alt='filter icon' src={require('@assets/images/icon-filter.png')} />
                    )}
                  </div>
                  <Text field={inputData['Filter Text']} tag='span' className='two-column-CTA-Button__contents__title' />
                </span>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='osim-view-type'>
          <button
            className={`osim-view-type__button ${viewType === 'category-landing--list-view' ? 'activated' : ''}`}
            onClick={() => onViewTypeHandler('category-landing--list-view')}
          >
            <img src='https://i.ibb.co/kchdCgh/list.png' alt='list-view' />
          </button>
          <button
            className={`osim-view-type__button ${viewType === 'category-landing--grid-view' ? 'activated' : ''}`}
            onClick={() => onViewTypeHandler('category-landing--grid-view')}
          >
            <img src='https://i.ibb.co/znw6X0X/grid.png' alt='grid-view' />
          </button>
        </div>
        <div className={`category-landing--view ${viewType} ${isClientTelingUser ? '--cp-view' : ''}`}>
          {props.productsList?.length
            ? props.productsList.map((item, index) => {
              return (
                <ProductListingItem
                  key={item.ProductId}
                  dataFromLayout={inputData}
                  dataFromAPI={{
                    ...item,
                    itemListName: item.CategoryName
                  }}
                  indexGA4={index}
                  handleOpenPopupLogin={handleOpenPopupLogin}
                />
              );
            })
            : ''}
          {props.filterLastest && !props.isLoading && !props.productsList?.length ? (
            <span className='category-landing--view__no-product'>
              <Message messageCode='Msg_002.4' />
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      {props.facets && (props.productsList?.length || (!props.productsList?.length && props.filterLastest)) ? (
        <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} titlePopup={<Text field={inputData['Filter Title']} />}>
          <FilterPopup dataSources={inputData} dataFromAPI={props.facets} appplyFilterEvt={onCloseModalHandler} />
        </ModalComponent>
      ) : (
        ''
      )}
      {props.isLoading ? <LoadingMask parent={ProductListing} /> : ''}
      <LoginRegisterModal
        isShow={isShowLoginPopup}
        onCloseHandler={handleClosePopupLogin}
        dataLayout={inputData}
        urlLoginPage={urlLoginPage}
      />
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <ProductListingShimmer></ProductListingShimmer>
    </LoadingShimmer>
  );
};

ProductListing.propTypes = {
  fields: Proptypes.object,
  productsList: Proptypes.array,
  facets: Proptypes.any,
  isLoading: Proptypes.bool,
  filterLastest: Proptypes.object
};

const mapStateToProps = (state) => {
  return {
    productsList: state.productReducer.productListReducer.productList,
    facets: state.productReducer.productListReducer.facets,
    isLoading: state.productReducer.productListReducer.isLoading,
    filterLastest: state.productReducer.productListReducer.filterLastest
  };
};

export default connect(mapStateToProps)(ProductListing);
