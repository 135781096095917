import './CPPageHeader.scss';

import PropTypes, { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useSelector } from 'react-redux';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import CPFilterPopup from './CPFilterPopup';
import CPFilterPopupOrders from './CPFilterPopupOrders';

const CPPageHeader = ({
  type,
  title,
  description,
  taskListLabel = '',
  taskListLink = '',
  getDate,
  filterFields,
  onFilterSubmit,
  onEdit,
  onClose,
  isShowBackBtn = false,
  layoutData,
  onSetIsReset,
  btnDisabled,
  currentStoreId
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const appointmentDetail = useSelector((state) => state.cpAppointmentDetailReducer.appointment);
  const curDate = new Date(`${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`);

  // const getDatePicker = (date) => {
  //   setStartDate(date);

  //   getDate(date);
  // };

  const handleEdit = () => {
    if (onEdit) onEdit();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleBackPreviousPage = () => {
    window.location.replace(document.referrer);
  };

  const checkNumber = (number) => (number < 10 ? '0' + number : number);

  const customeDate = (date) => {
    if (date) {
      const datePicker = new Date(date);
      const year = datePicker.getFullYear();
      const month = checkNumber(datePicker.getMonth() + 1);
      const day = checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  };

  const handleReplaceParamDateFilter = (date) => {
    setStartDate(date);

    getDate(date);

    const url = new URL(window.location.href);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('date-filter', customeDate(date));

    window.history.replaceState(null, null, url);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const dateFilterParams = urlSearchParams.get('date-filter');

    if (dateFilterParams) {
      setStartDate(new Date(dateFilterParams));
    }
  }, []);

  const isDisabledButton = useMemo(() => {
    let isDisable = btnDisabled;
    if (appointmentDetail && Object.keys(appointmentDetail).length)
      isDisable = appointmentDetail?.LeadStatus === 1 || !appointmentDetail?.IsEnableEdit;

    return isDisable;
  }, [appointmentDetail, btnDisabled]);

  return (
    <div className='cp-page-header'>
      <div className='container tablet-container'>
        <div className='cp-page-header__wrap'>
          <div className='cp-page-header__left'>
            {isShowBackBtn && layoutData?.['Back Label']?.value ? (
              <button className='cp-page-header__btn-link btn btn-link' onClick={handleBackPreviousPage}>
                <Text field={layoutData?.['Back Label']} />
              </button>
            ) : (
              <></>
            )}
            <div className='cp-page-header__left-title'>
              {typeof title === 'object' ? (
                <Text className='cp-page-header__title' tag='h2' field={title} />
              ) : typeof title === 'string' ? (
                <Text className='cp-page-header__title' tag='h2' field={{ value: title }} />
              ) : (
                <></>
              )}
              {taskListLink?.value?.href ? (
                <div className='task-list_button'>
                  <strong>{'('}</strong>
                  <Link field={taskListLink} className='task-list_link'>
                    <Text tag='strong' field={taskListLabel} />
                  </Link>
                  <strong>{')'}</strong>
                </div>
              ) : (
                <></>
              )}
            </div>
            {description ? <Text className='cp-page-header__label' tag='p' field={description || { value: 'description' }} /> : <></>}
          </div>
          <div className='cp-page-header__right'>
            {type == 'date' ? (
              <div className='cp-page-header__time-select'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => handleReplaceParamDateFilter(date)}
                  format='dd MMM yyyy'
                  dayPlaceholder='dd'
                  monthPlaceholder='mm'
                  yearPlaceholder='yyyy'
                  value={startDate}
                  viewMode='months'
                  minViewMode='months'
                />
              </div>
            ) : (
              <></>
            )}
            {type == 'button' ? (
              <div className='cp-page-header__btn'>
                {onEdit ? (
                  <button
                    className={`btn btn-outline-primary 
                    ${
                      // appointmentDetail?.LeadStatus === 1 || new Date(appointmentDetail?.AppointmentDate).getTime() < curDate.getTime()
                      isDisabledButton ? 'disable' : ''
                    }`}
                    onClick={handleEdit}
                  >
                    {layoutData?.['Button Edit']?.value || layoutData?.['CP Cart Edit Order']?.value}
                  </button>
                ) : (
                  <a href='' className='btn btn-outline-primary'>
                    {layoutData?.['Button Edit']?.value || layoutData?.['CP Cart Edit Order']?.value}
                  </a>
                )}
              </div>
            ) : (
              <></>
            )}
            {type == 'close-icon' ? (
              <div className='cp-page-header__btn-close' onClick={handleClose}>
                <i className='cp-page-header__btn-close__icon icon-close'></i>
              </div>
            ) : (
              <></>
            )}
            {type == 'filter' && filterFields ? <CPFilterPopup filterFields={filterFields} onFilterSubmit={onFilterSubmit} /> : <></>}
            {type == 'filter-orders' && filterFields ? (
              <CPFilterPopupOrders
                filterFields={filterFields}
                onFilterSubmit={onFilterSubmit}
                onSetIsReset={onSetIsReset}
                currentStoreId={currentStoreId}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CPPageHeader.propTypes = {
  type: PropTypes.string,
  taskListLabel: PropTypes.object,
  taskListLink: PropTypes.object,
  title: PropTypes.any,
  description: PropTypes.any,
  getDate: PropTypes.any,
  filterFields: PropTypes.any,
  onFilterSubmit: PropTypes.any,
  onEdit: func,
  onClose: func,
  isShowBackBtn: PropTypes.bool,
  layoutData: PropTypes.object,
  onSetIsReset: PropTypes.any,
  btnDisabled: PropTypes.bool,
  currentStoreId: PropTypes.any
};

export default CPPageHeader;
